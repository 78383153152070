/* You can add global styles to this file, and also import other style files */
@media (max-width: 991.98px)

  .carrello-doppio .c-d
    background: #efeff2 !important
    margin-right: 10px !important
    border-radius: 4px !important
    padding: 8px 15px 8px 10px !important
    display: inline-block !important

  .imgU
    float: none !important
    margin-bottom: 30px !important

  .card
    padding: 20px !important

@import '~quill/dist/quill.core.css'
@import '~quill/dist/quill.bubble.css'
@import '~quill/dist/quill.snow.css'


