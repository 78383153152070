@charset "UTF-8"


@font-face
  font-family: 'AMPermanent'
  font-style: normal
  font-weight: 500
  src: url('/assets/font/AMPermanent-Book.woff')

@font-face
  font-family: 'AMPermanent'
  font-style: italic
  font-weight: 500
  src: url('/assets/font/AMPermanent-BookItalic.woff')

@font-face
  font-family: 'AMPermanent'
  font-style: normal
  font-weight: 600
  src: url('/assets/font/AMPermanent-Semibold.woff')

@font-face
  font-family: 'AMPermanent'
  font-style: italic
  font-weight: 600
  src: url('/assets/font/AMPermanent-SemiboldItalic.woff')



*
  box-sizing: border-box

  &::before, &::after
    box-sizing: border-box

html
  font-family: 'AMPermanent', sans-serif
  line-height: 1.15
  -webkit-text-size-adjust: 100%
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0)

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section
  display: block

body
  font-family: 'AMPermanent', sans-serif
  font-size: 13px
  font-weight: 400
  line-height: 24px
  margin: 0
  text-align: left
  background: #fff
  color: #000
  min-height: 100vh

[tabindex='-1']:focus:not(:focus-visible)
  outline: 0 !important

hr
  overflow: visible
  box-sizing: content-box
  height: 0

h1, h2, h3, h4, h5, h6
  margin-top: 0
  margin-bottom: .5rem

p
  font-size: 13px
  line-height: 23px
  font-weight: 400
  margin-bottom: 15px
  margin-top: 0

abbr
  &[title], &[data-original-title]
    cursor: help
    text-decoration: underline
    text-decoration: underline dotted
    border-bottom: 0
    -webkit-text-decoration: underline dotted
    text-decoration-skip-ink: none

address
  font-style: normal
  line-height: inherit
  margin-bottom: 1rem

ol, ul, dl
  margin-top: 0
  margin-bottom: 1rem

ol ol, ul ul, ol ul, ul ol
  margin-bottom: 0

dt
  font-weight: 600

dd
  margin-bottom: .5rem
  margin-left: 0

blockquote
  margin: 0 0 1rem

b, strong
  font-weight: bolder

small
  font-size: 80%

sub, sup
  font-size: 75%
  line-height: 0
  position: relative
  vertical-align: baseline

sub
  bottom: -.25em

sup
  top: -.5em

a
  text-decoration: none
  color:  #031d6d
  background-color: transparent

  &:hover
    text-decoration: none
    color: #233dd2

  &:not([href])
    text-decoration: none
    color: inherit

    &:hover
      text-decoration: none
      color: inherit

pre, code, kbd, samp
  font-size: 1em

pre
  overflow: auto
  margin-top: 0
  margin-bottom: 1rem

figure
  margin: 0 0 1rem

img
  vertical-align: middle
  border-style: none

svg
  overflow: hidden
  vertical-align: middle

table
  border-collapse: collapse

caption
  padding-top: 1rem
  padding-bottom: 1rem
  caption-side: bottom
  text-align: left
  color: #8898aa

th
  text-align: inherit

label
  display: inline-block
  margin-bottom: .5rem

button
  border-radius: 0

  &:focus
    outline: 1px dotted
    outline: 5px auto -webkit-focus-ring-color

.industry


input, button, select, optgroup, textarea
  font-family: inherit
  font-size: inherit
  line-height: inherit
  margin: 0

button, input
  overflow: visible

button
  text-transform: none

select
  text-transform: none
  word-wrap: normal

button, [type='button'], [type='reset'], [type='submit']
  -webkit-appearance: button

button:not(:disabled), [type='button']:not(:disabled), [type='reset']:not(:disabled), [type='submit']:not(:disabled)
  cursor: pointer

button::-moz-focus-inner, [type='button']::-moz-focus-inner, [type='reset']::-moz-focus-inner, [type='submit']::-moz-focus-inner
  padding: 0
  border-style: none

input
  &[type='radio'], &[type='checkbox']
    box-sizing: border-box
    padding: 0

  &[type='date'], &[type='time'], &[type='datetime-local'], &[type='month']
    -webkit-appearance: listbox

textarea
  overflow: auto
  resize: vertical

fieldset
  min-width: 0
  margin: 0
  padding: 0
  border: 0

legend
  font-size: 1.5rem
  line-height: inherit
  display: block
  width: 100%
  max-width: 100%
  margin-bottom: .5rem
  padding: 0
  white-space: normal
  color: inherit

.alt-font


@media (max-width: 1200px)
  legend
    font-size: calc(1.275rem + .3vw)

progress
  vertical-align: baseline

[type='number']
  &::-webkit-inner-spin-button, &::-webkit-outer-spin-button
    height: auto

[type='search']
  outline-offset: -2px
  -webkit-appearance: none

  &::-webkit-search-decoration
    -webkit-appearance: none

\::-webkit-file-upload-button
  font: inherit
  -webkit-appearance: button

output
  display: inline-block

summary
  display: list-item
  cursor: pointer

template
  display: none

[hidden]
  display: none !important

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6
  font-family: inherit
  font-weight: 500
  line-height: 1.5
  margin-bottom: .5rem
  color: #000

h1, .h1
  font-size: 1.625rem

@media (max-width: 1200px)
  h1, .h1
    font-size: calc(1.2875rem + .45vw)

h2, .h2
  font-size: 1.25rem

h3, .h3
  font-size: 1.0625rem

h4, .h4
  font-size: .9375rem

h5, .h5
  font-size: .8125rem

h6, .h6
  font-size: .625rem

.lead
  font-size: 1.25rem
  font-weight: 300

.display-1
  font-size: 3.3rem
  font-weight: 600
  line-height: 1.5

@media (max-width: 1200px)
  .display-1
    font-size: calc(1.455rem + 2.46vw)

.display-2
  font-size: 2.75rem
  font-weight: 600
  line-height: 1.5

@media (max-width: 1200px)
  .display-2
    font-size: calc(1.4rem + 1.8vw)

.display-3
  font-size: 2.1875rem
  font-weight: 600
  line-height: 1.5

@media (max-width: 1200px)
  .display-3
    font-size: calc(1.34375rem + 1.125vw)

.display-4
  font-size: 1.6275rem
  font-weight: 600
  line-height: 1.5

@media (max-width: 1200px)
  .display-4
    font-size: calc(1.28775rem + .453vw)

hr
  margin-top: 20px
  margin-bottom: 20px
  border: 0
  border-top: 1px solid rgba(0, 0, 0, 0.1)

small, .small
  font-size: 80%
  font-weight: 400

mark, .mark
  padding: .2em
  background-color: #fcf8e3

.list-unstyled, .list-inline
  padding-left: 0
  list-style: none

.list-inline-item
  display: inline-block

  &:not(:last-child)
    margin-right: .5rem

.initialism
  font-size: 90%
  text-transform: uppercase

.blockquote
  font-size: 1.25rem
  margin-bottom: 1rem

.blockquote-footer
  font-size: 80%
  display: block
  color: #8898aa

  &::before
    content: '\2014\00A0'

.img-fluid
  max-width: 100%
  height: auto

.img-thumbnail
  max-width: 100%
  height: auto
  padding: .25rem
  border: 1px solid #dee2e6
  border-radius: .375rem
  background-color: #f8f9fe
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.075)

.figure
  display: inline-block

.figure-img
  line-height: 1
  margin-bottom: .5rem

.figure-caption
  font-size: 90%
  color: #8898aa

code
  font-size: 87.5%
  word-wrap: break-word
  color: #f3a4b5

a > code
  color: inherit

kbd
  font-size: 87.5%
  padding: .2rem .4rem
  color: #fff
  border-radius: .25rem
  background-color: #212529
  box-shadow: inset 0 -0.1rem 0 rgba(0, 0, 0, 0.25)

  kbd
    font-size: 100%
    font-weight: 600
    padding: 0
    box-shadow: none

pre
  font-size: 87.5%
  display: block
  color: #212529

  code
    font-size: inherit
    word-break: normal
    color: inherit

.pre-scrollable
  overflow-y: scroll
  max-height: 340px

.container, .container-fluid, .container-sm, .container-md, .container-lg, .container-xl
  width: 100%
  margin-right: auto
  margin-left: auto
  padding-right: 15px
  padding-left: 15px

@media (min-width: 576px)
  .container
    max-width: 540px

@media (min-width: 768px)
  .container
    max-width: 720px

@media (min-width: 992px)
  .container
    max-width: 960px

@media (min-width: 1200px)
  .container
    max-width: 1140px

@media (min-width: 576px)
  .container, .container-sm
    max-width: 540px

@media (min-width: 768px)
  .container, .container-sm, .container-md
    max-width: 720px

@media (min-width: 992px)
  .container, .container-sm, .container-md, .container-lg
    max-width: 960px

@media (min-width: 1200px)
  .container, .container-sm, .container-md, .container-lg, .container-xl
    max-width: 1140px

.row
  display: flex
  margin-right: -15px
  margin-left: -15px
  flex-wrap: wrap

.no-gutters
  margin-right: 0
  margin-left: 0

  >
  .col, [class*='col-']
    padding-right: 0
    padding-left: 0

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl, .col-xl-auto
  position: relative
  width: 100%
  padding-right: 15px
  padding-left: 15px

.col
  max-width: 100%
  flex-basis: 0
  flex-grow: 1

.row-cols-1 > *
  max-width: 100%
  flex: 0 0 100%

.row-cols-2 > *
  max-width: 50%
  flex: 0 0 50%

.row-cols-3 > *
  max-width: 33.33333%
  flex: 0 0 33.33333%

.row-cols-4 > *
  max-width: 25%
  flex: 0 0 25%

.row-cols-5 > *
  max-width: 20%
  flex: 0 0 20%

.row-cols-6 > *
  max-width: 16.66667%
  flex: 0 0 16.66667%

.col-auto
  width: auto
  max-width: 100%
  flex: 0 0 auto

.col-1
  max-width: 8.33333%
  flex: 0 0 8.33333%

.col-2
  max-width: 16.66667%
  flex: 0 0 16.66667%

.col-3
  max-width: 25%
  flex: 0 0 25%

.col-4
  max-width: 33.33333%
  flex: 0 0 33.33333%

.col-5
  max-width: 41.66667%
  flex: 0 0 41.66667%

.col-6
  max-width: 50%
  flex: 0 0 50%

.col-7
  max-width: 58.33333%
  flex: 0 0 58.33333%

.col-8
  max-width: 66.66667%
  flex: 0 0 66.66667%

.col-9
  max-width: 75%
  flex: 0 0 75%

.col-10
  max-width: 83.33333%
  flex: 0 0 83.33333%

.col-11
  max-width: 91.66667%
  flex: 0 0 91.66667%

.col-12
  max-width: 100%
  flex: 0 0 100%

.order-first
  order: -1

.order-last
  order: 13

.order-0
  order: 0

.order-1
  order: 1

.order-2
  order: 2

.order-3
  order: 3

.order-4
  order: 4

.order-5
  order: 5

.order-6
  order: 6

.order-7
  order: 7

.order-8
  order: 8

.order-9
  order: 9

.order-10
  order: 10

.order-11
  order: 11

.order-12
  order: 12

.offset-1
  margin-left: 8.33333%

.offset-2
  margin-left: 16.66667%

.offset-3
  margin-left: 25%

.offset-4
  margin-left: 33.33333%

.offset-5
  margin-left: 41.66667%

.offset-6
  margin-left: 50%

.offset-7
  margin-left: 58.33333%

.offset-8
  margin-left: 66.66667%

.offset-9
  margin-left: 75%

.offset-10
  margin-left: 83.33333%

.offset-11
  margin-left: 91.66667%

@media (min-width: 576px)
  .col-sm
    max-width: 100%
    flex-basis: 0
    flex-grow: 1

  .row-cols-sm-1 > *
    max-width: 100%
    flex: 0 0 100%

  .row-cols-sm-2 > *
    max-width: 50%
    flex: 0 0 50%

  .row-cols-sm-3 > *
    max-width: 33.33333%
    flex: 0 0 33.33333%

  .row-cols-sm-4 > *
    max-width: 25%
    flex: 0 0 25%

  .row-cols-sm-5 > *
    max-width: 20%
    flex: 0 0 20%

  .row-cols-sm-6 > *
    max-width: 16.66667%
    flex: 0 0 16.66667%

  .col-sm-auto
    width: auto
    max-width: 100%
    flex: 0 0 auto

  .col-sm-1
    max-width: 8.33333%
    flex: 0 0 8.33333%

  .col-sm-2
    max-width: 16.66667%
    flex: 0 0 16.66667%

  .col-sm-3
    max-width: 25%
    flex: 0 0 25%

  .col-sm-4
    max-width: 33.33333%
    flex: 0 0 33.33333%

  .col-sm-5
    max-width: 41.66667%
    flex: 0 0 41.66667%

  .col-sm-6
    max-width: 50%
    flex: 0 0 50%

  .col-sm-7
    max-width: 58.33333%
    flex: 0 0 58.33333%

  .col-sm-8
    max-width: 66.66667%
    flex: 0 0 66.66667%

  .col-sm-9
    max-width: 75%
    flex: 0 0 75%

  .col-sm-10
    max-width: 83.33333%
    flex: 0 0 83.33333%

  .col-sm-11
    max-width: 91.66667%
    flex: 0 0 91.66667%

  .col-sm-12
    max-width: 100%
    flex: 0 0 100%

  .order-sm-first
    order: -1

  .order-sm-last
    order: 13

  .order-sm-0
    order: 0

  .order-sm-1
    order: 1

  .order-sm-2
    order: 2

  .order-sm-3
    order: 3

  .order-sm-4
    order: 4

  .order-sm-5
    order: 5

  .order-sm-6
    order: 6

  .order-sm-7
    order: 7

  .order-sm-8
    order: 8

  .order-sm-9
    order: 9

  .order-sm-10
    order: 10

  .order-sm-11
    order: 11

  .order-sm-12
    order: 12

  .offset-sm-0
    margin-left: 0

  .offset-sm-1
    margin-left: 8.33333%

  .offset-sm-2
    margin-left: 16.66667%

  .offset-sm-3
    margin-left: 25%

  .offset-sm-4
    margin-left: 33.33333%

  .offset-sm-5
    margin-left: 41.66667%

  .offset-sm-6
    margin-left: 50%

  .offset-sm-7
    margin-left: 58.33333%

  .offset-sm-8
    margin-left: 66.66667%

  .offset-sm-9
    margin-left: 75%

  .offset-sm-10
    margin-left: 83.33333%

  .offset-sm-11
    margin-left: 91.66667%

@media (min-width: 768px)
  .col-md
    max-width: 100%
    flex-basis: 0
    flex-grow: 1

  .row-cols-md-1 > *
    max-width: 100%
    flex: 0 0 100%

  .row-cols-md-2 > *
    max-width: 50%
    flex: 0 0 50%

  .row-cols-md-3 > *
    max-width: 33.33333%
    flex: 0 0 33.33333%

  .row-cols-md-4 > *
    max-width: 25%
    flex: 0 0 25%

  .row-cols-md-5 > *
    max-width: 20%
    flex: 0 0 20%

  .row-cols-md-6 > *
    max-width: 16.66667%
    flex: 0 0 16.66667%

  .col-md-auto
    width: auto
    max-width: 100%
    flex: 0 0 auto

  .col-md-1
    max-width: 8.33333%
    flex: 0 0 8.33333%

  .col-md-2
    max-width: 16.66667%
    flex: 0 0 16.66667%

  .col-md-3
    max-width: 25%
    flex: 0 0 25%

  .col-md-4
    max-width: 33.33333%
    flex: 0 0 33.33333%

  .col-md-5
    max-width: 41.66667%
    flex: 0 0 41.66667%

  .col-md-6
    max-width: 50%
    flex: 0 0 50%

  .col-md-7
    max-width: 58.33333%
    flex: 0 0 58.33333%

  .col-md-8
    max-width: 66.66667%
    flex: 0 0 66.66667%

  .col-md-9
    max-width: 75%
    flex: 0 0 75%

  .col-md-10
    max-width: 83.33333%
    flex: 0 0 83.33333%

  .col-md-11
    max-width: 91.66667%
    flex: 0 0 91.66667%

  .col-md-12
    max-width: 100%
    flex: 0 0 100%

  .order-md-first
    order: -1

  .order-md-last
    order: 13

  .order-md-0
    order: 0

  .order-md-1
    order: 1

  .order-md-2
    order: 2

  .order-md-3
    order: 3

  .order-md-4
    order: 4

  .order-md-5
    order: 5

  .order-md-6
    order: 6

  .order-md-7
    order: 7

  .order-md-8
    order: 8

  .order-md-9
    order: 9

  .order-md-10
    order: 10

  .order-md-11
    order: 11

  .order-md-12
    order: 12

  .offset-md-0
    margin-left: 0

  .offset-md-1
    margin-left: 8.33333%

  .offset-md-2
    margin-left: 16.66667%

  .offset-md-3
    margin-left: 25%

  .offset-md-4
    margin-left: 33.33333%

  .offset-md-5
    margin-left: 41.66667%

  .offset-md-6
    margin-left: 50%

  .offset-md-7
    margin-left: 58.33333%

  .offset-md-8
    margin-left: 66.66667%

  .offset-md-9
    margin-left: 75%

  .offset-md-10
    margin-left: 83.33333%

  .offset-md-11
    margin-left: 91.66667%

@media (min-width: 992px)
  .col-lg
    max-width: 100%
    flex-basis: 0
    flex-grow: 1

  .row-cols-lg-1 > *
    max-width: 100%
    flex: 0 0 100%

  .row-cols-lg-2 > *
    max-width: 50%
    flex: 0 0 50%

  .row-cols-lg-3 > *
    max-width: 33.33333%
    flex: 0 0 33.33333%

  .row-cols-lg-4 > *
    max-width: 25%
    flex: 0 0 25%

  .row-cols-lg-5 > *
    max-width: 20%
    flex: 0 0 20%

  .row-cols-lg-6 > *
    max-width: 16.66667%
    flex: 0 0 16.66667%

  .col-lg-auto
    width: auto
    max-width: 100%
    flex: 0 0 auto

  .col-lg-1
    max-width: 8.33333%
    flex: 0 0 8.33333%

  .col-lg-2
    max-width: 16.66667%
    flex: 0 0 16.66667%

  .col-lg-3
    max-width: 25%
    flex: 0 0 25%

  .col-lg-4
    max-width: 33.33333%
    flex: 0 0 33.33333%

  .col-lg-5
    max-width: 41.66667%
    flex: 0 0 41.66667%

  .col-lg-6
    max-width: 50%
    flex: 0 0 50%

  .col-lg-7
    max-width: 58.33333%
    flex: 0 0 58.33333%

  .col-lg-8
    max-width: 66.66667%
    flex: 0 0 66.66667%

  .col-lg-9
    max-width: 75%
    flex: 0 0 75%

  .col-lg-10
    max-width: 83.33333%
    flex: 0 0 83.33333%

  .col-lg-11
    max-width: 91.66667%
    flex: 0 0 91.66667%

  .col-lg-12
    max-width: 100%
    flex: 0 0 100%

  .order-lg-first
    order: -1

  .order-lg-last
    order: 13

  .order-lg-0
    order: 0

  .order-lg-1
    order: 1

  .order-lg-2
    order: 2

  .order-lg-3
    order: 3

  .order-lg-4
    order: 4

  .order-lg-5
    order: 5

  .order-lg-6
    order: 6

  .order-lg-7
    order: 7

  .order-lg-8
    order: 8

  .order-lg-9
    order: 9

  .order-lg-10
    order: 10

  .order-lg-11
    order: 11

  .order-lg-12
    order: 12

  .offset-lg-0
    margin-left: 0

  .offset-lg-1
    margin-left: 8.33333%

  .offset-lg-2
    margin-left: 16.66667%

  .offset-lg-3
    margin-left: 25%

  .offset-lg-4
    margin-left: 33.33333%

  .offset-lg-5
    margin-left: 41.66667%

  .offset-lg-6
    margin-left: 50%

  .offset-lg-7
    margin-left: 58.33333%

  .offset-lg-8
    margin-left: 66.66667%

  .offset-lg-9
    margin-left: 75%

  .offset-lg-10
    margin-left: 83.33333%

  .offset-lg-11
    margin-left: 91.66667%

@media (min-width: 1200px)
  .col-xl
    max-width: 100%
    flex-basis: 0
    flex-grow: 1

  .row-cols-xl-1 > *
    max-width: 100%
    flex: 0 0 100%

  .row-cols-xl-2 > *
    max-width: 50%
    flex: 0 0 50%

  .row-cols-xl-3 > *
    max-width: 33.33333%
    flex: 0 0 33.33333%

  .row-cols-xl-4 > *
    max-width: 25%
    flex: 0 0 25%

  .row-cols-xl-5 > *
    max-width: 20%
    flex: 0 0 20%

  .row-cols-xl-6 > *
    max-width: 16.66667%
    flex: 0 0 16.66667%

  .col-xl-auto
    width: auto
    max-width: 100%
    flex: 0 0 auto

  .col-xl-1
    max-width: 8.33333%
    flex: 0 0 8.33333%

  .col-xl-2
    max-width: 16.66667%
    flex: 0 0 16.66667%

  .col-xl-3
    max-width: 25%
    flex: 0 0 25%

  .col-xl-4
    max-width: 33.33333%
    flex: 0 0 33.33333%

  .col-xl-5
    max-width: 41.66667%
    flex: 0 0 41.66667%

  .col-xl-6
    max-width: 50%
    flex: 0 0 50%

  .col-xl-7
    max-width: 58.33333%
    flex: 0 0 58.33333%

  .col-xl-8
    max-width: 66.66667%
    flex: 0 0 66.66667%

  .col-xl-9
    max-width: 75%
    flex: 0 0 75%

  .col-xl-10
    max-width: 83.33333%
    flex: 0 0 83.33333%

  .col-xl-11
    max-width: 91.66667%
    flex: 0 0 91.66667%

  .col-xl-12
    max-width: 100%
    flex: 0 0 100%

  .order-xl-first
    order: -1

  .order-xl-last
    order: 13

  .order-xl-0
    order: 0

  .order-xl-1
    order: 1

  .order-xl-2
    order: 2

  .order-xl-3
    order: 3

  .order-xl-4
    order: 4

  .order-xl-5
    order: 5

  .order-xl-6
    order: 6

  .order-xl-7
    order: 7

  .order-xl-8
    order: 8

  .order-xl-9
    order: 9

  .order-xl-10
    order: 10

  .order-xl-11
    order: 11

  .order-xl-12
    order: 12

  .offset-xl-0
    margin-left: 0

  .offset-xl-1
    margin-left: 8.33333%

  .offset-xl-2
    margin-left: 16.66667%

  .offset-xl-3
    margin-left: 25%

  .offset-xl-4
    margin-left: 33.33333%

  .offset-xl-5
    margin-left: 41.66667%

  .offset-xl-6
    margin-left: 50%

  .offset-xl-7
    margin-left: 58.33333%

  .offset-xl-8
    margin-left: 66.66667%

  .offset-xl-9
    margin-left: 75%

  .offset-xl-10
    margin-left: 83.33333%

  .offset-xl-11
    margin-left: 91.66667%

.table
  width: 100%
  margin-bottom: 1rem
  color: #000
  background-color: transparent

  th, td
    padding: 10px
    vertical-align: middle
    border-top: 1px solid #e9ecef
    margin: 0

  thead th
    vertical-align: bottom
    border-bottom: 2px solid #e9ecef

  tbody + tbody
    border-top: 2px solid #e9ecef

.table-sm
  th, td
    padding: .5rem

.table-bordered
  border: 1px solid #e9ecef
  border-radius: 5px
  overflow: hidden
  text-align: center

  th, td
    border: 1px solid #e9ecef

  thead
    th, td
      border-bottom-width: 2px

.table-borderless
  th, td, thead th, tbody + tbody
    border: 0

.table-striped.table-elenco-r tbody tr:nth-of-type(odd)
  background: #ffffff
  --bs-table-accent-bg: white


  td
    background: #fff
.table-striped>tbody>tr:nth-of-type(odd)>*
  --bs-table-accent-bg: rgb(255 255 255 / 5%)
  color: var(--bs-table-striped-color)

.table-hover tbody tr:hover
  color: #525f7f
  background-color: #f6f9fc

.table-primary
  background-color: #d2d8f7

  >
  th, td
    background-color: #d2d8f7

  th, td, thead th, tbody + tbody
    border-color: #abb6f1

.table-hover .table-primary:hover
  background-color: #bcc5f3

  >
  td, th
    background-color: #bcc5f3

.table-secondary
  background-color: #fdfefe

  >
  th, td
    background-color: #fdfefe

  th, td, thead th, tbody + tbody
    border-color: #fbfcfd

.table-hover .table-secondary:hover
  background-color: #ecf6f6

  >
  td, th
    background-color: #ecf6f6

.table-success
  background-color: #c4f1de

  >
  th, td
    background-color: #c4f1de

  th, td, thead th, tbody + tbody
    border-color: #92e6c2

.table-hover .table-success:hover
  background-color: #afecd2

  >
  td, th
    background-color: #afecd2

.table-info
  background-color: #bcf1fb

  >
  th, td
    background-color: #bcf1fb

  th, td, thead th, tbody + tbody
    border-color: #83e5f7

.table-hover .table-info:hover
  background-color: #a4ecfa

  >
  td, th
    background-color: #a4ecfa

.table-warning
  background-color: #fed3ca

  >
  th, td
    background-color: #fed3ca

  th, td, thead th, tbody + tbody
    border-color: #fdae9c

.table-hover .table-warning:hover
  background-color: #febeb1

  >
  td, th
    background-color: #febeb1

.table-danger
  background-color: #fcc7d1

  >
  th, td
    background-color: #fcc7d1

  th, td, thead th, tbody + tbody
    border-color: #fa96aa

.table-hover .table-danger:hover
  background-color: #fbafbd

  >
  td, th
    background-color: #fbafbd

.table-light
  background-color: #e8eaed

  >
  th, td
    background-color: #e8eaed

  th, td, thead th, tbody + tbody
    border-color: #d4d9dd

.table-hover .table-light:hover
  background-color: #dadde2

  >
  td, th
    background-color: #dadde2

.table-dark
  background-color: #c1c2c3

  >
  th, td
    background-color: #c1c2c3

  th, td, thead th, tbody + tbody
    border-color: #8c8e90

.table-hover .table-dark:hover
  background-color: #b4b5b6

  >
  td, th
    background-color: #b4b5b6

.table-default
  background-color: #bec4cd

  >
  th, td
    background-color: #bec4cd

  th, td, thead th, tbody + tbody
    border-color: #8691a2

.table-hover .table-default:hover
  background-color: #b0b7c2

  >
  td, th
    background-color: #b0b7c2

.table-white
  background-color: white

  >
  th, td
    background-color: white

  th, td, thead th, tbody + tbody
    border-color: white

.table-hover .table-white:hover
  background-color: #f2f2f2

  >
  td, th
    background-color: #f2f2f2

.table-neutral
  background-color: white

  >
  th, td
    background-color: white

  th, td, thead th, tbody + tbody
    border-color: white

.table-hover .table-neutral:hover
  background-color: #f2f2f2

  >
  td, th
    background-color: #f2f2f2

.table-darker
  background-color: #b8b8b8

  >
  th, td
    background-color: #b8b8b8

  th, td, thead th, tbody + tbody
    border-color: #7a7a7a

.table-hover .table-darker:hover
  background-color: #ababab

  >
  td, th
    background-color: #ababab

.table-active
  background-color: #f6f9fc

  >
  th, td
    background-color: #f6f9fc

.table-hover .table-active:hover
  background-color: #e3ecf6

  >
  td, th
    background-color: #e3ecf6

.table
  .thead-dark th
    color: #f8f9fe
    border-color: #1f3a68
    background-color: #172b4d

  .thead-light th
    color: #8898aa
    border-color: #e9ecef
    background-color: #f6f9fc

.table-dark
  color: #f8f9fe
  background-color: #172b4d

  th, td, thead th
    border-color: #1f3a68

  &.table-bordered
    border: 0

  &.table-striped tbody tr:nth-of-type(odd)
    background-color: rgba(255, 255, 255, 0.05)

  &.table-hover tbody tr:hover
    color: #f8f9fe
    background-color: rgba(255, 255, 255, 0.075)

@media (max-width: 575.98px)
  .table-responsive-sm
    display: block
    overflow-x: auto
    width: 100%
    -webkit-overflow-scrolling: touch

    > .table-bordered
      border: 0

@media (max-width: 767.98px)
  .info-prod
    position: relative !important
    top: -15px !important
    right: auto !important
    display: block !important

  span.type
    font-size: 11px !important

  .bottoni-dettagli
    position: relative !important
    bottom: 0 !important
    right: auto !important

  .second-level
    display: inline-block !important

  button.btn.btn-primary.mod-art
    position: relative !important
    top: -12px !important
    right: auto !important
    padding: 10px 30px !important
    font-size: 11px !important
    margin: 0 auto
    display: block

  .height-100vh
    height: auto !important

  a.crea-account
    bottom: -110px !important

  .xs-display-none
    display: none

  .login-form
    position: relative !important
    width: 80% !important
    top: 30px !important

  .card-body-scelta
    width: 90% !important
    padding: 70px 30px !important

  .table-responsive-md
    display: block
    overflow-x: auto
    width: 100%
    -webkit-overflow-scrolling: touch

    > .table-bordered
      border: 0

@media (max-width: 991.98px)
  app-item-list-brand
    flex-basis: 100% !important

  .navbar-dark .navbar-nav .nav-link b
    display: none

  .logo-cont.logo-header
    display: block !important


  .logo-cont.logo-header img
    width: 100px
    margin: 0 auto
    display: block

  .navbar-dark .navbar-nav .nav-link b
    display: none

  .card-b-e-box- button
    width: auto !important

  .img-cart
    float: right !important
    margin: 0 0 0 10px !important
    width: 35px !important
    height: 35px !important

  .table-responsive-lg
    display: block
    overflow-x: auto
    width: 100%
    -webkit-overflow-scrolling: touch

    > .table-bordered
      border: 0


@media (max-width: 1199.98px)
  .table-responsive-xl
    display: block
    overflow-x: auto
    width: 100%
    -webkit-overflow-scrolling: touch

    > .table-bordered
      border: 0

.table-responsive
  display: block
  overflow-x: auto
  width: 100%
  -webkit-overflow-scrolling: touch

  > .table-bordered
    border: 0

.form-control
  font-size: 0.875rem
  font-weight: 400
  line-height: 1.5
  display: block
  width: 100%
  padding: 10px
  transition: all 0.15s cubic-bezier(0.68, -0.55, 0.265, 1.55)
  color: #000
  border: 1px solid #000000
  border-radius: 5px
  background-color: #ffffff
  background-clip: padding-box
  box-shadow: none
  height: 40px !important

  &::-ms-expand
    border: 0
    background-color: transparent

  &:-moz-focusring
    color: transparent
    text-shadow: 0 0 0 #8898aa

  &:focus, &:active
    color: #000
    outline: 0
    background-color: #fff
    border-color: black
    box-shadow: none

  &::-ms-input-placeholder, &::placeholder
    opacity: 1
    color: #adb5bd

  &:disabled, &[readonly]
    opacity: 1
    background-color: #e9ecef

@media (prefers-reduced-motion: reduce)
  .form-control
    transition: none

select.form-control:focus::-ms-value
  color: #8898aa
  background-color: #fff

.form-control-file, .form-control-range
  display: block
  width: 100%

.col-form-label
  font-size: inherit
  line-height: 1.5
  margin-bottom: 0
  padding-top: calc(.625rem + 1px)
  padding-bottom: calc(.625rem + 1px)

.col-form-label-lg
  font-size: .875rem
  line-height: 1.5
  padding-top: calc(.875rem + 1px)
  padding-bottom: calc(.875rem + 1px)

.col-form-label-sm
  font-size: .75rem
  line-height: 1.5
  padding-top: calc(.25rem + 1px)
  padding-bottom: calc(.25rem + 1px)

.form-control-plaintext
  font-size: .875rem
  line-height: 1.5
  display: block
  width: 100%
  margin-bottom: 0
  padding: .625rem 0
  color: #525f7f
  border: solid transparent
  border-width: 1px 0
  background-color: transparent

  &.form-control-sm, &.form-control-lg
    padding-right: 0
    padding-left: 0

.form-control-sm
  font-size: .75rem
  line-height: 1.5
  height: calc(1.5em + .5rem + 2px)
  padding: .25rem .5rem
  border-radius: .25rem

.form-control-lg
  font-size: .875rem
  line-height: 1.5
  height: calc(1.5em + 1.75rem + 2px)
  padding: .875rem 1rem
  border-radius: .4375rem

select.form-control
  &[size], &[multiple]
    height: auto

textarea.form-control
  height: auto

.form-group
  margin-bottom: 1.5rem

.form-text
  display: block
  margin-top: .25rem

.form-row
  display: flex
  margin-right: -5px
  margin-left: -5px
  flex-wrap: wrap

  >
  .col, [class*='col-']
    padding-right: 5px
    padding-left: 5px

.form-check
  position: relative
  display: block
  padding-left: 1.25rem

.form-check-input
  position: absolute
  margin-top: .3rem
  margin-left: -1.25rem

  &[disabled] ~ .form-check-label, &:disabled ~ .form-check-label
    color: #8898aa

.form-check-label
  margin-bottom: 0

.form-check-inline
  display: inline-flex
  margin-right: .75rem
  padding-left: 0
  align-items: center

  .form-check-input
    position: static
    margin-top: 0
    margin-right: .3125rem
    margin-left: 0

.valid-feedback
  font-size: 80%
  display: none
  width: 100%
  margin-top: .25rem
  color: #2dce89

.valid-tooltip
  font-size: .875rem
  line-height: 1
  position: absolute
  z-index: 5
  top: 100%
  display: none
  max-width: 100%
  margin-top: .1rem
  padding: .5rem
  color: #fff
  border-radius: .2rem
  background-color: rgba(45, 206, 137, 0.8)

.was-validated .form-control:valid, .form-control.is-valid, .was-validated .custom-select:valid, .custom-select.is-valid, .was-validated .form-control:valid:focus, .form-control.is-valid:focus, .was-validated .custom-select:valid:focus, .custom-select.is-valid:focus
  border-color: #2dce89

.was-validated .form-control:valid ~
.valid-feedback, .valid-tooltip
  display: block

.form-control.is-valid ~
.valid-feedback, .valid-tooltip
  display: block

.was-validated .custom-select:valid ~
.valid-feedback, .valid-tooltip
  display: block

.custom-select.is-valid ~
.valid-feedback, .valid-tooltip
  display: block

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label
  color: #2dce89

.was-validated .form-check-input:valid ~
.valid-feedback, .valid-tooltip
  display: block

.form-check-input.is-valid ~
.valid-feedback, .valid-tooltip
  display: block

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label
  color: #2dce89

.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before
  border-color: #93e7c3
  background-color: #93e7c3

.was-validated .custom-control-input:valid ~
.valid-feedback, .valid-tooltip
  display: block

.custom-control-input.is-valid ~
.valid-feedback, .valid-tooltip
  display: block

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before
  border-color: #93e7c3
  background-color: #54daa1

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before
  box-shadow: 0 0 0 1px #f8f9fe, 0 0 0 0 rgba(45, 206, 137, 0.25)

.custom-file-input
  width: 60% !important
  margin: 8px !important
  opacity: 1 !important

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label
  border-color: #2dce89

.was-validated .custom-file-input:valid ~ .custom-file-label::before, .custom-file-input.is-valid ~ .custom-file-label::before
  border-color: inherit

.was-validated .custom-file-input:valid ~
.valid-feedback, .valid-tooltip
  display: block

.custom-file-input.is-valid ~
.valid-feedback, .valid-tooltip
  display: block

.was-validated .custom-file-input:valid:focus ~ .custom-file-label
  box-shadow: 0 0 0 0 rgba(45, 206, 137, 0.25)

.custom-file-input
  &.is-valid:focus ~ .custom-file-label
    box-shadow: 0 0 0 0 rgba(45, 206, 137, 0.25)

  &:focus
    outline: none

.invalid-feedback
  font-size: 80%
  display: none
  width: 100%
  margin-top: .25rem
  color: #fb6340

.invalid-tooltip
  font-size: .875rem
  line-height: 1
  position: absolute
  z-index: 5
  top: 100%
  display: none
  max-width: 100%
  margin-top: .1rem
  padding: .5rem
  color: #fff
  border-radius: .2rem
  background-color: rgba(251, 99, 64, 0.8)

.was-validated .form-control:invalid, .form-control.is-invalid, .was-validated .custom-select:invalid, .custom-select.is-invalid, .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus, .was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus
  border-color: #fb6340

.was-validated .form-control:invalid ~
.invalid-feedback, .invalid-tooltip
  display: block

.form-control.is-invalid ~
.invalid-feedback, .invalid-tooltip
  display: block

.was-validated .custom-select:invalid ~
.invalid-feedback, .invalid-tooltip
  display: block

.custom-select.is-invalid ~
.invalid-feedback, .invalid-tooltip
  display: block

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label
  color: #fb6340

.was-validated .form-check-input:invalid ~
.invalid-feedback, .invalid-tooltip
  display: block

.form-check-input.is-invalid ~
.invalid-feedback, .invalid-tooltip
  display: block

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label
  color: #fb6340

.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before
  border-color: #fec9bd
  background-color: #fec9bd

.was-validated .custom-control-input:invalid ~
.invalid-feedback, .invalid-tooltip
  display: block

.custom-control-input.is-invalid ~
.invalid-feedback, .invalid-tooltip
  display: block

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before
  border-color: #fec9bd
  background-color: #fc8c72

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before
  box-shadow: 0 0 0 1px #f8f9fe, 0 0 0 0 rgba(251, 99, 64, 0.25)

.custom-file-input
  width: 60% !important
  margin: 8px !important
  opacity: 1 !important

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label
  border-color: #fb6340

.was-validated .custom-file-input:invalid ~ .custom-file-label::before, .custom-file-input.is-invalid ~ .custom-file-label::before
  border-color: inherit

.was-validated .custom-file-input:invalid ~
.invalid-feedback, .invalid-tooltip
  display: block

.custom-file-input.is-invalid ~
.invalid-feedback, .invalid-tooltip
  display: block

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label
  box-shadow: 0 0 0 0 rgba(251, 99, 64, 0.25)

.custom-file-input
  &.is-invalid:focus ~ .custom-file-label
    box-shadow: 0 0 0 0 rgba(251, 99, 64, 0.25)

  &:focus
    outline: none

.form-inline
  display: flex
  flex-flow: row wrap
  align-items: center

  .form-check
    width: 100%

@media (min-width: 576px)
  .form-inline
    label
      display: flex
      margin-bottom: 0
      align-items: center
      justify-content: center

    .form-group
      display: flex
      margin-bottom: 0
      flex: 0 0 auto
      flex-flow: row wrap
      align-items: center

    .form-control
      display: inline-block
      width: auto
      vertical-align: middle

    .form-control-plaintext
      display: inline-block

    .input-group, .custom-select
      width: auto

    .form-check
      display: flex
      width: auto
      padding-left: 0
      align-items: center
      justify-content: center

    .form-check-input
      position: relative
      margin-top: 0
      margin-right: .25rem
      margin-left: 0
      flex-shrink: 0

    .custom-control
      align-items: center
      justify-content: center

    .custom-control-label
      margin-bottom: 0

.btn
  font-size: .875rem
  font-weight: 600
  line-height: 1.5
  display: inline-block
  padding: .625rem 1.25rem
  cursor: pointer
  -webkit-user-select: none
  -ms-user-select: none
  user-select: none
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
  text-align: center
  vertical-align: middle
  color: #000
  border: 1px solid transparent
  border-radius: .25rem
  background-color: transparent

  &:hover
    text-decoration: none
    color: #525f7f

  &:focus, &.focus
    outline: 0
    box-shadow: none

  &.disabled, &:disabled
    opacity: .65
    box-shadow: none

  &:not(:disabled):not(.disabled)
    &:active, &.active
      box-shadow: none

    &:active:focus, &.active:focus
      box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08)

@media (prefers-reduced-motion: reduce)
  .btn
    transition: none

a.btn.disabled, fieldset:disabled a.btn
  pointer-events: none

.btn-primary
  color: #fff
  border-color: black
  background-color: black
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08)
  border: 2px solid black

  &:hover
    color: black
    border-color: #ccc
    background-color: #ccc

  &:focus, &.focus
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08), 0 0 0 0 rgba(94, 114, 228, 0.5)

  &.disabled, &:disabled
    color: #fff
    border-color: #5e72e4
    background-color: #5e72e4

  &:not(:disabled):not(.disabled)
    &:active, &.active
      color: #031d6d
      border-color: #031d6d
      background-color: white

.show > .btn-primary.dropdown-toggle
  color: #031d6d
  border-color: #031d6d
  background-color: white

.btn-primary:not(:disabled):not(.disabled)
  &:active:focus, &.active:focus
    box-shadow: none, 0 0 0 0 rgba(94, 114, 228, 0.5)

.show > .btn-primary.dropdown-toggle:focus
  box-shadow: none, 0 0 0 0 rgba(94, 114, 228, 0.5)

.btn-secondary
  background: #efefef
  color: #031d6d
  font-weight: 600 !important

  &:hover
    color: #031d6d
    border-color: #ffffff
    background-color: #ffffff

.btn-success
  color: #fff
  border-color: #2dce89
  background-color: #2dce89
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08)

  &:hover
    color: #fff
    border-color: #2dce89
    background-color: #2dce89

  &:focus, &.focus
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08), 0 0 0 0 rgba(45, 206, 137, 0.5)

  &.disabled, &:disabled
    color: #fff
    border-color: #2dce89
    background-color: #2dce89

  &:not(:disabled):not(.disabled)
    &:active, &.active
      color: #fff
      border-color: #2dce89
      background-color: #24a46d

.show > .btn-success.dropdown-toggle
  color: #fff
  border-color: #2dce89
  background-color: #24a46d

.btn-success:not(:disabled):not(.disabled)
  &:active:focus, &.active:focus
    box-shadow: none, 0 0 0 0 rgba(45, 206, 137, 0.5)

.show > .btn-success.dropdown-toggle:focus
  box-shadow: none, 0 0 0 0 rgba(45, 206, 137, 0.5)

.btn-info
  color: #fff
  border-color: #11cdef
  background-color: #11cdef
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08)

  &:hover
    color: #fff
    border-color: #11cdef
    background-color: #11cdef

  &:focus, &.focus
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08), 0 0 0 0 rgba(17, 205, 239, 0.5)

  &.disabled, &:disabled
    color: #fff
    border-color: #11cdef
    background-color: #11cdef

  &:not(:disabled):not(.disabled)
    &:active, &.active
      color: #fff
      border-color: #11cdef
      background-color: #0da5c0

.show > .btn-info.dropdown-toggle
  color: #fff
  border-color: #11cdef
  background-color: #0da5c0

.btn-info:not(:disabled):not(.disabled)
  &:active:focus, &.active:focus
    box-shadow: none, 0 0 0 0 rgba(17, 205, 239, 0.5)

.show > .btn-info.dropdown-toggle:focus
  box-shadow: none, 0 0 0 0 rgba(17, 205, 239, 0.5)

.btn-warning
  color: #fff
  border-color: #fb6340
  background-color: #fb6340
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08)

  &:hover
    color: #fff
    border-color: #fb6340
    background-color: #fb6340

  &:focus, &.focus
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08), 0 0 0 0 rgba(251, 99, 64, 0.5)

  &.disabled, &:disabled
    color: #fff
    border-color: #fb6340
    background-color: #fb6340

  &:not(:disabled):not(.disabled)
    &:active, &.active
      color: #fff
      border-color: #fb6340
      background-color: #fa3a0e

.show > .btn-warning.dropdown-toggle
  color: #fff
  border-color: #fb6340
  background-color: #fa3a0e

.btn-warning:not(:disabled):not(.disabled)
  &:active:focus, &.active:focus
    box-shadow: none, 0 0 0 0 rgba(251, 99, 64, 0.5)

.show > .btn-warning.dropdown-toggle:focus
  box-shadow: none, 0 0 0 0 rgba(251, 99, 64, 0.5)

.btn-danger
  color: #fff
  border-color: #f5365c
  background-color: #f5365c
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08)

  &:hover
    color: #fff
    border-color: #f5365c
    background-color: #f5365c

  &:focus, &.focus
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08), 0 0 0 0 rgba(245, 54, 92, 0.5)

  &.disabled, &:disabled
    color: #fff
    border-color: #f5365c
    background-color: #f5365c

  &:not(:disabled):not(.disabled)
    &:active, &.active
      color: #fff
      border-color: #f5365c
      background-color: #ec0c38

.show > .btn-danger.dropdown-toggle
  color: #fff
  border-color: #f5365c
  background-color: #ec0c38

.btn-danger:not(:disabled):not(.disabled)
  &:active:focus, &.active:focus
    box-shadow: none, 0 0 0 0 rgba(245, 54, 92, 0.5)

.show > .btn-danger.dropdown-toggle:focus
  box-shadow: none, 0 0 0 0 rgba(245, 54, 92, 0.5)

.btn-light
  color: #fff
  border-color: #adb5bd
  background-color: #adb5bd
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08)

  &:hover
    color: #fff
    border-color: #adb5bd
    background-color: #adb5bd

  &:focus, &.focus
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08), 0 0 0 0 rgba(173, 181, 189, 0.5)

  &.disabled, &:disabled
    color: #fff
    border-color: #adb5bd
    background-color: #adb5bd

  &:not(:disabled):not(.disabled)
    &:active, &.active
      color: #fff
      border-color: #adb5bd
      background-color: #919ca6

.show > .btn-light.dropdown-toggle
  color: #fff
  border-color: #adb5bd
  background-color: #919ca6

.btn-light:not(:disabled):not(.disabled)
  &:active:focus, &.active:focus
    box-shadow: none, 0 0 0 0 rgba(173, 181, 189, 0.5)

.show > .btn-light.dropdown-toggle:focus
  box-shadow: none, 0 0 0 0 rgba(173, 181, 189, 0.5)

.btn-dark
  color: #fff
  border-color: #212529
  background-color: #212529
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08)

  &:hover
    color: #fff
    border-color: #212529
    background-color: #212529

  &:focus, &.focus
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08), 0 0 0 0 rgba(33, 37, 41, 0.5)

  &.disabled, &:disabled
    color: #fff
    border-color: #212529
    background-color: #212529

  &:not(:disabled):not(.disabled)
    &:active, &.active
      color: #fff
      border-color: #212529
      background-color: #0a0c0d

.show > .btn-dark.dropdown-toggle
  color: #fff
  border-color: #212529
  background-color: #0a0c0d

.btn-dark:not(:disabled):not(.disabled)
  &:active:focus, &.active:focus
    box-shadow: none, 0 0 0 0 rgba(33, 37, 41, 0.5)

.show > .btn-dark.dropdown-toggle:focus
  box-shadow: none, 0 0 0 0 rgba(33, 37, 41, 0.5)

.btn-default
  color: #fff
  border-color: #172b4d
  background-color: #172b4d
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08)

  &:hover
    color: #fff
    border-color: #172b4d
    background-color: #172b4d

  &:focus, &.focus
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08), 0 0 0 0 rgba(23, 43, 77, 0.5)

  &.disabled, &:disabled
    color: #fff
    border-color: #172b4d
    background-color: #172b4d

  &:not(:disabled):not(.disabled)
    &:active, &.active
      color: #fff
      border-color: #172b4d
      background-color: #0b1526

.show > .btn-default.dropdown-toggle
  color: #fff
  border-color: #172b4d
  background-color: #0b1526

.btn-default:not(:disabled):not(.disabled)
  &:active:focus, &.active:focus
    box-shadow: none, 0 0 0 0 rgba(23, 43, 77, 0.5)

.show > .btn-default.dropdown-toggle:focus
  box-shadow: none, 0 0 0 0 rgba(23, 43, 77, 0.5)

.btn-white
  color: #212529
  border-color: #fff
  background-color: #fff
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08)

  &:hover
    color: #212529
    border-color: white
    background-color: white

  &:focus, &.focus
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08), 0 0 0 0 rgba(255, 255, 255, 0.5)

  &.disabled, &:disabled
    color: #212529
    border-color: #fff
    background-color: #fff

  &:not(:disabled):not(.disabled)
    &:active, &.active
      color: #212529
      border-color: white
      background-color: #e6e6e6

.show > .btn-white.dropdown-toggle
  color: #212529
  border-color: white
  background-color: #e6e6e6

.btn-white:not(:disabled):not(.disabled)
  &:active:focus, &.active:focus
    box-shadow: none, 0 0 0 0 rgba(255, 255, 255, 0.5)

.show > .btn-white.dropdown-toggle:focus
  box-shadow: none, 0 0 0 0 rgba(255, 255, 255, 0.5)

.btn-neutral
  color: #212529
  border-color: #fff
  background-color: #fff
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08)

  &:hover
    color: #212529
    border-color: white
    background-color: white

  &:focus, &.focus
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08), 0 0 0 0 rgba(255, 255, 255, 0.5)

  &.disabled, &:disabled
    color: #212529
    border-color: #fff
    background-color: #fff

  &:not(:disabled):not(.disabled)
    &:active, &.active
      color: #212529
      border-color: white
      background-color: #e6e6e6

.show > .btn-neutral.dropdown-toggle
  color: #212529
  border-color: white
  background-color: #e6e6e6

.btn-neutral:not(:disabled):not(.disabled)
  &:active:focus, &.active:focus
    box-shadow: none, 0 0 0 0 rgba(255, 255, 255, 0.5)

.show > .btn-neutral.dropdown-toggle:focus
  box-shadow: none, 0 0 0 0 rgba(255, 255, 255, 0.5)

.btn-darker
  color: #fff
  border-color: black
  background-color: black
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08)

  &:hover
    color: #fff
    border-color: black
    background-color: black

  &:focus, &.focus
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08), 0 0 0 0 rgba(0, 0, 0, 0.5)

  &.disabled, &:disabled
    color: #fff
    border-color: black
    background-color: black

  &:not(:disabled):not(.disabled)
    &:active, &.active
      color: #fff
      border-color: black
      background-color: black

.show > .btn-darker.dropdown-toggle
  color: #fff
  border-color: black
  background-color: black

.btn-darker:not(:disabled):not(.disabled)
  &:active:focus, &.active:focus
    box-shadow: none, 0 0 0 0 rgba(0, 0, 0, 0.5)

.show > .btn-darker.dropdown-toggle:focus
  box-shadow: none, 0 0 0 0 rgba(0, 0, 0, 0.5)

.btn-outline-primary
  color: #5e72e4
  border-color: #5e72e4
  background-color: transparent
  background-image: none

  &:hover
    color: #fff
    border-color: #5e72e4
    background-color: #5e72e4

  &:focus, &.focus
    box-shadow: 0 0 0 0 rgba(94, 114, 228, 0.5)

  &.disabled, &:disabled
    color: #5e72e4
    background-color: transparent

  &:not(:disabled):not(.disabled)
    &:active, &.active
      color: #fff
      border-color: #5e72e4
      background-color: #5e72e4

.show > .btn-outline-primary.dropdown-toggle
  color: #fff
  border-color: #5e72e4
  background-color: #5e72e4

.btn-outline-primary:not(:disabled):not(.disabled)
  &:active:focus, &.active:focus
    box-shadow: 0 0 0 0 rgba(94, 114, 228, 0.5)

.show > .btn-outline-primary.dropdown-toggle:focus
  box-shadow: 0 0 0 0 rgba(94, 114, 228, 0.5)

.btn-outline-secondary
  color: #f7fafc
  border-color: #f7fafc
  background-color: transparent
  background-image: none

  &:hover
    color: #212529
    border-color: #f7fafc
    background-color: #f7fafc

  &:focus, &.focus
    box-shadow: 0 0 0 0 rgba(247, 250, 252, 0.5)

  &.disabled, &:disabled
    color: #f7fafc
    background-color: transparent

  &:not(:disabled):not(.disabled)
    &:active, &.active
      color: #212529
      border-color: #f7fafc
      background-color: #f7fafc

.show > .btn-outline-secondary.dropdown-toggle
  color: #212529
  border-color: #f7fafc
  background-color: #f7fafc

.btn-outline-secondary:not(:disabled):not(.disabled)
  &:active:focus, &.active:focus
    box-shadow: 0 0 0 0 rgba(247, 250, 252, 0.5)

.show > .btn-outline-secondary.dropdown-toggle:focus
  box-shadow: 0 0 0 0 rgba(247, 250, 252, 0.5)

.btn-outline-success
  color: #2dce89
  border-color: #2dce89
  background-color: transparent
  background-image: none

  &:hover
    color: #fff
    border-color: #2dce89
    background-color: #2dce89

  &:focus, &.focus
    box-shadow: 0 0 0 0 rgba(45, 206, 137, 0.5)

  &.disabled, &:disabled
    color: #2dce89
    background-color: transparent

  &:not(:disabled):not(.disabled)
    &:active, &.active
      color: #fff
      border-color: #2dce89
      background-color: #2dce89

.show > .btn-outline-success.dropdown-toggle
  color: #fff
  border-color: #2dce89
  background-color: #2dce89

.btn-outline-success:not(:disabled):not(.disabled)
  &:active:focus, &.active:focus
    box-shadow: 0 0 0 0 rgba(45, 206, 137, 0.5)

.show > .btn-outline-success.dropdown-toggle:focus
  box-shadow: 0 0 0 0 rgba(45, 206, 137, 0.5)

.btn-outline-info
  color: #11cdef
  border-color: #11cdef
  background-color: transparent
  background-image: none

  &:hover
    color: #fff
    border-color: #11cdef
    background-color: #11cdef

  &:focus, &.focus
    box-shadow: 0 0 0 0 rgba(17, 205, 239, 0.5)

  &.disabled, &:disabled
    color: #11cdef
    background-color: transparent

  &:not(:disabled):not(.disabled)
    &:active, &.active
      color: #fff
      border-color: #11cdef
      background-color: #11cdef

.show > .btn-outline-info.dropdown-toggle
  color: #fff
  border-color: #11cdef
  background-color: #11cdef

.btn-outline-info:not(:disabled):not(.disabled)
  &:active:focus, &.active:focus
    box-shadow: 0 0 0 0 rgba(17, 205, 239, 0.5)

.show > .btn-outline-info.dropdown-toggle:focus
  box-shadow: 0 0 0 0 rgba(17, 205, 239, 0.5)

.btn-outline-warning
  color: #fb6340
  border-color: #fb6340
  background-color: transparent
  background-image: none

  &:hover
    color: #fff
    border-color: #fb6340
    background-color: #fb6340

  &:focus, &.focus
    box-shadow: 0 0 0 0 rgba(251, 99, 64, 0.5)

  &.disabled, &:disabled
    color: #fb6340
    background-color: transparent

  &:not(:disabled):not(.disabled)
    &:active, &.active
      color: #fff
      border-color: #fb6340
      background-color: #fb6340

.show > .btn-outline-warning.dropdown-toggle
  color: #fff
  border-color: #fb6340
  background-color: #fb6340

.btn-outline-warning:not(:disabled):not(.disabled)
  &:active:focus, &.active:focus
    box-shadow: 0 0 0 0 rgba(251, 99, 64, 0.5)

.show > .btn-outline-warning.dropdown-toggle:focus
  box-shadow: 0 0 0 0 rgba(251, 99, 64, 0.5)

.btn-outline-danger
  color: #f5365c
  border-color: #f5365c
  background-color: transparent
  background-image: none

  &:hover
    color: #fff
    border-color: #f5365c
    background-color: #f5365c

  &:focus, &.focus
    box-shadow: 0 0 0 0 rgba(245, 54, 92, 0.5)

  &.disabled, &:disabled
    color: #f5365c
    background-color: transparent

  &:not(:disabled):not(.disabled)
    &:active, &.active
      color: #fff
      border-color: #f5365c
      background-color: #f5365c

.show > .btn-outline-danger.dropdown-toggle
  color: #fff
  border-color: #f5365c
  background-color: #f5365c

.btn-outline-danger:not(:disabled):not(.disabled)
  &:active:focus, &.active:focus
    box-shadow: 0 0 0 0 rgba(245, 54, 92, 0.5)

.show > .btn-outline-danger.dropdown-toggle:focus
  box-shadow: 0 0 0 0 rgba(245, 54, 92, 0.5)

.btn-outline-light
  color: #adb5bd
  border-color: #adb5bd
  background-color: transparent
  background-image: none

  &:hover
    color: #fff
    border-color: #adb5bd
    background-color: #adb5bd

  &:focus, &.focus
    box-shadow: 0 0 0 0 rgba(173, 181, 189, 0.5)

  &.disabled, &:disabled
    color: #adb5bd
    background-color: transparent

  &:not(:disabled):not(.disabled)
    &:active, &.active
      color: #fff
      border-color: #adb5bd
      background-color: #adb5bd

.show > .btn-outline-light.dropdown-toggle
  color: #fff
  border-color: #adb5bd
  background-color: #adb5bd

.btn-outline-light:not(:disabled):not(.disabled)
  &:active:focus, &.active:focus
    box-shadow: 0 0 0 0 rgba(173, 181, 189, 0.5)

.show > .btn-outline-light.dropdown-toggle:focus
  box-shadow: 0 0 0 0 rgba(173, 181, 189, 0.5)

.btn-outline-dark
  color: #212529
  border-color: #212529
  background-color: transparent
  background-image: none

  &:hover
    color: #fff
    border-color: #212529
    background-color: #212529

  &:focus, &.focus
    box-shadow: 0 0 0 0 rgba(33, 37, 41, 0.5)

  &.disabled, &:disabled
    color: #212529
    background-color: transparent

  &:not(:disabled):not(.disabled)
    &:active, &.active
      color: #fff
      border-color: #212529
      background-color: #212529

.show > .btn-outline-dark.dropdown-toggle
  color: #fff
  border-color: #212529
  background-color: #212529

.btn-outline-dark:not(:disabled):not(.disabled)
  &:active:focus, &.active:focus
    box-shadow: 0 0 0 0 rgba(33, 37, 41, 0.5)

.show > .btn-outline-dark.dropdown-toggle:focus
  box-shadow: 0 0 0 0 rgba(33, 37, 41, 0.5)

.btn-outline-default
  color: #172b4d
  border-color: #172b4d
  background-color: transparent
  background-image: none

  &:hover
    color: #fff
    border-color: #172b4d
    background-color: #172b4d

  &:focus, &.focus
    box-shadow: 0 0 0 0 rgba(23, 43, 77, 0.5)

  &.disabled, &:disabled
    color: #172b4d
    background-color: transparent

  &:not(:disabled):not(.disabled)
    &:active, &.active
      color: #fff
      border-color: #172b4d
      background-color: #172b4d

.show > .btn-outline-default.dropdown-toggle
  color: #fff
  border-color: #172b4d
  background-color: #172b4d

.btn-outline-default:not(:disabled):not(.disabled)
  &:active:focus, &.active:focus
    box-shadow: 0 0 0 0 rgba(23, 43, 77, 0.5)

.show > .btn-outline-default.dropdown-toggle:focus
  box-shadow: 0 0 0 0 rgba(23, 43, 77, 0.5)

.btn-outline-white
  color: #fff
  border-color: #fff
  background-color: transparent
  background-image: none

  &:hover
    color: #212529
    border-color: #fff
    background-color: #fff

  &:focus, &.focus
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.5)

  &.disabled, &:disabled
    color: #fff
    background-color: transparent

  &:not(:disabled):not(.disabled)
    &:active, &.active
      color: #212529
      border-color: #fff
      background-color: #fff

.show > .btn-outline-white.dropdown-toggle
  color: #212529
  border-color: #fff
  background-color: #fff

.btn-outline-white:not(:disabled):not(.disabled)
  &:active:focus, &.active:focus
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.5)

.show > .btn-outline-white.dropdown-toggle:focus
  box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.5)

.btn-outline-neutral
  color: #fff
  border-color: #fff
  background-color: transparent
  background-image: none

  &:hover
    color: #212529
    border-color: #fff
    background-color: #fff

  &:focus, &.focus
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.5)

  &.disabled, &:disabled
    color: #fff
    background-color: transparent

  &:not(:disabled):not(.disabled)
    &:active, &.active
      color: #212529
      border-color: #fff
      background-color: #fff

.show > .btn-outline-neutral.dropdown-toggle
  color: #212529
  border-color: #fff
  background-color: #fff

.btn-outline-neutral:not(:disabled):not(.disabled)
  &:active:focus, &.active:focus
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.5)

.show > .btn-outline-neutral.dropdown-toggle:focus
  box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.5)

.btn-outline-darker
  color: black
  border-color: black
  background-color: transparent
  background-image: none

  &:hover
    color: #fff
    border-color: black
    background-color: black

  &:focus, &.focus
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.5)

  &.disabled, &:disabled
    color: black
    background-color: transparent

  &:not(:disabled):not(.disabled)
    &:active, &.active
      color: #fff
      border-color: black
      background-color: black

.show > .btn-outline-darker.dropdown-toggle
  color: #fff
  border-color: black
  background-color: black

.btn-outline-darker:not(:disabled):not(.disabled)
  &:active:focus, &.active:focus
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.5)

.show > .btn-outline-darker.dropdown-toggle:focus
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.5)

.btn-link
  font-weight: 400
  text-decoration: none
  color: #5e72e4

  &:hover
    text-decoration: none
    color: #233dd2

  &:focus, &.focus
    text-decoration: none
    box-shadow: none

  &:disabled, &.disabled
    pointer-events: none
    color: #8898aa

.btn-lg, .btn-group-lg > .btn
  font-size: .875rem
  line-height: 1.5
  padding: .875rem 1rem
  border-radius: .4375rem

.btn-sm, .btn-group-sm > .btn
  font-size: .75rem
  line-height: 1.5
  padding: .25rem .5rem
  border-radius: .25rem

.btn-block
  display: block
  width: 100%

  + .btn-block
    margin-top: .5rem

input
  &[type='submit'].btn-block, &[type='reset'].btn-block, &[type='button'].btn-block
    width: 100%

.fade
  transition: opacity .15s linear

  &:not(.show)
    opacity: 0

@media (prefers-reduced-motion: reduce)
  .fade
    transition: none

.collapse:not(.show)
  display: none

.collapsing
  position: relative
  overflow: hidden
  height: 0
  transition: height .3s ease

@media (prefers-reduced-motion: reduce)
  .collapsing
    transition: none

.dropup, .dropright, .dropdown, .dropleft
  position: relative

.dropdown-toggle
  white-space: nowrap

  &::after
    display: inline-block
    margin-left: .255em
    content: ''
    vertical-align: .255em
    border-top: .3em solid
    border-right: .3em solid transparent
    border-bottom: 0
    border-left: .3em solid transparent

  &:empty::after
    margin-left: 0

.dropdown-menu
  font-size: 1rem
  position: absolute
  z-index: 1000
  top: 100%
  left: 0
  display: none
  float: left
  min-width: 10rem
  margin: .125rem 0 0
  padding: .5rem 0
  list-style: none
  text-align: left
  color: #525f7f
  border: 0 solid rgba(0, 0, 0, 0.15)
  border-radius: .4375rem
  background-color: #fff
  background-clip: padding-box
  box-shadow: 0 50px 100px rgba(50, 50, 93, 0.1), 0 15px 35px rgba(50, 50, 93, 0.15), 0 5px 15px rgba(0, 0, 0, 0.1)

.dropdown-menu-left
  right: auto
  left: 0

.dropdown-menu-right
  right: 0
  left: auto

@media (min-width: 576px)
  .dropdown-menu-sm-left
    right: auto
    left: 0

  .dropdown-menu-sm-right
    right: 0
    left: auto

@media (min-width: 768px)
  .dropdown-menu-md-left
    right: auto
    left: 0

  .dropdown-menu-md-right
    right: 0
    left: auto

@media (min-width: 992px)
  .dropdown-menu-lg-left
    right: auto
    left: 0

  .dropdown-menu-lg-right
    right: 0
    left: auto

@media (min-width: 1200px)
  .dropdown-menu-xl-left
    right: auto
    left: 0

  .dropdown-menu-xl-right
    right: 0
    left: auto

.dropup
  .dropdown-menu
    top: auto
    bottom: 100%
    margin-top: 0
    margin-bottom: .125rem

  .dropdown-toggle
    &::after
      display: inline-block
      margin-left: .255em
      content: ''
      vertical-align: .255em
      border-top: 0
      border-right: .3em solid transparent
      border-bottom: .3em solid
      border-left: .3em solid transparent

    &:empty::after
      margin-left: 0

.dropright
  .dropdown-menu
    top: 0
    right: auto
    left: 100%
    margin-top: 0
    margin-left: .125rem

  .dropdown-toggle
    &::after
      display: inline-block
      margin-left: .255em
      content: ''
      vertical-align: .255em
      border-top: .3em solid transparent
      border-right: 0
      border-bottom: .3em solid transparent
      border-left: .3em solid

    &:empty::after
      margin-left: 0

    &::after
      vertical-align: 0

.dropleft
  .dropdown-menu
    top: 0
    right: 100%
    left: auto
    margin-top: 0
    margin-right: .125rem

  .dropdown-toggle
    &::after
      display: inline-block
      margin-left: .255em
      content: ''
      vertical-align: .255em
      display: none

    &::before
      display: inline-block
      margin-right: .255em
      content: ''
      vertical-align: .255em
      border-top: .3em solid transparent
      border-right: .3em solid
      border-bottom: .3em solid transparent

    &:empty::after
      margin-left: 0

    &::before
      vertical-align: 0

.dropdown-menu
  &[x-placement^='top'], &[x-placement^='right'], &[x-placement^='bottom'], &[x-placement^='left']
    right: auto
    bottom: auto

.dropdown-divider
  overflow: hidden
  height: 0
  margin: .5rem 0
  border-top: 1px solid #e9ecef

.dropdown-item
  font-weight: 400
  display: block
  clear: both
  width: 100%
  padding: .5rem 1rem
  text-align: inherit
  white-space: nowrap
  color: #212529
  border: 0
  background-color: transparent

  &:hover, &:focus
    text-decoration: none
    color: #16181b
    background-color: #f6f9fc

  &.active, &:active
    text-decoration: none
    color: #16181b
    background-color: transparent

  &.disabled, &:disabled
    pointer-events: none
    color: #8898aa
    background-color: transparent

.dropdown-menu.show
  display: block

.dropdown-header
  font-size: .875rem
  display: block
  margin-bottom: 0
  padding: .5rem 1rem
  white-space: nowrap
  color: #8898aa

.dropdown-item-text
  display: block
  padding: .5rem 1rem
  color: #212529

.btn-group, .btn-group-vertical
  position: relative
  display: inline-flex
  vertical-align: middle

.btn-group > .btn, .btn-group-vertical > .btn
  position: relative
  flex: 1 1 auto

.btn-group > .btn:hover, .btn-group-vertical > .btn:hover
  z-index: 1

.btn-group > .btn
  &:focus, &:active, &.active
    z-index: 1

.btn-group-vertical > .btn
  &:focus, &:active, &.active
    z-index: 1

.btn-toolbar
  display: flex
  flex-wrap: wrap
  justify-content: flex-start

  .input-group
    width: auto

.btn-group >
.btn:not(:first-child), .btn-group:not(:first-child)
  margin-left: -1px

  .btn:not(:last-child):not(.dropdown-toggle), .btn-group:not(:last-child) > .btn
    border-top-right-radius: 0
    border-bottom-right-radius: 0

  .btn:not(:first-child), .btn-group:not(:first-child) > .btn
    border-top-left-radius: 0
    border-bottom-left-radius: 0

.dropdown-toggle-split
  padding-right: .9375rem
  padding-left: .9375rem

  &::after
    margin-left: 0

.dropup .dropdown-toggle-split::after, .dropright .dropdown-toggle-split::after
  margin-left: 0

.dropleft .dropdown-toggle-split::before
  margin-right: 0

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split
  padding-right: .375rem
  padding-left: .375rem

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split
  padding-right: .75rem
  padding-left: .75rem

.btn-group.show .dropdown-toggle
  box-shadow: none

  &.btn-link
    box-shadow: none

.btn-group-vertical
  flex-direction: column
  align-items: flex-start
  justify-content: center

  >
  .btn, .btn-group
    width: 100%

    .btn:not(:first-child), .btn-group:not(:first-child)
      margin-top: -1px

    .btn:not(:last-child):not(.dropdown-toggle), .btn-group:not(:last-child) > .btn
      border-bottom-right-radius: 0
      border-bottom-left-radius: 0

    .btn:not(:first-child), .btn-group:not(:first-child) > .btn
      border-top-left-radius: 0
      border-top-right-radius: 0

.btn-group-toggle >
.btn, .btn-group > .btn
  margin-bottom: 0

  .btn input
    &[type='radio'], &[type='checkbox']
      position: absolute
      clip: rect(0, 0, 0, 0)
      pointer-events: none

  .btn-group > .btn input
    &[type='radio'], &[type='checkbox']
      position: absolute
      clip: rect(0, 0, 0, 0)
      pointer-events: none

.input-group
  position: relative
  display: flex
  width: 100%
  flex-wrap: wrap
  align-items: stretch

  >
  .form-control, .form-control-plaintext, .custom-select, .custom-file
    position: relative
    min-width: 0
    margin-bottom: 0
    flex: 1 1

    .form-control +
    .form-control, .custom-select, .custom-file
      margin-left: -1px

    .form-control-plaintext +
    .form-control, .custom-select, .custom-file
      margin-left: -1px

    .custom-select +
    .form-control, .custom-select, .custom-file
      margin-left: -1px

    .custom-file +
    .form-control, .custom-select, .custom-file
      margin-left: -1px

    .form-control:focus, .custom-select:focus
      z-index: 3

    .custom-file .custom-file-input:focus
      ~ .custom-file-label
        z-index: 3

      z-index: 4

    .form-control:not(:last-child), .custom-select:not(:last-child)
      border-top-right-radius: 0
      border-bottom-right-radius: 0

    .form-control:not(:first-child), .custom-select:not(:first-child)
      border-top-left-radius: 0
      border-bottom-left-radius: 0

    .custom-file
      display: flex
      align-items: center

      &:not(:last-child) .custom-file-label
        border-top-right-radius: 0
        border-bottom-right-radius: 0

        &::after
          border-top-right-radius: 0
          border-bottom-right-radius: 0

      &:not(:first-child) .custom-file-label
        border-top-left-radius: 0
        border-bottom-left-radius: 0

.input-group-prepend, .input-group-append
  display: flex

.input-group-prepend .btn, .input-group-append .btn
  position: relative
  z-index: 2

.input-group-prepend .btn:focus, .input-group-append .btn:focus
  z-index: 3

.input-group-prepend
  .btn +
  .btn, .input-group-text
    margin-left: -1px

  .input-group-text +
  .input-group-text, .btn
    margin-left: -1px

.input-group-append
  .btn +
  .btn, .input-group-text
    margin-left: -1px

  .input-group-text +
  .input-group-text, .btn
    margin-left: -1px

.input-group-prepend
  margin-right: -1px

.input-group-append
  margin-left: -1px

.input-group-text
  font-size: .875rem
  font-weight: 400
  line-height: 1.5
  display: flex
  margin-bottom: 0
  padding: .625rem .75rem
  text-align: center
  white-space: nowrap
  color: #adb5bd
  border: 1px solid #dee2e6
  border-radius: .25rem
  background-color: #fff
  align-items: center

  input
    &[type='radio'], &[type='checkbox']
      margin-top: 0

.input-group-lg >
.form-control:not(textarea), .custom-select
  height: calc(1.5em + 1.75rem + 2px)

  .form-control, .custom-select, .input-group-prepend > .input-group-text, .input-group-append > .input-group-text, .input-group-prepend > .btn, .input-group-append > .btn
    font-size: .875rem
    line-height: 1.5
    padding: .875rem 1rem
    border-radius: .4375rem

.input-group-sm >
.form-control:not(textarea), .custom-select
  height: calc(1.5em + .5rem + 2px)

  .form-control, .custom-select, .input-group-prepend > .input-group-text, .input-group-append > .input-group-text, .input-group-prepend > .btn, .input-group-append > .btn
    font-size: .75rem
    line-height: 1.5
    padding: .25rem .5rem
    border-radius: .25rem

.input-group-lg > .custom-select, .input-group-sm > .custom-select
  padding-right: 1.75rem

.input-group >
.input-group-prepend >
.btn, .input-group-text
  border-top-right-radius: 0
  border-bottom-right-radius: 0

  .input-group-append
    &:not(:last-child) >
    .btn, .input-group-text
      border-top-right-radius: 0
      border-bottom-right-radius: 0

    &:last-child >
    .btn:not(:last-child):not(.dropdown-toggle), .input-group-text:not(:last-child)
      border-top-right-radius: 0
      border-bottom-right-radius: 0

    >
    .btn, .input-group-text
      border-top-left-radius: 0
      border-bottom-left-radius: 0

  .input-group-prepend
    &:not(:first-child) >
    .btn, .input-group-text
      border-top-left-radius: 0
      border-bottom-left-radius: 0

    &:first-child >
    .btn:not(:first-child), .input-group-text:not(:first-child)
      border-top-left-radius: 0
      border-bottom-left-radius: 0

.custom-control
  position: relative
  display: block
  min-height: 1.5rem
  padding-left: 2.75rem

.custom-control-inline
  display: inline-flex
  margin-right: 1rem

.custom-control-input
  position: absolute
  z-index: -1
  left: 0
  width: 1rem
  height: 1.25rem
  opacity: 0

  &:checked ~ .custom-control-label::before
    color: #fff
    border-color: #5e72e4
    background-color: #5e72e4
    box-shadow: 0 3px 2px rgba(233, 236, 239, 0.05)

  &:focus
    ~ .custom-control-label::before
      box-shadow: 0 3px 2px rgba(233, 236, 239, 0.05), 0 3px 9px rgba(50, 50, 9, 0), 3px 4px 8px rgba(94, 114, 228, 0.1)

    &:not(:checked) ~ .custom-control-label::before
      border-color: #5e72e4

  &:not(:disabled):active ~ .custom-control-label::before
    color: #fff
    border-color: #5e72e4
    background-color: #5e72e4
    box-shadow: 0 3px 2px rgba(233, 236, 239, 0.05)

  &[disabled] ~ .custom-control-label, &:disabled ~ .custom-control-label
    color: #8898aa

  &[disabled] ~ .custom-control-label::before, &:disabled ~ .custom-control-label::before
    background-color: #e9ecef

.custom-control-label
  position: relative
  margin-bottom: 0
  vertical-align: top

  &::before
    position: absolute
    top: .25rem
    left: -2.75rem
    display: block
    width: 1rem
    height: 1rem
    content: ''
    pointer-events: none
    border: #dee2e6 solid 1px
    background-color: #fff
    box-shadow: 0 3px 2px rgba(233, 236, 239, 0.05)

  &::after
    position: absolute
    top: .25rem
    left: -2.75rem
    display: block
    width: 1rem
    height: 1rem
    content: ''
    background: no-repeat 50% / 50% 50%

.custom-checkbox
  .custom-control-label::before
    border-radius: .25rem

  .custom-control-input
    &:checked ~ .custom-control-label::after
      background-image: url('data:image/svg+xml,%3csvg xmlns=\'http://www.w3.org/2000/svg\' width=\'8\' height=\'8\' viewBox=\'0 0 8 8\'%3e%3cpath fill=\'%23fff\' d=\'M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z\'/%3e%3c/svg%3e')

    &:indeterminate ~ .custom-control-label
      &::before
        border-color: #5e72e4
        background-color: #5e72e4
        box-shadow: none

      &::after
        background-image: url('data:image/svg+xml,%3csvg xmlns=\'http://www.w3.org/2000/svg\' width=\'4\' height=\'4\' viewBox=\'0 0 4 4\'%3e%3cpath stroke=\'%23fff\' d=\'M0 2h4\'/%3e%3c/svg%3e')

    &:disabled
      &:checked ~ .custom-control-label::before, &:indeterminate ~ .custom-control-label::before
        background-color: rgba(94, 114, 228, 0.5)

.custom-radio
  .custom-control-label::before
    border-radius: 50%

  .custom-control-input
    &:checked ~ .custom-control-label::after
      background-image: url('data:image/svg+xml,%3csvg xmlns=\'http://www.w3.org/2000/svg\' width=\'12\' height=\'12\' viewBox=\'-4 -4 8 8\'%3e%3ccircle r=\'3\' fill=\'%23fff\'/%3e%3c/svg%3e')

    &:disabled:checked ~ .custom-control-label::before
      background-color: rgba(94, 114, 228, 0.5)

.custom-switch
  padding-left: 3.5rem

  .custom-control-label
    &::before
      left: -3.5rem
      width: 1.75rem
      pointer-events: all
      border-radius: .5rem

    &::after
      top: calc(.25rem + 2px)
      left: calc(-3.5rem + 2px)
      width: calc(1rem - 4px)
      height: calc(1rem - 4px)
      transition: transform .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
      border-radius: .5rem
      background-color: #dee2e6

  .custom-control-input
    &:checked ~ .custom-control-label::after
      transform: translateX(0.75rem)
      background-color: #fff

    &:disabled:checked ~ .custom-control-label::before
      background-color: rgba(94, 114, 228, 0.5)

@media (prefers-reduced-motion: reduce)
  .custom-switch .custom-control-label::after
    transition: none

.custom-select
  font-size: .875rem
  font-weight: 400
  line-height: 1.5
  display: inline-block
  width: 100%
  height: calc(1.5em + 1.25rem + 2px)
  padding: .625rem 1.75rem .625rem .75rem
  vertical-align: middle
  color: #8898aa
  border: 1px solid #dee2e6
  border-radius: .375rem
  background: #fff url('data:image/svg+xml,%3csvg xmlns=\'http://www.w3.org/2000/svg\' width=\'4\' height=\'5\' viewBox=\'0 0 4 5\'%3e%3cpath fill=\'%2332325d\' d=\'M2 0L0 2h4zm0 5L0 3h4z\'/%3e%3c/svg%3e') no-repeat right 0.75rem (center / 8px) 10px
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075)
  -webkit-appearance: none
  -moz-appearance: none
  appearance: none

  &:focus
    border-color: #5e72e4
    outline: 0
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075), 0 0 0 0 #5e72e4

    &::-ms-value
      color: #8898aa
      background-color: #fff

  &[multiple], &[size]:not([size='1'])
    height: auto
    padding-right: .75rem
    background-image: none

  &:disabled
    color: #8898aa
    background-color: #e9ecef

  &::-ms-expand
    display: none

  &:-moz-focusring
    color: transparent
    text-shadow: 0 0 0 #8898aa

.custom-select-sm
  font-size: .75rem
  height: calc(1.5em + .5rem + 2px)
  padding-top: .25rem
  padding-bottom: .25rem
  padding-left: .5rem

.custom-select-lg
  font-size: .875rem
  height: calc(1.5em + 1.75rem + 2px)
  padding-top: .875rem
  padding-bottom: .875rem
  padding-left: 1rem

.custom-file
  position: relative
  display: inline-block
  width: 100%
  height: calc(1.5em + 1.25rem + 2px)
  margin-bottom: 0

.custom-file-input
  position: relative
  z-index: 2
  width: 100%
  height: calc(1.5em + 1.25rem + 2px)
  margin: 0
  opacity: 0

  &:focus ~ .custom-file-label
    border-color: #5e72e4
    box-shadow: 0 3px 9px rgba(50, 50, 9, 0), 3px 4px 8px rgba(94, 114, 228, 0.1)

  &[disabled] ~ .custom-file-label, &:disabled ~ .custom-file-label
    background-color: #e9ecef

  &:lang(en) ~ .custom-file-label::after
    content: 'Browse'

  ~ .custom-file-label[data-browse]::after
    content: attr(data-browse)

.custom-file-label
  font-weight: 400
  line-height: 1.5
  position: absolute
  z-index: 1
  top: 0
  right: 0
  left: 0
  height: calc(1.5em + 1.25rem + 2px)
  padding: .625rem .75rem
  color: #8898aa
  border: 1px solid #dee2e6
  border-radius: .25rem
  background-color: #fff
  box-shadow: 0 3px 2px rgba(233, 236, 239, 0.05)

  &::after
    line-height: 1.5
    position: absolute
    z-index: 3
    top: 0
    right: 0
    bottom: 0
    display: block
    height: calc(1.5em + 1.25rem)
    padding: .625rem .75rem
    content: 'Browse'
    color: #8898aa
    border-left: inherit
    border-radius: 0 .25rem .25rem 0
    background-color: #fff

.custom-range
  width: 100%
  height: 1rem
  padding: 0
  background-color: transparent
  -webkit-appearance: none
  -moz-appearance: none
  appearance: none

  &:focus
    outline: none

    &::-webkit-slider-thumb, &::-moz-range-thumb, &::-ms-thumb
      box-shadow: 0 0 0 1px #f8f9fe, 0 3px 9px rgba(50, 50, 9, 0), 3px 4px 8px rgba(94, 114, 228, 0.1)

  &::-moz-focus-outer
    border: 0

  &::-webkit-slider-thumb
    width: 1rem
    height: 1rem
    margin-top: -.25rem
    transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
    border: 0
    border-radius: 1rem
    background-color: #5e72e4
    box-shadow: 0 0.1rem 0.25rem rgba(0, 0, 0, 0.1)
    -webkit-appearance: none
    appearance: none

    &:active
      background-color: #f7f8fe

  &::-webkit-slider-runnable-track
    width: 100%
    height: .5rem
    cursor: pointer
    color: transparent
    border-color: transparent
    border-radius: 1rem
    background-color: #dee2e6
    box-shadow: inset 0 0.25rem 0.25rem rgba(0, 0, 0, 0.1)

  &::-moz-range-thumb
    width: 1rem
    height: 1rem
    transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
    border: 0
    border-radius: 1rem
    background-color: #5e72e4
    box-shadow: 0 0.1rem 0.25rem rgba(0, 0, 0, 0.1)
    -moz-appearance: none
    appearance: none

    &:active
      background-color: #f7f8fe

  &::-moz-range-track
    width: 100%
    height: .5rem
    cursor: pointer
    color: transparent
    border-color: transparent
    border-radius: 1rem
    background-color: #dee2e6
    box-shadow: inset 0 0.25rem 0.25rem rgba(0, 0, 0, 0.1)

  &::-ms-thumb
    width: 1rem
    height: 1rem
    margin-top: 0
    margin-right: 0
    margin-left: 0
    transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
    border: 0
    border-radius: 1rem
    background-color: #5e72e4
    box-shadow: 0 0.1rem 0.25rem rgba(0, 0, 0, 0.1)
    appearance: none

    &:active
      background-color: #f7f8fe

  &::-ms-track
    width: 100%
    height: .5rem
    cursor: pointer
    color: transparent
    border-width: .5rem
    border-color: transparent
    background-color: transparent
    box-shadow: inset 0 0.25rem 0.25rem rgba(0, 0, 0, 0.1)

  &::-ms-fill-lower
    border-radius: 1rem
    background-color: #dee2e6

  &::-ms-fill-upper
    margin-right: 15px
    border-radius: 1rem
    background-color: #dee2e6

  &:disabled
    &::-webkit-slider-thumb
      background-color: #adb5bd

    &::-webkit-slider-runnable-track
      cursor: default

    &::-moz-range-thumb
      background-color: #adb5bd

    &::-moz-range-track
      cursor: default

    &::-ms-thumb
      background-color: #adb5bd

@media (prefers-reduced-motion: reduce)
  .custom-range::-webkit-slider-thumb
    transition: none

@media (prefers-reduced-motion: reduce)
  .custom-range::-moz-range-thumb
    transition: none

@media (prefers-reduced-motion: reduce)
  .custom-range::-ms-thumb
    transition: none

.custom-control-label::before, .custom-file-label, .custom-select
  transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out

@media (prefers-reduced-motion: reduce)
  .custom-control-label::before, .custom-file-label, .custom-select
    transition: none

.nav
  display: flex
  margin-bottom: 0
  padding-left: 0
  list-style: none
  flex-wrap: wrap

.nav-link
  display: block
  padding: .25rem .75rem

  &:hover, &:focus
    text-decoration: none

  &.disabled
    cursor: default
    pointer-events: none
    color: #8898aa

.nav-tabs
  border-bottom: 1px solid #dee2e6

  .nav-item
    margin-bottom: -1px

  .nav-link
    border: 1px solid transparent
    border-top-left-radius: .375rem
    border-top-right-radius: .375rem

    &:hover, &:focus
      border-color: #e9ecef #e9ecef #dee2e6

    &.disabled
      color: #8898aa
      border-color: transparent
      background-color: transparent

    &.active
      color: #525f7f
      border-color: #dee2e6 #dee2e6 #f8f9fe
      background-color: #f8f9fe

  .nav-item.show .nav-link
    color: #525f7f
    border-color: #dee2e6 #dee2e6 #f8f9fe
    background-color: #f8f9fe

  .dropdown-menu
    margin-top: -1px
    border-top-left-radius: 0
    border-top-right-radius: 0

.nav-pills
  .nav-link.active, .show > .nav-link
    color: #fff
    background-color: #000
    border: 1px solid black

.nav-fill .nav-item
  text-align: center
  flex: 1 1 auto

.nav-justified .nav-item
  text-align: center
  flex-basis: 0
  flex-grow: 1

.tab-content > .tab-pane
  display: none

.tab-content > .active
  display: block

.navbar
  position: relative
  display: flex
  padding: 0
  align-items: center
  height: 90px
  margin-bottom: 10px

  .container, .container-fluid, .container-sm, .container-md, .container-lg, .container-xl
    display: flex
    flex-wrap: wrap
    align-items: center
    justify-content: space-between

.navbar-brand
  font-size: 1.25rem
  line-height: inherit
  display: inline-block
  margin-right: 1rem
  padding-top: .0625rem
  padding-bottom: .0625rem
  white-space: nowrap

  &:hover, &:focus
    text-decoration: none

.navbar-nav
  display: flex
  flex-direction: column
  margin-bottom: 0
  padding-left: 0
  list-style: none

  .nav-link
    padding-right: 0
    padding-left: 0

  .dropdown-menu
    position: static
    float: none

.navbar-text
  display: inline-block
  padding-top: .25rem
  padding-bottom: .25rem

.navbar-collapse
  flex-basis: 100%
  flex-grow: 1
  align-items: center

.navbar-toggler
  font-size: 1.25rem
  line-height: 1
  padding: .25rem .75rem
  border: 1px solid transparent
  border-radius: .25rem
  background-color: transparent

  &:hover, &:focus
    text-decoration: none

.navbar-toggler-icon
  display: inline-block
  width: 1.5em
  height: 1.5em
  content: ''
  vertical-align: middle
  background: no-repeat center center
  background-size: 100% 100%

@media (max-width: 575.98px)

@media (min-width: 576px)
  .navbar-expand-sm
    flex-flow: row nowrap
    justify-content: flex-start

    .navbar-nav
      flex-direction: row

      .dropdown-menu
        position: absolute

      .nav-link
        padding-right: 1rem
        padding-left: 1rem

    >
    .container, .container-fluid, .container-sm, .container-md, .container-lg, .container-xl
      flex-wrap: nowrap

    .navbar-collapse
      display: flex !important
      flex-basis: auto

    .navbar-toggler
      display: none

@media (max-width: 767.98px)


@media (min-width: 768px)
  .navbar-expand-md
    flex-flow: row nowrap
    justify-content: flex-start

    .navbar-nav
      flex-direction: row

      .dropdown-menu
        position: absolute

      .nav-link
        padding-right: 1rem
        padding-left: 1rem

    >
    .container, .container-fluid, .container-sm, .container-md, .container-lg, .container-xl
      flex-wrap: nowrap

    .navbar-collapse
      display: flex !important
      flex-basis: auto

    .navbar-toggler
      display: none

@media (max-width: 991.98px)


@media (min-width: 992px)
  .navbar-expand-lg
    flex-flow: row nowrap
    justify-content: flex-start

    .navbar-nav
      flex-direction: row

      .dropdown-menu
        position: absolute

      .nav-link
        padding-right: 1rem
        padding-left: 1rem

    >
    .container, .container-fluid, .container-sm, .container-md, .container-lg, .container-xl
      flex-wrap: nowrap

    .navbar-collapse
      display: flex !important
      flex-basis: auto

    .navbar-toggler
      display: none

@media (max-width: 1199.98px)


@media (min-width: 1200px)
  .navbar-expand-xl
    flex-flow: row nowrap
    justify-content: flex-start

    .navbar-nav
      flex-direction: row

      .dropdown-menu
        position: absolute

      .nav-link
        padding-right: 1rem
        padding-left: 1rem

    >
    .container, .container-fluid, .container-sm, .container-md, .container-lg, .container-xl
      flex-wrap: nowrap

    .navbar-collapse
      display: flex !important
      flex-basis: auto

    .navbar-toggler
      display: none

.navbar-expand
  flex-flow: row nowrap
  justify-content: flex-start



  .navbar-nav
    flex-direction: row

    .dropdown-menu
      position: absolute

    .nav-link
      padding-right: 1rem
      padding-left: 1rem

  >
  .container, .container-fluid, .container-sm, .container-md, .container-lg, .container-xl
    flex-wrap: nowrap

  .navbar-collapse
    display: flex !important
    flex-basis: auto
    background: #fff
    padding: 10px 30px 10px 10px
    border-radius: 50px

  .navbar-toggler
    display: none

.navbar-light
  .navbar-brand
    color: rgba(0, 0, 0, 0.9)

    &:hover, &:focus
      color: rgba(0, 0, 0, 0.9)

  .navbar-nav
    .nav-link
      color: rgba(0, 0, 0, 0.6)

      &:hover, &:focus
        color: rgba(0, 0, 0, 0.7)

      &.disabled
        color: rgba(0, 0, 0, 0.3)

    .show > .nav-link, .active > .nav-link
      color: rgba(0, 0, 0, 0.9)

    .nav-link
      &.show, &.active
        color: rgba(0, 0, 0, 0.9)

  .navbar-toggler
    color: rgba(0, 0, 0, 0.6)
    border-color: transparent

  .navbar-toggler-icon
    background-image: url('data:image/svg+xml,%3csvg xmlns=\'http://www.w3.org/2000/svg\' width=\'30\' height=\'30\' viewBox=\'0 0 30 30\'%3e%3cpath stroke=\'rgba(0, 0, 0, 0.6)\' stroke-linecap=\'round\' stroke-miterlimit=\'10\' stroke-width=\'2\' d=\'M4 7h22M4 15h22M4 23h22\'/%3e%3c/svg%3e')

  .navbar-text
    color: rgba(0, 0, 0, 0.6)

    a
      color: rgba(0, 0, 0, 0.9)

      &:hover, &:focus
        color: rgba(0, 0, 0, 0.9)

.navbar-dark
  .navbar-brand
    color: rgba(255, 255, 255, 0.65)

    &:hover, &:focus
      color: rgba(255, 255, 255, 0.65)

  .navbar-nav
    .nav-link
      color: #000
      font-weight: 500 !important

      &:hover, &:focus

      &.disabled
        color: rgba(255, 255, 255, 0.25)

    .show > .nav-link, .active > .nav-link
      color: #031d6d

    .nav-link
      &.show, &.active
        color: #031d6d

  .navbar-toggler
    color: rgba(255, 255, 255, 0.95)
    border-color: transparent

  .navbar-toggler-icon
    background-image: url('data:image/svg+xml,%3csvg xmlns=\'http://www.w3.org/2000/svg\' width=\'30\' height=\'30\' viewBox=\'0 0 30 30\'%3e%3cpath stroke=\'rgba(255, 255, 255, 0.95)\' stroke-linecap=\'round\' stroke-miterlimit=\'10\' stroke-width=\'2\' d=\'M4 7h22M4 15h22M4 23h22\'/%3e%3c/svg%3e')

  .navbar-text
    color: rgba(255, 255, 255, 0.95)

    a
      color: rgba(255, 255, 255, 0.65)

      &:hover, &:focus
        color: rgba(255, 255, 255, 0.65)

.card
  position: relative
  display: flex
  flex-direction: column
  min-width: 0
  word-wrap: break-word
  border: 1px solid rgba(0, 0, 0, 0.05)
  border-radius: .375rem
  background-color: #fff
  background-clip: border-box

.card-a
  position: relative
  display: flex
  flex-direction: column
  min-width: 0
  word-wrap: break-word
  border: 1px solid rgba(0, 0, 0, 0.05)
  border-radius: .375rem
  background-color: #fff
  background-clip: border-box
  margin-bottom: 10px

.card >
hr
  margin-right: 0
  margin-left: 0

  .list-group
    &:first-child .list-group-item:first-child
      border-top-left-radius: .375rem
      border-top-right-radius: .375rem

    &:last-child .list-group-item:last-child
      border-bottom-right-radius: .375rem
      border-bottom-left-radius: .375rem

.card-body
  min-height: 1px
  padding: 1.5rem
  flex: 1 1 auto

.card-title
  margin-bottom: 1.25rem

.card-subtitle
  margin-top: -.625rem
  margin-bottom: 0

.card-text:last-child
  margin-bottom: 0

.card-link
  &:hover
    text-decoration: none

  + .card-link
    margin-left: 1.5rem

.card-header
  margin-bottom: 0
  padding: 1.25rem 1.5rem
  border-bottom: 1px solid rgba(0, 0, 0, 0.05)
  background-color: #fff

  &:first-child
    border-radius: calc(.375rem - 1px) calc(.375rem - 1px) 0 0

  + .list-group .list-group-item:first-child
    border-top: 0

.card-footer
  padding: 1.25rem 1.5rem
  border-top: 1px solid rgba(0, 0, 0, 0.05)
  background-color: #fff

  &:last-child
    border-radius: 0 0 calc(.375rem - 1px) calc(.375rem - 1px)

.card-header-tabs
  margin-right: -.75rem
  margin-bottom: -1.25rem
  margin-left: -.75rem
  border-bottom: 0

.card-header-pills
  margin-right: -.75rem
  margin-left: -.75rem

.card-img-overlay
  position: absolute
  top: 0
  right: 0
  bottom: 0
  left: 0
  padding: 1.25rem

.card-img, .card-img-top, .card-img-bottom
  width: 100%
  flex-shrink: 0

.card-img, .card-img-top
  border-top-left-radius: calc(.375rem - 1px)
  border-top-right-radius: calc(.375rem - 1px)

.card-img, .card-img-bottom
  border-bottom-right-radius: calc(.375rem - 1px)
  border-bottom-left-radius: calc(.375rem - 1px)

.card-deck .card, .card-group > .card
  margin-bottom: 15px

@media (min-width: 576px)
  .card-deck
    display: flex
    margin-right: -15px
    margin-left: -15px
    flex-flow: row wrap

    .card
      margin-right: 15px
      margin-bottom: 0
      margin-left: 15px
      flex: 1 0

@media (min-width: 576px)
  .card-group
    display: flex
    flex-flow: row wrap

    > .card
      margin-bottom: 0
      flex: 1 0

      + .card
        margin-left: 0
        border-left: 0

      &:not(:last-child)
        border-top-right-radius: 0
        border-bottom-right-radius: 0

        .card-img-top, .card-header
          border-top-right-radius: 0

        .card-img-bottom, .card-footer
          border-bottom-right-radius: 0

      &:not(:first-child)
        border-top-left-radius: 0
        border-bottom-left-radius: 0

        .card-img-top, .card-header
          border-top-left-radius: 0

        .card-img-bottom, .card-footer
          border-bottom-left-radius: 0

.card-columns .card
  margin-bottom: 1.25rem

@media (min-width: 576px)
  .card-columns
    column-count: 3
    column-gap: 1.25rem
    orphans: 1
    widows: 1

    .card
      display: inline-block
      width: 100%

.accordion > .card
  overflow: hidden

  &:not(:last-of-type)
    border-bottom: 0
    border-bottom-right-radius: 0
    border-bottom-left-radius: 0

  &:not(:first-of-type)
    border-top-left-radius: 0
    border-top-right-radius: 0

  > .card-header
    margin-bottom: -1px
    border-radius: 0

.breadcrumb
  display: flex
  margin-bottom: 1rem
  padding: .75rem 1rem
  list-style: none
  border-radius: .375rem
  background-color: #e9ecef
  flex-wrap: wrap

.breadcrumb-item
  + .breadcrumb-item
    padding-left: .5rem

    &::before
      display: inline-block
      padding-right: .5rem
      content: '/'
      color: #8898aa

    &:hover::before
      text-decoration: underline
      text-decoration: none

  &.active
    color: #8898aa

.pagination
  display: flex
  padding-left: 0
  list-style: none
  float: right
  border-radius: .375rem

.page-link
  line-height: 1.25
  position: relative
  display: block
  margin-left: -1px
  padding: .5rem .75rem
  color: #8898aa
  border: 1px solid #dee2e6
  background-color: #fff

  &:hover
    z-index: 2
    text-decoration: none
    color: #8898aa
    border-color: #dee2e6
    background-color: #dee2e6

  &:focus
    z-index: 3
    outline: 0
    box-shadow: none

.page-item
  &:first-child .page-link
    margin-left: 0
    border-top-left-radius: .375rem
    border-bottom-left-radius: .375rem

  &:last-child .page-link
    border-top-right-radius: .375rem
    border-bottom-right-radius: .375rem

  &.active .page-link
    color: #383232
    border: none
    background-color: #ececec
    box-shadow: none !important

  &.disabled .page-link
    cursor: auto
    pointer-events: none
    color: #8898aa
    border-color: #dee2e6
    background-color: #fff

.pagination-lg
  .page-link
    font-size: 1.25rem
    line-height: 1.5
    padding: .75rem 1.5rem

  .page-item
    &:first-child .page-link
      border-top-left-radius: .4375rem
      border-bottom-left-radius: .4375rem

    &:last-child .page-link
      border-top-right-radius: .4375rem
      border-bottom-right-radius: .4375rem

.pagination-sm
  .page-link
    font-size: .875rem
    line-height: 1.5
    padding: .25rem .5rem

  .page-item
    &:first-child .page-link
      border-top-left-radius: .25rem
      border-bottom-left-radius: .25rem

    &:last-child .page-link
      border-top-right-radius: .25rem
      border-bottom-right-radius: .25rem

.badge
  font-size: 66%
  font-weight: 600
  line-height: 1
  display: inline-block
  padding: .35rem .375rem
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
  text-align: center
  vertical-align: baseline
  white-space: nowrap
  border-radius: .375rem

@media (prefers-reduced-motion: reduce)
  .badge
    transition: none

a.badge
  &:hover, &:focus
    text-decoration: none

.badge:empty
  display: none

.btn .badge
  position: relative
  top: -1px

.badge-pill
  padding-right: .875em
  padding-left: .875em
  border-radius: 10rem

.badge-primary
  color: #2643e9
  background-color: #eaecfb

  &[href]
    &:hover, &:focus
      text-decoration: none
      color: #fff
      background-color: #2a44db

.badge-secondary
  color: #cfe3f1
  background-color: white

  &[href]
    &:hover, &:focus
      text-decoration: none
      color: #212529
      background-color: #cadeeb

.badge-success
  color: #1aae6f
  background-color: #b0eed3

  &[href]
    &:hover, &:focus
      text-decoration: none
      color: #fff
      background-color: #229c68

.badge-info
  color: #03acca
  background-color: #aaedf9

  &[href]
    &:hover, &:focus
      text-decoration: none
      color: #fff
      background-color: #0c9cb7

.badge-warning
  color: #ff3709
  background-color: #fee6e0

  &[href]
    &:hover, &:focus
      text-decoration: none
      color: #fff
      background-color: #f93305

.badge-danger
  color: #f80031
  background-color: #fdd1da

  &[href]
    &:hover, &:focus
      text-decoration: none
      color: #fff
      background-color: #e30b36

.badge-light
  color: #879cb0
  background-color: white

  &[href]
    &:hover, &:focus
      text-decoration: none
      color: #fff
      background-color: #8b96a2

.badge-dark
  color: #090c0e
  background-color: #6a7783

  &[href]
    &:hover, &:focus
      text-decoration: none
      color: #fff
      background-color: #060607

.badge-default
  color: #091428
  background-color: #4172c6

  &[href]
    &:hover, &:focus
      text-decoration: none
      color: #fff
      background-color: #09111e

.badge-white
  color: #e8e3e3
  background-color: white

  &[href]
    &:hover, &:focus
      text-decoration: none
      color: #212529
      background-color: #e0e0e0

.badge-neutral
  color: #e8e3e3
  background-color: white

  &[href]
    &:hover, &:focus
      text-decoration: none
      color: #212529
      background-color: #e0e0e0

.badge-darker
  color: black
  background-color: #525252

  &[href]
    &:hover, &:focus
      text-decoration: none
      color: #fff
      background-color: black

.jumbotron
  margin-bottom: 2rem
  padding: 2rem 1rem
  border-radius: .4375rem
  background-color: #e9ecef

@media (min-width: 576px)
  .jumbotron
    padding: 4rem 2rem

.jumbotron-fluid
  padding-right: 0
  padding-left: 0
  border-radius: 0

.alert
  position: relative
  margin-bottom: 1rem
  padding: 1rem 1.5rem
  border: 1px solid transparent
  border-radius: .375rem

.alert-heading
  color: inherit

.alert-link
  font-weight: 600

.alert-dismissible
  padding-right: 4.5rem

  .close
    position: absolute
    top: 0
    right: 0
    padding: 1rem 1.5rem
    color: inherit

.alert-primary
  color: #fff
  border-color: #7889e8
  background-color: #7889e8

  a
    font-weight: 600
    color: #1d32aa

    &:hover
      color: #fff

  hr
    border-top-color: #6276e4

  .alert-link
    color: #324cdd

.alert-secondary
  color: #212529
  border-color: #f8fbfc
  background-color: #f8fbfc

  a
    font-weight: 600
    color: #8dbece

    &:hover
      color: #212529

  hr
    border-top-color: #e6f1f4

  .alert-link
    color: #d2e3ee

.alert-success
  color: #fff
  border-color: #4fd69c
  background-color: #4fd69c

  a
    font-weight: 600
    color: #1a724c

    &:hover
      color: #fff

  hr
    border-top-color: #3ad190

  .alert-link
    color: #24a46d

.alert-info
  color: #fff
  border-color: #37d5f2
  background-color: #37d5f2

  a
    font-weight: 600
    color: #097487

    &:hover
      color: #fff

  hr
    border-top-color: #1fd0f0

  .alert-link
    color: #0da5c0

.alert-warning
  color: #ffffff
  background-color: #031d6d
  margin-top: 10px

  a
    font-weight: 600
    color: #be2604

    &:hover
      color: #fff

  hr
    border-top-color: #fc6846

  .alert-link
    color: #fa3a0e

.alert-danger
  color: #fff
  border-color: #f75676
  background-color: #f75676

  a
    font-weight: 600
    color: #ac0829

    &:hover
      color: #fff

  hr
    border-top-color: #f63e62

  .alert-link
    color: #ec0c38

.alert-light
  color: #fff
  border-color: #bac1c8
  background-color: #bac1c8

  a
    font-weight: 600
    color: #677582

    &:hover
      color: #fff

  hr
    border-top-color: #acb4bd

  .alert-link
    color: #919ca6

.alert-dark
  color: #fff
  border-color: #45484b
  background-color: #45484b

  a
    font-weight: 600
    color: black

    &:hover
      color: #fff

  hr
    border-top-color: #393b3e

  .alert-link
    color: #0a0c0d

.alert-default
  color: #fff
  border-color: #3c4d69
  background-color: #3c4d69

  a
    font-weight: 600
    color: #040608

    &:hover
      color: #fff

  hr
    border-top-color: #334159

  .alert-link
    color: #0b1526

.alert-white, .alert-neutral
  color: #212529
  border-color: white
  background-color: white

  a
    font-weight: 600
    color: #b3b3b3

    &:hover
      color: #212529

  hr
    border-top-color: #f2f2f2

  .alert-link
    color: #e6e6e6

.alert-darker
  color: #fff
  border-color: #292929
  background-color: #292929

  a
    font-weight: 600
    color: black

    &:hover
      color: #fff

  hr
    border-top-color: #1c1c1c

  .alert-link
    color: black

@keyframes progress-bar-stripes
  from
    background-position: 1rem 0

  to
    background-position: 0 0

.progress
  font-size: .75rem
  display: flex
  overflow: hidden
  height: 1rem
  border-radius: .375rem
  background-color: #e9ecef
  box-shadow: inset 0 0.1rem 0.1rem rgba(0, 0, 0, 0.1)

.progress-bar
  display: flex
  overflow: hidden
  flex-direction: column
  transition: width .6s ease
  text-align: center
  white-space: nowrap
  color: #fff
  background-color: #5e72e4
  justify-content: center

@media (prefers-reduced-motion: reduce)
  .progress-bar
    transition: none

.progress-bar-striped
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent)
  background-size: 1rem 1rem

.progress-bar-animated
  animation: progress-bar-stripes 1s linear infinite

@media (prefers-reduced-motion: reduce)
  .progress-bar-animated
    animation: none

.media
  display: flex
  align-items: flex-start

.media-body
  flex: 1 1

.list-group
  display: flex
  flex-direction: column
  margin-bottom: 0
  padding-left: 0

.list-group-item-action
  width: 100%
  text-align: inherit
  color: #525f7f

  &:hover, &:focus
    z-index: 1
    text-decoration: none
    color: #525f7f
    background-color: #f6f9fc

  &:active
    color: #525f7f
    background-color: #e9ecef

.list-group-item
  position: relative
  display: block
  padding: 1rem 1rem
  border: 1px solid #e9ecef
  background-color: #fff

  &:first-child
    border-top-left-radius: .375rem
    border-top-right-radius: .375rem

  &:last-child
    border-bottom-right-radius: .375rem
    border-bottom-left-radius: .375rem

  &.disabled, &:disabled
    pointer-events: none
    color: #8898aa
    background-color: #fff

  &.active
    z-index: 2
    color: #fff
    border-color: #5e72e4
    background-color: #5e72e4

  + .list-group-item
    border-top-width: 0

    &.active
      margin-top: -1px
      border-top-width: 1px

.list-group-horizontal
  flex-direction: row

  .list-group-item
    &:first-child
      border-top-right-radius: 0
      border-bottom-left-radius: .375rem

    &:last-child
      border-top-right-radius: .375rem
      border-bottom-left-radius: 0

    &.active
      margin-top: 0

    + .list-group-item
      border-top-width: 1px
      border-left-width: 0

      &.active
        margin-left: -1px
        border-left-width: 1px

@media (min-width: 576px)
  .list-group-horizontal-sm
    flex-direction: row

    .list-group-item
      &:first-child
        border-top-right-radius: 0
        border-bottom-left-radius: .375rem

      &:last-child
        border-top-right-radius: .375rem
        border-bottom-left-radius: 0

      &.active
        margin-top: 0

      + .list-group-item
        border-top-width: 1px
        border-left-width: 0

        &.active
          margin-left: -1px
          border-left-width: 1px

@media (min-width: 768px)
  .list-group-horizontal-md
    flex-direction: row

    .list-group-item
      &:first-child
        border-top-right-radius: 0
        border-bottom-left-radius: .375rem

      &:last-child
        border-top-right-radius: .375rem
        border-bottom-left-radius: 0

      &.active
        margin-top: 0

      + .list-group-item
        border-top-width: 1px
        border-left-width: 0

        &.active
          margin-left: -1px
          border-left-width: 1px

@media (min-width: 992px)
  .list-group-horizontal-lg
    flex-direction: row

    .list-group-item
      &:first-child
        border-top-right-radius: 0
        border-bottom-left-radius: .375rem

      &:last-child
        border-top-right-radius: .375rem
        border-bottom-left-radius: 0

      &.active
        margin-top: 0

      + .list-group-item
        border-top-width: 1px
        border-left-width: 0

        &.active
          margin-left: -1px
          border-left-width: 1px

@media (min-width: 1200px)
  .list-group-horizontal-xl
    flex-direction: row

    .list-group-item
      &:first-child
        border-top-right-radius: 0
        border-bottom-left-radius: .375rem

      &:last-child
        border-top-right-radius: .375rem
        border-bottom-left-radius: 0

      &.active
        margin-top: 0

      + .list-group-item
        border-top-width: 1px
        border-left-width: 0

        &.active
          margin-left: -1px
          border-left-width: 1px

.list-group-flush
  .list-group-item
    border-right-width: 0
    border-left-width: 0
    border-radius: 0

    &:first-child
      border-top-width: 0

  &:last-child .list-group-item:last-child
    border-bottom-width: 0

.list-group-item-primary
  color: #313b77
  background-color: #d2d8f7

  &.list-group-item-action
    &:hover, &:focus
      color: #313b77
      background-color: #bcc5f3

    &.active
      color: #fff
      border-color: #313b77
      background-color: #313b77

.list-group-item-secondary
  color: #808283
  background-color: #fdfefe

  &.list-group-item-action
    &:hover, &:focus
      color: #808283
      background-color: #ecf6f6

    &.active
      color: #fff
      border-color: #808283
      background-color: #808283

.list-group-item-success
  color: #176b47
  background-color: #c4f1de

  &.list-group-item-action
    &:hover, &:focus
      color: #176b47
      background-color: #afecd2

    &.active
      color: #fff
      border-color: #176b47
      background-color: #176b47

.list-group-item-info
  color: #096b7c
  background-color: #bcf1fb

  &.list-group-item-action
    &:hover, &:focus
      color: #096b7c
      background-color: #a4ecfa

    &.active
      color: #fff
      border-color: #096b7c
      background-color: #096b7c

.list-group-item-warning
  color: #833321
  background-color: #fed3ca

  &.list-group-item-action
    &:hover, &:focus
      color: #833321
      background-color: #febeb1

    &.active
      color: #fff
      border-color: #833321
      background-color: #833321

.list-group-item-danger
  color: #7f1c30
  background-color: #fcc7d1

  &.list-group-item-action
    &:hover, &:focus
      color: #7f1c30
      background-color: #fbafbd

    &.active
      color: #fff
      border-color: #7f1c30
      background-color: #7f1c30

.list-group-item-light
  color: #5a5e62
  background-color: #e8eaed

  &.list-group-item-action
    &:hover, &:focus
      color: #5a5e62
      background-color: #dadde2

    &.active
      color: #fff
      border-color: #5a5e62
      background-color: #5a5e62

.list-group-item-dark
  color: #111315
  background-color: #c1c2c3

  &.list-group-item-action
    &:hover, &:focus
      color: #111315
      background-color: #b4b5b6

    &.active
      color: #fff
      border-color: #111315
      background-color: #111315

.list-group-item-default
  color: #0c1628
  background-color: #bec4cd

  &.list-group-item-action
    &:hover, &:focus
      color: #0c1628
      background-color: #b0b7c2

    &.active
      color: #fff
      border-color: #0c1628
      background-color: #0c1628

.list-group-item-white
  color: #858585
  background-color: white

  &.list-group-item-action
    &:hover, &:focus
      color: #858585
      background-color: #f2f2f2

    &.active
      color: #fff
      border-color: #858585
      background-color: #858585

.list-group-item-neutral
  color: #858585
  background-color: white

  &.list-group-item-action
    &:hover, &:focus
      color: #858585
      background-color: #f2f2f2

    &.active
      color: #fff
      border-color: #858585
      background-color: #858585

.list-group-item-darker
  color: black
  background-color: #b8b8b8

  &.list-group-item-action
    &:hover, &:focus
      color: black
      background-color: #ababab

    &.active
      color: #fff
      border-color: black
      background-color: black

.close
  font-size: 1.5rem
  font-weight: 600
  line-height: 1
  float: right
  opacity: .5
  color: rgba(0, 0, 0, 0.6)
  text-shadow: none

  &:hover
    text-decoration: none
    color: rgba(0, 0, 0, 0.6)

  &:not(:disabled):not(.disabled)
    &:hover, &:focus
      opacity: .75

@media (max-width: 1200px)
  .close
    font-size: calc(1.275rem + .3vw)

button.close
  padding: 0
  border: 0
  background-color: transparent
  -webkit-appearance: none
  -moz-appearance: none
  appearance: none

a.close.disabled
  pointer-events: none

.modal-open
  overflow: hidden

  .modal
    overflow-x: hidden
    overflow-y: auto
    background: rgb(0 0 0 / 50%)

.modal
  position: fixed
  z-index: 1050
  top: 0
  left: 0
  display: none
  overflow: hidden
  width: 100%
  height: 100%
  outline: 0

.modal-dialog
  position: relative
  width: auto
  margin: .5rem
  pointer-events: none

.modal
  &.fade .modal-dialog
    transition: transform .3s ease-out
    transform: translate(0, -50px)

  &.show .modal-dialog
    transform: none

  &.modal-static .modal-dialog
    transform: scale(1.02)

@media (prefers-reduced-motion: reduce)
  .modal.fade .modal-dialog
    transition: none

.modal-dialog-scrollable
  display: flex
  max-height: calc(100% - 1rem)

  .modal-content
    overflow: hidden
    max-height: calc(100vh - 1rem)

  .modal-header, .modal-footer
    flex-shrink: 0

  .modal-body
    overflow-y: auto

.modal-dialog-centered
  display: flex
  min-height: calc(100% - 1rem)
  align-items: center

  &::before
    display: block
    height: calc(100vh - 1rem)
    content: ''

  &.modal-dialog-scrollable
    flex-direction: column
    height: 100%
    justify-content: center

    .modal-content
      max-height: none

    &::before
      content: none

.modal-content
  position: relative
  display: flex
  flex-direction: column
  width: 100%
  pointer-events: auto
  border: 0 solid rgba(0, 0, 0, 0.2)
  border-radius: .4375rem
  outline: 0
  background-color: #fff
  background-clip: padding-box
  box-shadow: 0 15px 35px rgba(50, 50, 93, 0.2), 0 5px 15px rgba(0, 0, 0, 0.17)

.modal-backdrop
  position: fixed
  z-index: 1040
  top: 0
  left: 0
  width: 100vw
  height: 100vh
  background-color: #000

  &.fade
    opacity: 0

  &.show
    opacity: .16

.modal-header
  background: #f3f3f3
  display: flex
  padding: 1.25rem
  border-bottom: 0 solid #e9ecef
  border-top-left-radius: .4375rem
  border-top-right-radius: .4375rem
  align-items: flex-start
  justify-content: space-between

  .close
    margin: -1rem -1rem -1rem auto
    padding: 1.25rem

.modal-title
  margin-bottom: 0
  text-transform: uppercase
  line-height: inherit

.modal-body
  position: relative
  padding: 1.5rem
  flex: 1 1 auto

.modal-footer
  display: inline-flex
  background: #fff
  padding: 1.25rem
  border-top: 0 solid #e9ecef
  border-bottom-right-radius: .4375rem
  border-bottom-left-radius: .4375rem

  /* flex-wrap: wrap;
  align-items: center
  justify-content: center
  width: 100%

  button
    width: 100%

  > *
    margin: .25rem

.modal-scrollbar-measure
  position: absolute
  top: -9999px
  overflow: scroll
  width: 50px
  height: 50px

@media (min-width: 576px)
  .modal-dialog
    max-width: 500px
    margin: 1.75rem auto

  .modal-dialog-scrollable
    max-height: calc(100% - 3.5rem)

    .modal-content
      max-height: calc(100vh - 3.5rem)

  .modal-dialog-centered
    min-height: calc(100% - 3.5rem)

    &::before
      height: calc(100vh - 3.5rem)

  .modal-content
    box-shadow: 0 15px 35px rgba(50, 50, 93, 0.2), 0 5px 15px rgba(0, 0, 0, 0.17)

  .modal-sm
    max-width: 380px

@media (min-width: 992px)
  .modal-lg, .modal-xl
    max-width: 800px

@media (min-width: 1200px)
  .modal-xl
    max-width: 1140px

.tooltip
  font-family: Open Sans, sans-serif
  font-size: .875rem
  font-weight: 400
  font-style: normal
  line-height: 1.5
  position: absolute
  z-index: 1070
  display: block
  margin: 0
  text-align: left
  text-align: start
  white-space: normal
  text-decoration: none
  letter-spacing: normal
  word-spacing: normal
  text-transform: none
  word-wrap: break-word
  word-break: normal
  opacity: 0
  text-shadow: none
  line-break: auto

  &.show
    opacity: .9

  .arrow
    position: absolute
    display: block
    width: .8rem
    height: .4rem

    &::before
      position: absolute
      content: ''
      border-style: solid
      border-color: transparent

.bs-tooltip-top, .bs-tooltip-auto[x-placement^='top']
  padding: .4rem 0

.bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^='top'] .arrow
  bottom: 0

.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^='top'] .arrow::before
  top: 0
  border-width: .4rem .4rem 0
  border-top-color: #000

.bs-tooltip-right, .bs-tooltip-auto[x-placement^='right']
  padding: 0 .4rem

.bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^='right'] .arrow
  left: 0
  width: .4rem
  height: .8rem

.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^='right'] .arrow::before
  right: 0
  border-width: .4rem .4rem .4rem 0
  border-right-color: #000

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^='bottom']
  padding: .4rem 0

.bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^='bottom'] .arrow
  top: 0

.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^='bottom'] .arrow::before
  bottom: 0
  border-width: 0 .4rem .4rem
  border-bottom-color: #000

.bs-tooltip-left, .bs-tooltip-auto[x-placement^='left']
  padding: 0 .4rem

.bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^='left'] .arrow
  right: 0
  width: .4rem
  height: .8rem

.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^='left'] .arrow::before
  left: 0
  border-width: .4rem 0 .4rem .4rem
  border-left-color: #000

.tooltip-inner
  max-width: 200px
  padding: .25rem .5rem
  text-align: center
  color: #fff
  border-radius: .375rem
  background-color: #000

.popover
  font-size: .875rem
  font-weight: 400
  font-style: normal
  line-height: 1.5
  position: absolute
  z-index: 1060
  top: 0
  left: 0
  display: block
  max-width: 276px
  text-align: left
  text-align: start
  white-space: normal
  text-decoration: none
  letter-spacing: normal
  word-spacing: normal
  text-transform: none
  word-wrap: break-word
  word-break: normal
  border: 1px solid rgba(0, 0, 0, 0.05)
  border-radius: .4375rem
  background-color: #fff
  background-clip: padding-box
  box-shadow: 0 0.5rem 2rem 0 rgba(0, 0, 0, 0.2)
  text-shadow: none
  line-break: auto

  .arrow
    position: absolute
    display: block
    width: 1rem
    height: .5rem
    margin: 0 .4375rem

    &::before, &::after
      position: absolute
      display: block
      content: ''
      border-style: solid
      border-color: transparent

.bs-popover-top, .bs-popover-auto[x-placement^='top']
  margin-bottom: .5rem

.bs-popover-top > .arrow, .bs-popover-auto[x-placement^='top'] > .arrow
  bottom: calc(-.5rem - 1px)

.bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^='top'] > .arrow::before
  bottom: 0
  border-width: .5rem .5rem 0
  border-top-color: transparent

.bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^='top'] > .arrow::after
  bottom: 1px
  border-width: .5rem .5rem 0
  border-top-color: #fff

.bs-popover-right, .bs-popover-auto[x-placement^='right']
  margin-left: .5rem

.bs-popover-right > .arrow, .bs-popover-auto[x-placement^='right'] > .arrow
  left: calc(-.5rem - 1px)
  width: .5rem
  height: 1rem
  margin: .4375rem 0

.bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^='right'] > .arrow::before
  left: 0
  border-width: .5rem .5rem .5rem 0
  border-right-color: transparent

.bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^='right'] > .arrow::after
  left: 1px
  border-width: .5rem .5rem .5rem 0
  border-right-color: #fff

.bs-popover-bottom, .bs-popover-auto[x-placement^='bottom']
  margin-top: .5rem

.bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^='bottom'] > .arrow
  top: calc(-.5rem - 1px)

.bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^='bottom'] > .arrow::before
  top: 0
  border-width: 0 .5rem .5rem .5rem
  border-bottom-color: transparent

.bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^='bottom'] > .arrow::after
  top: 1px
  border-width: 0 .5rem .5rem .5rem
  border-bottom-color: #fff

.bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^='bottom'] .popover-header::before
  position: absolute
  top: 0
  left: 50%
  display: block
  width: 1rem
  margin-left: -.5rem
  content: ''
  border-bottom: 1px solid #fff

.bs-popover-left, .bs-popover-auto[x-placement^='left']
  margin-right: .5rem

.bs-popover-left > .arrow, .bs-popover-auto[x-placement^='left'] > .arrow
  right: calc(-.5rem - 1px)
  width: .5rem
  height: 1rem
  margin: .4375rem 0

.bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^='left'] > .arrow::before
  right: 0
  border-width: .5rem 0 .5rem .5rem
  border-left-color: transparent

.bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^='left'] > .arrow::after
  right: 1px
  border-width: .5rem 0 .5rem .5rem
  border-left-color: #fff

.popover-header
  font-size: 1rem
  margin-bottom: 0
  padding: .5rem .95rem
  color: #32325d
  border-bottom: 1px solid #f2f2f2
  border-top-left-radius: calc(.4375rem - 1px)
  border-top-right-radius: calc(.4375rem - 1px)
  background-color: #fff

  &:empty
    display: none

.popover-body
  padding: .5rem .95rem
  color: #525f7f

.carousel
  position: relative

  &.pointer-event
    touch-action: pan-y

.carousel-inner
  position: relative
  overflow: hidden
  width: 100%

  &::after
    display: block
    clear: both
    content: ''

.carousel-item
  position: relative
  display: none
  float: left
  width: 100%
  margin-right: -100%
  transition: transform .6s ease-in-out
  -webkit-backface-visibility: hidden
  backface-visibility: hidden

  &.active
    display: block

@media (prefers-reduced-motion: reduce)
  .carousel-item
    transition: none

.carousel-item-next, .carousel-item-prev
  display: block

.carousel-item-next:not(.carousel-item-left), .active.carousel-item-right
  transform: translateX(100%)

.carousel-item-prev:not(.carousel-item-right), .active.carousel-item-left
  transform: translateX(-100%)

.carousel-fade
  .carousel-item
    transition-property: opacity
    transform: none
    opacity: 0

    &.active
      z-index: 1
      opacity: 1

  .carousel-item-next.carousel-item-left, .carousel-item-prev.carousel-item-right
    z-index: 1
    opacity: 1

  .active
    &.carousel-item-left, &.carousel-item-right
      z-index: 0
      transition: opacity 0s .6s
      opacity: 0

@media (prefers-reduced-motion: reduce)
  .carousel-fade .active
    &.carousel-item-left, &.carousel-item-right
      transition: none

.carousel-control-prev, .carousel-control-next
  position: absolute
  z-index: 1
  top: 0
  bottom: 0
  display: flex
  width: 15%
  transition: opacity .15s ease
  text-align: center
  opacity: .5
  color: #fff
  align-items: center
  justify-content: center

@media (prefers-reduced-motion: reduce)
  .carousel-control-prev, .carousel-control-next
    transition: none

.carousel-control-prev
  &:hover, &:focus
    text-decoration: none
    opacity: .9
    color: #fff
    outline: 0

.carousel-control-next
  &:hover, &:focus
    text-decoration: none
    opacity: .9
    color: #fff
    outline: 0

.carousel-control-prev
  left: 0

.carousel-control-next
  right: 0

.carousel-control-prev-icon, .carousel-control-next-icon
  display: inline-block
  width: 20px
  height: 20px
  background: no-repeat 50% / 100% 100%

.carousel-control-prev-icon
  background-image: url('data:image/svg+xml,%3csvg xmlns=\'http://www.w3.org/2000/svg\' fill=\'%23fff\' width=\'8\' height=\'8\' viewBox=\'0 0 8 8\'%3e%3cpath d=\'M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z\'/%3e%3c/svg%3e')

.carousel-control-next-icon
  background-image: url('data:image/svg+xml,%3csvg xmlns=\'http://www.w3.org/2000/svg\' fill=\'%23fff\' width=\'8\' height=\'8\' viewBox=\'0 0 8 8\'%3e%3cpath d=\'M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z\'/%3e%3c/svg%3e')

.carousel-indicators
  position: absolute
  z-index: 15
  right: 0
  bottom: 0
  left: 0
  display: flex
  margin-right: 15%
  margin-left: 15%
  padding-left: 0
  list-style: none
  justify-content: center

  li
    box-sizing: content-box
    width: 30px
    height: 3px
    margin-right: 3px
    margin-left: 3px
    cursor: pointer
    transition: opacity .6s ease
    text-indent: -999px
    opacity: .5
    border-top: 10px solid transparent
    border-bottom: 10px solid transparent
    background-color: #fff
    background-clip: padding-box
    flex: 0 1 auto

  .active
    opacity: 1

@media (prefers-reduced-motion: reduce)
  .carousel-indicators li
    transition: none

.carousel-caption
  position: absolute
  z-index: 10
  right: 15%
  bottom: 20px
  left: 15%
  padding-top: 20px
  padding-bottom: 20px
  text-align: center
  color: #fff

.align-baseline
  vertical-align: baseline !important

.align-top
  vertical-align: top !important

.align-middle
  vertical-align: middle !important

.align-bottom
  vertical-align: bottom !important

.align-text-bottom
  vertical-align: text-bottom !important

.align-text-top
  vertical-align: text-top !important

.bg-primary
  background-color: #5e72e4 !important

a.bg-primary
  &:hover, &:focus
    background-color: #324cdd !important

button.bg-primary
  &:hover, &:focus
    background-color: #324cdd !important

.bg-secondary
  background-color: #f7fafc !important

a.bg-secondary
  &:hover, &:focus
    background-color: #d2e3ee !important

button.bg-secondary
  &:hover, &:focus
    background-color: #d2e3ee !important

.bg-success
  background-color: #2dce89 !important

a.bg-success
  &:hover, &:focus
    background-color: #24a46d !important

button.bg-success
  &:hover, &:focus
    background-color: #24a46d !important

.bg-info
  background-color: #11cdef !important

a.bg-info
  &:hover, &:focus
    background-color: #0da5c0 !important

button.bg-info
  &:hover, &:focus
    background-color: #0da5c0 !important

.bg-warning
  background-color: #fb6340 !important

a.bg-warning
  &:hover, &:focus
    background-color: #fa3a0e !important

button.bg-warning
  &:hover, &:focus
    background-color: #fa3a0e !important

.bg-danger
  background-color: #f5365c !important

a.bg-danger
  &:hover, &:focus
    background-color: #ec0c38 !important

button.bg-danger
  &:hover, &:focus
    background-color: #ec0c38 !important

.bg-light
  background-color: #adb5bd !important

a.bg-light
  &:hover, &:focus
    background-color: #919ca6 !important

button.bg-light
  &:hover, &:focus
    background-color: #919ca6 !important

.bg-dark
  background-color: #212529 !important

a.bg-dark
  &:hover, &:focus
    background-color: #0a0c0d !important

button.bg-dark
  &:hover, &:focus
    background-color: #0a0c0d !important

.bg-default
  background-color: #172b4d !important

a.bg-default
  &:hover, &:focus
    background-color: #0b1526 !important

button.bg-default
  &:hover, &:focus
    background-color: #0b1526 !important

.bg-white
  background-color: #fff !important

a.bg-white
  &:hover, &:focus
    background-color: #e6e6e6 !important

button.bg-white
  &:hover, &:focus
    background-color: #e6e6e6 !important

.bg-neutral
  background-color: #fff !important

a.bg-neutral
  &:hover, &:focus
    background-color: #e6e6e6 !important

button.bg-neutral
  &:hover, &:focus
    background-color: #e6e6e6 !important

.bg-darker
  background-color: black !important

a.bg-darker
  &:hover, &:focus
    background-color: black !important

button.bg-darker
  &:hover, &:focus
    background-color: black !important

.bg-white
  background-color: #fff !important

.bg-transparent
  background-color: transparent !important

.border
  border: 1px solid #e9ecef !important

.border-top
  border-top: 1px solid #e9ecef !important

.border-right
  border-right: 1px solid #e9ecef !important

.border-bottom
  border-bottom: 1px solid #e9ecef !important

.border-left
  border-left: 1px solid #e9ecef !important

.border-0
  border: 0 !important

.border-top-0
  border-top: 0 !important

.border-right-0
  border-right: 0 !important

.border-bottom-0
  border-bottom: 0 !important

.border-left-0
  border-left: 0 !important

.border-primary
  border-color: #5e72e4 !important

.border-secondary
  border-color: #f7fafc !important

.border-success
  border-color: #2dce89 !important

.border-info
  border-color: #11cdef !important

.border-warning
  border-color: #fb6340 !important

.border-danger
  border-color: #f5365c !important

.border-light
  border-color: #adb5bd !important

.border-dark
  border-color: #212529 !important

.border-default
  border-color: #172b4d !important

.border-white, .border-neutral
  border-color: #fff !important

.border-darker
  border-color: black !important

.border-white
  border-color: #fff !important

.rounded-sm
  border-radius: .25rem !important

.rounded
  border-radius: .375rem !important

.rounded-top
  border-top-left-radius: .375rem !important
  border-top-right-radius: .375rem !important

.rounded-right
  border-top-right-radius: .375rem !important
  border-bottom-right-radius: .375rem !important

.rounded-bottom
  border-bottom-right-radius: .375rem !important
  border-bottom-left-radius: .375rem !important

.rounded-left
  border-top-left-radius: .375rem !important
  border-bottom-left-radius: .375rem !important

.rounded-lg
  border-radius: .4375rem !important

.rounded-circle, .avatar.rounded-circle img
  border-radius: 50% !important

.rounded-pill
  border-radius: 50rem !important

.rounded-0
  border-radius: 0 !important

.clearfix::after
  display: block
  clear: both
  content: ''

.d-none
  display: none !important

.d-inline
  display: inline !important

.d-inline-block
  display: inline-block !important

.d-block
  display: block !important

.d-table
  display: table !important

.d-table-row
  display: table-row !important

.d-table-cell
  display: table-cell !important

.d-flex
  display: flex !important

.d-inline-flex
  display: inline-flex !important

@media (min-width: 576px)
  .d-sm-none
    display: none !important

  .d-sm-inline
    display: inline !important

  .d-sm-inline-block
    display: inline-block !important

  .d-sm-block
    display: block !important

  .d-sm-table
    display: table !important

  .d-sm-table-row
    display: table-row !important

  .d-sm-table-cell
    display: table-cell !important

  .d-sm-flex
    display: flex !important

  .d-sm-inline-flex
    display: inline-flex !important

@media (min-width: 768px)
  .d-md-none
    display: none !important

  .d-md-inline
    display: inline !important

  .d-md-inline-block
    display: inline-block !important

  .d-md-block
    display: block !important

  .d-md-table
    display: table !important

  .d-md-table-row
    display: table-row !important

  .d-md-table-cell
    display: table-cell !important

  .d-md-flex
    display: flex !important

  .d-md-inline-flex
    display: inline-flex !important

@media (min-width: 992px)
  .d-lg-none
    display: none !important

  .d-lg-inline
    display: inline !important

  .d-lg-inline-block
    display: inline-block !important

  .d-lg-block
    display: block !important

  .d-lg-table
    display: table !important

  .d-lg-table-row
    display: table-row !important

  .d-lg-table-cell
    display: table-cell !important

  .d-lg-flex
    display: flex !important

  .d-lg-inline-flex
    display: inline-flex !important

@media (min-width: 1200px)
  .d-xl-none
    display: none !important

  .d-xl-inline
    display: inline !important

  .d-xl-inline-block
    display: inline-block !important

  .d-xl-block
    display: block !important

  .d-xl-table
    display: table !important

  .d-xl-table-row
    display: table-row !important

  .d-xl-table-cell
    display: table-cell !important

  .d-xl-flex
    display: flex !important

  .d-xl-inline-flex
    display: inline-flex !important

@media print
  .d-print-none
    display: none !important

  .d-print-inline
    display: inline !important

  .d-print-inline-block
    display: inline-block !important

  .d-print-block
    display: block !important

  .d-print-table
    display: table !important

  .d-print-table-row
    display: table-row !important

  .d-print-table-cell
    display: table-cell !important

  .d-print-flex
    display: flex !important

  .d-print-inline-flex
    display: inline-flex !important

.embed-responsive
  position: relative
  display: block
  overflow: hidden
  width: 100%
  padding: 0

  &::before
    display: block
    content: ''

  .embed-responsive-item, iframe, embed, object, video
    position: absolute
    top: 0
    bottom: 0
    left: 0
    width: 100%
    height: 100%
    border: 0

.embed-responsive-21by9::before
  padding-top: 42.85714%

.embed-responsive-16by9::before
  padding-top: 56.25%

.embed-responsive-4by3::before
  padding-top: 75%

.embed-responsive-1by1::before
  padding-top: 100%

.embed-responsive-21by9::before
  padding-top: 42.85714%

.embed-responsive-16by9::before
  padding-top: 56.25%

.embed-responsive-4by3::before
  padding-top: 75%

.embed-responsive-1by1::before
  padding-top: 100%

.flex-row
  flex-direction: row !important

.flex-column
  flex-direction: column !important

.flex-row-reverse
  flex-direction: row-reverse !important

.flex-column-reverse
  flex-direction: column-reverse !important

.flex-wrap
  flex-wrap: wrap !important

.flex-nowrap
  flex-wrap: nowrap !important

.flex-wrap-reverse
  flex-wrap: wrap-reverse !important

.flex-fill
  flex: 1 1 auto !important

.flex-grow-0
  flex-grow: 0 !important

.flex-grow-1
  flex-grow: 1 !important

.flex-shrink-0
  flex-shrink: 0 !important

.flex-shrink-1
  flex-shrink: 1 !important

.justify-content-start
  justify-content: flex-start !important

.justify-content-end
  justify-content: flex-end !important

.justify-content-center
  justify-content: center !important

.justify-content-between
  justify-content: space-between !important

.justify-content-around
  justify-content: space-around !important

.align-items-start
  align-items: flex-start !important

.align-items-end
  align-items: flex-end !important

.align-items-center
  align-items: center !important

.align-items-baseline
  align-items: baseline !important

.align-items-stretch
  align-items: stretch !important

.align-content-start
  align-content: flex-start !important

.align-content-end
  align-content: flex-end !important

.align-content-center
  align-content: center !important

.align-content-between
  align-content: space-between !important

.align-content-around
  align-content: space-around !important

.align-content-stretch
  align-content: stretch !important

.align-self-auto
  align-self: auto !important

.align-self-start
  align-self: flex-start !important

.align-self-end
  align-self: flex-end !important

.align-self-center
  align-self: center !important

.align-self-baseline
  align-self: baseline !important

.align-self-stretch
  align-self: stretch !important

@media (min-width: 576px)
  .flex-sm-row
    flex-direction: row !important

  .flex-sm-column
    flex-direction: column !important

  .flex-sm-row-reverse
    flex-direction: row-reverse !important

  .flex-sm-column-reverse
    flex-direction: column-reverse !important

  .flex-sm-wrap
    flex-wrap: wrap !important

  .flex-sm-nowrap
    flex-wrap: nowrap !important

  .flex-sm-wrap-reverse
    flex-wrap: wrap-reverse !important

  .flex-sm-fill
    flex: 1 1 auto !important

  .flex-sm-grow-0
    flex-grow: 0 !important

  .flex-sm-grow-1
    flex-grow: 1 !important

  .flex-sm-shrink-0
    flex-shrink: 0 !important

  .flex-sm-shrink-1
    flex-shrink: 1 !important

  .justify-content-sm-start
    justify-content: flex-start !important

  .justify-content-sm-end
    justify-content: flex-end !important

  .justify-content-sm-center
    justify-content: center !important

  .justify-content-sm-between
    justify-content: space-between !important

  .justify-content-sm-around
    justify-content: space-around !important

  .align-items-sm-start
    align-items: flex-start !important

  .align-items-sm-end
    align-items: flex-end !important

  .align-items-sm-center
    align-items: center !important

  .align-items-sm-baseline
    align-items: baseline !important

  .align-items-sm-stretch
    align-items: stretch !important

  .align-content-sm-start
    align-content: flex-start !important

  .align-content-sm-end
    align-content: flex-end !important

  .align-content-sm-center
    align-content: center !important

  .align-content-sm-between
    align-content: space-between !important

  .align-content-sm-around
    align-content: space-around !important

  .align-content-sm-stretch
    align-content: stretch !important

  .align-self-sm-auto
    align-self: auto !important

  .align-self-sm-start
    align-self: flex-start !important

  .align-self-sm-end
    align-self: flex-end !important

  .align-self-sm-center
    align-self: center !important

  .align-self-sm-baseline
    align-self: baseline !important

  .align-self-sm-stretch
    align-self: stretch !important

@media (min-width: 768px)
  .flex-md-row
    flex-direction: row !important

  .flex-md-column
    flex-direction: column !important

  .flex-md-row-reverse
    flex-direction: row-reverse !important

  .flex-md-column-reverse
    flex-direction: column-reverse !important

  .flex-md-wrap
    flex-wrap: wrap !important

  .flex-md-nowrap
    flex-wrap: nowrap !important

  .flex-md-wrap-reverse
    flex-wrap: wrap-reverse !important

  .flex-md-fill
    flex: 1 1 auto !important

  .flex-md-grow-0
    flex-grow: 0 !important

  .flex-md-grow-1
    flex-grow: 1 !important

  .flex-md-shrink-0
    flex-shrink: 0 !important

  .flex-md-shrink-1
    flex-shrink: 1 !important

  .justify-content-md-start
    justify-content: flex-start !important

  .justify-content-md-end
    justify-content: flex-end !important

  .justify-content-md-center
    justify-content: center !important

  .justify-content-md-between
    justify-content: space-between !important

  .justify-content-md-around
    justify-content: space-around !important

  .align-items-md-start
    align-items: flex-start !important

  .align-items-md-end
    align-items: flex-end !important

  .align-items-md-center
    align-items: center !important

  .align-items-md-baseline
    align-items: baseline !important

  .align-items-md-stretch
    align-items: stretch !important

  .align-content-md-start
    align-content: flex-start !important

  .align-content-md-end
    align-content: flex-end !important

  .align-content-md-center
    align-content: center !important

  .align-content-md-between
    align-content: space-between !important

  .align-content-md-around
    align-content: space-around !important

  .align-content-md-stretch
    align-content: stretch !important

  .align-self-md-auto
    align-self: auto !important

  .align-self-md-start
    align-self: flex-start !important

  .align-self-md-end
    align-self: flex-end !important

  .align-self-md-center
    align-self: center !important

  .align-self-md-baseline
    align-self: baseline !important

  .align-self-md-stretch
    align-self: stretch !important

@media (min-width: 992px)
  .flex-lg-row
    flex-direction: row !important

  .flex-lg-column
    flex-direction: column !important

  .flex-lg-row-reverse
    flex-direction: row-reverse !important

  .flex-lg-column-reverse
    flex-direction: column-reverse !important

  .flex-lg-wrap
    flex-wrap: wrap !important

  .flex-lg-nowrap
    flex-wrap: nowrap !important

  .flex-lg-wrap-reverse
    flex-wrap: wrap-reverse !important

  .flex-lg-fill
    flex: 1 1 auto !important

  .flex-lg-grow-0
    flex-grow: 0 !important

  .flex-lg-grow-1
    flex-grow: 1 !important

  .flex-lg-shrink-0
    flex-shrink: 0 !important

  .flex-lg-shrink-1
    flex-shrink: 1 !important

  .justify-content-lg-start
    justify-content: flex-start !important

  .justify-content-lg-end
    justify-content: flex-end !important

  .justify-content-lg-center
    justify-content: center !important

  .justify-content-lg-between
    justify-content: space-between !important

  .justify-content-lg-around
    justify-content: space-around !important

  .align-items-lg-start
    align-items: flex-start !important

  .align-items-lg-end
    align-items: flex-end !important

  .align-items-lg-center
    align-items: center !important

  .align-items-lg-baseline
    align-items: baseline !important

  .align-items-lg-stretch
    align-items: stretch !important

  .align-content-lg-start
    align-content: flex-start !important

  .align-content-lg-end
    align-content: flex-end !important

  .align-content-lg-center
    align-content: center !important

  .align-content-lg-between
    align-content: space-between !important

  .align-content-lg-around
    align-content: space-around !important

  .align-content-lg-stretch
    align-content: stretch !important

  .align-self-lg-auto
    align-self: auto !important

  .align-self-lg-start
    align-self: flex-start !important

  .align-self-lg-end
    align-self: flex-end !important

  .align-self-lg-center
    align-self: center !important

  .align-self-lg-baseline
    align-self: baseline !important

  .align-self-lg-stretch
    align-self: stretch !important

@media (min-width: 1200px)
  .flex-xl-row
    flex-direction: row !important

  .flex-xl-column
    flex-direction: column !important

  .flex-xl-row-reverse
    flex-direction: row-reverse !important

  .flex-xl-column-reverse
    flex-direction: column-reverse !important

  .flex-xl-wrap
    flex-wrap: wrap !important

  .flex-xl-nowrap
    flex-wrap: nowrap !important

  .flex-xl-wrap-reverse
    flex-wrap: wrap-reverse !important

  .flex-xl-fill
    flex: 1 1 auto !important

  .flex-xl-grow-0
    flex-grow: 0 !important

  .flex-xl-grow-1
    flex-grow: 1 !important

  .flex-xl-shrink-0
    flex-shrink: 0 !important

  .flex-xl-shrink-1
    flex-shrink: 1 !important

  .justify-content-xl-start
    justify-content: flex-start !important

  .justify-content-xl-end
    justify-content: flex-end !important

  .justify-content-xl-center
    justify-content: center !important

  .justify-content-xl-between
    justify-content: space-between !important

  .justify-content-xl-around
    justify-content: space-around !important

  .align-items-xl-start
    align-items: flex-start !important

  .align-items-xl-end
    align-items: flex-end !important

  .align-items-xl-center
    align-items: center !important

  .align-items-xl-baseline
    align-items: baseline !important

  .align-items-xl-stretch
    align-items: stretch !important

  .align-content-xl-start
    align-content: flex-start !important

  .align-content-xl-end
    align-content: flex-end !important

  .align-content-xl-center
    align-content: center !important

  .align-content-xl-between
    align-content: space-between !important

  .align-content-xl-around
    align-content: space-around !important

  .align-content-xl-stretch
    align-content: stretch !important

  .align-self-xl-auto
    align-self: auto !important

  .align-self-xl-start
    align-self: flex-start !important

  .align-self-xl-end
    align-self: flex-end !important

  .align-self-xl-center
    align-self: center !important

  .align-self-xl-baseline
    align-self: baseline !important

  .align-self-xl-stretch
    align-self: stretch !important

.float-left
  float: left !important

.float-right
  float: right !important

.float-none
  float: none !important

@media (min-width: 576px)
  .float-sm-left
    float: left !important

  .float-sm-right
    float: right !important

  .float-sm-none
    float: none !important

@media (min-width: 768px)
  .float-md-left
    float: left !important

  .float-md-right
    float: right !important

  .float-md-none
    float: none !important

@media (min-width: 992px)
  .float-lg-left
    float: left !important

  .float-lg-right
    float: right !important

  .float-lg-none
    float: none !important

@media (min-width: 1200px)
  .float-xl-left
    float: left !important

  .float-xl-right
    float: right !important

  .float-xl-none
    float: none !important

.overflow-auto
  overflow: auto !important

.overflow-hidden
  overflow: hidden !important

.position-static
  position: static !important

.position-relative
  position: relative !important

.position-absolute
  position: absolute !important

.position-fixed
  position: fixed !important

.position-sticky
  position: -webkit-sticky !important
  position: sticky !important

.fixed-top
  position: fixed
  z-index: 1030
  top: 0
  right: 0
  left: 0

.fixed-bottom
  position: fixed
  z-index: 1030
  right: 0
  bottom: 0
  left: 0

@supports (position: -webkit-sticky) or (position: sticky)
  .sticky-top
    position: -webkit-sticky
    position: sticky
    z-index: 1020
    top: 0

.sr-only
  position: absolute
  overflow: hidden
  clip: rect(0, 0, 0, 0)
  width: 1px
  height: 1px
  margin: -1px
  padding: 0
  white-space: nowrap
  border: 0

.sr-only-focusable
  &:active, &:focus
    position: static
    overflow: visible
    clip: auto
    width: auto
    height: auto
    white-space: normal

.shadow-sm
  box-shadow: 0 0 0.5rem rgba(136, 152, 170, 0.075) !important

.shadow
  box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15) !important

.shadow-lg
  box-shadow: 0 0 3rem rgba(136, 152, 170, 0.175) !important

.shadow-none
  box-shadow: none !important

.w-25
  width: 25% !important

.w-50
  width: 50% !important

.w-75
  width: 75% !important

.w-100
  width: 100% !important

.w-auto
  width: auto !important

.h-25
  height: 25% !important

.h-50
  height: 50% !important

.h-75
  height: 75% !important

.h-100
  height: 100% !important

.h-auto
  height: auto !important

.mw-100
  max-width: 100% !important

.mh-100
  max-height: 100% !important

.min-vw-100
  min-width: 100vw !important

.min-vh-100
  min-height: 100vh !important

.vw-100
  width: 100vw !important

.vh-100
  height: 100vh !important

.stretched-link::after
  position: absolute
  z-index: 1
  top: 0
  right: 0
  bottom: 0
  left: 0
  content: ''
  pointer-events: auto
  background-color: rgba(0, 0, 0, 0)

.m-0
  margin: 0 !important

.mt-0, .my-0
  margin-top: 0 !important

.mr-0, .mx-0
  margin-right: 0 !important

.mb-0, .my-0
  margin-bottom: 0 !important

.ml-0, .mx-0
  margin-left: 0 !important

.m-1
  margin: .25rem !important

.mt-1, .my-1
  margin-top: .25rem !important

.mr-1, .mx-1
  margin-right: .25rem !important

.mb-1, .my-1
  margin-bottom: .25rem !important

.ml-1, .mx-1
  margin-left: .25rem !important

.m-2
  margin: .5rem !important

.mt-2, .my-2
  margin-top: .5rem !important

.mr-2, .mx-2
  margin-right: .5rem !important

.mb-2, .my-2
  margin-bottom: .5rem !important

.ml-2, .mx-2
  margin-left: .5rem !important

.m-3
  margin: 1rem !important

.mt-3, .my-3
  margin-top: 1rem !important

.mr-3, .mx-3
  margin-right: 1rem !important

.mb-3, .my-3
  margin-bottom: 1rem !important

.ml-3, .mx-3
  margin-left: 1rem !important

.m-4
  margin: 1.5rem !important

.mt-4, .my-4
  margin-top: 1.5rem !important

.mr-4, .mx-4
  margin-right: 1.5rem !important

.mb-4, .my-4
  margin-bottom: 1.5rem !important

.ml-4, .mx-4
  margin-left: 1.5rem !important

.m-5
  margin: 3rem !important

.mt-5, .my-5
  margin-top: 3rem !important

.mr-5, .mx-5
  margin-right: 3rem !important

.mb-5, .my-5
  margin-bottom: 3rem !important

.ml-5, .mx-5
  margin-left: 3rem !important

.m--9
  margin: -10rem !important

.mt--9, .my--9
  margin-top: -10rem !important

.mr--9, .mx--9
  margin-right: -10rem !important

.mb--9, .my--9
  margin-bottom: -10rem !important

.ml--9, .mx--9
  margin-left: -10rem !important

.m--8
  margin: -8rem !important

.mt--8, .my--8
  margin-top: -8rem !important

.mr--8, .mx--8
  margin-right: -8rem !important

.mb--8, .my--8
  margin-bottom: -8rem !important

.ml--8, .mx--8
  margin-left: -8rem !important

.m--7
  margin: -6rem !important

.mt--7, .my--7
  margin-top: -6rem !important

.mr--7, .mx--7
  margin-right: -6rem !important

.mb--7, .my--7
  margin-bottom: -6rem !important

.ml--7, .mx--7
  margin-left: -6rem !important

.m--6
  margin: -4.5rem !important

.mt--6, .my--6
  margin-top: -4.5rem !important

.mr--6, .mx--6
  margin-right: -4.5rem !important

.mb--6, .my--6
  margin-bottom: -4.5rem !important

.ml--6, .mx--6
  margin-left: -4.5rem !important

.m--5
  margin: -3rem !important

.mt--5, .my--5
  margin-top: -3rem !important

.mr--5, .mx--5
  margin-right: -3rem !important

.mb--5, .my--5
  margin-bottom: -3rem !important

.ml--5, .mx--5
  margin-left: -3rem !important

.m--4
  margin: -1.5rem !important

.mt--4, .my--4
  margin-top: -1.5rem !important

.mr--4, .mx--4
  margin-right: -1.5rem !important

.mb--4, .my--4
  margin-bottom: -1.5rem !important

.ml--4, .mx--4
  margin-left: -1.5rem !important

.m--3
  margin: -1rem !important

.mt--3, .my--3
  margin-top: -1rem !important

.mr--3, .mx--3
  margin-right: -1rem !important

.mb--3, .my--3
  margin-bottom: -1rem !important

.ml--3, .mx--3
  margin-left: -1rem !important

.m--2
  margin: -.5rem !important

.mt--2, .my--2
  margin-top: -.5rem !important

.mr--2, .mx--2
  margin-right: -.5rem !important

.mb--2, .my--2
  margin-bottom: -.5rem !important

.ml--2, .mx--2
  margin-left: -.5rem !important

.m--1
  margin: -.25rem !important

.mt--1, .my--1
  margin-top: -.25rem !important

.mr--1, .mx--1
  margin-right: -.25rem !important

.mb--1, .my--1
  margin-bottom: -.25rem !important

.ml--1, .mx--1
  margin-left: -.25rem !important

.m-6
  margin: 4.5rem !important

.mt-6, .my-6
  margin-top: 4.5rem !important

.mr-6, .mx-6
  margin-right: 4.5rem !important

.mb-6, .my-6
  margin-bottom: 4.5rem !important

.ml-6, .mx-6
  margin-left: 4.5rem !important

.m-7
  margin: 6rem !important

.mt-7, .my-7
  margin-top: 6rem !important

.mr-7, .mx-7
  margin-right: 6rem !important

.mb-7, .my-7
  margin-bottom: 6rem !important

.ml-7, .mx-7
  margin-left: 6rem !important

.m-8
  margin: 8rem !important

.mt-8, .my-8
  margin-top: 8rem !important

.mr-8, .mx-8
  margin-right: 8rem !important

.mb-8, .my-8
  margin-bottom: 8rem !important

.ml-8, .mx-8
  margin-left: 8rem !important

.m-9
  margin: 10rem !important

.mt-9, .my-9
  margin-top: 10rem !important

.mr-9, .mx-9
  margin-right: 10rem !important

.mb-9, .my-9
  margin-bottom: 10rem !important

.ml-9, .mx-9
  margin-left: 10rem !important

.p-0
  padding: 0 !important

.pt-0, .py-0
  padding-top: 0 !important

.pr-0, .px-0
  padding-right: 0 !important

.pb-0, .py-0
  padding-bottom: 0 !important

.pl-0, .px-0
  padding-left: 0 !important

.p-1
  padding: .25rem !important

.pt-1, .py-1
  padding-top: .25rem !important

.pr-1, .px-1
  padding-right: .25rem !important

.pb-1, .py-1
  padding-bottom: .25rem !important

.pl-1, .px-1
  padding-left: .25rem !important

.p-2
  padding: .5rem !important

.pt-2, .py-2
  padding-top: .5rem !important

.pr-2, .px-2
  padding-right: .5rem !important

.pb-2, .py-2
  padding-bottom: .5rem !important

.pl-2, .px-2
  padding-left: .5rem !important

.p-3
  padding: 1rem !important

.pt-3, .py-3
  padding-top: 1rem !important

.pr-3, .px-3
  padding-right: 1rem !important

.pb-3, .py-3
  padding-bottom: 1rem !important

.pl-3, .px-3
  padding-left: 1rem !important

.p-4
  padding: 1.5rem !important

.pt-4, .py-4
  padding-top: 1.5rem !important

.pr-4, .px-4
  padding-right: 1.5rem !important

.pb-4, .py-4
  padding-bottom: 1.5rem !important

.pl-4, .px-4
  padding-left: 1.5rem !important

.p-5
  padding: 3rem !important

.pt-5, .py-5
  padding-top: 3rem !important

.pr-5, .px-5
  padding-right: 3rem !important

.pb-5, .py-5
  padding-bottom: 3rem !important

.pl-5, .px-5
  padding-left: 3rem !important

.p--9
  padding: -10rem !important

.pt--9, .py--9
  padding-top: -10rem !important

.pr--9, .px--9
  padding-right: -10rem !important

.pb--9, .py--9
  padding-bottom: -10rem !important

.pl--9, .px--9
  padding-left: -10rem !important

.p--8
  padding: -8rem !important

.pt--8, .py--8
  padding-top: -8rem !important

.pr--8, .px--8
  padding-right: -8rem !important

.pb--8, .py--8
  padding-bottom: -8rem !important

.pl--8, .px--8
  padding-left: -8rem !important

.p--7
  padding: -6rem !important

.pt--7, .py--7
  padding-top: -6rem !important

.pr--7, .px--7
  padding-right: -6rem !important

.pb--7, .py--7
  padding-bottom: -6rem !important

.pl--7, .px--7
  padding-left: -6rem !important

.p--6
  padding: -4.5rem !important

.pt--6, .py--6
  padding-top: -4.5rem !important

.pr--6, .px--6
  padding-right: -4.5rem !important

.pb--6, .py--6
  padding-bottom: -4.5rem !important

.pl--6, .px--6
  padding-left: -4.5rem !important

.p--5
  padding: -3rem !important

.pt--5, .py--5
  padding-top: -3rem !important

.pr--5, .px--5
  padding-right: -3rem !important

.pb--5, .py--5
  padding-bottom: -3rem !important

.pl--5, .px--5
  padding-left: -3rem !important

.p--4
  padding: -1.5rem !important

.pt--4, .py--4
  padding-top: -1.5rem !important

.pr--4, .px--4
  padding-right: -1.5rem !important

.pb--4, .py--4
  padding-bottom: -1.5rem !important

.pl--4, .px--4
  padding-left: -1.5rem !important

.p--3
  padding: -1rem !important

.pt--3, .py--3
  padding-top: -1rem !important

.pr--3, .px--3
  padding-right: -1rem !important

.pb--3, .py--3
  padding-bottom: -1rem !important

.pl--3, .px--3
  padding-left: -1rem !important

.p--2
  padding: -.5rem !important

.pt--2, .py--2
  padding-top: -.5rem !important

.pr--2, .px--2
  padding-right: -.5rem !important

.pb--2, .py--2
  padding-bottom: -.5rem !important

.pl--2, .px--2
  padding-left: -.5rem !important

.p--1
  padding: -.25rem !important

.pt--1, .py--1
  padding-top: -.25rem !important

.pr--1, .px--1
  padding-right: -.25rem !important

.pb--1, .py--1
  padding-bottom: -.25rem !important

.pl--1, .px--1
  padding-left: -.25rem !important

.p-6
  padding: 4.5rem !important

.pt-6, .py-6
  padding-top: 4.5rem !important

.pr-6, .px-6
  padding-right: 4.5rem !important

.pb-6, .py-6
  padding-bottom: 4.5rem !important

.pl-6, .px-6
  padding-left: 4.5rem !important

.p-7
  padding: 6rem !important

.pt-7, .py-7
  padding-top: 6rem !important

.pr-7, .px-7
  padding-right: 6rem !important

.pb-7, .py-7
  padding-bottom: 6rem !important

.pl-7, .px-7
  padding-left: 6rem !important

.p-8
  padding: 8rem !important

.pt-8, .py-8
  padding-top: 8rem !important

.pr-8, .px-8
  padding-right: 8rem !important

.pb-8, .py-8
  padding-bottom: 8rem !important

.pl-8, .px-8
  padding-left: 8rem !important

.p-9
  padding: 10rem !important

.pt-9, .py-9
  padding-top: 10rem !important

.pr-9, .px-9
  padding-right: 10rem !important

.pb-9, .py-9
  padding-bottom: 10rem !important

.pl-9, .px-9
  padding-left: 10rem !important

.m-n1
  margin: -.25rem !important

.mt-n1, .my-n1
  margin-top: -.25rem !important

.mr-n1, .mx-n1
  margin-right: -.25rem !important

.mb-n1, .my-n1
  margin-bottom: -.25rem !important

.ml-n1, .mx-n1
  margin-left: -.25rem !important

.m-n2
  margin: -.5rem !important

.mt-n2, .my-n2
  margin-top: -.5rem !important

.mr-n2, .mx-n2
  margin-right: -.5rem !important

.mb-n2, .my-n2
  margin-bottom: -.5rem !important

.ml-n2, .mx-n2
  margin-left: -.5rem !important

.m-n3
  margin: -1rem !important

.mt-n3, .my-n3
  margin-top: -1rem !important

.mr-n3, .mx-n3
  margin-right: -1rem !important

.mb-n3, .my-n3
  margin-bottom: -1rem !important

.ml-n3, .mx-n3
  margin-left: -1rem !important

.m-n4
  margin: -1.5rem !important

.mt-n4, .my-n4
  margin-top: -1.5rem !important

.mr-n4, .mx-n4
  margin-right: -1.5rem !important

.mb-n4, .my-n4
  margin-bottom: -1.5rem !important

.ml-n4, .mx-n4
  margin-left: -1.5rem !important

.m-n5
  margin: -3rem !important

.mt-n5, .my-n5
  margin-top: -3rem !important

.mr-n5, .mx-n5
  margin-right: -3rem !important

.mb-n5, .my-n5
  margin-bottom: -3rem !important

.ml-n5, .mx-n5
  margin-left: -3rem !important

.m-n-9
  margin: 10rem !important

.mt-n-9, .my-n-9
  margin-top: 10rem !important

.mr-n-9, .mx-n-9
  margin-right: 10rem !important

.mb-n-9, .my-n-9
  margin-bottom: 10rem !important

.ml-n-9, .mx-n-9
  margin-left: 10rem !important

.m-n-8
  margin: 8rem !important

.mt-n-8, .my-n-8
  margin-top: 8rem !important

.mr-n-8, .mx-n-8
  margin-right: 8rem !important

.mb-n-8, .my-n-8
  margin-bottom: 8rem !important

.ml-n-8, .mx-n-8
  margin-left: 8rem !important

.m-n-7
  margin: 6rem !important

.mt-n-7, .my-n-7
  margin-top: 6rem !important

.mr-n-7, .mx-n-7
  margin-right: 6rem !important

.mb-n-7, .my-n-7
  margin-bottom: 6rem !important

.ml-n-7, .mx-n-7
  margin-left: 6rem !important

.m-n-6
  margin: 4.5rem !important

.mt-n-6, .my-n-6
  margin-top: 4.5rem !important

.mr-n-6, .mx-n-6
  margin-right: 4.5rem !important

.mb-n-6, .my-n-6
  margin-bottom: 4.5rem !important

.ml-n-6, .mx-n-6
  margin-left: 4.5rem !important

.m-n-5
  margin: 3rem !important

.mt-n-5, .my-n-5
  margin-top: 3rem !important

.mr-n-5, .mx-n-5
  margin-right: 3rem !important

.mb-n-5, .my-n-5
  margin-bottom: 3rem !important

.ml-n-5, .mx-n-5
  margin-left: 3rem !important

.m-n-4
  margin: 1.5rem !important

.mt-n-4, .my-n-4
  margin-top: 1.5rem !important

.mr-n-4, .mx-n-4
  margin-right: 1.5rem !important

.mb-n-4, .my-n-4
  margin-bottom: 1.5rem !important

.ml-n-4, .mx-n-4
  margin-left: 1.5rem !important

.m-n-3
  margin: 1rem !important

.mt-n-3, .my-n-3
  margin-top: 1rem !important

.mr-n-3, .mx-n-3
  margin-right: 1rem !important

.mb-n-3, .my-n-3
  margin-bottom: 1rem !important

.ml-n-3, .mx-n-3
  margin-left: 1rem !important

.m-n-2
  margin: .5rem !important

.mt-n-2, .my-n-2
  margin-top: .5rem !important

.mr-n-2, .mx-n-2
  margin-right: .5rem !important

.mb-n-2, .my-n-2
  margin-bottom: .5rem !important

.ml-n-2, .mx-n-2
  margin-left: .5rem !important

.m-n-1
  margin: .25rem !important

.mt-n-1, .my-n-1
  margin-top: .25rem !important

.mr-n-1, .mx-n-1
  margin-right: .25rem !important

.mb-n-1, .my-n-1
  margin-bottom: .25rem !important

.ml-n-1, .mx-n-1
  margin-left: .25rem !important

.m-n6
  margin: -4.5rem !important

.mt-n6, .my-n6
  margin-top: -4.5rem !important

.mr-n6, .mx-n6
  margin-right: -4.5rem !important

.mb-n6, .my-n6
  margin-bottom: -4.5rem !important

.ml-n6, .mx-n6
  margin-left: -4.5rem !important

.m-n7
  margin: -6rem !important

.mt-n7, .my-n7
  margin-top: -6rem !important

.mr-n7, .mx-n7
  margin-right: -6rem !important

.mb-n7, .my-n7
  margin-bottom: -6rem !important

.ml-n7, .mx-n7
  margin-left: -6rem !important

.m-n8
  margin: -8rem !important

.mt-n8, .my-n8
  margin-top: -8rem !important

.mr-n8, .mx-n8
  margin-right: -8rem !important

.mb-n8, .my-n8
  margin-bottom: -8rem !important

.ml-n8, .mx-n8
  margin-left: -8rem !important

.m-n9
  margin: -10rem !important

.mt-n9, .my-n9
  margin-top: -10rem !important

.mr-n9, .mx-n9
  margin-right: -10rem !important

.mb-n9, .my-n9
  margin-bottom: -10rem !important

.ml-n9, .mx-n9
  margin-left: -10rem !important

.m-auto
  margin: auto !important

.mt-auto, .my-auto
  margin-top: auto !important

.mr-auto, .mx-auto
  margin-right: auto !important

.mb-auto, .my-auto
  margin-bottom: auto !important

.ml-auto, .mx-auto
  margin-left: auto !important

@media (min-width: 576px)
  .m-sm-0
    margin: 0 !important

  .mt-sm-0, .my-sm-0
    margin-top: 0 !important

  .mr-sm-0, .mx-sm-0
    margin-right: 0 !important

  .mb-sm-0, .my-sm-0
    margin-bottom: 0 !important

  .ml-sm-0, .mx-sm-0
    margin-left: 0 !important

  .m-sm-1
    margin: .25rem !important

  .mt-sm-1, .my-sm-1
    margin-top: .25rem !important

  .mr-sm-1, .mx-sm-1
    margin-right: .25rem !important

  .mb-sm-1, .my-sm-1
    margin-bottom: .25rem !important

  .ml-sm-1, .mx-sm-1
    margin-left: .25rem !important

  .m-sm-2
    margin: .5rem !important

  .mt-sm-2, .my-sm-2
    margin-top: .5rem !important

  .mr-sm-2, .mx-sm-2
    margin-right: .5rem !important

  .mb-sm-2, .my-sm-2
    margin-bottom: .5rem !important

  .ml-sm-2, .mx-sm-2
    margin-left: .5rem !important

  .m-sm-3
    margin: 1rem !important

  .mt-sm-3, .my-sm-3
    margin-top: 1rem !important

  .mr-sm-3, .mx-sm-3
    margin-right: 1rem !important

  .mb-sm-3, .my-sm-3
    margin-bottom: 1rem !important

  .ml-sm-3, .mx-sm-3
    margin-left: 1rem !important

  .m-sm-4
    margin: 1.5rem !important

  .mt-sm-4, .my-sm-4
    margin-top: 1.5rem !important

  .mr-sm-4, .mx-sm-4
    margin-right: 1.5rem !important

  .mb-sm-4, .my-sm-4
    margin-bottom: 1.5rem !important

  .ml-sm-4, .mx-sm-4
    margin-left: 1.5rem !important

  .m-sm-5
    margin: 3rem !important

  .mt-sm-5, .my-sm-5
    margin-top: 3rem !important

  .mr-sm-5, .mx-sm-5
    margin-right: 3rem !important

  .mb-sm-5, .my-sm-5
    margin-bottom: 3rem !important

  .ml-sm-5, .mx-sm-5
    margin-left: 3rem !important

  .m-sm--9
    margin: -10rem !important

  .mt-sm--9, .my-sm--9
    margin-top: -10rem !important

  .mr-sm--9, .mx-sm--9
    margin-right: -10rem !important

  .mb-sm--9, .my-sm--9
    margin-bottom: -10rem !important

  .ml-sm--9, .mx-sm--9
    margin-left: -10rem !important

  .m-sm--8
    margin: -8rem !important

  .mt-sm--8, .my-sm--8
    margin-top: -8rem !important

  .mr-sm--8, .mx-sm--8
    margin-right: -8rem !important

  .mb-sm--8, .my-sm--8
    margin-bottom: -8rem !important

  .ml-sm--8, .mx-sm--8
    margin-left: -8rem !important

  .m-sm--7
    margin: -6rem !important

  .mt-sm--7, .my-sm--7
    margin-top: -6rem !important

  .mr-sm--7, .mx-sm--7
    margin-right: -6rem !important

  .mb-sm--7, .my-sm--7
    margin-bottom: -6rem !important

  .ml-sm--7, .mx-sm--7
    margin-left: -6rem !important

  .m-sm--6
    margin: -4.5rem !important

  .mt-sm--6, .my-sm--6
    margin-top: -4.5rem !important

  .mr-sm--6, .mx-sm--6
    margin-right: -4.5rem !important

  .mb-sm--6, .my-sm--6
    margin-bottom: -4.5rem !important

  .ml-sm--6, .mx-sm--6
    margin-left: -4.5rem !important

  .m-sm--5
    margin: -3rem !important

  .mt-sm--5, .my-sm--5
    margin-top: -3rem !important

  .mr-sm--5, .mx-sm--5
    margin-right: -3rem !important

  .mb-sm--5, .my-sm--5
    margin-bottom: -3rem !important

  .ml-sm--5, .mx-sm--5
    margin-left: -3rem !important

  .m-sm--4
    margin: -1.5rem !important

  .mt-sm--4, .my-sm--4
    margin-top: -1.5rem !important

  .mr-sm--4, .mx-sm--4
    margin-right: -1.5rem !important

  .mb-sm--4, .my-sm--4
    margin-bottom: -1.5rem !important

  .ml-sm--4, .mx-sm--4
    margin-left: -1.5rem !important

  .m-sm--3
    margin: -1rem !important

  .mt-sm--3, .my-sm--3
    margin-top: -1rem !important

  .mr-sm--3, .mx-sm--3
    margin-right: -1rem !important

  .mb-sm--3, .my-sm--3
    margin-bottom: -1rem !important

  .ml-sm--3, .mx-sm--3
    margin-left: -1rem !important

  .m-sm--2
    margin: -.5rem !important

  .mt-sm--2, .my-sm--2
    margin-top: -.5rem !important

  .mr-sm--2, .mx-sm--2
    margin-right: -.5rem !important

  .mb-sm--2, .my-sm--2
    margin-bottom: -.5rem !important

  .ml-sm--2, .mx-sm--2
    margin-left: -.5rem !important

  .m-sm--1
    margin: -.25rem !important

  .mt-sm--1, .my-sm--1
    margin-top: -.25rem !important

  .mr-sm--1, .mx-sm--1
    margin-right: -.25rem !important

  .mb-sm--1, .my-sm--1
    margin-bottom: -.25rem !important

  .ml-sm--1, .mx-sm--1
    margin-left: -.25rem !important

  .m-sm-6
    margin: 4.5rem !important

  .mt-sm-6, .my-sm-6
    margin-top: 4.5rem !important

  .mr-sm-6, .mx-sm-6
    margin-right: 4.5rem !important

  .mb-sm-6, .my-sm-6
    margin-bottom: 4.5rem !important

  .ml-sm-6, .mx-sm-6
    margin-left: 4.5rem !important

  .m-sm-7
    margin: 6rem !important

  .mt-sm-7, .my-sm-7
    margin-top: 6rem !important

  .mr-sm-7, .mx-sm-7
    margin-right: 6rem !important

  .mb-sm-7, .my-sm-7
    margin-bottom: 6rem !important

  .ml-sm-7, .mx-sm-7
    margin-left: 6rem !important

  .m-sm-8
    margin: 8rem !important

  .mt-sm-8, .my-sm-8
    margin-top: 8rem !important

  .mr-sm-8, .mx-sm-8
    margin-right: 8rem !important

  .mb-sm-8, .my-sm-8
    margin-bottom: 8rem !important

  .ml-sm-8, .mx-sm-8
    margin-left: 8rem !important

  .m-sm-9
    margin: 10rem !important

  .mt-sm-9, .my-sm-9
    margin-top: 10rem !important

  .mr-sm-9, .mx-sm-9
    margin-right: 10rem !important

  .mb-sm-9, .my-sm-9
    margin-bottom: 10rem !important

  .ml-sm-9, .mx-sm-9
    margin-left: 10rem !important

  .p-sm-0
    padding: 0 !important

  .pt-sm-0, .py-sm-0
    padding-top: 0 !important

  .pr-sm-0, .px-sm-0
    padding-right: 0 !important

  .pb-sm-0, .py-sm-0
    padding-bottom: 0 !important

  .pl-sm-0, .px-sm-0
    padding-left: 0 !important

  .p-sm-1
    padding: .25rem !important

  .pt-sm-1, .py-sm-1
    padding-top: .25rem !important

  .pr-sm-1, .px-sm-1
    padding-right: .25rem !important

  .pb-sm-1, .py-sm-1
    padding-bottom: .25rem !important

  .pl-sm-1, .px-sm-1
    padding-left: .25rem !important

  .p-sm-2
    padding: .5rem !important

  .pt-sm-2, .py-sm-2
    padding-top: .5rem !important

  .pr-sm-2, .px-sm-2
    padding-right: .5rem !important

  .pb-sm-2, .py-sm-2
    padding-bottom: .5rem !important

  .pl-sm-2, .px-sm-2
    padding-left: .5rem !important

  .p-sm-3
    padding: 1rem !important

  .pt-sm-3, .py-sm-3
    padding-top: 1rem !important

  .pr-sm-3, .px-sm-3
    padding-right: 1rem !important

  .pb-sm-3, .py-sm-3
    padding-bottom: 1rem !important

  .pl-sm-3, .px-sm-3
    padding-left: 1rem !important

  .p-sm-4
    padding: 1.5rem !important

  .pt-sm-4, .py-sm-4
    padding-top: 1.5rem !important

  .pr-sm-4, .px-sm-4
    padding-right: 1.5rem !important

  .pb-sm-4, .py-sm-4
    padding-bottom: 1.5rem !important

  .pl-sm-4, .px-sm-4
    padding-left: 1.5rem !important

  .p-sm-5
    padding: 3rem !important

  .pt-sm-5, .py-sm-5
    padding-top: 3rem !important

  .pr-sm-5, .px-sm-5
    padding-right: 3rem !important

  .pb-sm-5, .py-sm-5
    padding-bottom: 3rem !important

  .pl-sm-5, .px-sm-5
    padding-left: 3rem !important

  .p-sm--9
    padding: -10rem !important

  .pt-sm--9, .py-sm--9
    padding-top: -10rem !important

  .pr-sm--9, .px-sm--9
    padding-right: -10rem !important

  .pb-sm--9, .py-sm--9
    padding-bottom: -10rem !important

  .pl-sm--9, .px-sm--9
    padding-left: -10rem !important

  .p-sm--8
    padding: -8rem !important

  .pt-sm--8, .py-sm--8
    padding-top: -8rem !important

  .pr-sm--8, .px-sm--8
    padding-right: -8rem !important

  .pb-sm--8, .py-sm--8
    padding-bottom: -8rem !important

  .pl-sm--8, .px-sm--8
    padding-left: -8rem !important

  .p-sm--7
    padding: -6rem !important

  .pt-sm--7, .py-sm--7
    padding-top: -6rem !important

  .pr-sm--7, .px-sm--7
    padding-right: -6rem !important

  .pb-sm--7, .py-sm--7
    padding-bottom: -6rem !important

  .pl-sm--7, .px-sm--7
    padding-left: -6rem !important

  .p-sm--6
    padding: -4.5rem !important

  .pt-sm--6, .py-sm--6
    padding-top: -4.5rem !important

  .pr-sm--6, .px-sm--6
    padding-right: -4.5rem !important

  .pb-sm--6, .py-sm--6
    padding-bottom: -4.5rem !important

  .pl-sm--6, .px-sm--6
    padding-left: -4.5rem !important

  .p-sm--5
    padding: -3rem !important

  .pt-sm--5, .py-sm--5
    padding-top: -3rem !important

  .pr-sm--5, .px-sm--5
    padding-right: -3rem !important

  .pb-sm--5, .py-sm--5
    padding-bottom: -3rem !important

  .pl-sm--5, .px-sm--5
    padding-left: -3rem !important

  .p-sm--4
    padding: -1.5rem !important

  .pt-sm--4, .py-sm--4
    padding-top: -1.5rem !important

  .pr-sm--4, .px-sm--4
    padding-right: -1.5rem !important

  .pb-sm--4, .py-sm--4
    padding-bottom: -1.5rem !important

  .pl-sm--4, .px-sm--4
    padding-left: -1.5rem !important

  .p-sm--3
    padding: -1rem !important

  .pt-sm--3, .py-sm--3
    padding-top: -1rem !important

  .pr-sm--3, .px-sm--3
    padding-right: -1rem !important

  .pb-sm--3, .py-sm--3
    padding-bottom: -1rem !important

  .pl-sm--3, .px-sm--3
    padding-left: -1rem !important

  .p-sm--2
    padding: -.5rem !important

  .pt-sm--2, .py-sm--2
    padding-top: -.5rem !important

  .pr-sm--2, .px-sm--2
    padding-right: -.5rem !important

  .pb-sm--2, .py-sm--2
    padding-bottom: -.5rem !important

  .pl-sm--2, .px-sm--2
    padding-left: -.5rem !important

  .p-sm--1
    padding: -.25rem !important

  .pt-sm--1, .py-sm--1
    padding-top: -.25rem !important

  .pr-sm--1, .px-sm--1
    padding-right: -.25rem !important

  .pb-sm--1, .py-sm--1
    padding-bottom: -.25rem !important

  .pl-sm--1, .px-sm--1
    padding-left: -.25rem !important

  .p-sm-6
    padding: 4.5rem !important

  .pt-sm-6, .py-sm-6
    padding-top: 4.5rem !important

  .pr-sm-6, .px-sm-6
    padding-right: 4.5rem !important

  .pb-sm-6, .py-sm-6
    padding-bottom: 4.5rem !important

  .pl-sm-6, .px-sm-6
    padding-left: 4.5rem !important

  .p-sm-7
    padding: 6rem !important

  .pt-sm-7, .py-sm-7
    padding-top: 6rem !important

  .pr-sm-7, .px-sm-7
    padding-right: 6rem !important

  .pb-sm-7, .py-sm-7
    padding-bottom: 6rem !important

  .pl-sm-7, .px-sm-7
    padding-left: 6rem !important

  .p-sm-8
    padding: 8rem !important

  .pt-sm-8, .py-sm-8
    padding-top: 8rem !important

  .pr-sm-8, .px-sm-8
    padding-right: 8rem !important

  .pb-sm-8, .py-sm-8
    padding-bottom: 8rem !important

  .pl-sm-8, .px-sm-8
    padding-left: 8rem !important

  .p-sm-9
    padding: 10rem !important

  .pt-sm-9, .py-sm-9
    padding-top: 10rem !important

  .pr-sm-9, .px-sm-9
    padding-right: 10rem !important

  .pb-sm-9, .py-sm-9
    padding-bottom: 10rem !important

  .pl-sm-9, .px-sm-9
    padding-left: 10rem !important

  .m-sm-n1
    margin: -.25rem !important

  .mt-sm-n1, .my-sm-n1
    margin-top: -.25rem !important

  .mr-sm-n1, .mx-sm-n1
    margin-right: -.25rem !important

  .mb-sm-n1, .my-sm-n1
    margin-bottom: -.25rem !important

  .ml-sm-n1, .mx-sm-n1
    margin-left: -.25rem !important

  .m-sm-n2
    margin: -.5rem !important

  .mt-sm-n2, .my-sm-n2
    margin-top: -.5rem !important

  .mr-sm-n2, .mx-sm-n2
    margin-right: -.5rem !important

  .mb-sm-n2, .my-sm-n2
    margin-bottom: -.5rem !important

  .ml-sm-n2, .mx-sm-n2
    margin-left: -.5rem !important

  .m-sm-n3
    margin: -1rem !important

  .mt-sm-n3, .my-sm-n3
    margin-top: -1rem !important

  .mr-sm-n3, .mx-sm-n3
    margin-right: -1rem !important

  .mb-sm-n3, .my-sm-n3
    margin-bottom: -1rem !important

  .ml-sm-n3, .mx-sm-n3
    margin-left: -1rem !important

  .m-sm-n4
    margin: -1.5rem !important

  .mt-sm-n4, .my-sm-n4
    margin-top: -1.5rem !important

  .mr-sm-n4, .mx-sm-n4
    margin-right: -1.5rem !important

  .mb-sm-n4, .my-sm-n4
    margin-bottom: -1.5rem !important

  .ml-sm-n4, .mx-sm-n4
    margin-left: -1.5rem !important

  .m-sm-n5
    margin: -3rem !important

  .mt-sm-n5, .my-sm-n5
    margin-top: -3rem !important

  .mr-sm-n5, .mx-sm-n5
    margin-right: -3rem !important

  .mb-sm-n5, .my-sm-n5
    margin-bottom: -3rem !important

  .ml-sm-n5, .mx-sm-n5
    margin-left: -3rem !important

  .m-sm-n-9
    margin: 10rem !important

  .mt-sm-n-9, .my-sm-n-9
    margin-top: 10rem !important

  .mr-sm-n-9, .mx-sm-n-9
    margin-right: 10rem !important

  .mb-sm-n-9, .my-sm-n-9
    margin-bottom: 10rem !important

  .ml-sm-n-9, .mx-sm-n-9
    margin-left: 10rem !important

  .m-sm-n-8
    margin: 8rem !important

  .mt-sm-n-8, .my-sm-n-8
    margin-top: 8rem !important

  .mr-sm-n-8, .mx-sm-n-8
    margin-right: 8rem !important

  .mb-sm-n-8, .my-sm-n-8
    margin-bottom: 8rem !important

  .ml-sm-n-8, .mx-sm-n-8
    margin-left: 8rem !important

  .m-sm-n-7
    margin: 6rem !important

  .mt-sm-n-7, .my-sm-n-7
    margin-top: 6rem !important

  .mr-sm-n-7, .mx-sm-n-7
    margin-right: 6rem !important

  .mb-sm-n-7, .my-sm-n-7
    margin-bottom: 6rem !important

  .ml-sm-n-7, .mx-sm-n-7
    margin-left: 6rem !important

  .m-sm-n-6
    margin: 4.5rem !important

  .mt-sm-n-6, .my-sm-n-6
    margin-top: 4.5rem !important

  .mr-sm-n-6, .mx-sm-n-6
    margin-right: 4.5rem !important

  .mb-sm-n-6, .my-sm-n-6
    margin-bottom: 4.5rem !important

  .ml-sm-n-6, .mx-sm-n-6
    margin-left: 4.5rem !important

  .m-sm-n-5
    margin: 3rem !important

  .mt-sm-n-5, .my-sm-n-5
    margin-top: 3rem !important

  .mr-sm-n-5, .mx-sm-n-5
    margin-right: 3rem !important

  .mb-sm-n-5, .my-sm-n-5
    margin-bottom: 3rem !important

  .ml-sm-n-5, .mx-sm-n-5
    margin-left: 3rem !important

  .m-sm-n-4
    margin: 1.5rem !important

  .mt-sm-n-4, .my-sm-n-4
    margin-top: 1.5rem !important

  .mr-sm-n-4, .mx-sm-n-4
    margin-right: 1.5rem !important

  .mb-sm-n-4, .my-sm-n-4
    margin-bottom: 1.5rem !important

  .ml-sm-n-4, .mx-sm-n-4
    margin-left: 1.5rem !important

  .m-sm-n-3
    margin: 1rem !important

  .mt-sm-n-3, .my-sm-n-3
    margin-top: 1rem !important

  .mr-sm-n-3, .mx-sm-n-3
    margin-right: 1rem !important

  .mb-sm-n-3, .my-sm-n-3
    margin-bottom: 1rem !important

  .ml-sm-n-3, .mx-sm-n-3
    margin-left: 1rem !important

  .m-sm-n-2
    margin: .5rem !important

  .mt-sm-n-2, .my-sm-n-2
    margin-top: .5rem !important

  .mr-sm-n-2, .mx-sm-n-2
    margin-right: .5rem !important

  .mb-sm-n-2, .my-sm-n-2
    margin-bottom: .5rem !important

  .ml-sm-n-2, .mx-sm-n-2
    margin-left: .5rem !important

  .m-sm-n-1
    margin: .25rem !important

  .mt-sm-n-1, .my-sm-n-1
    margin-top: .25rem !important

  .mr-sm-n-1, .mx-sm-n-1
    margin-right: .25rem !important

  .mb-sm-n-1, .my-sm-n-1
    margin-bottom: .25rem !important

  .ml-sm-n-1, .mx-sm-n-1
    margin-left: .25rem !important

  .m-sm-n6
    margin: -4.5rem !important

  .mt-sm-n6, .my-sm-n6
    margin-top: -4.5rem !important

  .mr-sm-n6, .mx-sm-n6
    margin-right: -4.5rem !important

  .mb-sm-n6, .my-sm-n6
    margin-bottom: -4.5rem !important

  .ml-sm-n6, .mx-sm-n6
    margin-left: -4.5rem !important

  .m-sm-n7
    margin: -6rem !important

  .mt-sm-n7, .my-sm-n7
    margin-top: -6rem !important

  .mr-sm-n7, .mx-sm-n7
    margin-right: -6rem !important

  .mb-sm-n7, .my-sm-n7
    margin-bottom: -6rem !important

  .ml-sm-n7, .mx-sm-n7
    margin-left: -6rem !important

  .m-sm-n8
    margin: -8rem !important

  .mt-sm-n8, .my-sm-n8
    margin-top: -8rem !important

  .mr-sm-n8, .mx-sm-n8
    margin-right: -8rem !important

  .mb-sm-n8, .my-sm-n8
    margin-bottom: -8rem !important

  .ml-sm-n8, .mx-sm-n8
    margin-left: -8rem !important

  .m-sm-n9
    margin: -10rem !important

  .mt-sm-n9, .my-sm-n9
    margin-top: -10rem !important

  .mr-sm-n9, .mx-sm-n9
    margin-right: -10rem !important

  .mb-sm-n9, .my-sm-n9
    margin-bottom: -10rem !important

  .ml-sm-n9, .mx-sm-n9
    margin-left: -10rem !important

  .m-sm-auto
    margin: auto !important

  .mt-sm-auto, .my-sm-auto
    margin-top: auto !important

  .mr-sm-auto, .mx-sm-auto
    margin-right: auto !important

  .mb-sm-auto, .my-sm-auto
    margin-bottom: auto !important

  .ml-sm-auto, .mx-sm-auto
    margin-left: auto !important

@media (min-width: 768px)
  .m-md-0
    margin: 0 !important

  .mt-md-0, .my-md-0
    margin-top: 0 !important

  .mr-md-0, .mx-md-0
    margin-right: 0 !important

  .mb-md-0, .my-md-0
    margin-bottom: 0 !important

  .ml-md-0, .mx-md-0
    margin-left: 0 !important

  .m-md-1
    margin: .25rem !important

  .mt-md-1, .my-md-1
    margin-top: .25rem !important

  .mr-md-1, .mx-md-1
    margin-right: .25rem !important

  .mb-md-1, .my-md-1
    margin-bottom: .25rem !important

  .ml-md-1, .mx-md-1
    margin-left: .25rem !important

  .m-md-2
    margin: .5rem !important

  .mt-md-2, .my-md-2
    margin-top: .5rem !important

  .mr-md-2, .mx-md-2
    margin-right: .5rem !important

  .mb-md-2, .my-md-2
    margin-bottom: .5rem !important

  .ml-md-2, .mx-md-2
    margin-left: .5rem !important

  .m-md-3
    margin: 1rem !important

  .mt-md-3, .my-md-3
    margin-top: 1rem !important

  .mr-md-3, .mx-md-3
    margin-right: 1rem !important

  .mb-md-3, .my-md-3
    margin-bottom: 1rem !important

  .ml-md-3, .mx-md-3
    margin-left: 1rem !important

  .m-md-4
    margin: 1.5rem !important

  .mt-md-4, .my-md-4
    margin-top: 1.5rem !important

  .mr-md-4, .mx-md-4
    margin-right: 1.5rem !important

  .mb-md-4, .my-md-4
    margin-bottom: 1.5rem !important

  .ml-md-4, .mx-md-4
    margin-left: 1.5rem !important

  .m-md-5
    margin: 3rem !important

  .mt-md-5, .my-md-5
    margin-top: 3rem !important

  .mr-md-5, .mx-md-5
    margin-right: 3rem !important

  .mb-md-5, .my-md-5
    margin-bottom: 3rem !important

  .ml-md-5, .mx-md-5
    margin-left: 3rem !important

  .m-md--9
    margin: -10rem !important

  .mt-md--9, .my-md--9
    margin-top: -10rem !important

  .mr-md--9, .mx-md--9
    margin-right: -10rem !important

  .mb-md--9, .my-md--9
    margin-bottom: -10rem !important

  .ml-md--9, .mx-md--9
    margin-left: -10rem !important

  .m-md--8
    margin: -8rem !important

  .mt-md--8, .my-md--8
    margin-top: -8rem !important

  .mr-md--8, .mx-md--8
    margin-right: -8rem !important

  .mb-md--8, .my-md--8
    margin-bottom: -8rem !important

  .ml-md--8, .mx-md--8
    margin-left: -8rem !important

  .m-md--7
    margin: -6rem !important

  .mt-md--7, .my-md--7
    margin-top: -6rem !important

  .mr-md--7, .mx-md--7
    margin-right: -6rem !important

  .mb-md--7, .my-md--7
    margin-bottom: -6rem !important

  .ml-md--7, .mx-md--7
    margin-left: -6rem !important

  .m-md--6
    margin: -4.5rem !important

  .mt-md--6, .my-md--6
    margin-top: -4.5rem !important

  .mr-md--6, .mx-md--6
    margin-right: -4.5rem !important

  .mb-md--6, .my-md--6
    margin-bottom: -4.5rem !important

  .ml-md--6, .mx-md--6
    margin-left: -4.5rem !important

  .m-md--5
    margin: -3rem !important

  .mt-md--5, .my-md--5
    margin-top: -3rem !important

  .mr-md--5, .mx-md--5
    margin-right: -3rem !important

  .mb-md--5, .my-md--5
    margin-bottom: -3rem !important

  .ml-md--5, .mx-md--5
    margin-left: -3rem !important

  .m-md--4
    margin: -1.5rem !important

  .mt-md--4, .my-md--4
    margin-top: -1.5rem !important

  .mr-md--4, .mx-md--4
    margin-right: -1.5rem !important

  .mb-md--4, .my-md--4
    margin-bottom: -1.5rem !important

  .ml-md--4, .mx-md--4
    margin-left: -1.5rem !important

  .m-md--3
    margin: -1rem !important

  .mt-md--3, .my-md--3
    margin-top: -1rem !important

  .mr-md--3, .mx-md--3
    margin-right: -1rem !important

  .mb-md--3, .my-md--3
    margin-bottom: -1rem !important

  .ml-md--3, .mx-md--3
    margin-left: -1rem !important

  .m-md--2
    margin: -.5rem !important

  .mt-md--2, .my-md--2
    margin-top: -.5rem !important

  .mr-md--2, .mx-md--2
    margin-right: -.5rem !important

  .mb-md--2, .my-md--2
    margin-bottom: -.5rem !important

  .ml-md--2, .mx-md--2
    margin-left: -.5rem !important

  .m-md--1
    margin: -.25rem !important

  .mt-md--1, .my-md--1
    margin-top: -.25rem !important

  .mr-md--1, .mx-md--1
    margin-right: -.25rem !important

  .mb-md--1, .my-md--1
    margin-bottom: -.25rem !important

  .ml-md--1, .mx-md--1
    margin-left: -.25rem !important

  .m-md-6
    margin: 4.5rem !important

  .mt-md-6, .my-md-6
    margin-top: 4.5rem !important

  .mr-md-6, .mx-md-6
    margin-right: 4.5rem !important

  .mb-md-6, .my-md-6
    margin-bottom: 4.5rem !important

  .ml-md-6, .mx-md-6
    margin-left: 4.5rem !important

  .m-md-7
    margin: 6rem !important

  .mt-md-7, .my-md-7
    margin-top: 6rem !important

  .mr-md-7, .mx-md-7
    margin-right: 6rem !important

  .mb-md-7, .my-md-7
    margin-bottom: 6rem !important

  .ml-md-7, .mx-md-7
    margin-left: 6rem !important

  .m-md-8
    margin: 8rem !important

  .mt-md-8, .my-md-8
    margin-top: 8rem !important

  .mr-md-8, .mx-md-8
    margin-right: 8rem !important

  .mb-md-8, .my-md-8
    margin-bottom: 8rem !important

  .ml-md-8, .mx-md-8
    margin-left: 8rem !important

  .m-md-9
    margin: 10rem !important

  .mt-md-9, .my-md-9
    margin-top: 10rem !important

  .mr-md-9, .mx-md-9
    margin-right: 10rem !important

  .mb-md-9, .my-md-9
    margin-bottom: 10rem !important

  .ml-md-9, .mx-md-9
    margin-left: 10rem !important

  .p-md-0
    padding: 0 !important

  .pt-md-0, .py-md-0
    padding-top: 0 !important

  .pr-md-0, .px-md-0
    padding-right: 0 !important

  .pb-md-0, .py-md-0
    padding-bottom: 0 !important

  .pl-md-0, .px-md-0
    padding-left: 0 !important

  .p-md-1
    padding: .25rem !important

  .pt-md-1, .py-md-1
    padding-top: .25rem !important

  .pr-md-1, .px-md-1
    padding-right: .25rem !important

  .pb-md-1, .py-md-1
    padding-bottom: .25rem !important

  .pl-md-1, .px-md-1
    padding-left: .25rem !important

  .p-md-2
    padding: .5rem !important

  .pt-md-2, .py-md-2
    padding-top: .5rem !important

  .pr-md-2, .px-md-2
    padding-right: .5rem !important

  .pb-md-2, .py-md-2
    padding-bottom: .5rem !important

  .pl-md-2, .px-md-2
    padding-left: .5rem !important

  .p-md-3
    padding: 1rem !important

  .pt-md-3, .py-md-3
    padding-top: 1rem !important

  .pr-md-3, .px-md-3
    padding-right: 1rem !important

  .pb-md-3, .py-md-3
    padding-bottom: 1rem !important

  .pl-md-3, .px-md-3
    padding-left: 1rem !important

  .p-md-4
    padding: 1.5rem !important

  .pt-md-4, .py-md-4
    padding-top: 1.5rem !important

  .pr-md-4, .px-md-4
    padding-right: 1.5rem !important

  .pb-md-4, .py-md-4
    padding-bottom: 1.5rem !important

  .pl-md-4, .px-md-4
    padding-left: 1.5rem !important

  .p-md-5
    padding: 3rem !important

  .pt-md-5, .py-md-5
    padding-top: 3rem !important

  .pr-md-5, .px-md-5
    padding-right: 3rem !important

  .pb-md-5, .py-md-5
    padding-bottom: 3rem !important

  .pl-md-5, .px-md-5
    padding-left: 3rem !important

  .p-md--9
    padding: -10rem !important

  .pt-md--9, .py-md--9
    padding-top: -10rem !important

  .pr-md--9, .px-md--9
    padding-right: -10rem !important

  .pb-md--9, .py-md--9
    padding-bottom: -10rem !important

  .pl-md--9, .px-md--9
    padding-left: -10rem !important

  .p-md--8
    padding: -8rem !important

  .pt-md--8, .py-md--8
    padding-top: -8rem !important

  .pr-md--8, .px-md--8
    padding-right: -8rem !important

  .pb-md--8, .py-md--8
    padding-bottom: -8rem !important

  .pl-md--8, .px-md--8
    padding-left: -8rem !important

  .p-md--7
    padding: -6rem !important

  .pt-md--7, .py-md--7
    padding-top: -6rem !important

  .pr-md--7, .px-md--7
    padding-right: -6rem !important

  .pb-md--7, .py-md--7
    padding-bottom: -6rem !important

  .pl-md--7, .px-md--7
    padding-left: -6rem !important

  .p-md--6
    padding: -4.5rem !important

  .pt-md--6, .py-md--6
    padding-top: -4.5rem !important

  .pr-md--6, .px-md--6
    padding-right: -4.5rem !important

  .pb-md--6, .py-md--6
    padding-bottom: -4.5rem !important

  .pl-md--6, .px-md--6
    padding-left: -4.5rem !important

  .p-md--5
    padding: -3rem !important

  .pt-md--5, .py-md--5
    padding-top: -3rem !important

  .pr-md--5, .px-md--5
    padding-right: -3rem !important

  .pb-md--5, .py-md--5
    padding-bottom: -3rem !important

  .pl-md--5, .px-md--5
    padding-left: -3rem !important

  .p-md--4
    padding: -1.5rem !important

  .pt-md--4, .py-md--4
    padding-top: -1.5rem !important

  .pr-md--4, .px-md--4
    padding-right: -1.5rem !important

  .pb-md--4, .py-md--4
    padding-bottom: -1.5rem !important

  .pl-md--4, .px-md--4
    padding-left: -1.5rem !important

  .p-md--3
    padding: -1rem !important

  .pt-md--3, .py-md--3
    padding-top: -1rem !important

  .pr-md--3, .px-md--3
    padding-right: -1rem !important

  .pb-md--3, .py-md--3
    padding-bottom: -1rem !important

  .pl-md--3, .px-md--3
    padding-left: -1rem !important

  .p-md--2
    padding: -.5rem !important

  .pt-md--2, .py-md--2
    padding-top: -.5rem !important

  .pr-md--2, .px-md--2
    padding-right: -.5rem !important

  .pb-md--2, .py-md--2
    padding-bottom: -.5rem !important

  .pl-md--2, .px-md--2
    padding-left: -.5rem !important

  .p-md--1
    padding: -.25rem !important

  .pt-md--1, .py-md--1
    padding-top: -.25rem !important

  .pr-md--1, .px-md--1
    padding-right: -.25rem !important

  .pb-md--1, .py-md--1
    padding-bottom: -.25rem !important

  .pl-md--1, .px-md--1
    padding-left: -.25rem !important

  .p-md-6
    padding: 4.5rem !important

  .pt-md-6, .py-md-6
    padding-top: 4.5rem !important

  .pr-md-6, .px-md-6
    padding-right: 4.5rem !important

  .pb-md-6, .py-md-6
    padding-bottom: 4.5rem !important

  .pl-md-6, .px-md-6
    padding-left: 4.5rem !important

  .p-md-7
    padding: 6rem !important

  .pt-md-7, .py-md-7
    padding-top: 6rem !important

  .pr-md-7, .px-md-7
    padding-right: 6rem !important

  .pb-md-7, .py-md-7
    padding-bottom: 6rem !important

  .pl-md-7, .px-md-7
    padding-left: 6rem !important

  .p-md-8
    padding: 8rem !important

  .pt-md-8, .py-md-8
    padding-top: 8rem !important

  .pr-md-8, .px-md-8
    padding-right: 8rem !important

  .pb-md-8, .py-md-8
    padding-bottom: 8rem !important

  .pl-md-8, .px-md-8
    padding-left: 8rem !important

  .p-md-9
    padding: 10rem !important

  .pt-md-9, .py-md-9
    padding-top: 10rem !important

  .pr-md-9, .px-md-9
    padding-right: 10rem !important

  .pb-md-9, .py-md-9
    padding-bottom: 10rem !important

  .pl-md-9, .px-md-9
    padding-left: 10rem !important

  .m-md-n1
    margin: -.25rem !important

  .mt-md-n1, .my-md-n1
    margin-top: -.25rem !important

  .mr-md-n1, .mx-md-n1
    margin-right: -.25rem !important

  .mb-md-n1, .my-md-n1
    margin-bottom: -.25rem !important

  .ml-md-n1, .mx-md-n1
    margin-left: -.25rem !important

  .m-md-n2
    margin: -.5rem !important

  .mt-md-n2, .my-md-n2
    margin-top: -.5rem !important

  .mr-md-n2, .mx-md-n2
    margin-right: -.5rem !important

  .mb-md-n2, .my-md-n2
    margin-bottom: -.5rem !important

  .ml-md-n2, .mx-md-n2
    margin-left: -.5rem !important

  .m-md-n3
    margin: -1rem !important

  .mt-md-n3, .my-md-n3
    margin-top: -1rem !important

  .mr-md-n3, .mx-md-n3
    margin-right: -1rem !important

  .mb-md-n3, .my-md-n3
    margin-bottom: -1rem !important

  .ml-md-n3, .mx-md-n3
    margin-left: -1rem !important

  .m-md-n4
    margin: -1.5rem !important

  .mt-md-n4, .my-md-n4
    margin-top: -1.5rem !important

  .mr-md-n4, .mx-md-n4
    margin-right: -1.5rem !important

  .mb-md-n4, .my-md-n4
    margin-bottom: -1.5rem !important

  .ml-md-n4, .mx-md-n4
    margin-left: -1.5rem !important

  .m-md-n5
    margin: -3rem !important

  .mt-md-n5, .my-md-n5
    margin-top: -3rem !important

  .mr-md-n5, .mx-md-n5
    margin-right: -3rem !important

  .mb-md-n5, .my-md-n5
    margin-bottom: -3rem !important

  .ml-md-n5, .mx-md-n5
    margin-left: -3rem !important

  .m-md-n-9
    margin: 10rem !important

  .mt-md-n-9, .my-md-n-9
    margin-top: 10rem !important

  .mr-md-n-9, .mx-md-n-9
    margin-right: 10rem !important

  .mb-md-n-9, .my-md-n-9
    margin-bottom: 10rem !important

  .ml-md-n-9, .mx-md-n-9
    margin-left: 10rem !important

  .m-md-n-8
    margin: 8rem !important

  .mt-md-n-8, .my-md-n-8
    margin-top: 8rem !important

  .mr-md-n-8, .mx-md-n-8
    margin-right: 8rem !important

  .mb-md-n-8, .my-md-n-8
    margin-bottom: 8rem !important

  .ml-md-n-8, .mx-md-n-8
    margin-left: 8rem !important

  .m-md-n-7
    margin: 6rem !important

  .mt-md-n-7, .my-md-n-7
    margin-top: 6rem !important

  .mr-md-n-7, .mx-md-n-7
    margin-right: 6rem !important

  .mb-md-n-7, .my-md-n-7
    margin-bottom: 6rem !important

  .ml-md-n-7, .mx-md-n-7
    margin-left: 6rem !important

  .m-md-n-6
    margin: 4.5rem !important

  .mt-md-n-6, .my-md-n-6
    margin-top: 4.5rem !important

  .mr-md-n-6, .mx-md-n-6
    margin-right: 4.5rem !important

  .mb-md-n-6, .my-md-n-6
    margin-bottom: 4.5rem !important

  .ml-md-n-6, .mx-md-n-6
    margin-left: 4.5rem !important

  .m-md-n-5
    margin: 3rem !important

  .mt-md-n-5, .my-md-n-5
    margin-top: 3rem !important

  .mr-md-n-5, .mx-md-n-5
    margin-right: 3rem !important

  .mb-md-n-5, .my-md-n-5
    margin-bottom: 3rem !important

  .ml-md-n-5, .mx-md-n-5
    margin-left: 3rem !important

  .m-md-n-4
    margin: 1.5rem !important

  .mt-md-n-4, .my-md-n-4
    margin-top: 1.5rem !important

  .mr-md-n-4, .mx-md-n-4
    margin-right: 1.5rem !important

  .mb-md-n-4, .my-md-n-4
    margin-bottom: 1.5rem !important

  .ml-md-n-4, .mx-md-n-4
    margin-left: 1.5rem !important

  .m-md-n-3
    margin: 1rem !important

  .mt-md-n-3, .my-md-n-3
    margin-top: 1rem !important

  .mr-md-n-3, .mx-md-n-3
    margin-right: 1rem !important

  .mb-md-n-3, .my-md-n-3
    margin-bottom: 1rem !important

  .ml-md-n-3, .mx-md-n-3
    margin-left: 1rem !important

  .m-md-n-2
    margin: .5rem !important

  .mt-md-n-2, .my-md-n-2
    margin-top: .5rem !important

  .mr-md-n-2, .mx-md-n-2
    margin-right: .5rem !important

  .mb-md-n-2, .my-md-n-2
    margin-bottom: .5rem !important

  .ml-md-n-2, .mx-md-n-2
    margin-left: .5rem !important

  .m-md-n-1
    margin: .25rem !important

  .mt-md-n-1, .my-md-n-1
    margin-top: .25rem !important

  .mr-md-n-1, .mx-md-n-1
    margin-right: .25rem !important

  .mb-md-n-1, .my-md-n-1
    margin-bottom: .25rem !important

  .ml-md-n-1, .mx-md-n-1
    margin-left: .25rem !important

  .m-md-n6
    margin: -4.5rem !important

  .mt-md-n6, .my-md-n6
    margin-top: -4.5rem !important

  .mr-md-n6, .mx-md-n6
    margin-right: -4.5rem !important

  .mb-md-n6, .my-md-n6
    margin-bottom: -4.5rem !important

  .ml-md-n6, .mx-md-n6
    margin-left: -4.5rem !important

  .m-md-n7
    margin: -6rem !important

  .mt-md-n7, .my-md-n7
    margin-top: -6rem !important

  .mr-md-n7, .mx-md-n7
    margin-right: -6rem !important

  .mb-md-n7, .my-md-n7
    margin-bottom: -6rem !important

  .ml-md-n7, .mx-md-n7
    margin-left: -6rem !important

  .m-md-n8
    margin: -8rem !important

  .mt-md-n8, .my-md-n8
    margin-top: -8rem !important

  .mr-md-n8, .mx-md-n8
    margin-right: -8rem !important

  .mb-md-n8, .my-md-n8
    margin-bottom: -8rem !important

  .ml-md-n8, .mx-md-n8
    margin-left: -8rem !important

  .m-md-n9
    margin: -10rem !important

  .mt-md-n9, .my-md-n9
    margin-top: -10rem !important

  .mr-md-n9, .mx-md-n9
    margin-right: -10rem !important

  .mb-md-n9, .my-md-n9
    margin-bottom: -10rem !important

  .ml-md-n9, .mx-md-n9
    margin-left: -10rem !important

  .m-md-auto
    margin: auto !important

  .mt-md-auto, .my-md-auto
    margin-top: auto !important

  .mr-md-auto, .mx-md-auto
    margin-right: auto !important

  .mb-md-auto, .my-md-auto
    margin-bottom: auto !important

  .ml-md-auto, .mx-md-auto
    margin-left: auto !important

@media (min-width: 992px)
  .m-lg-0
    margin: 0 !important

  .mt-lg-0, .my-lg-0
    margin-top: 0 !important

  .mr-lg-0, .mx-lg-0
    margin-right: 0 !important

  .mb-lg-0, .my-lg-0
    margin-bottom: 0 !important

  .ml-lg-0, .mx-lg-0
    margin-left: 0 !important

  .m-lg-1
    margin: .25rem !important

  .mt-lg-1, .my-lg-1
    margin-top: .25rem !important

  .mr-lg-1, .mx-lg-1
    margin-right: .25rem !important

  .mb-lg-1, .my-lg-1
    margin-bottom: .25rem !important

  .ml-lg-1, .mx-lg-1
    margin-left: .25rem !important

  .m-lg-2
    margin: .5rem !important

  .mt-lg-2, .my-lg-2
    margin-top: .5rem !important

  .mr-lg-2, .mx-lg-2
    margin-right: .5rem !important

  .mb-lg-2, .my-lg-2
    margin-bottom: .5rem !important

  .ml-lg-2, .mx-lg-2
    margin-left: .5rem !important

  .m-lg-3
    margin: 1rem !important

  .mt-lg-3, .my-lg-3
    margin-top: 1rem !important

  .mr-lg-3, .mx-lg-3
    margin-right: 1rem !important

  .mb-lg-3, .my-lg-3
    margin-bottom: 1rem !important

  .ml-lg-3, .mx-lg-3
    margin-left: 1rem !important

  .m-lg-4
    margin: 1.5rem !important

  .mt-lg-4, .my-lg-4
    margin-top: 1.5rem !important

  .mr-lg-4, .mx-lg-4
    margin-right: 1.5rem !important

  .mb-lg-4, .my-lg-4
    margin-bottom: 1.5rem !important

  .ml-lg-4, .mx-lg-4
    margin-left: 1.5rem !important

  .m-lg-5
    margin: 3rem !important

  .mt-lg-5, .my-lg-5
    margin-top: 3rem !important

  .mr-lg-5, .mx-lg-5
    margin-right: 3rem !important

  .mb-lg-5, .my-lg-5
    margin-bottom: 3rem !important

  .ml-lg-5, .mx-lg-5
    margin-left: 3rem !important

  .m-lg--9
    margin: -10rem !important

  .mt-lg--9, .my-lg--9
    margin-top: -10rem !important

  .mr-lg--9, .mx-lg--9
    margin-right: -10rem !important

  .mb-lg--9, .my-lg--9
    margin-bottom: -10rem !important

  .ml-lg--9, .mx-lg--9
    margin-left: -10rem !important

  .m-lg--8
    margin: -8rem !important

  .mt-lg--8, .my-lg--8
    margin-top: -8rem !important

  .mr-lg--8, .mx-lg--8
    margin-right: -8rem !important

  .mb-lg--8, .my-lg--8
    margin-bottom: -8rem !important

  .ml-lg--8, .mx-lg--8
    margin-left: -8rem !important

  .m-lg--7
    margin: -6rem !important

  .mt-lg--7, .my-lg--7
    margin-top: -6rem !important

  .mr-lg--7, .mx-lg--7
    margin-right: -6rem !important

  .mb-lg--7, .my-lg--7
    margin-bottom: -6rem !important

  .ml-lg--7, .mx-lg--7
    margin-left: -6rem !important

  .m-lg--6
    margin: -4.5rem !important

  .mt-lg--6, .my-lg--6
    margin-top: -4.5rem !important

  .mr-lg--6, .mx-lg--6
    margin-right: -4.5rem !important

  .mb-lg--6, .my-lg--6
    margin-bottom: -4.5rem !important

  .ml-lg--6, .mx-lg--6
    margin-left: -4.5rem !important

  .m-lg--5
    margin: -3rem !important

  .mt-lg--5, .my-lg--5
    margin-top: -3rem !important

  .mr-lg--5, .mx-lg--5
    margin-right: -3rem !important

  .mb-lg--5, .my-lg--5
    margin-bottom: -3rem !important

  .ml-lg--5, .mx-lg--5
    margin-left: -3rem !important

  .m-lg--4
    margin: -1.5rem !important

  .mt-lg--4, .my-lg--4
    margin-top: -1.5rem !important

  .mr-lg--4, .mx-lg--4
    margin-right: -1.5rem !important

  .mb-lg--4, .my-lg--4
    margin-bottom: -1.5rem !important

  .ml-lg--4, .mx-lg--4
    margin-left: -1.5rem !important

  .m-lg--3
    margin: -1rem !important

  .mt-lg--3, .my-lg--3
    margin-top: -1rem !important

  .mr-lg--3, .mx-lg--3
    margin-right: -1rem !important

  .mb-lg--3, .my-lg--3
    margin-bottom: -1rem !important

  .ml-lg--3, .mx-lg--3
    margin-left: -1rem !important

  .m-lg--2
    margin: -.5rem !important

  .mt-lg--2, .my-lg--2
    margin-top: -.5rem !important

  .mr-lg--2, .mx-lg--2
    margin-right: -.5rem !important

  .mb-lg--2, .my-lg--2
    margin-bottom: -.5rem !important

  .ml-lg--2, .mx-lg--2
    margin-left: -.5rem !important

  .m-lg--1
    margin: -.25rem !important

  .mt-lg--1, .my-lg--1
    margin-top: -.25rem !important

  .mr-lg--1, .mx-lg--1
    margin-right: -.25rem !important

  .mb-lg--1, .my-lg--1
    margin-bottom: -.25rem !important

  .ml-lg--1, .mx-lg--1
    margin-left: -.25rem !important

  .m-lg-6
    margin: 4.5rem !important

  .mt-lg-6, .my-lg-6
    margin-top: 4.5rem !important

  .mr-lg-6, .mx-lg-6
    margin-right: 4.5rem !important

  .mb-lg-6, .my-lg-6
    margin-bottom: 4.5rem !important

  .ml-lg-6, .mx-lg-6
    margin-left: 4.5rem !important

  .m-lg-7
    margin: 6rem !important

  .mt-lg-7, .my-lg-7
    margin-top: 6rem !important

  .mr-lg-7, .mx-lg-7
    margin-right: 6rem !important

  .mb-lg-7, .my-lg-7
    margin-bottom: 6rem !important

  .ml-lg-7, .mx-lg-7
    margin-left: 6rem !important

  .m-lg-8
    margin: 8rem !important

  .mt-lg-8, .my-lg-8
    margin-top: 8rem !important

  .mr-lg-8, .mx-lg-8
    margin-right: 8rem !important

  .mb-lg-8, .my-lg-8
    margin-bottom: 8rem !important

  .ml-lg-8, .mx-lg-8
    margin-left: 8rem !important

  .m-lg-9
    margin: 10rem !important

  .mt-lg-9, .my-lg-9
    margin-top: 10rem !important

  .mr-lg-9, .mx-lg-9
    margin-right: 10rem !important

  .mb-lg-9, .my-lg-9
    margin-bottom: 10rem !important

  .ml-lg-9, .mx-lg-9
    margin-left: 10rem !important

  .p-lg-0
    padding: 0 !important

  .pt-lg-0, .py-lg-0
    padding-top: 0 !important

  .pr-lg-0, .px-lg-0
    padding-right: 0 !important

  .pb-lg-0, .py-lg-0
    padding-bottom: 0 !important

  .pl-lg-0, .px-lg-0
    padding-left: 0 !important

  .p-lg-1
    padding: .25rem !important

  .pt-lg-1, .py-lg-1
    padding-top: .25rem !important

  .pr-lg-1, .px-lg-1
    padding-right: .25rem !important

  .pb-lg-1, .py-lg-1
    padding-bottom: .25rem !important

  .pl-lg-1, .px-lg-1
    padding-left: .25rem !important

  .p-lg-2
    padding: .5rem !important

  .pt-lg-2, .py-lg-2
    padding-top: .5rem !important

  .pr-lg-2, .px-lg-2
    padding-right: .5rem !important

  .pb-lg-2, .py-lg-2
    padding-bottom: .5rem !important

  .pl-lg-2, .px-lg-2
    padding-left: .5rem !important

  .p-lg-3
    padding: 1rem !important

  .pt-lg-3, .py-lg-3
    padding-top: 1rem !important

  .pr-lg-3, .px-lg-3
    padding-right: 1rem !important

  .pb-lg-3, .py-lg-3
    padding-bottom: 1rem !important

  .pl-lg-3, .px-lg-3
    padding-left: 1rem !important

  .p-lg-4
    padding: 1.5rem !important

  .pt-lg-4, .py-lg-4
    padding-top: 1.5rem !important

  .pr-lg-4, .px-lg-4
    padding-right: 1.5rem !important

  .pb-lg-4, .py-lg-4
    padding-bottom: 1.5rem !important

  .pl-lg-4, .px-lg-4
    padding-left: 1.5rem !important

  .p-lg-5
    padding: 3rem !important

  .pt-lg-5, .py-lg-5
    padding-top: 3rem !important

  .pr-lg-5, .px-lg-5
    padding-right: 3rem !important

  .pb-lg-5, .py-lg-5
    padding-bottom: 3rem !important

  .pl-lg-5, .px-lg-5
    padding-left: 3rem !important

  .p-lg--9
    padding: -10rem !important

  .pt-lg--9, .py-lg--9
    padding-top: -10rem !important

  .pr-lg--9, .px-lg--9
    padding-right: -10rem !important

  .pb-lg--9, .py-lg--9
    padding-bottom: -10rem !important

  .pl-lg--9, .px-lg--9
    padding-left: -10rem !important

  .p-lg--8
    padding: -8rem !important

  .pt-lg--8, .py-lg--8
    padding-top: -8rem !important

  .pr-lg--8, .px-lg--8
    padding-right: -8rem !important

  .pb-lg--8, .py-lg--8
    padding-bottom: -8rem !important

  .pl-lg--8, .px-lg--8
    padding-left: -8rem !important

  .p-lg--7
    padding: -6rem !important

  .pt-lg--7, .py-lg--7
    padding-top: -6rem !important

  .pr-lg--7, .px-lg--7
    padding-right: -6rem !important

  .pb-lg--7, .py-lg--7
    padding-bottom: -6rem !important

  .pl-lg--7, .px-lg--7
    padding-left: -6rem !important

  .p-lg--6
    padding: -4.5rem !important

  .pt-lg--6, .py-lg--6
    padding-top: -4.5rem !important

  .pr-lg--6, .px-lg--6
    padding-right: -4.5rem !important

  .pb-lg--6, .py-lg--6
    padding-bottom: -4.5rem !important

  .pl-lg--6, .px-lg--6
    padding-left: -4.5rem !important

  .p-lg--5
    padding: -3rem !important

  .pt-lg--5, .py-lg--5
    padding-top: -3rem !important

  .pr-lg--5, .px-lg--5
    padding-right: -3rem !important

  .pb-lg--5, .py-lg--5
    padding-bottom: -3rem !important

  .pl-lg--5, .px-lg--5
    padding-left: -3rem !important

  .p-lg--4
    padding: -1.5rem !important

  .pt-lg--4, .py-lg--4
    padding-top: -1.5rem !important

  .pr-lg--4, .px-lg--4
    padding-right: -1.5rem !important

  .pb-lg--4, .py-lg--4
    padding-bottom: -1.5rem !important

  .pl-lg--4, .px-lg--4
    padding-left: -1.5rem !important

  .p-lg--3
    padding: -1rem !important

  .pt-lg--3, .py-lg--3
    padding-top: -1rem !important

  .pr-lg--3, .px-lg--3
    padding-right: -1rem !important

  .pb-lg--3, .py-lg--3
    padding-bottom: -1rem !important

  .pl-lg--3, .px-lg--3
    padding-left: -1rem !important

  .p-lg--2
    padding: -.5rem !important

  .pt-lg--2, .py-lg--2
    padding-top: -.5rem !important

  .pr-lg--2, .px-lg--2
    padding-right: -.5rem !important

  .pb-lg--2, .py-lg--2
    padding-bottom: -.5rem !important

  .pl-lg--2, .px-lg--2
    padding-left: -.5rem !important

  .p-lg--1
    padding: -.25rem !important

  .pt-lg--1, .py-lg--1
    padding-top: -.25rem !important

  .pr-lg--1, .px-lg--1
    padding-right: -.25rem !important

  .pb-lg--1, .py-lg--1
    padding-bottom: -.25rem !important

  .pl-lg--1, .px-lg--1
    padding-left: -.25rem !important

  .p-lg-6
    padding: 4.5rem !important

  .pt-lg-6, .py-lg-6
    padding-top: 4.5rem !important

  .pr-lg-6, .px-lg-6
    padding-right: 4.5rem !important

  .pb-lg-6, .py-lg-6
    padding-bottom: 4.5rem !important

  .pl-lg-6, .px-lg-6
    padding-left: 4.5rem !important

  .p-lg-7
    padding: 6rem !important

  .pt-lg-7, .py-lg-7
    padding-top: 6rem !important

  .pr-lg-7, .px-lg-7
    padding-right: 6rem !important

  .pb-lg-7, .py-lg-7
    padding-bottom: 6rem !important

  .pl-lg-7, .px-lg-7
    padding-left: 6rem !important

  .p-lg-8
    padding: 8rem !important

  .pt-lg-8, .py-lg-8
    padding-top: 8rem !important

  .pr-lg-8, .px-lg-8
    padding-right: 8rem !important

  .pb-lg-8, .py-lg-8
    padding-bottom: 8rem !important

  .pl-lg-8, .px-lg-8
    padding-left: 8rem !important

  .p-lg-9
    padding: 10rem !important

  .pt-lg-9, .py-lg-9
    padding-top: 10rem !important

  .pr-lg-9, .px-lg-9
    padding-right: 10rem !important

  .pb-lg-9, .py-lg-9
    padding-bottom: 10rem !important

  .pl-lg-9, .px-lg-9
    padding-left: 10rem !important

  .m-lg-n1
    margin: -.25rem !important

  .mt-lg-n1, .my-lg-n1
    margin-top: -.25rem !important

  .mr-lg-n1, .mx-lg-n1
    margin-right: -.25rem !important

  .mb-lg-n1, .my-lg-n1
    margin-bottom: -.25rem !important

  .ml-lg-n1, .mx-lg-n1
    margin-left: -.25rem !important

  .m-lg-n2
    margin: -.5rem !important

  .mt-lg-n2, .my-lg-n2
    margin-top: -.5rem !important

  .mr-lg-n2, .mx-lg-n2
    margin-right: -.5rem !important

  .mb-lg-n2, .my-lg-n2
    margin-bottom: -.5rem !important

  .ml-lg-n2, .mx-lg-n2
    margin-left: -.5rem !important

  .m-lg-n3
    margin: -1rem !important

  .mt-lg-n3, .my-lg-n3
    margin-top: -1rem !important

  .mr-lg-n3, .mx-lg-n3
    margin-right: -1rem !important

  .mb-lg-n3, .my-lg-n3
    margin-bottom: -1rem !important

  .ml-lg-n3, .mx-lg-n3
    margin-left: -1rem !important

  .m-lg-n4
    margin: -1.5rem !important

  .mt-lg-n4, .my-lg-n4
    margin-top: -1.5rem !important

  .mr-lg-n4, .mx-lg-n4
    margin-right: -1.5rem !important

  .mb-lg-n4, .my-lg-n4
    margin-bottom: -1.5rem !important

  .ml-lg-n4, .mx-lg-n4
    margin-left: -1.5rem !important

  .m-lg-n5
    margin: -3rem !important

  .mt-lg-n5, .my-lg-n5
    margin-top: -3rem !important

  .mr-lg-n5, .mx-lg-n5
    margin-right: -3rem !important

  .mb-lg-n5, .my-lg-n5
    margin-bottom: -3rem !important

  .ml-lg-n5, .mx-lg-n5
    margin-left: -3rem !important

  .m-lg-n-9
    margin: 10rem !important

  .mt-lg-n-9, .my-lg-n-9
    margin-top: 10rem !important

  .mr-lg-n-9, .mx-lg-n-9
    margin-right: 10rem !important

  .mb-lg-n-9, .my-lg-n-9
    margin-bottom: 10rem !important

  .ml-lg-n-9, .mx-lg-n-9
    margin-left: 10rem !important

  .m-lg-n-8
    margin: 8rem !important

  .mt-lg-n-8, .my-lg-n-8
    margin-top: 8rem !important

  .mr-lg-n-8, .mx-lg-n-8
    margin-right: 8rem !important

  .mb-lg-n-8, .my-lg-n-8
    margin-bottom: 8rem !important

  .ml-lg-n-8, .mx-lg-n-8
    margin-left: 8rem !important

  .m-lg-n-7
    margin: 6rem !important

  .mt-lg-n-7, .my-lg-n-7
    margin-top: 6rem !important

  .mr-lg-n-7, .mx-lg-n-7
    margin-right: 6rem !important

  .mb-lg-n-7, .my-lg-n-7
    margin-bottom: 6rem !important

  .ml-lg-n-7, .mx-lg-n-7
    margin-left: 6rem !important

  .m-lg-n-6
    margin: 4.5rem !important

  .mt-lg-n-6, .my-lg-n-6
    margin-top: 4.5rem !important

  .mr-lg-n-6, .mx-lg-n-6
    margin-right: 4.5rem !important

  .mb-lg-n-6, .my-lg-n-6
    margin-bottom: 4.5rem !important

  .ml-lg-n-6, .mx-lg-n-6
    margin-left: 4.5rem !important

  .m-lg-n-5
    margin: 3rem !important

  .mt-lg-n-5, .my-lg-n-5
    margin-top: 3rem !important

  .mr-lg-n-5, .mx-lg-n-5
    margin-right: 3rem !important

  .mb-lg-n-5, .my-lg-n-5
    margin-bottom: 3rem !important

  .ml-lg-n-5, .mx-lg-n-5
    margin-left: 3rem !important

  .m-lg-n-4
    margin: 1.5rem !important

  .mt-lg-n-4, .my-lg-n-4
    margin-top: 1.5rem !important

  .mr-lg-n-4, .mx-lg-n-4
    margin-right: 1.5rem !important

  .mb-lg-n-4, .my-lg-n-4
    margin-bottom: 1.5rem !important

  .ml-lg-n-4, .mx-lg-n-4
    margin-left: 1.5rem !important

  .m-lg-n-3
    margin: 1rem !important

  .mt-lg-n-3, .my-lg-n-3
    margin-top: 1rem !important

  .mr-lg-n-3, .mx-lg-n-3
    margin-right: 1rem !important

  .mb-lg-n-3, .my-lg-n-3
    margin-bottom: 1rem !important

  .ml-lg-n-3, .mx-lg-n-3
    margin-left: 1rem !important

  .m-lg-n-2
    margin: .5rem !important

  .mt-lg-n-2, .my-lg-n-2
    margin-top: .5rem !important

  .mr-lg-n-2, .mx-lg-n-2
    margin-right: .5rem !important

  .mb-lg-n-2, .my-lg-n-2
    margin-bottom: .5rem !important

  .ml-lg-n-2, .mx-lg-n-2
    margin-left: .5rem !important

  .m-lg-n-1
    margin: .25rem !important

  .mt-lg-n-1, .my-lg-n-1
    margin-top: .25rem !important

  .mr-lg-n-1, .mx-lg-n-1
    margin-right: .25rem !important

  .mb-lg-n-1, .my-lg-n-1
    margin-bottom: .25rem !important

  .ml-lg-n-1, .mx-lg-n-1
    margin-left: .25rem !important

  .m-lg-n6
    margin: -4.5rem !important

  .mt-lg-n6, .my-lg-n6
    margin-top: -4.5rem !important

  .mr-lg-n6, .mx-lg-n6
    margin-right: -4.5rem !important

  .mb-lg-n6, .my-lg-n6
    margin-bottom: -4.5rem !important

  .ml-lg-n6, .mx-lg-n6
    margin-left: -4.5rem !important

  .m-lg-n7
    margin: -6rem !important

  .mt-lg-n7, .my-lg-n7
    margin-top: -6rem !important

  .mr-lg-n7, .mx-lg-n7
    margin-right: -6rem !important

  .mb-lg-n7, .my-lg-n7
    margin-bottom: -6rem !important

  .ml-lg-n7, .mx-lg-n7
    margin-left: -6rem !important

  .m-lg-n8
    margin: -8rem !important

  .mt-lg-n8, .my-lg-n8
    margin-top: -8rem !important

  .mr-lg-n8, .mx-lg-n8
    margin-right: -8rem !important

  .mb-lg-n8, .my-lg-n8
    margin-bottom: -8rem !important

  .ml-lg-n8, .mx-lg-n8
    margin-left: -8rem !important

  .m-lg-n9
    margin: -10rem !important

  .mt-lg-n9, .my-lg-n9
    margin-top: -10rem !important

  .mr-lg-n9, .mx-lg-n9
    margin-right: -10rem !important

  .mb-lg-n9, .my-lg-n9
    margin-bottom: -10rem !important

  .ml-lg-n9, .mx-lg-n9
    margin-left: -10rem !important

  .m-lg-auto
    margin: auto !important

  .mt-lg-auto, .my-lg-auto
    margin-top: auto !important

  .mr-lg-auto, .mx-lg-auto
    margin-right: auto !important

  .mb-lg-auto, .my-lg-auto
    margin-bottom: auto !important

  .ml-lg-auto, .mx-lg-auto
    margin-left: auto !important

@media (min-width: 1200px)
  .m-xl-0
    margin: 0 !important

  .mt-xl-0, .my-xl-0
    margin-top: 0 !important

  .mr-xl-0, .mx-xl-0
    margin-right: 0 !important

  .mb-xl-0, .my-xl-0
    margin-bottom: 0 !important

  .ml-xl-0, .mx-xl-0
    margin-left: 0 !important

  .m-xl-1
    margin: .25rem !important

  .mt-xl-1, .my-xl-1
    margin-top: .25rem !important

  .mr-xl-1, .mx-xl-1
    margin-right: .25rem !important

  .mb-xl-1, .my-xl-1
    margin-bottom: .25rem !important

  .ml-xl-1, .mx-xl-1
    margin-left: .25rem !important

  .m-xl-2
    margin: .5rem !important

  .mt-xl-2, .my-xl-2
    margin-top: .5rem !important

  .mr-xl-2, .mx-xl-2
    margin-right: .5rem !important

  .mb-xl-2, .my-xl-2
    margin-bottom: .5rem !important

  .ml-xl-2, .mx-xl-2
    margin-left: .5rem !important

  .m-xl-3
    margin: 1rem !important

  .mt-xl-3, .my-xl-3
    margin-top: 1rem !important

  .mr-xl-3, .mx-xl-3
    margin-right: 1rem !important

  .mb-xl-3, .my-xl-3
    margin-bottom: 1rem !important

  .ml-xl-3, .mx-xl-3
    margin-left: 1rem !important

  .m-xl-4
    margin: 1.5rem !important

  .mt-xl-4, .my-xl-4
    margin-top: 1.5rem !important

  .mr-xl-4, .mx-xl-4
    margin-right: 1.5rem !important

  .mb-xl-4, .my-xl-4
    margin-bottom: 1.5rem !important

  .ml-xl-4, .mx-xl-4
    margin-left: 1.5rem !important

  .m-xl-5
    margin: 3rem !important

  .mt-xl-5, .my-xl-5
    margin-top: 3rem !important

  .mr-xl-5, .mx-xl-5
    margin-right: 3rem !important

  .mb-xl-5, .my-xl-5
    margin-bottom: 3rem !important

  .ml-xl-5, .mx-xl-5
    margin-left: 3rem !important

  .m-xl--9
    margin: -10rem !important

  .mt-xl--9, .my-xl--9
    margin-top: -10rem !important

  .mr-xl--9, .mx-xl--9
    margin-right: -10rem !important

  .mb-xl--9, .my-xl--9
    margin-bottom: -10rem !important

  .ml-xl--9, .mx-xl--9
    margin-left: -10rem !important

  .m-xl--8
    margin: -8rem !important

  .mt-xl--8, .my-xl--8
    margin-top: -8rem !important

  .mr-xl--8, .mx-xl--8
    margin-right: -8rem !important

  .mb-xl--8, .my-xl--8
    margin-bottom: -8rem !important

  .ml-xl--8, .mx-xl--8
    margin-left: -8rem !important

  .m-xl--7
    margin: -6rem !important

  .mt-xl--7, .my-xl--7
    margin-top: -6rem !important

  .mr-xl--7, .mx-xl--7
    margin-right: -6rem !important

  .mb-xl--7, .my-xl--7
    margin-bottom: -6rem !important

  .ml-xl--7, .mx-xl--7
    margin-left: -6rem !important

  .m-xl--6
    margin: -4.5rem !important

  .mt-xl--6, .my-xl--6
    margin-top: -4.5rem !important

  .mr-xl--6, .mx-xl--6
    margin-right: -4.5rem !important

  .mb-xl--6, .my-xl--6
    margin-bottom: -4.5rem !important

  .ml-xl--6, .mx-xl--6
    margin-left: -4.5rem !important

  .m-xl--5
    margin: -3rem !important

  .mt-xl--5, .my-xl--5
    margin-top: -3rem !important

  .mr-xl--5, .mx-xl--5
    margin-right: -3rem !important

  .mb-xl--5, .my-xl--5
    margin-bottom: -3rem !important

  .ml-xl--5, .mx-xl--5
    margin-left: -3rem !important

  .m-xl--4
    margin: -1.5rem !important

  .mt-xl--4, .my-xl--4
    margin-top: -1.5rem !important

  .mr-xl--4, .mx-xl--4
    margin-right: -1.5rem !important

  .mb-xl--4, .my-xl--4
    margin-bottom: -1.5rem !important

  .ml-xl--4, .mx-xl--4
    margin-left: -1.5rem !important

  .m-xl--3
    margin: -1rem !important

  .mt-xl--3, .my-xl--3
    margin-top: -1rem !important

  .mr-xl--3, .mx-xl--3
    margin-right: -1rem !important

  .mb-xl--3, .my-xl--3
    margin-bottom: -1rem !important

  .ml-xl--3, .mx-xl--3
    margin-left: -1rem !important

  .m-xl--2
    margin: -.5rem !important

  .mt-xl--2, .my-xl--2
    margin-top: -.5rem !important

  .mr-xl--2, .mx-xl--2
    margin-right: -.5rem !important

  .mb-xl--2, .my-xl--2
    margin-bottom: -.5rem !important

  .ml-xl--2, .mx-xl--2
    margin-left: -.5rem !important

  .m-xl--1
    margin: -.25rem !important

  .mt-xl--1, .my-xl--1
    margin-top: -.25rem !important

  .mr-xl--1, .mx-xl--1
    margin-right: -.25rem !important

  .mb-xl--1, .my-xl--1
    margin-bottom: -.25rem !important

  .ml-xl--1, .mx-xl--1
    margin-left: -.25rem !important

  .m-xl-6
    margin: 4.5rem !important

  .mt-xl-6, .my-xl-6
    margin-top: 4.5rem !important

  .mr-xl-6, .mx-xl-6
    margin-right: 4.5rem !important

  .mb-xl-6, .my-xl-6
    margin-bottom: 4.5rem !important

  .ml-xl-6, .mx-xl-6
    margin-left: 4.5rem !important

  .m-xl-7
    margin: 6rem !important

  .mt-xl-7, .my-xl-7
    margin-top: 6rem !important

  .mr-xl-7, .mx-xl-7
    margin-right: 6rem !important

  .mb-xl-7, .my-xl-7
    margin-bottom: 6rem !important

  .ml-xl-7, .mx-xl-7
    margin-left: 6rem !important

  .m-xl-8
    margin: 8rem !important

  .mt-xl-8, .my-xl-8
    margin-top: 8rem !important

  .mr-xl-8, .mx-xl-8
    margin-right: 8rem !important

  .mb-xl-8, .my-xl-8
    margin-bottom: 8rem !important

  .ml-xl-8, .mx-xl-8
    margin-left: 8rem !important

  .m-xl-9
    margin: 10rem !important

  .mt-xl-9, .my-xl-9
    margin-top: 10rem !important

  .mr-xl-9, .mx-xl-9
    margin-right: 10rem !important

  .mb-xl-9, .my-xl-9
    margin-bottom: 10rem !important

  .ml-xl-9, .mx-xl-9
    margin-left: 10rem !important

  .p-xl-0
    padding: 0 !important

  .pt-xl-0, .py-xl-0
    padding-top: 0 !important

  .pr-xl-0, .px-xl-0
    padding-right: 0 !important

  .pb-xl-0, .py-xl-0
    padding-bottom: 0 !important

  .pl-xl-0, .px-xl-0
    padding-left: 0 !important

  .p-xl-1
    padding: .25rem !important

  .pt-xl-1, .py-xl-1
    padding-top: .25rem !important

  .pr-xl-1, .px-xl-1
    padding-right: .25rem !important

  .pb-xl-1, .py-xl-1
    padding-bottom: .25rem !important

  .pl-xl-1, .px-xl-1
    padding-left: .25rem !important

  .p-xl-2
    padding: .5rem !important

  .pt-xl-2, .py-xl-2
    padding-top: .5rem !important

  .pr-xl-2, .px-xl-2
    padding-right: .5rem !important

  .pb-xl-2, .py-xl-2
    padding-bottom: .5rem !important

  .pl-xl-2, .px-xl-2
    padding-left: .5rem !important

  .p-xl-3
    padding: 1rem !important

  .pt-xl-3, .py-xl-3
    padding-top: 1rem !important

  .pr-xl-3, .px-xl-3
    padding-right: 1rem !important

  .pb-xl-3, .py-xl-3
    padding-bottom: 1rem !important

  .pl-xl-3, .px-xl-3
    padding-left: 1rem !important

  .p-xl-4
    padding: 1.5rem !important

  .pt-xl-4, .py-xl-4
    padding-top: 1.5rem !important

  .pr-xl-4, .px-xl-4
    padding-right: 1.5rem !important

  .pb-xl-4, .py-xl-4
    padding-bottom: 1.5rem !important

  .pl-xl-4, .px-xl-4
    padding-left: 1.5rem !important

  .p-xl-5
    padding: 3rem !important

  .pt-xl-5, .py-xl-5
    padding-top: 3rem !important

  .pr-xl-5, .px-xl-5
    padding-right: 3rem !important

  .pb-xl-5, .py-xl-5
    padding-bottom: 3rem !important

  .pl-xl-5, .px-xl-5
    padding-left: 3rem !important

  .p-xl--9
    padding: -10rem !important

  .pt-xl--9, .py-xl--9
    padding-top: -10rem !important

  .pr-xl--9, .px-xl--9
    padding-right: -10rem !important

  .pb-xl--9, .py-xl--9
    padding-bottom: -10rem !important

  .pl-xl--9, .px-xl--9
    padding-left: -10rem !important

  .p-xl--8
    padding: -8rem !important

  .pt-xl--8, .py-xl--8
    padding-top: -8rem !important

  .pr-xl--8, .px-xl--8
    padding-right: -8rem !important

  .pb-xl--8, .py-xl--8
    padding-bottom: -8rem !important

  .pl-xl--8, .px-xl--8
    padding-left: -8rem !important

  .p-xl--7
    padding: -6rem !important

  .pt-xl--7, .py-xl--7
    padding-top: -6rem !important

  .pr-xl--7, .px-xl--7
    padding-right: -6rem !important

  .pb-xl--7, .py-xl--7
    padding-bottom: -6rem !important

  .pl-xl--7, .px-xl--7
    padding-left: -6rem !important

  .p-xl--6
    padding: -4.5rem !important

  .pt-xl--6, .py-xl--6
    padding-top: -4.5rem !important

  .pr-xl--6, .px-xl--6
    padding-right: -4.5rem !important

  .pb-xl--6, .py-xl--6
    padding-bottom: -4.5rem !important

  .pl-xl--6, .px-xl--6
    padding-left: -4.5rem !important

  .p-xl--5
    padding: -3rem !important

  .pt-xl--5, .py-xl--5
    padding-top: -3rem !important

  .pr-xl--5, .px-xl--5
    padding-right: -3rem !important

  .pb-xl--5, .py-xl--5
    padding-bottom: -3rem !important

  .pl-xl--5, .px-xl--5
    padding-left: -3rem !important

  .p-xl--4
    padding: -1.5rem !important

  .pt-xl--4, .py-xl--4
    padding-top: -1.5rem !important

  .pr-xl--4, .px-xl--4
    padding-right: -1.5rem !important

  .pb-xl--4, .py-xl--4
    padding-bottom: -1.5rem !important

  .pl-xl--4, .px-xl--4
    padding-left: -1.5rem !important

  .p-xl--3
    padding: -1rem !important

  .pt-xl--3, .py-xl--3
    padding-top: -1rem !important

  .pr-xl--3, .px-xl--3
    padding-right: -1rem !important

  .pb-xl--3, .py-xl--3
    padding-bottom: -1rem !important

  .pl-xl--3, .px-xl--3
    padding-left: -1rem !important

  .p-xl--2
    padding: -.5rem !important

  .pt-xl--2, .py-xl--2
    padding-top: -.5rem !important

  .pr-xl--2, .px-xl--2
    padding-right: -.5rem !important

  .pb-xl--2, .py-xl--2
    padding-bottom: -.5rem !important

  .pl-xl--2, .px-xl--2
    padding-left: -.5rem !important

  .p-xl--1
    padding: -.25rem !important

  .pt-xl--1, .py-xl--1
    padding-top: -.25rem !important

  .pr-xl--1, .px-xl--1
    padding-right: -.25rem !important

  .pb-xl--1, .py-xl--1
    padding-bottom: -.25rem !important

  .pl-xl--1, .px-xl--1
    padding-left: -.25rem !important

  .p-xl-6
    padding: 4.5rem !important

  .pt-xl-6, .py-xl-6
    padding-top: 4.5rem !important

  .pr-xl-6, .px-xl-6
    padding-right: 4.5rem !important

  .pb-xl-6, .py-xl-6
    padding-bottom: 4.5rem !important

  .pl-xl-6, .px-xl-6
    padding-left: 4.5rem !important

  .p-xl-7
    padding: 6rem !important

  .pt-xl-7, .py-xl-7
    padding-top: 6rem !important

  .pr-xl-7, .px-xl-7
    padding-right: 6rem !important

  .pb-xl-7, .py-xl-7
    padding-bottom: 6rem !important

  .pl-xl-7, .px-xl-7
    padding-left: 6rem !important

  .p-xl-8
    padding: 8rem !important

  .pt-xl-8, .py-xl-8
    padding-top: 8rem !important

  .pr-xl-8, .px-xl-8
    padding-right: 8rem !important

  .pb-xl-8, .py-xl-8
    padding-bottom: 8rem !important

  .pl-xl-8, .px-xl-8
    padding-left: 8rem !important

  .p-xl-9
    padding: 10rem !important

  .pt-xl-9, .py-xl-9
    padding-top: 10rem !important

  .pr-xl-9, .px-xl-9
    padding-right: 10rem !important

  .pb-xl-9, .py-xl-9
    padding-bottom: 10rem !important

  .pl-xl-9, .px-xl-9
    padding-left: 10rem !important

  .m-xl-n1
    margin: -.25rem !important

  .mt-xl-n1, .my-xl-n1
    margin-top: -.25rem !important

  .mr-xl-n1, .mx-xl-n1
    margin-right: -.25rem !important

  .mb-xl-n1, .my-xl-n1
    margin-bottom: -.25rem !important

  .ml-xl-n1, .mx-xl-n1
    margin-left: -.25rem !important

  .m-xl-n2
    margin: -.5rem !important

  .mt-xl-n2, .my-xl-n2
    margin-top: -.5rem !important

  .mr-xl-n2, .mx-xl-n2
    margin-right: -.5rem !important

  .mb-xl-n2, .my-xl-n2
    margin-bottom: -.5rem !important

  .ml-xl-n2, .mx-xl-n2
    margin-left: -.5rem !important

  .m-xl-n3
    margin: -1rem !important

  .mt-xl-n3, .my-xl-n3
    margin-top: -1rem !important

  .mr-xl-n3, .mx-xl-n3
    margin-right: -1rem !important

  .mb-xl-n3, .my-xl-n3
    margin-bottom: -1rem !important

  .ml-xl-n3, .mx-xl-n3
    margin-left: -1rem !important

  .m-xl-n4
    margin: -1.5rem !important

  .mt-xl-n4, .my-xl-n4
    margin-top: -1.5rem !important

  .mr-xl-n4, .mx-xl-n4
    margin-right: -1.5rem !important

  .mb-xl-n4, .my-xl-n4
    margin-bottom: -1.5rem !important

  .ml-xl-n4, .mx-xl-n4
    margin-left: -1.5rem !important

  .m-xl-n5
    margin: -3rem !important

  .mt-xl-n5, .my-xl-n5
    margin-top: -3rem !important

  .mr-xl-n5, .mx-xl-n5
    margin-right: -3rem !important

  .mb-xl-n5, .my-xl-n5
    margin-bottom: -3rem !important

  .ml-xl-n5, .mx-xl-n5
    margin-left: -3rem !important

  .m-xl-n-9
    margin: 10rem !important

  .mt-xl-n-9, .my-xl-n-9
    margin-top: 10rem !important

  .mr-xl-n-9, .mx-xl-n-9
    margin-right: 10rem !important

  .mb-xl-n-9, .my-xl-n-9
    margin-bottom: 10rem !important

  .ml-xl-n-9, .mx-xl-n-9
    margin-left: 10rem !important

  .m-xl-n-8
    margin: 8rem !important

  .mt-xl-n-8, .my-xl-n-8
    margin-top: 8rem !important

  .mr-xl-n-8, .mx-xl-n-8
    margin-right: 8rem !important

  .mb-xl-n-8, .my-xl-n-8
    margin-bottom: 8rem !important

  .ml-xl-n-8, .mx-xl-n-8
    margin-left: 8rem !important

  .m-xl-n-7
    margin: 6rem !important

  .mt-xl-n-7, .my-xl-n-7
    margin-top: 6rem !important

  .mr-xl-n-7, .mx-xl-n-7
    margin-right: 6rem !important

  .mb-xl-n-7, .my-xl-n-7
    margin-bottom: 6rem !important

  .ml-xl-n-7, .mx-xl-n-7
    margin-left: 6rem !important

  .m-xl-n-6
    margin: 4.5rem !important

  .mt-xl-n-6, .my-xl-n-6
    margin-top: 4.5rem !important

  .mr-xl-n-6, .mx-xl-n-6
    margin-right: 4.5rem !important

  .mb-xl-n-6, .my-xl-n-6
    margin-bottom: 4.5rem !important

  .ml-xl-n-6, .mx-xl-n-6
    margin-left: 4.5rem !important

  .m-xl-n-5
    margin: 3rem !important

  .mt-xl-n-5, .my-xl-n-5
    margin-top: 3rem !important

  .mr-xl-n-5, .mx-xl-n-5
    margin-right: 3rem !important

  .mb-xl-n-5, .my-xl-n-5
    margin-bottom: 3rem !important

  .ml-xl-n-5, .mx-xl-n-5
    margin-left: 3rem !important

  .m-xl-n-4
    margin: 1.5rem !important

  .mt-xl-n-4, .my-xl-n-4
    margin-top: 1.5rem !important

  .mr-xl-n-4, .mx-xl-n-4
    margin-right: 1.5rem !important

  .mb-xl-n-4, .my-xl-n-4
    margin-bottom: 1.5rem !important

  .ml-xl-n-4, .mx-xl-n-4
    margin-left: 1.5rem !important

  .m-xl-n-3
    margin: 1rem !important

  .mt-xl-n-3, .my-xl-n-3
    margin-top: 1rem !important

  .mr-xl-n-3, .mx-xl-n-3
    margin-right: 1rem !important

  .mb-xl-n-3, .my-xl-n-3
    margin-bottom: 1rem !important

  .ml-xl-n-3, .mx-xl-n-3
    margin-left: 1rem !important

  .m-xl-n-2
    margin: .5rem !important

  .mt-xl-n-2, .my-xl-n-2
    margin-top: .5rem !important

  .mr-xl-n-2, .mx-xl-n-2
    margin-right: .5rem !important

  .mb-xl-n-2, .my-xl-n-2
    margin-bottom: .5rem !important

  .ml-xl-n-2, .mx-xl-n-2
    margin-left: .5rem !important

  .m-xl-n-1
    margin: .25rem !important

  .mt-xl-n-1, .my-xl-n-1
    margin-top: .25rem !important

  .mr-xl-n-1, .mx-xl-n-1
    margin-right: .25rem !important

  .mb-xl-n-1, .my-xl-n-1
    margin-bottom: .25rem !important

  .ml-xl-n-1, .mx-xl-n-1
    margin-left: .25rem !important

  .m-xl-n6
    margin: -4.5rem !important

  .mt-xl-n6, .my-xl-n6
    margin-top: -4.5rem !important

  .mr-xl-n6, .mx-xl-n6
    margin-right: -4.5rem !important

  .mb-xl-n6, .my-xl-n6
    margin-bottom: -4.5rem !important

  .ml-xl-n6, .mx-xl-n6
    margin-left: -4.5rem !important

  .m-xl-n7
    margin: -6rem !important

  .mt-xl-n7, .my-xl-n7
    margin-top: -6rem !important

  .mr-xl-n7, .mx-xl-n7
    margin-right: -6rem !important

  .mb-xl-n7, .my-xl-n7
    margin-bottom: -6rem !important

  .ml-xl-n7, .mx-xl-n7
    margin-left: -6rem !important

  .m-xl-n8
    margin: -8rem !important

  .mt-xl-n8, .my-xl-n8
    margin-top: -8rem !important

  .mr-xl-n8, .mx-xl-n8
    margin-right: -8rem !important

  .mb-xl-n8, .my-xl-n8
    margin-bottom: -8rem !important

  .ml-xl-n8, .mx-xl-n8
    margin-left: -8rem !important

  .m-xl-n9
    margin: -10rem !important

  .mt-xl-n9, .my-xl-n9
    margin-top: -10rem !important

  .mr-xl-n9, .mx-xl-n9
    margin-right: -10rem !important

  .mb-xl-n9, .my-xl-n9
    margin-bottom: -10rem !important

  .ml-xl-n9, .mx-xl-n9
    margin-left: -10rem !important

  .m-xl-auto
    margin: auto !important

  .mt-xl-auto, .my-xl-auto
    margin-top: auto !important

  .mr-xl-auto, .mx-xl-auto
    margin-right: auto !important

  .mb-xl-auto, .my-xl-auto
    margin-bottom: auto !important

  .ml-xl-auto, .mx-xl-auto
    margin-left: auto !important


.text-justify
  text-align: justify !important

.text-wrap
  white-space: normal !important

.text-nowrap
  white-space: nowrap !important

.text-truncate
  overflow: hidden
  white-space: nowrap
  text-overflow: ellipsis

.text-left
  text-align: left !important

.text-right
  text-align: right !important

.text-center
  text-align: center !important

@media (min-width: 576px)
  .text-sm-left
    text-align: left !important

  .text-sm-right
    text-align: right !important

  .text-sm-center
    text-align: center !important

@media (min-width: 768px)
  .text-md-left
    text-align: left !important

  .text-md-right
    text-align: right !important

  .text-md-center
    text-align: center !important

@media (min-width: 992px)
  .text-lg-left
    text-align: left !important

  .text-lg-right
    text-align: right !important

  .text-lg-center
    text-align: center !important

@media (min-width: 1200px)
  .text-xl-left
    text-align: left !important

  .text-xl-right
    text-align: right !important

  .text-xl-center
    text-align: center !important

.text-lowercase
  text-transform: lowercase !important

.text-uppercase
  text-transform: uppercase !important

.text-capitalize
  text-transform: capitalize !important

.font-weight-light
  font-weight: 300 !important

.font-weight-lighter
  font-weight: lighter !important

.font-weight-normal
  font-weight: 400 !important

.font-weight-bold
  font-weight: 600 !important

.font-weight-bolder
  font-weight: bolder !important

.font-italic
  font-style: italic !important

.text-white
  color: #fff !important

.text-primary
  color: #031d6d !important

a.text-primary
  &:hover, &:focus
    color: #233dd2 !important

.text-secondary
  color: #f7fafc !important

a.text-secondary
  &:hover, &:focus
    color: #bfd7e7 !important

.text-success
  color: #2dce89 !important

a.text-success
  &:hover, &:focus
    color: #1f8f5f !important

.text-info
  color: #11cdef !important

a.text-info
  &:hover, &:focus
    color: #0b90a8 !important

.text-warning
  color: #fb6340 !important

a.text-warning
  &:hover, &:focus
    color: #ea3005 !important

.text-danger
  color: #f5365c !important

a.text-danger
  &:hover, &:focus
    color: #d40b33 !important

.text-light
  color: #adb5bd !important

a.text-light
  &:hover, &:focus
    color: #838f9b !important

.text-dark
  color: #212529 !important

a.text-dark
  &:hover, &:focus
    color: black !important

.text-default
  color: #172b4d !important

a.text-default
  &:hover, &:focus
    color: #050a12 !important

.text-white
  color: #fff !important

a.text-white
  &:hover, &:focus
    color: #d9d9d9 !important

.text-neutral
  color: #fff !important

a.text-neutral
  &:hover, &:focus
    color: #d9d9d9 !important

.text-darker
  color: black !important

a.text-darker
  &:hover, &:focus
    color: black !important

.text-body
  color: #525f7f !important

.text-muted
  color: #8898aa !important

.text-black-50
  color: rgba(0, 0, 0, 0.5) !important

.text-white-50
  color: rgba(255, 255, 255, 0.5) !important

.text-hide
  font: 0/0 a
  color: transparent
  border: 0
  background-color: transparent
  text-shadow: none

.text-decoration-none
  text-decoration: none !important

.text-break
  word-break: break-word !important
  overflow-wrap: break-word !important

.text-reset
  color: inherit !important

.visible
  visibility: visible !important

.invisible
  visibility: hidden !important

@media print
  *
    box-shadow: none !important
    text-shadow: none !important

    &::before, &::after
      box-shadow: none !important
      text-shadow: none !important

  a:not(.btn)
    text-decoration: underline

  abbr[title]::after
    content: " (" attr(title) ")"

  pre
    white-space: pre-wrap !important
    border: 1px solid #adb5bd
    page-break-inside: avoid

  blockquote
    border: 1px solid #adb5bd
    page-break-inside: avoid

  thead
    display: table-header-group

  tr, img
    page-break-inside: avoid

  p, h2, h3
    orphans: 3
    widows: 3

  h2, h3
    page-break-after: avoid

  @page
    size: a3

  body, .container
    min-width: 992px !important

  .navbar
    display: none

  .badge
    border: 1px solid #000

  .table
    border-collapse: collapse !important

    td, th
      background-color: #fff !important

  .table-bordered
    th, td
      border: 1px solid #dee2e6 !important

  .table-dark
    color: inherit

    th, td, thead th, tbody + tbody
      border-color: #e9ecef

  .table .thead-dark th
    color: inherit
    border-color: #e9ecef

.alert
  font-size: .875rem

.alert-heading
  font-size: .9375rem
  font-weight: 600
  margin-top: .15rem

.alert-icon
  font-size: 1.25rem
  display: inline-block
  margin-right: 1.25rem
  vertical-align: middle

  i.ni
    position: relative
    top: 2px

.alert-text
  display: inline-block
  vertical-align: middle

[class*='alert-'] .alert-link
  color: #fff
  border-bottom: 1px dotted rgba(255, 255, 255, 0.5)

.alert-dismissible .close
  top: 50%
  right: 1.5rem
  padding: 0
  transform: translateY(-50%)
  opacity: 1

  > span:not(.sr-only)
    font-size: 1.5rem
    color: rgba(255, 255, 255, 0.6)
    background-color: transparent

  &:hover > span:not(.sr-only), &:focus > span:not(.sr-only)
    color: white
    background-color: transparent

@media (max-width: 575.98px)
  .alert-dismissible .close
    top: 1rem
    right: .5rem

.alert-secondary .close
  > span:not(.sr-only)
    color: rgba(23, 43, 77, 0.6)

  &:hover > span:not(.sr-only), &:focus > span:not(.sr-only)
    color: #172b4d

.avatar
  font-size: 1rem
  display: inline-flex
  width: 48px
  height: 48px
  color: #fff
  border-radius: .375rem
  background-color: #adb5bd
  align-items: center
  justify-content: center

  img
    width: 100%
    border-radius: .375rem
    height: 36px
    object-fit: cover

  + .avatar-content
    display: inline-block
    margin-left: .75rem

.avatar-lg
  font-size: .875rem
  width: 58px
  height: 58px

.avatar-sm
  font-size: .875rem
  object-fit: cover
  width: 36px
  height: 36px

.avatar-group .avatar
  position: relative
  z-index: 2
  border: 2px solid #fff

  &:hover
    z-index: 3

  + .avatar
    margin-left: -1rem

.badge
  text-transform: uppercase

  a
    color: #fff

.badge-md
  padding: .65em 1em

.badge-lg
  padding: .85em 1.375em

.badge-inline
  margin-right: .625rem

  + span
    position: relative
    top: 2px

    > a
      text-decoration: underline

.badge-default
  color: #fff

.badge-secondary
  color: #212529
  background-color: #f7fafc

.btn .badge
  &:not(:first-child)
    margin-left: .5rem

  &:not(:last-child)
    margin-right: .5rem

.badge-circle
  font-size: .75rem
  font-weight: 600
  display: inline-flex
  width: 1.25rem
  height: 1.25rem
  padding: 0 !important
  text-align: center
  border-radius: 50%
  align-items: center
  justify-content: center

  &.badge-md
    width: 1.5rem
    height: 1.5rem

  &.badge-lg
    width: 2rem
    height: 2rem

.badge-dot
  font-size: .875rem
  font-weight: 400
  padding-right: 0
  padding-left: 0
  text-transform: none
  background: transparent

  strong
    color: #32325d

  i
    display: inline-block
    width: .375rem
    height: .375rem
    margin-right: .375rem
    vertical-align: middle
    border-radius: 50%

  &.badge-md i
    width: .5rem
    height: .5rem

  &.badge-lg i
    width: .625rem
    height: .625rem

.btn
  position: relative
  transition: all .15s ease
  text-transform: uppercase
  will-change: transform
  padding: 10px 30px
  border-radius: 0
  font-size: 12px !important
  font-weight: 500

  &:hover
    box-shadow: none

  &:not(:last-child)
  /* margin-right: .5rem;


  i:not(:first-child), svg:not(:first-child)
    margin-left: .5rem

  i:not(:last-child), svg:not(:last-child)
    margin-right: .5rem

.btn-group .btn, .input-group .btn
  margin-right: 0
  transform: translateY(0)

.btn-sm, .btn-group-sm > .btn
  font-size: .75rem

.btn-lg, .btn-group-lg > .btn
  font-size: .875rem

[class*='btn-outline-']
  border-width: 1px

.btn-outline-secondary
  color: #4385b1

.btn-inner--icon i:not(.fas):not(.fab)
  position: relative
  top: 2px

.btn-link
  font-weight: 600
  box-shadow: none

  &:hover
    transform: none
    box-shadow: none

.btn-neutral
  color: #5e72e4

.btn-facebook
  color: #fff
  border-color: #3b5999
  background-color: #3b5999
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08)

  &:hover
    color: #fff
    border-color: #3b5999
    background-color: #3b5999

  &:focus, &.focus
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08), 0 0 0 0 rgba(59, 89, 153, 0.5)

  &.disabled, &:disabled
    color: #fff
    border-color: #3b5999
    background-color: #3b5999

  &:not(:disabled):not(.disabled)
    &:active, &.active
      color: #fff
      border-color: #3b5999
      background-color: #2d4474

.show > .btn-facebook.dropdown-toggle
  color: #fff
  border-color: #3b5999
  background-color: #2d4474

.btn-facebook:not(:disabled):not(.disabled)
  &:active:focus, &.active:focus
    box-shadow: none, 0 0 0 0 rgba(59, 89, 153, 0.5)

.show > .btn-facebook.dropdown-toggle:focus
  box-shadow: none, 0 0 0 0 rgba(59, 89, 153, 0.5)

.btn-twitter
  color: #fff
  border-color: #1da1f2
  background-color: #1da1f2
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08)

  &:hover
    color: #fff
    border-color: #1da1f2
    background-color: #1da1f2

  &:focus, &.focus
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08), 0 0 0 0 rgba(29, 161, 242, 0.5)

  &.disabled, &:disabled
    color: #fff
    border-color: #1da1f2
    background-color: #1da1f2

  &:not(:disabled):not(.disabled)
    &:active, &.active
      color: #fff
      border-color: #1da1f2
      background-color: #0c85d0

.show > .btn-twitter.dropdown-toggle
  color: #fff
  border-color: #1da1f2
  background-color: #0c85d0

.btn-twitter:not(:disabled):not(.disabled)
  &:active:focus, &.active:focus
    box-shadow: none, 0 0 0 0 rgba(29, 161, 242, 0.5)

.show > .btn-twitter.dropdown-toggle:focus
  box-shadow: none, 0 0 0 0 rgba(29, 161, 242, 0.5)

.btn-google-plus
  color: #fff
  border-color: #dd4b39
  background-color: #dd4b39
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08)

  &:hover
    color: #fff
    border-color: #dd4b39
    background-color: #dd4b39

  &:focus, &.focus
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08), 0 0 0 0 rgba(221, 75, 57, 0.5)

  &.disabled, &:disabled
    color: #fff
    border-color: #dd4b39
    background-color: #dd4b39

  &:not(:disabled):not(.disabled)
    &:active, &.active
      color: #fff
      border-color: #dd4b39
      background-color: #c23321

.show > .btn-google-plus.dropdown-toggle
  color: #fff
  border-color: #dd4b39
  background-color: #c23321

.btn-google-plus:not(:disabled):not(.disabled)
  &:active:focus, &.active:focus
    box-shadow: none, 0 0 0 0 rgba(221, 75, 57, 0.5)

.show > .btn-google-plus.dropdown-toggle:focus
  box-shadow: none, 0 0 0 0 rgba(221, 75, 57, 0.5)

.btn-instagram
  color: #fff
  border-color: #e4405f
  background-color: #e4405f
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08)

  &:hover
    color: #fff
    border-color: #e4405f
    background-color: #e4405f

  &:focus, &.focus
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08), 0 0 0 0 rgba(228, 64, 95, 0.5)

  &.disabled, &:disabled
    color: #fff
    border-color: #e4405f
    background-color: #e4405f

  &:not(:disabled):not(.disabled)
    &:active, &.active
      color: #fff
      border-color: #e4405f
      background-color: #d31e40

.show > .btn-instagram.dropdown-toggle
  color: #fff
  border-color: #e4405f
  background-color: #d31e40

.btn-instagram:not(:disabled):not(.disabled)
  &:active:focus, &.active:focus
    box-shadow: none, 0 0 0 0 rgba(228, 64, 95, 0.5)

.show > .btn-instagram.dropdown-toggle:focus
  box-shadow: none, 0 0 0 0 rgba(228, 64, 95, 0.5)

.btn-pinterest
  color: #fff
  border-color: #bd081c
  background-color: #bd081c
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08)

  &:hover
    color: #fff
    border-color: #bd081c
    background-color: #bd081c

  &:focus, &.focus
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08), 0 0 0 0 rgba(189, 8, 28, 0.5)

  &.disabled, &:disabled
    color: #fff
    border-color: #bd081c
    background-color: #bd081c

  &:not(:disabled):not(.disabled)
    &:active, &.active
      color: #fff
      border-color: #bd081c
      background-color: #8c0615

.show > .btn-pinterest.dropdown-toggle
  color: #fff
  border-color: #bd081c
  background-color: #8c0615

.btn-pinterest:not(:disabled):not(.disabled)
  &:active:focus, &.active:focus
    box-shadow: none, 0 0 0 0 rgba(189, 8, 28, 0.5)

.show > .btn-pinterest.dropdown-toggle:focus
  box-shadow: none, 0 0 0 0 rgba(189, 8, 28, 0.5)

.btn-youtube
  color: #fff
  border-color: #cd201f
  background-color: #cd201f
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08)

  &:hover
    color: #fff
    border-color: #cd201f
    background-color: #cd201f

  &:focus, &.focus
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08), 0 0 0 0 rgba(205, 32, 31, 0.5)

  &.disabled, &:disabled
    color: #fff
    border-color: #cd201f
    background-color: #cd201f

  &:not(:disabled):not(.disabled)
    &:active, &.active
      color: #fff
      border-color: #cd201f
      background-color: #a11918

.show > .btn-youtube.dropdown-toggle
  color: #fff
  border-color: #cd201f
  background-color: #a11918

.btn-youtube:not(:disabled):not(.disabled)
  &:active:focus, &.active:focus
    box-shadow: none, 0 0 0 0 rgba(205, 32, 31, 0.5)

.show > .btn-youtube.dropdown-toggle:focus
  box-shadow: none, 0 0 0 0 rgba(205, 32, 31, 0.5)

.btn-slack
  color: #fff
  border-color: #3aaf85
  background-color: #3aaf85
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08)

  &:hover
    color: #fff
    border-color: #3aaf85
    background-color: #3aaf85

  &:focus, &.focus
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08), 0 0 0 0 rgba(58, 175, 133, 0.5)

  &.disabled, &:disabled
    color: #fff
    border-color: #3aaf85
    background-color: #3aaf85

  &:not(:disabled):not(.disabled)
    &:active, &.active
      color: #fff
      border-color: #3aaf85
      background-color: #2d8968

.show > .btn-slack.dropdown-toggle
  color: #fff
  border-color: #3aaf85
  background-color: #2d8968

.btn-slack:not(:disabled):not(.disabled)
  &:active:focus, &.active:focus
    box-shadow: none, 0 0 0 0 rgba(58, 175, 133, 0.5)

.show > .btn-slack.dropdown-toggle:focus
  box-shadow: none, 0 0 0 0 rgba(58, 175, 133, 0.5)

.btn-dribbble
  color: #fff
  border-color: #ea4c89
  background-color: #ea4c89
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08)

  &:hover
    color: #fff
    border-color: #ea4c89
    background-color: #ea4c89

  &:focus, &.focus
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08), 0 0 0 0 rgba(234, 76, 137, 0.5)

  &.disabled, &:disabled
    color: #fff
    border-color: #ea4c89
    background-color: #ea4c89

  &:not(:disabled):not(.disabled)
    &:active, &.active
      color: #fff
      border-color: #ea4c89
      background-color: #e51e6b

.show > .btn-dribbble.dropdown-toggle
  color: #fff
  border-color: #ea4c89
  background-color: #e51e6b

.btn-dribbble:not(:disabled):not(.disabled)
  &:active:focus, &.active:focus
    box-shadow: none, 0 0 0 0 rgba(234, 76, 137, 0.5)

.show > .btn-dribbble.dropdown-toggle:focus
  box-shadow: none, 0 0 0 0 rgba(234, 76, 137, 0.5)

.btn-github
  color: #fff
  border-color: #222
  background-color: #222
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08)

  &:hover
    color: #fff
    border-color: #222
    background-color: #222

  &:focus, &.focus
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08), 0 0 0 0 rgba(34, 34, 34, 0.5)

  &.disabled, &:disabled
    color: #fff
    border-color: #222
    background-color: #222

  &:not(:disabled):not(.disabled)
    &:active, &.active
      color: #fff
      border-color: #222
      background-color: #090909

.show > .btn-github.dropdown-toggle
  color: #fff
  border-color: #222
  background-color: #090909

.btn-github:not(:disabled):not(.disabled)
  &:active:focus, &.active:focus
    box-shadow: none, 0 0 0 0 rgba(34, 34, 34, 0.5)

.show > .btn-github.dropdown-toggle:focus
  box-shadow: none, 0 0 0 0 rgba(34, 34, 34, 0.5)

.btn-vimeo
  color: #fff
  border-color: #04a0f0
  background-color: #04a0f0
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08)

  &:hover
    color: #fff
    border-color: #04a0f0
    background-color: #04a0f0

  &:focus, &.focus
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08), 0 0 0 0 rgba(4, 160, 240, 0.5)

  &.disabled, &:disabled
    color: #fff
    border-color: #04a0f0
    background-color: #04a0f0

  &:not(:disabled):not(.disabled)
    &:active, &.active
      color: #fff
      border-color: #04a0f0
      background-color: #037fbe

.show > .btn-vimeo.dropdown-toggle
  color: #fff
  border-color: #04a0f0
  background-color: #037fbe

.btn-vimeo:not(:disabled):not(.disabled)
  &:active:focus, &.active:focus
    box-shadow: none, 0 0 0 0 rgba(4, 160, 240, 0.5)

.show > .btn-vimeo.dropdown-toggle:focus
  box-shadow: none, 0 0 0 0 rgba(4, 160, 240, 0.5)

.btn-icon
  .btn-inner--icon img
    width: 20px

  .btn-inner--text
    &:not(:first-child)
      margin-left: .75em

    &:not(:last-child)
      margin-right: .75em

.btn-icon-only
  width: 2.375rem
  height: 2.375rem
  padding: 0

a.btn-icon-only
  line-height: 2.5

.btn-icon-only.btn-sm, .btn-group-sm > .btn-icon-only.btn
  width: 2rem
  height: 2rem

.btn-icon-clipboard
  font-family: inherit
  font-size: 1rem
  font-weight: 400
  line-height: 1.25
  display: inline-block
  width: 100%
  margin: 0
  margin: .5rem 0
  padding: 1.5rem
  cursor: pointer
  text-align: left
  vertical-align: middle
  text-decoration: none
  color: #32325d
  border: 0
  border-radius: .375rem
  background-color: #f6f9fc
  -moz-appearance: none

  &:hover
    background-color: #fff
    box-shadow: rgba(0, 0, 0, 0.1) 0 0 0 1px, rgba(0, 0, 0, 0.1) 0 4px 16px

  > div
    display: flex
    align-items: center

  i
    font-size: 1.5rem
    box-sizing: content-box
    vertical-align: middle
    color: #5e72e4

  span
    font-size: .875rem
    line-height: 1.5
    display: inline-block
    overflow: hidden
    margin-left: 16px
    vertical-align: middle
    white-space: nowrap
    text-overflow: ellipsis

.card
  margin-bottom: 30px
  border: 0
  box-shadow: 0 0 15px rgb(156 156 156 / 30%)
  padding: 20px 30px

.card-translucent
  background-color: rgba(18, 91, 152, 0.08)

.card-deck .card
  margin-bottom: 30px

.card.shadow
  border: 0 !important

@media (min-width: 576px)
  .card-columns
    column-count: 1

@media (min-width: 768px)
  .card-columns
    column-count: 2

@media (min-width: 1200px)
  .card-columns
    column-count: 3
    column-gap: 1.25rem

.card-lift--hover:hover
  transition: all .15s ease
  transform: translateY(-20px)

@media (prefers-reduced-motion: reduce)
  .card-lift--hover:hover
    transition: none

.card-blockquote
  position: relative
  padding: 2rem

  .svg-bg
    position: absolute
    top: -94px
    left: 0
    display: block
    width: 100%
    height: 95px

.card-profile-image
  position: relative

  img
    position: absolute
    left: 50%
    max-width: 140px
    transition: all .15s ease
    transform: translate(-50%, -50%) scale(1)
    border: 3px solid #fff
    border-radius: .375rem

    &:hover
      transform: translate(-50%, -50%) scale(1.03)

.card-profile-stats
  padding: 1rem 0

  > div
    margin-right: 1rem
    padding: .875rem
    text-align: center

    &:last-child
      margin-right: 0

    .heading
      font-size: 1.1rem
      font-weight: bold
      display: block

    .description
      font-size: .875rem
      color: #adb5bd

.card-profile-actions
  padding: .875rem

.card-stats
  .card-body
    padding: 1rem 1.5rem

  .card-status-bullet
    position: absolute
    top: 0
    right: 0
    transform: translate(50%, -50%)

.chart
  position: relative
  height: 350px

.chart-sm
  height: 230px

.chart-legend
  font-size: .875rem
  display: flex
  margin-top: 2.5rem
  text-align: center
  color: #8898aa
  justify-content: center

.chart-legend-item
  display: inline-flex
  align-items: center

  + .chart-legend-item
    margin-left: 1rem

.chart-legend-indicator
  display: inline-block
  width: .5rem
  height: .5rem
  margin-right: .375rem
  border-radius: 50%

#chart-tooltip
  z-index: 0

  .arrow
    top: 100%
    left: 50%
    transform: translateX(-50%) translateX(-0.5rem)

.chart-info-overlay
  position: absolute
  z-index: 1
  top: 0
  left: 5%
  max-width: 350px
  padding: 20px

.close
  transition: all .15s ease

  > span:not(.sr-only)
    font-size: 1.25rem
    line-height: 17px
    display: block
    width: 1.25rem
    height: 1.25rem
    transition: all .15s ease
    color: rgba(0, 0, 0, 0.6)
    border-radius: 50%
    background-color: transparent

  &:hover, &:focus
    color: rgba(0, 0, 0, 0.9)
    outline: none
    background-color: transparent

  &:hover span:not(.sr-only), &:focus span:not(.sr-only)
    background-color: transparent

.accordion .card-header
  position: relative
  cursor: pointer

  &:after
    font: normal normal normal 14px/1 NucleoIcons
    line-height: 0
    position: absolute
    top: 50%
    right: 1.5rem
    content: '\ea0f'
    transition: all 0.15s cubic-bezier(0.68, -0.55, 0.265, 1.55)
    transform: translateY(-50%)

  &[aria-expanded='false']:after
    content: '\ea0f'

  &[aria-expanded='true']
    &:after
      transform: rotate(180deg)

    .heading
      color: #5e72e4

@media (prefers-reduced-motion: reduce)
  .accordion .card-header:after
    transition: none

.main-content
  position: initial

  .navbar-top
    padding-right: 0 !important
    padding-left: 0 !important

@media (min-width: 768px)


.custom-checkbox .custom-control-input
  ~ .custom-control-label
    font-size: .875rem
    cursor: pointer

  &:checked ~ .custom-control-label
    &::before
      border-color: #5e72e4

    &::after
      background-image: url('data:image/svg+xml,<svg xmlns=\'http://www.w3.org/2000/svg\' width=\'8\' height=\'8\' viewBox=\'0 0 8 8\'><path fill=\'#fff\' d=\'M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z\'/></svg>')

  &:disabled
    ~ .custom-control-label::before
      border-color: #e9ecef

    &:checked::before
      border-color: rgba(94, 114, 228, 0.5)

.custom-control-label
  &::before
    top: .25rem
    left: -1.75rem
    transition: all 0.15s cubic-bezier(0.68, -0.55, 0.265, 1.55)
    border: 1px solid #dee2e6

  &::after
    top: .25rem
    left: -1.75rem

  span
    position: relative
    top: 2px

  margin-bottom: 0

@media (prefers-reduced-motion: reduce)
  .custom-control-label::before
    transition: none

.custom-control-alternative
  .custom-control-label::before
    border: 0
    box-shadow: 0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02)

  .custom-control-input
    &:checked ~ .custom-control-label::before
      box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08)

    &:active ~ .custom-control-label::before, &:focus ~ .custom-control-label::before
      box-shadow: 0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02)

.custom-checkbox .custom-control-input
  ~ .custom-control-label
    font-size: .875rem
    height: 1rem
    cursor: pointer

  &:checked ~ .custom-control-label
    &::before
      border-color: #5e72e4

    &::after
      background-image: url('data:image/svg+xml,<svg xmlns=\'http://www.w3.org/2000/svg\' width=\'8\' height=\'8\' viewBox=\'0 0 8 8\'><path fill=\'#fff\' d=\'M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z\'/></svg>')

  &:disabled
    ~ .custom-control-label::before
      border-color: #e9ecef

    &:checked::before
      border-color: rgba(94, 114, 228, 0.5)

.custom-checkbox-primary .custom-control-input:checked ~ .custom-control-label
  &::before
    border-color: #5e72e4
    background-color: #5e72e4

  &::after
    background-image: #5e72e4

.custom-checkbox-secondary .custom-control-input:checked ~ .custom-control-label
  &::before
    border-color: #f7fafc
    background-color: #f7fafc

  &::after
    background-image: #f7fafc

.custom-checkbox-success .custom-control-input:checked ~ .custom-control-label
  &::before
    border-color: #2dce89
    background-color: #2dce89

  &::after
    background-image: #2dce89

.custom-checkbox-info .custom-control-input:checked ~ .custom-control-label
  &::before
    border-color: #11cdef
    background-color: #11cdef

  &::after
    background-image: #11cdef

.custom-checkbox-warning .custom-control-input:checked ~ .custom-control-label
  &::before
    border-color: #fb6340
    background-color: #fb6340

  &::after
    background-image: #fb6340

.custom-checkbox-danger .custom-control-input:checked ~ .custom-control-label
  &::before
    border-color: #f5365c
    background-color: #f5365c

  &::after
    background-image: #f5365c

.custom-checkbox-light .custom-control-input:checked ~ .custom-control-label
  &::before
    border-color: #adb5bd
    background-color: #adb5bd

  &::after
    background-image: #adb5bd

.custom-checkbox-dark .custom-control-input:checked ~ .custom-control-label
  &::before
    border-color: #212529
    background-color: #212529

  &::after
    background-image: #212529

.custom-checkbox-default .custom-control-input:checked ~ .custom-control-label
  &::before
    border-color: #172b4d
    background-color: #172b4d

  &::after
    background-image: #172b4d

.custom-checkbox-white .custom-control-input:checked ~ .custom-control-label, .custom-checkbox-neutral .custom-control-input:checked ~ .custom-control-label
  &::before
    border-color: #fff
    background-color: #fff

  &::after
    background-image: #fff

.custom-checkbox-darker .custom-control-input:checked ~ .custom-control-label
  &::before
    border-color: black
    background-color: black

  &::after
    background-image: black

.custom-radio .custom-control-input
  ~ .custom-control-label
    font-size: .875rem
    height: 1rem
    cursor: pointer

  &:checked ~ .custom-control-label
    &::before
      border-color: #5e72e4

    &::after
      background-image: url('data:image/svg+xml,<svg xmlns=\'http://www.w3.org/2000/svg\' width=\'12\' height=\'12\' viewBox=\'-4 -4 8 8\'><circle r=\'3\' fill=\'#fff\'/></svg>')

  &:disabled
    ~ .custom-control-label::before
      border-color: #e9ecef

    &:checked::before
      border-color: rgba(94, 114, 228, 0.5)

.custom-toggle
  position: relative
  display: inherit
  display: inline-block
  width: 52px
  height: 1.5rem
  margin: 0

  input
    display: none

    &:checked + .custom-toggle-slider
      border: 1px solid #5e72e4

      &:before
        transform: translateX(28px)
        background: #5e72e4

    &:disabled
      + .custom-toggle-slider
        border: 1px solid #e9ecef

      &:checked + .custom-toggle-slider
        border: 1px solid #e9ecef

        &:before
          background-color: #8a98eb

.custom-toggle-slider
  position: absolute
  top: 0
  right: 0
  bottom: 0
  left: 0
  cursor: pointer
  border: 1px solid #ced4da
  border-radius: 34px !important
  background-color: transparent

  &:before
    position: absolute
    bottom: 2px
    left: 2px
    width: 18px
    height: 18px
    content: ''
    transition: all 0.15s cubic-bezier(0.68, -0.55, 0.265, 1.55)
    border-radius: 50% !important
    background-color: #e9ecef

.custom-toggle-wrapper .custom-toggle + .custom-toggle
  margin-left: 1rem !important

.custom-toggle input:checked + .custom-toggle-slider:after
  right: auto
  left: 0
  content: attr(data-label-on)
  color: #5e72e4

.custom-toggle-slider:after
  font-family: inherit
  font-size: .75rem
  font-weight: 600
  line-height: 24px
  position: absolute
  top: 0
  right: 0
  display: block
  overflow: hidden
  min-width: 1.66667rem
  margin: 0 .21667rem
  content: attr(data-label-off)
  transition: all .15s ease
  text-align: center
  color: #ced4da

@media (prefers-reduced-motion: reduce)
  .custom-toggle-slider:after
    transition: none

.custom-toggle-primary input
  &:checked + .custom-toggle-slider
    border-color: #5e72e4

    &:before
      background: #5e72e4

    &:after
      color: #5e72e4

  &:disabled:checked + .custom-toggle-slider
    border-color: #5e72e4

    &:before
      background-color: #8a98eb

.custom-toggle-secondary input
  &:checked + .custom-toggle-slider
    border-color: #f7fafc

    &:before
      background: #f7fafc

    &:after
      color: #f7fafc

  &:disabled:checked + .custom-toggle-slider
    border-color: #f7fafc

    &:before
      background-color: white

.custom-toggle-success input
  &:checked + .custom-toggle-slider
    border-color: #2dce89

    &:before
      background: #2dce89

    &:after
      color: #2dce89

  &:disabled:checked + .custom-toggle-slider
    border-color: #2dce89

    &:before
      background-color: #54daa1

.custom-toggle-info input
  &:checked + .custom-toggle-slider
    border-color: #11cdef

    &:before
      background: #11cdef

    &:after
      color: #11cdef

  &:disabled:checked + .custom-toggle-slider
    border-color: #11cdef

    &:before
      background-color: #41d7f2

.custom-toggle-warning input
  &:checked + .custom-toggle-slider
    border-color: #fb6340

    &:before
      background: #fb6340

    &:after
      color: #fb6340

  &:disabled:checked + .custom-toggle-slider
    border-color: #fb6340

    &:before
      background-color: #fc8c72

.custom-toggle-danger input
  &:checked + .custom-toggle-slider
    border-color: #f5365c

    &:before
      background: #f5365c

    &:after
      color: #f5365c

  &:disabled:checked + .custom-toggle-slider
    border-color: #f5365c

    &:before
      background-color: #f76783

.custom-toggle-light input
  &:checked + .custom-toggle-slider
    border-color: #adb5bd

    &:before
      background: #adb5bd

    &:after
      color: #adb5bd

  &:disabled:checked + .custom-toggle-slider
    border-color: #adb5bd

    &:before
      background-color: #c9cfd4

.custom-toggle-dark input
  &:checked + .custom-toggle-slider
    border-color: #212529

    &:before
      background: #212529

    &:after
      color: #212529

  &:disabled:checked + .custom-toggle-slider
    border-color: #212529

    &:before
      background-color: #383f45

.custom-toggle-default input
  &:checked + .custom-toggle-slider
    border-color: #172b4d

    &:before
      background: #172b4d

    &:after
      color: #172b4d

  &:disabled:checked + .custom-toggle-slider
    border-color: #172b4d

    &:before
      background-color: #234174

.custom-toggle-white input, .custom-toggle-neutral input
  &:checked + .custom-toggle-slider
    border-color: #fff

    &:before
      background: #fff

    &:after
      color: #fff

  &:disabled:checked + .custom-toggle-slider
    border-color: #fff

    &:before
      background-color: white

.custom-toggle-darker input
  &:checked + .custom-toggle-slider
    border-color: black

    &:before
      background: black

    &:after
      color: black

  &:disabled:checked + .custom-toggle-slider
    border-color: black

    &:before
      background-color: #1a1a1a

.dropdown, .dropup, .dropright, .dropleft
  display: inline-block

.dropdown-menu
  min-width: 12rem

  .dropdown-item
    font-size: 12px
    text-transform: uppercase
    font-weight: 500

    >
    i, svg
      font-size: 14px
      margin-right: 8px
      vertical-align: bottom
      color: #031d6d
      background: #e7eaf2
      display: inline-block
      width: 25px
      height: 25px
      text-align: center
      line-height: 29px
      border-radius: 3px

.dropdown-header
  font-size: .625rem
  font-weight: 700
  padding-right: 1rem
  padding-left: 1rem
  text-transform: uppercase

.dropdown-menu a.media
  > div:first-child
    line-height: 1

  p
    color: #8898aa

  &:hover
    .heading, p
      color: #172b4d !important

.dropdown-menu-sm
  min-width: 100px
  border: .4375rem

.dropdown-menu-lg
  min-width: 320px
  border-radius: .4375rem

.dropdown-menu-xl
  min-width: 420px
  border-radius: .4375rem

.footer
  padding: 30px
  background:#000
  position: absolute
  width: 100%
  bottom: 0

  .col-footer .heading
    font-size: .875rem
    font-weight: 600
    margin-bottom: 1rem
    letter-spacing: 0
    text-transform: uppercase
    color: #8898aa

  .nav .nav-item .nav-link, .footer-link
    color: #8898aa !important

  .nav .nav-item .nav-link:hover, .footer-link:hover
    color: #525f7f !important

  .list-unstyled li a
    font-size: .85rem
    display: inline-block
    padding: .125rem 0
    color: #8898aa

    &:hover
      color: #525f7f

  .copyright
    font-size: .875rem
    color: #fff

.footer-dark .col-footer .heading
  color: #fff

.nav-footer
  .nav-link
    font-size: .875rem

  .nav-item:last-child .nav-link
    padding-right: 0

footer a
  color: #f3f5f8

.footer.has-cards
  position: relative
  overflow: hidden
  margin-top: -420px
  padding-top: 500px
  pointer-events: none
  background: transparent

  &:before
    position: absolute
    top: 600px
    right: 0
    left: 0
    height: 2000px
    content: ''
    transform: skew(0, -8deg)
    background: #f7fafc

  .container
    position: relative
    pointer-events: auto

.footer-auto-bottom
  position: absolute
  bottom: 0
  width: 100%

.form-control-label
  font-size: .875rem
  font-weight: 600
  color: #525f7f

.form-control
  font-size: 12px
  height: calc(1.5em + 1.25rem + 5px)
  transition: all .15s ease-in-out

  &:focus
    &::-ms-input-placeholder, &::placeholder
      color: #adb5bd

@media (prefers-reduced-motion: reduce)
  .form-control
    transition: none

.form-control-lg
  height: calc(2.25em + 1.25rem + 5px)

.form-control-sm
  height: calc(.45em + 1.25rem + 5px)

.form-control-flush
  padding: 0
  border-width: 0
  background-color: transparent
  box-shadow: none

  &:focus
    border-width: 0
    background-color: transparent
    box-shadow: none

textarea
  &[resize='none']
    resize: none !important

  &[resize='both']
    resize: both !important

  &[resize='vertical']
    resize: vertical !important

  &[resize='horizontal']
    resize: horizontal !important

.form-control-muted
  border-color: #f7fafe
  background-color: #f7fafe
  box-shadow: none

  &:focus
    background-color: #fcfdff

.form-control-alternative
  transition: box-shadow .15s ease
  border: 0
  box-shadow: 0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02)

  &:focus
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08)

.form-control-lg, .input-group-lg .form-control, .input-group-text
  font-size: 1rem !important

.custom-control
  padding-left: 1.75rem

.input-group
  transition: all .15s ease-in-out
  border-radius: .25rem
  box-shadow: 0 3px 2px rgba(233, 236, 239, 0.05)

  .form-control
    box-shadow: none

    &:focus
      box-shadow: none

@media (prefers-reduced-motion: reduce)
  .input-group
    transition: none

.input-group-text
  transition: all 0.15s cubic-bezier(0.68, -0.55, 0.265, 1.55)

  i
    font-size: .875rem

  .custom-control
    line-height: 1

@media (prefers-reduced-motion: reduce)
  .input-group-text
    transition: none

.input-group-prepend .input-group-text
  border-right: 0

.input-group-append .input-group-text
  border-left: 0

.input-group-merge .form-control
  &:not(:first-child)
    padding-left: 15px
    border-left: 0

  &:not(:last-child)
    padding-right: 0
    border-right: 0

.input-group-alternative
  transition: box-shadow .15s ease
  border: 0
  box-shadow: 0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02)

  .form-control, .input-group-text
    border: 0
    box-shadow: none

.focused .input-group-alternative
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08) !important

.input-group-flush
  box-shadow: none

  >
  .form-control
    padding: 0
    border-width: 0
    background-color: transparent

    .input-group-prepend > .input-group-text, .input-group-append > .input-group-text
      padding: 0
      padding-right: 1rem
      border-width: 0
      background-color: transparent

.focused
  .input-group
    box-shadow: 0 3px 9px rgba(50, 50, 9, 0), 3px 4px 8px rgba(94, 114, 228, 0.1)

  .input-group-text
    color: #8898aa
    border-color: #5e72e4
    background-color: #fff

  .form-control
    border-color: #5e72e4

  .input-group-flush
    box-shadow: none

.has-success, .has-danger
  position: relative

.has-success:after, .has-danger:after
  font-family: 'NucleoIcons'
  font-size: 9px
  line-height: 19px
  position: absolute
  top: 50%
  right: 15px
  display: none
  width: 19px
  height: 19px
  transform: translateY(-50%)
  text-align: center
  opacity: 1
  border-radius: 50%

.has-success .input-group-prepend .input-group-text, .has-danger .input-group-prepend .input-group-text
  border-color: red

.has-success
  &:after
    content: '\ea26'
    color: #1d8357
    background-color: #69deac

  .form-control
    background-color: #fff

    &::-ms-input-placeholder, &::placeholder
      color: #2dce89

.has-danger
  &:after
    content: '\ea53'
    color: #db2d05
    background-color: #fda08b

  .form-control
    background-color: #fff

    &::-ms-input-placeholder, &::placeholder
      color: #fb6340



.no-gutters >
.col span, [class^='col-'] span
  border-radius: 0

.header
  position: relative

.icon
  width: 3rem
  height: 3rem

  i, svg
    font-size: 2.25rem

  + .icon-text
    width: calc(100% - 3rem - 1)
    padding-left: 1rem

.icon-xl
  width: 5rem
  height: 5rem

  i, svg
    font-size: 4.25rem

  + .icon-text
    width: calc(100% - 5rem - 1)

.icon-lg
  width: 4rem
  height: 4rem

  i, svg
    font-size: 3.25rem

  + .icon-text
    width: calc(100% - $icon-size-lg - 1)

.icon-sm
  width: 2rem
  height: 2rem

  i, svg
    font-size: 1.25rem

  + .icon-text
    width: calc(100% - $icon-size-sm - 1)

.icon-xs
  width: 1.25rem
  height: 1.25rem

  i, svg
    font-size: .5rem

  + .icon-text
    width: calc(100% - $icon-size-xs - 1)

.icon-shape
  display: inline-flex
  padding: 12px
  text-align: center
  border-radius: 50%
  align-items: center
  justify-content: center

  i, svg
    font-size: 1.25rem

  &.icon-lg
    i, svg
      font-size: 1.625rem

  &.icon-sm
    i, svg
      font-size: .875rem

  &.icon-xs
    i, svg
      font-size: .6rem

  svg
    width: 30px
    height: 30px

.icon-shape-primary
  color: #2643e9
  background-color: rgba(138, 152, 235, 0.5)

.icon-shape-secondary
  color: #cfe3f1
  background-color: rgba(255, 255, 255, 0.5)

.icon-shape-success
  color: #1aae6f
  background-color: rgba(84, 218, 161, 0.5)

.icon-shape-info
  color: #03acca
  background-color: rgba(65, 215, 242, 0.5)

.icon-shape-warning
  color: #ff3709
  background-color: rgba(252, 140, 114, 0.5)

.icon-shape-danger
  color: #f80031
  background-color: rgba(247, 103, 131, 0.5)

.icon-shape-light
  color: #879cb0
  background-color: rgba(201, 207, 212, 0.5)

.icon-shape-dark
  color: #090c0e
  background-color: rgba(56, 63, 69, 0.5)

.icon-shape-default
  color: #091428
  background-color: rgba(35, 65, 116, 0.5)

.icon-shape-white, .icon-shape-neutral
  color: #e8e3e3
  background-color: rgba(255, 255, 255, 0.5)

.icon-shape-darker
  color: black
  background-color: rgba(26, 26, 26, 0.5)

.list-group-space .list-group-item
  margin-bottom: 1.5rem
  border-radius: .375rem

.list-group-img
  width: 3rem
  height: 3rem
  margin: -.1rem 1.2rem 0 -.2rem
  vertical-align: top
  border-radius: 50%

.list-group-content
  min-width: 0
  flex: 1 1

  > p
    line-height: 1.5
    margin: .2rem 0 0
    color: #adb5bd

.list-group-heading
  font-size: 1rem
  color: #32325d

  > small
    font-weight: 500
    float: right
    color: #adb5bd

.map-canvas
  position: relative
  width: 100%
  height: 500px
  border-radius: .375rem

.mask
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 100%
  transition: all .15s ease

@media (prefers-reduced-motion: reduce)
  .mask
    transition: none

.modal-title
  font-size: 1.0625rem

.modal-fluid
  .modal-dialog
    margin-top: 0
    margin-bottom: 0

  .modal-content
    border-radius: 0

.modal-primary
  .modal-title
    color: #fff

  .modal-header, .modal-footer
    border-color: rgba(255, 255, 255, 0.075)

  .modal-content
    color: #fff
    background-color: #5e72e4

    .heading
      color: #fff

  .close > span:not(.sr-only)
    color: #fff

.modal-secondary
  .modal-title
    color: #212529

  .modal-header, .modal-footer
    border-color: rgba(33, 37, 41, 0.075)

  .modal-content
    color: #212529
    background-color: #f7fafc

    .heading
      color: #212529

  .close > span:not(.sr-only)
    color: #fff

.modal-success
  .modal-title
    color: #fff

  .modal-header, .modal-footer
    border-color: rgba(255, 255, 255, 0.075)

  .modal-content
    color: #fff
    background-color: #2dce89

    .heading
      color: #fff

  .close > span:not(.sr-only)
    color: #fff

.modal-info
  .modal-title
    color: #fff

  .modal-header, .modal-footer
    border-color: rgba(255, 255, 255, 0.075)

  .modal-content
    color: #fff
    background-color: #11cdef

    .heading
      color: #fff

  .close > span:not(.sr-only)
    color: #fff

.modal-warning
  .modal-title
    color: #fff

  .modal-header, .modal-footer
    border-color: rgba(255, 255, 255, 0.075)

  .modal-content
    color: #fff
    background-color: #fb6340

    .heading
      color: #fff

  .close > span:not(.sr-only)
    color: #fff

.modal-danger
  .modal-title
    color: #fff

  .modal-header, .modal-footer
    border-color: rgba(255, 255, 255, 0.075)

  .modal-content
    color: #fff
    background-color: #f5365c

    .heading
      color: #fff

  .close > span:not(.sr-only)
    color: #fff

.modal-light
  .modal-title
    color: #fff

  .modal-header, .modal-footer
    border-color: rgba(255, 255, 255, 0.075)

  .modal-content
    color: #fff
    background-color: #adb5bd

    .heading
      color: #fff

  .close > span:not(.sr-only)
    color: #fff

.modal-dark
  .modal-title
    color: #fff

  .modal-header, .modal-footer
    border-color: rgba(255, 255, 255, 0.075)

  .modal-content
    color: #fff
    background-color: #212529

    .heading
      color: #fff

  .close > span:not(.sr-only)
    color: #fff

.modal-default
  .modal-title
    color: #fff

  .modal-header, .modal-footer
    border-color: rgba(255, 255, 255, 0.075)

  .modal-content
    color: #fff
    background-color: #172b4d

    .heading
      color: #fff

  .close > span:not(.sr-only)
    color: #fff

.modal-white
  .modal-title
    color: #212529

  .modal-header, .modal-footer
    border-color: rgba(33, 37, 41, 0.075)

  .modal-content
    color: #212529
    background-color: #fff

    .heading
      color: #212529

  .close > span:not(.sr-only)
    color: #fff

.modal-neutral
  .modal-title
    color: #212529

  .modal-header, .modal-footer
    border-color: rgba(33, 37, 41, 0.075)

  .modal-content
    color: #212529
    background-color: #fff

    .heading
      color: #212529

  .close > span:not(.sr-only)
    color: #fff

.modal-darker
  .modal-title
    color: #fff

  .modal-header, .modal-footer
    border-color: rgba(255, 255, 255, 0.075)

  .modal-content
    color: #fff
    background-color: black

    .heading
      color: #fff

  .close > span:not(.sr-only)
    color: #fff

.navbar-horizontal
  .navbar-nav .nav-link
    font-size: .875rem
    font-weight: 500
    letter-spacing: 0
    text-transform: normal

    .nav-link-inner--text
      margin-left: .25rem

  .navbar-brand
    font-size: .875rem
    font-size: .875rem
    font-weight: 600
    letter-spacing: .05px
    text-transform: uppercase

    img
      height: 30px

  .navbar-dark .navbar-brand
    color: #fff

  .navbar-light .navbar-brand
    color: #32325d

  .navbar-nav .nav-item .media:not(:last-child)
    margin-bottom: 1.5rem

  &.navbar-transparent
    position: absolute
    z-index: 100
    top: 0
    width: 100%
    border: 0
    background-color: transparent
    box-shadow: none

    .navbar-brand, .navbar-toggler
      color: white

    .navbar-toggler-icon
      background-image: url('data:image/svg+xml,<svg xmlns=\'http://www.w3.org/2000/svg\' width=\'30\' height=\'30\' viewBox=\'0 0 30 30\'><path stroke=\'rgba(255, 255, 255, 0.95)\' stroke-linecap=\'round\' stroke-miterlimit=\'10\' stroke-width=\'2\' d=\'M4 7h22M4 15h22M4 23h22\'/></svg>')

  .navbar-collapse-header
    display: none

@media (min-width: 992px)
  .navbar-horizontal .navbar-nav
    .nav-item
      margin-right: .5rem

      [data-toggle='dropdown']::after
        transition: all .15s ease

      &.show [data-toggle='dropdown']::after
        transform: rotate(180deg)

    .nav-link
      padding-top: 1rem
      padding-bottom: 1rem
      border-radius: .375rem

      i
        margin-right: .625rem

    .nav-link-icon
      font-size: 1rem
      padding-right: .5rem !important
      padding-left: .5rem !important
      border-radius: .375rem

      i
        margin-right: 0

    .dropdown-menu
      margin: 0
      pointer-events: none
      opacity: 0

      &:before
        position: absolute
        z-index: -5
        bottom: 100%
        left: 20px
        display: block
        width: 16px
        height: 16px
        content: ''
        transform: rotate(-45deg) translateY(1rem)
        border-radius: .25rem
        background: #fff
        box-shadow: none

    .dropdown-menu-right:before
      right: 20px
      left: auto

    &:not(.navbar-nav-hover) .dropdown-menu
      &.show
        animation: show-navbar-dropdown .25s ease forwards
        pointer-events: auto
        opacity: 1

      &.close
        display: block
        animation: hide-navbar-dropdown .15s ease backwards

    &.navbar-nav-hover
      .dropdown-menu
        display: block
        transition: visibility .25s, opacity .25s, transform .25s
        transform: translate(0, 10px) perspective(200px) rotateX(-2deg)
        pointer-events: none
        opacity: 0

      .nav-item.dropdown:hover > .dropdown-menu
        display: block
        visibility: visible
        transform: translate(0, 0)
        animation: none
        pointer-events: auto
        opacity: 1

    .dropdown-menu-inner
      position: relative
      padding: 1rem

@media (min-width: 768px)
  .navbar-horizontal.navbar-transparent
    .navbar-nav
      .nav-link
        color: rgba(255, 255, 255, 0.95)

        &:hover, &:focus
          color: rgba(255, 255, 255, 0.65)

        &.disabled
          color: rgba(255, 255, 255, 0.25)

      .show > .nav-link, .active > .nav-link
        color: rgba(255, 255, 255, 0.65)

      .nav-link
        &.show, &.active
          color: rgba(255, 255, 255, 0.65)

    .navbar-brand
      color: rgba(255, 255, 255, 0.95)

      &:hover, &:focus
        color: rgba(255, 255, 255, 0.95)

@media (max-width: 991.98px)
  .xs-p-0
    padding: 0

  .button-flex
    button
      font-size: 11px !important

      i
        font-size: 11px !important

      font-size: 11px !important
      padding: 12px 5px

    width: 100% !important
    margin-top: 20px

  .giac-input
    margin-top: 20px
    width: max-content

  .navbar-horizontal
    .navbar-nav
      .nav-link
        padding: .625rem 0
        color: #172b4d !important

      .dropdown-menu
        min-width: auto
        box-shadow: none

        .media svg
          width: 30px

    .navbar-collapse
      position: absolute
      z-index: 1050
      top: 0
      right: 0
      left: 0
      overflow-y: auto
      width: calc(100% - 1.4rem)
      height: auto !important
      margin: .7rem
      opacity: 0

      .navbar-toggler
        position: relative
        display: inline-block
        width: 20px
        height: 20px
        padding: 0
        cursor: pointer

        span
          position: absolute
          display: block
          width: 100%
          height: 2px
          opacity: 1
          border-radius: 2px
          background: #283448

        \:nth-child(1)
          transform: rotate(135deg)

        \:nth-child(2)
          transform: rotate(-135deg)

      .navbar-collapse-header
        display: block
        margin-bottom: 1rem
        padding-bottom: 1rem
        border-bottom: 1px solid rgba(0, 0, 0, 0.1)

      .collapse-brand img
        height: 36px

      .collapse-close
        text-align: right

      &.collapsing, &.show
        padding: 1.5rem
        animation: show-navbar-collapse .2s ease forwards
        border-radius: .375rem
        background: #fff
        box-shadow: 0 50px 100px rgba(50, 50, 93, 0.1), 0 15px 35px rgba(50, 50, 93, 0.15), 0 5px 15px rgba(0, 0, 0, 0.1)

      &.collapsing-out
        animation: hide-navbar-collapse .2s ease forwards

@keyframes show-navbar-collapse
  0%
    transform: scale(0.95)
    transform-origin: 100% 0
    opacity: 0

  100%
    transform: scale(1)
    opacity: 1

@keyframes hide-navbar-collapse
  from
    transform: scale(1)
    transform-origin: 100% 0
    opacity: 1

  to
    transform: scale(0.95)
    opacity: 0

@keyframes show-navbar-dropdown
  0%
    transition: visibility .25s, opacity .25s, transform .25s
    transform: translate(0, 10px) perspective(200px) rotateX(-2deg)
    opacity: 0

  100%
    transform: translate(0, 0)
    opacity: 1

@keyframes hide-navbar-dropdown
  from
    opacity: 1

  to
    transform: translate(0, 10px)
    opacity: 0

.nav-open
  .sidenav
    transform: translateX(0px)

  .sidenav-toggler-inner .sidenav-toggler-line
    &:first-child, &:last-child
      width: 13px
      transform: translateX(5px)

.navbar-vertical
  padding-top: 0
  border-width: 0 0 1px 0
  border-style: solid
  box-shadow: none

  &.navbar-light
    border-color: rgba(0, 0, 0, 0.05)
    background-color: #f6f9fc

  &.navbar-dark
    border-color: rgba(255, 255, 255, 0.1)
    background-color: transparent

  .navbar-brand
    margin-right: 0

  .navbar-brand-img, .navbar-brand > img
    max-width: 170px
    width: 130px
    margin: 0 auto
    display: block

  .navbar-nav
    margin-right: -1rem
    margin-left: -1rem

    .nav-link
      font-size: .875rem
      font-weight: 500
      padding-right: 1rem
      padding-left: 1rem

      &.active
        position: relative

      > i
        font-size: .9375rem
        line-height: 1.5rem
        min-width: 2rem

      .dropdown-menu
        border: none

        .dropdown-menu
          margin-left: .5rem

    .nav-sm .nav-link
      font-size: .8125rem

    .nav-link
      display: flex
      align-items: center

      &[data-toggle='collapse']
        &:after
          font-family: 'Font Awesome 5 Free'
          font-weight: 700
          font-style: normal
          font-variant: normal
          display: inline-block
          margin-left: auto
          content: '\f105'
          transition: all .15s ease
          color: #ced4da
          text-rendering: auto
          -webkit-font-smoothing: antialiased

        &[aria-expanded='true']:after
          transform: rotate(90deg)
          color: #5e72e4

    .nav
      .nav-link
        padding-left: 3rem

      .nav .nav-link
        padding-left: 3.5rem



  .navbar-heading
    font-size: .75rem
    padding-top: .25rem
    padding-bottom: .25rem
    letter-spacing: .04em
    text-transform: uppercase

  &.navbar-expand-xs
    position: fixed
    top: 0
    bottom: 0
    display: block
    overflow-y: auto
    width: 100%
    max-width: 250px
    padding-right: 0
    padding-left: 0
    height: 100%
    margin: 0

    .navbar-inner
      padding-right: 1.5rem
      padding-left: 1.5rem
      padding-top: 40px

    > [class*='container']
      flex-direction: column
      min-height: 100%
      padding-right: 0
      padding-left: 0
      align-items: stretch

    &.fixed-left
      left: 0
      background: #fff !important
      padding-top: 30px

    &.fixed-right
      right: 0
      border-width: 0 0 0 1px

    .navbar-collapse
      display: flex
      flex-direction: column
      margin-right: -1.5rem
      margin-left: -1.5rem
      padding-right: 1.5rem
      padding-left: 1.5rem
      flex: 1 1
      align-items: stretch

      > *
        min-width: 100%

    .navbar-nav
      flex-direction: column
      margin-right: -1.5rem
      margin-left: -1.5rem

      .nav-link
        padding: 15px

      > .nav-item
        margin: 0px 30px
        border-radius: 12px

        > .nav-link.active
          margin-right: .5rem
          margin-left: .5rem
          padding-right: 1rem
          padding-left: 1rem
          color:#000
          text-transform: uppercase
          font-size: 12px
          letter-spacing: 0px
          font-weight: 500


    .lavalamp-object
      width: calc(100% - 1rem) !important
      margin-right: .5rem
      margin-left: .5rem
      padding-right: 1rem
      padding-left: 1rem
      color: #fff
      border-radius: .375rem
      background: #5e72e4

    .navbar-nav .nav
      .nav-link
        padding-top: .45rem
        padding-bottom: .45rem
        padding-left: 3.5rem

      .nav .nav-link
        padding-left: 4.25rem

    &.fixed-left + .main-content
      margin-left: 250px
      overflow: hidden
      position: initial
      min-height: 100vh
      padding-bottom: 7em

    &.fixed-right + .main-content
      margin-right: 62px

@media (min-width: 768px)
  .navbar-vertical .navbar-collapse
    margin-right: -1rem
    margin-left: -1rem

    &:before
      display: block
      margin: .5rem
      content: ''

@media (prefers-reduced-motion: reduce)
  .navbar-vertical .navbar-nav .nav-link[data-toggle='collapse']:after
    transition: none

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)
  .navbar-vertical.navbar-expand-xs > [class*='container']
    height: 100%
    min-height: none

@media (min-width: 576px)
  .navbar-vertical.navbar-expand-sm
    position: fixed
    top: 0
    bottom: 0
    display: block
    overflow-y: auto
    width: 100%
    max-width: 250px
    padding-right: 0
    padding-left: 0

    .navbar-inner
      padding-right: 1.5rem
      padding-left: 1.5rem

    > [class*='container']
      flex-direction: column
      min-height: 100%
      padding-right: 0
      padding-left: 0
      align-items: stretch

@media all and (min-width: 576px) and (-ms-high-contrast: none), (min-width: 576px) and (-ms-high-contrast: active)
  .navbar-vertical.navbar-expand-sm > [class*='container']
    height: 100%
    min-height: none

@media (min-width: 576px)
  .navbar-vertical.navbar-expand-sm
    &.fixed-left
      left: 0
      border-width: 0 1px 0 0

    &.fixed-right
      right: 0
      border-width: 0 0 0 1px

    .navbar-collapse
      display: flex
      flex-direction: column
      margin-right: -1.5rem
      margin-left: -1.5rem
      padding-right: 1.5rem
      padding-left: 1.5rem
      flex: 1 1
      align-items: stretch

      > *
        min-width: 100%

    .navbar-nav
      flex-direction: column
      margin-right: -1.5rem
      margin-left: -1.5rem

      .nav-link
        padding: .675rem 1.5rem

      > .nav-item
        margin-top: 2px

        > .nav-link.active
          margin-right: .5rem
          margin-left: .5rem
          padding-right: 1rem
          padding-left: 1rem
          border-radius: .375rem
          background: #f6f9fc

    .lavalamp-object
      width: calc(100% - 1rem) !important
      margin-right: .5rem
      margin-left: .5rem
      padding-right: 1rem
      padding-left: 1rem
      color: #fff
      border-radius: .375rem
      background: #5e72e4

    .navbar-nav .nav
      .nav-link
        padding-top: .45rem
        padding-bottom: .45rem
        padding-left: 3.5rem

      .nav .nav-link
        padding-left: 4.25rem

@media (min-width: 768px)
  .navbar-vertical.navbar-expand-md
    position: fixed
    top: 0
    bottom: 0
    display: block
    overflow-y: auto
    width: 100%
    max-width: 250px
    padding-right: 0
    padding-left: 0

    .navbar-inner
      padding-right: 1.5rem
      padding-left: 1.5rem

    > [class*='container']
      flex-direction: column
      min-height: 100%
      padding-right: 0
      padding-left: 0
      align-items: stretch

@media all and (min-width: 768px) and (-ms-high-contrast: none), (min-width: 768px) and (-ms-high-contrast: active)
  .navbar-vertical.navbar-expand-md > [class*='container']
    height: 100%
    min-height: none

@media (min-width: 768px)
  .navbar-vertical.navbar-expand-md
    &.fixed-left
      left: 0
      border-width: 0 1px 0 0

    &.fixed-right
      right: 0
      border-width: 0 0 0 1px

    .navbar-collapse
      display: flex
      flex-direction: column
      margin-right: -1.5rem
      margin-left: -1.5rem
      padding-right: 1.5rem
      padding-left: 1.5rem
      flex: 1 1
      align-items: stretch

      > *
        min-width: 100%

    .navbar-nav
      flex-direction: column
      margin-right: -1.5rem
      margin-left: -1.5rem

      .nav-link
        padding: .675rem 1.5rem

      > .nav-item
        margin-top: 2px

        > .nav-link.active
          margin-right: .5rem
          margin-left: .5rem
          padding-right: 1rem
          padding-left: 1rem
          border-radius: .375rem
          background: #f6f9fc

    .lavalamp-object
      width: calc(100% - 1rem) !important
      margin-right: .5rem
      margin-left: .5rem
      padding-right: 1rem
      padding-left: 1rem
      color: #fff
      border-radius: .375rem
      background: #5e72e4

    .navbar-nav .nav
      .nav-link
        padding-top: .45rem
        padding-bottom: .45rem
        padding-left: 3.5rem

      .nav .nav-link
        padding-left: 4.25rem

@media (min-width: 992px)
  .navbar-vertical.navbar-expand-lg
    position: fixed
    top: 0
    bottom: 0
    display: block
    overflow-y: auto
    width: 100%
    max-width: 250px
    padding-right: 0
    padding-left: 0

    .navbar-inner
      padding-right: 1.5rem
      padding-left: 1.5rem

    > [class*='container']
      flex-direction: column
      min-height: 100%
      padding-right: 0
      padding-left: 0
      align-items: stretch

@media all and (min-width: 992px) and (-ms-high-contrast: none), (min-width: 992px) and (-ms-high-contrast: active)
  .navbar-vertical.navbar-expand-lg > [class*='container']
    height: 100%
    min-height: none

@media (min-width: 992px)
  .navbar-vertical.navbar-expand-lg
    &.fixed-left
      left: 0
      border-width: 0 1px 0 0

    &.fixed-right
      right: 0
      border-width: 0 0 0 1px

    .navbar-collapse
      display: flex
      flex-direction: column
      margin-right: -1.5rem
      margin-left: -1.5rem
      padding-right: 1.5rem
      padding-left: 1.5rem
      flex: 1 1
      align-items: stretch

      > *
        min-width: 100%

    .navbar-nav
      flex-direction: column
      margin-right: -1.5rem
      margin-left: -1.5rem

      .nav-link
        padding: .675rem 1.5rem

      > .nav-item
        margin-top: 2px

        > .nav-link.active
          margin-right: .5rem
          margin-left: .5rem
          padding-right: 1rem
          padding-left: 1rem
          border-radius: .375rem
          background: #f6f9fc

    .lavalamp-object
      width: calc(100% - 1rem) !important
      margin-right: .5rem
      margin-left: .5rem
      padding-right: 1rem
      padding-left: 1rem
      color: #fff
      border-radius: .375rem
      background: #5e72e4

    .navbar-nav .nav
      .nav-link
        padding-top: .45rem
        padding-bottom: .45rem
        padding-left: 3.5rem

      .nav .nav-link
        padding-left: 4.25rem

@media (min-width: 1200px)
  .navbar-vertical.navbar-expand-xl
    position: fixed
    top: 0
    bottom: 0
    display: block
    overflow-y: auto
    width: 100%
    max-width: 250px
    padding-right: 0
    padding-left: 0

    .navbar-inner
      padding-right: 1.5rem
      padding-left: 1.5rem

    > [class*='container']
      flex-direction: column
      min-height: 100%
      padding-right: 0
      padding-left: 0
      align-items: stretch

@media all and (min-width: 1200px) and (-ms-high-contrast: none), (min-width: 1200px) and (-ms-high-contrast: active)
  .navbar-vertical.navbar-expand-xl > [class*='container']
    height: 100%
    min-height: none

@media (min-width: 1200px)
  .navbar-vertical.navbar-expand-xl
    &.fixed-left
      left: 0
      border-width: 0 1px 0 0

    &.fixed-right
      right: 0
      border-width: 0 0 0 1px

    .navbar-collapse
      display: flex
      flex-direction: column
      margin-right: -1.5rem
      margin-left: -1.5rem
      padding-right: 1.5rem
      padding-left: 1.5rem
      flex: 1 1
      align-items: stretch

      > *
        min-width: 100%

    .navbar-nav
      flex-direction: column
      margin-right: -1.5rem
      margin-left: -1.5rem

      .nav-link
        padding: .675rem 1.5rem

      > .nav-item
        margin-top: 2px

        > .nav-link.active
          margin-right: .5rem
          margin-left: .5rem
          padding-right: 1rem
          padding-left: 1rem
          border-radius: .375rem
          background: #f6f9fc

    .lavalamp-object
      width: calc(100% - 1rem) !important
      margin-right: .5rem
      margin-left: .5rem
      padding-right: 1rem
      padding-left: 1rem
      color: #fff
      border-radius: .375rem
      background: #5e72e4

    .navbar-nav .nav
      .nav-link
        padding-top: .45rem
        padding-bottom: .45rem
        padding-left: 3.5rem

      .nav .nav-link
        padding-left: 4.25rem

@media (min-width: 576px)
  .navbar-vertical.navbar-expand-sm
    &.fixed-left + .main-content
      margin-left: 250px

    &.fixed-right + .main-content
      margin-right: 62px

@media (min-width: 768px)
  .navbar-vertical.navbar-expand-md
    &.fixed-left + .main-content
      margin-left: 250px

    &.fixed-right + .main-content
      margin-right: 62px

@media (min-width: 992px)
  .navbar-vertical.navbar-expand-lg
    &.fixed-left + .main-content
      margin-left: 250px

    &.fixed-right + .main-content
      margin-right: 62px

@media (min-width: 1200px)
  .navbar-vertical.navbar-expand-xl
    &.fixed-left + .main-content
      margin-left: 250px

    &.fixed-right + .main-content
      margin-right: 62px

.sidenav
  &.fixed-left + .main-content
    margin-left: 62px
    transition: all .15s ease

  &.fixed-right + .main-content
    margin-right: 62px
    transition: all .15s ease

  z-index: 1050
  transition: all .4s ease

  .navbar-brand, .navbar-heading
    padding: 1.5rem


.main-content
  margin-left: 250px
  overflow: hidden
  position: relative
  min-height: 100vh
  padding-bottom: 7em
  background: #f3f5f8
  border-radius: 20px
  margin-top: 10px
  margin-right: 5px
  margin-bottom: 10px

@media (prefers-reduced-motion: reduce)
  .sidenav.fixed-left + .main-content
    transition: none

@media (prefers-reduced-motion: reduce)
  .sidenav.fixed-right + .main-content
    transition: none

@media (min-width: 1200px)
  .g-sidenav-pinned .sidenav
    &.fixed-left + .main-content
      margin-left: 250px

    &.fixed-right + .main-content
      margin-right: 250px

@media (min-width: 1200px)
  .sidenav
    &:hover
      max-width: 250px

    .sidenav-toggler
      padding: 1.5rem

.sidenav-header
  height: 78px
  text-align: center

.g-sidenav-show .sidenav
  .navbar-brand, .navbar-heading
    display: block

  .nav-item .collapse
    height: auto
    transition: all .15s ease

  .nav-link-text
    transition: .3s ease
    opacity: 1

@media (prefers-reduced-motion: reduce)
  .g-sidenav-show .sidenav .nav-item .collapse
    transition: none

.g-sidenav-pinned .sidenav
  max-width: 250px !important

  .navbar-brand, .navbar-heading
    display: block

@media (min-width: 768px)
  .nav-item .nav-link.active.active-pro
    position: absolute
    bottom: 10px
    width: 100%
    margin-left: 0 !important
    padding-left: 25px !important
    border-radius: 0 !important
    background: #f6f9fc

  .main-content .container-fluid, .main-content .container-sm, .main-content .container-md, .main-content .container-lg, .main-content .container-xl
    padding-right: 30px !important
    padding-left: 30px !important


@media (max-width: 1199.98px)
  .card.card-colored h1
    padding: 40px 0 10px

  .sidenav
    transform: translateX(-250px) !important

  .sidenav.fixed-left + .main-content
    margin-left: 0 !important

  .main-content
    margin-left: 5px !important

  .g-sidenav-pinned .sidenav
    transform: translateX(0)
    box-shadow: 0 0 15px rgb(81 77 130 / 30%)

.sidenav-toggler-inner, .sidenav-toggler-line
  width: 18px
  transition: all .15s ease

@media (prefers-reduced-motion: reduce)
  .sidenav-toggler-inner, .sidenav-toggler-line
    transition: none

.sidenav-toggler-inner
  position: relative

  &:before
    position: absolute
    top: -14px
    left: -11px
    width: 40px
    height: 40px
    content: ''
    transition: all 0.15s cubic-bezier(0.68, -0.55, 0.265, 1.55)
    transform: scale(0)
    border-radius: 50%

@media (prefers-reduced-motion: reduce)
  .sidenav-toggler-inner:before
    transition: none

.sidenav-toggler-line
  position: relative
  display: block
  height: 2px
  background-color: #172b4d

  &:not(:last-child)
    margin-bottom: 3px

.sidenav-toggler-dark .sidenav-toggler-line
  background-color: #272424

.sidenav-toggler
  cursor: pointer

  &.active
    .sidenav-toggler-inner:before
      transform: scale(1)

    .sidenav-toggler-line
      &:first-child, &:last-child
        width: 13px
        transform: translateX(5px)

.bodyClick
  position: absolute
  top: 0
  width: 100%
  height: 100vh

.navbar-search
  .input-group
    transition: background-color .3s linear
    transition-delay: .15s
    border: 0 solid
    border-radius: 2rem

    .input-group-text
      padding-left: 1rem
      border: 0
      background-color: transparent

  .form-control
    width: 250px
    transition: all 0.15s cubic-bezier(0.68, -0.55, 0.265, 1.55)
    border: 0
    background-color: transparent

  .focused .input-group .form-control
    width: 380px

  .close
    display: none

@media (prefers-reduced-motion: reduce)
  .navbar-search .input-group
    transition: none

@media (prefers-reduced-motion: reduce)
  .navbar-search .form-control
    transition: none

.navbar-search-dark
  .input-group
    border-color: rgba(255, 255, 255, 0.6)
    background-color: rgba(23, 43, 77, 0.8)

  .input-group-text
    color: rgba(255, 255, 255, 0.6)

  .form-control
    color: rgba(255, 255, 255, 0.9)

    &::-ms-input-placeholder, &::placeholder
      color: rgba(255, 255, 255, 0.6)

  .focused .input-group
    border-color: rgba(255, 255, 255, 0.9)
    background-color: rgba(23, 43, 77, 0.9)

.navbar-search-light
  .input-group
    border-color: rgba(0, 0, 0, 0.6)
    background-color: rgba(255, 255, 255, 0.9)

  .input-group-text
    color: rgba(0, 0, 0, 0.6)

  .form-control
    color: rgba(0, 0, 0, 0.9)

    &::-ms-input-placeholder, &::placeholder
      color: rgba(0, 0, 0, 0.6)

  .focused .input-group
    border-color: rgba(0, 0, 0, 0.9)
    background-color: white

@media (max-width: 575.98px)
  .navbar-search
    display: none
    width: 100%
    transition: all 0.15s cubic-bezier(0.68, -0.55, 0.265, 1.55)
    transform: translateX(-150%)

@media (max-width: 575.98px) and (prefers-reduced-motion: reduce)
  .navbar-search
    transition: none

@media (max-width: 575.98px)
  .navbar-search
    .form-group
      width: 100%

    .form-control, .focused .input-group .form-control
      width: auto

    .close
      display: none
      opacity: 0

      span
        width: auto
        height: auto

  .navbar-top .navbar-nav
    transition: all .15s ease

@media (max-width: 575.98px) and (prefers-reduced-motion: reduce)
  .navbar-top .navbar-nav
    transition: none

@media (max-width: 575.98px)
  .g-navbar-search-showing
    .navbar-search .close
      display: block

    .navbar-top .navbar-nav
      display: flex
      transform: translateX(150%)
      opacity: 1

  .g-navbar-search-show
    .navbar-search
      display: block

      .form-control
        width: auto

      .close
        line-height: 46px
        position: absolute
        top: 0
        right: 0
        width: 46px
        height: 46px
        cursor: pointer
        text-align: center
        color: white

    .navbar-top
      .navbar-nav
        display: none

      .navbar-collapse
        width: 100%

  .g-navbar-search-shown .navbar-search
    transform: translateX(0)

    .close
      display: block
      opacity: 1

  .g-navbar-search-hiding .navbar-top .navbar-nav
    display: flex
    transform: translateX(150%)
    opacity: 0

  .g-navbar-search-hidden .navbar-top .navbar-nav
    transform: translateX(0)
    opacity: 1

.navbar
  .dropdown-menu
    margin: 0
    pointer-events: none
    opacity: 0

  .dropdown-menu-arrow:before
    position: absolute
    z-index: -5
    bottom: 100%
    left: 20px
    display: block
    width: 12px
    height: 12px
    content: ''
    transform: rotate(-45deg) translateY(12px)
    border-radius: 2px
    background: #fff
    box-shadow: none

  .dropdown-menu-right:before
    right: 20px
    left: auto

  &:not(.navbar-nav-hover) .dropdown-menu
    &.show
      animation: show-navbar-dropdown .25s ease forwards
      pointer-events: auto
      opacity: 1

    &.close
      display: block
      animation: hide-navbar-dropdown .15s ease backwards

  &.navbar-nav-hover
    .dropdown-menu
      display: block
      transition: visibility .25s, opacity .25s, transform .25s
      transform: translate(0, 10px) perspective(200px) rotateX(-2deg)
      pointer-events: none
      opacity: 0

    .nav-item.dropdown:hover > .dropdown-menu
      display: block
      visibility: visible
      transform: translate(0, 0)
      animation: none
      pointer-events: auto
      opacity: 1

  .dropdown-menu-inner
    position: relative
    padding: 1rem

@keyframes show-navbar-dropdown
  0%
    transition: visibility .25s, opacity .25s, transform .25s
    transform: translate(0, 10px) perspective(200px)
    opacity: 0

  100%
    transform: translate(0, 0)
    opacity: 1

@keyframes hide-navbar-dropdown
  from
    opacity: 1

  to
    transform: translate(0, 10px)
    opacity: 0

.navbar-collapse-header
  display: none

@keyframes show-navbar-collapse
  0%
    transform: scale(0.95)
    transform-origin: 100% 0
    opacity: 0

  100%
    transform: scale(1)
    opacity: 1

@keyframes hide-navbar-collapse
  from
    transform: scale(1)
    transform-origin: 100% 0
    opacity: 1

  to
    transform: scale(0.95)
    opacity: 0

.navbar-top
  padding-right: 1rem
  padding-left: 1rem
  border: 0


  &.border-bottom
    &.navbar-dark

    &.navbar-light
      border-color: rgba(0, 0, 0, 0.04) !important

@media (min-width: 576px)
  .navbar-top .navbar-brand
    display: none

@media (max-width: 575.98px)
  .navbar-top
    .navbar-collapse
      width: 100%

    .nav-item
      position: static

      .dropdown-menu
        position: absolute
        right: auto
        left: 3%
        width: 94%
        min-width: auto

.sidenav-pinned .navbar-top .navbar-brand
  display: none

.nav-wrapper
  padding: 1rem 0
  border-top-left-radius: .375rem
  border-top-right-radius: .375rem

  + .card
    border-top-left-radius: 0
    border-top-right-radius: 0
    border-bottom-right-radius: .375rem
    border-bottom-left-radius: .375rem

.nav-link
  color: #525f7f

  &:hover
    color: #5e72e4

  i.ni
    position: relative
    top: 0
    background: #fff
    border-radius: 50%
    width: 40px
    height: 40px
    text-align: center
    padding-top: 14px

a.nav-link.nav-cart
  position: relative

.nav-cart i
  /*position: relative;
   *top: 0;
   *background: #fff;
   *border-radius: 50%;
   *width: 40px;
   *height: 40px;
   *text-align: center;
   *padding-top: 14px;
  margin-right: 7px

.carrello-doppio .c-d
  background: white
  margin-right: 10px
  border-radius: 40px
  padding: 6px 20px 6px 10px !important
  display: inline-block

.imgU
  position: relative
  display: inline-block
  float: left


button.btn.btn-imgP
  background: black
  position: absolute
  left: -6px
  bottom: -6px
  width: 25px
  height: 25px
  padding: 0
  border-radius: 4px
  color: #fff
  text-align: center

  i
    margin: 0
    line-height: 26px

.box.utente-img p
  margin-bottom: 3px

.navbar-vertical img
  width: 16px
  margin-right: 10px
  margin-top: -2px

.nav-pills

  .nav-link
    font-size: 12px
    font-weight: 500
    padding: 10px
    transition: all 0.15s ease
    color: #000
    border: 1px solid


.nav-pills .nav-link

  .show > .nav-link
    color: var(--white) !important

    background-color: #000

  i
    margin-right: 10px
    vertical-align: middle
    font-size: 13px

li.nav-item.nav-utente
  margin: 2px

li.nav-item.nav-utente .nav-pills .nav-link.active
  border: 1px solid black

@media (max-width: 575.98px)
  .nav-pills .nav-item
    margin-bottom: 1rem

@media (max-width: 767.98px)
  .nav-pills:not(.nav-pills-circle) .nav-item
    padding-right: 0

.nav-pills-circle
  .nav-link
    line-height: 60px
    width: 60px
    height: 60px
    padding: 0
    text-align: center
    border-radius: 50%

  .nav-link-icon
    i, svg
      font-size: 1rem

.page-item
  &.active .page-link
    box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08)

  .page-link, span
    font-size: 11px
    display: flex
    width: 30px
    height: 30px
    margin: 0 3px
    padding: 0
    border-radius: 50% !important
    align-items: center
    justify-content: center
    font-weight: 500


.pagination-lg .page-item
  .page-link, span
    line-height: 46px
    width: 46px
    height: 46px

.pagination-sm .page-item
  .page-link, span
    line-height: 30px
    width: 30px
    height: 30px

.popover
  border: 0

.popover-header
  font-weight: 600

.popover-primary
  background-color: #5e72e4

  .popover-header
    color: #fff
    background-color: #5e72e4

  .popover-body
    color: #fff

  .popover-header
    border-color: rgba(255, 255, 255, 0.2)

  &.bs-popover-top .arrow::after, &.bs-popover-auto[x-placement^='top'] .arrow::after
    border-top-color: #5e72e4

  &.bs-popover-right .arrow::after, &.bs-popover-auto[x-placement^='right'] .arrow::after
    border-right-color: #5e72e4

  &.bs-popover-bottom .arrow::after, &.bs-popover-auto[x-placement^='bottom'] .arrow::after
    border-bottom-color: #5e72e4

  &.bs-popover-left .arrow::after, &.bs-popover-auto[x-placement^='left'] .arrow::after
    border-left-color: #5e72e4

.popover-secondary
  background-color: #f7fafc

  .popover-header
    color: #212529
    background-color: #f7fafc

  .popover-body
    color: #212529

  .popover-header
    border-color: rgba(33, 37, 41, 0.2)

  &.bs-popover-top .arrow::after, &.bs-popover-auto[x-placement^='top'] .arrow::after
    border-top-color: #f7fafc

  &.bs-popover-right .arrow::after, &.bs-popover-auto[x-placement^='right'] .arrow::after
    border-right-color: #f7fafc

  &.bs-popover-bottom .arrow::after, &.bs-popover-auto[x-placement^='bottom'] .arrow::after
    border-bottom-color: #f7fafc

  &.bs-popover-left .arrow::after, &.bs-popover-auto[x-placement^='left'] .arrow::after
    border-left-color: #f7fafc

.popover-success
  background-color: #2dce89

  .popover-header
    color: #fff
    background-color: #2dce89

  .popover-body
    color: #fff

  .popover-header
    border-color: rgba(255, 255, 255, 0.2)

  &.bs-popover-top .arrow::after, &.bs-popover-auto[x-placement^='top'] .arrow::after
    border-top-color: #2dce89

  &.bs-popover-right .arrow::after, &.bs-popover-auto[x-placement^='right'] .arrow::after
    border-right-color: #2dce89

  &.bs-popover-bottom .arrow::after, &.bs-popover-auto[x-placement^='bottom'] .arrow::after
    border-bottom-color: #2dce89

  &.bs-popover-left .arrow::after, &.bs-popover-auto[x-placement^='left'] .arrow::after
    border-left-color: #2dce89

.popover-info
  background-color: #11cdef

  .popover-header
    color: #fff
    background-color: #11cdef

  .popover-body
    color: #fff

  .popover-header
    border-color: rgba(255, 255, 255, 0.2)

  &.bs-popover-top .arrow::after, &.bs-popover-auto[x-placement^='top'] .arrow::after
    border-top-color: #11cdef

  &.bs-popover-right .arrow::after, &.bs-popover-auto[x-placement^='right'] .arrow::after
    border-right-color: #11cdef

  &.bs-popover-bottom .arrow::after, &.bs-popover-auto[x-placement^='bottom'] .arrow::after
    border-bottom-color: #11cdef

  &.bs-popover-left .arrow::after, &.bs-popover-auto[x-placement^='left'] .arrow::after
    border-left-color: #11cdef

.popover-warning
  background-color: #fb6340

  .popover-header
    color: #fff
    background-color: #fb6340

  .popover-body
    color: #fff

  .popover-header
    border-color: rgba(255, 255, 255, 0.2)

  &.bs-popover-top .arrow::after, &.bs-popover-auto[x-placement^='top'] .arrow::after
    border-top-color: #fb6340

  &.bs-popover-right .arrow::after, &.bs-popover-auto[x-placement^='right'] .arrow::after
    border-right-color: #fb6340

  &.bs-popover-bottom .arrow::after, &.bs-popover-auto[x-placement^='bottom'] .arrow::after
    border-bottom-color: #fb6340

  &.bs-popover-left .arrow::after, &.bs-popover-auto[x-placement^='left'] .arrow::after
    border-left-color: #fb6340

.popover-danger
  background-color: #f5365c

  .popover-header
    color: #fff
    background-color: #f5365c

  .popover-body
    color: #fff

  .popover-header
    border-color: rgba(255, 255, 255, 0.2)

  &.bs-popover-top .arrow::after, &.bs-popover-auto[x-placement^='top'] .arrow::after
    border-top-color: #f5365c

  &.bs-popover-right .arrow::after, &.bs-popover-auto[x-placement^='right'] .arrow::after
    border-right-color: #f5365c

  &.bs-popover-bottom .arrow::after, &.bs-popover-auto[x-placement^='bottom'] .arrow::after
    border-bottom-color: #f5365c

  &.bs-popover-left .arrow::after, &.bs-popover-auto[x-placement^='left'] .arrow::after
    border-left-color: #f5365c

.popover-light
  background-color: #adb5bd

  .popover-header
    color: #fff
    background-color: #adb5bd

  .popover-body
    color: #fff

  .popover-header
    border-color: rgba(255, 255, 255, 0.2)

  &.bs-popover-top .arrow::after, &.bs-popover-auto[x-placement^='top'] .arrow::after
    border-top-color: #adb5bd

  &.bs-popover-right .arrow::after, &.bs-popover-auto[x-placement^='right'] .arrow::after
    border-right-color: #adb5bd

  &.bs-popover-bottom .arrow::after, &.bs-popover-auto[x-placement^='bottom'] .arrow::after
    border-bottom-color: #adb5bd

  &.bs-popover-left .arrow::after, &.bs-popover-auto[x-placement^='left'] .arrow::after
    border-left-color: #adb5bd

.popover-dark
  background-color: #212529

  .popover-header
    color: #fff
    background-color: #212529

  .popover-body
    color: #fff

  .popover-header
    border-color: rgba(255, 255, 255, 0.2)

  &.bs-popover-top .arrow::after, &.bs-popover-auto[x-placement^='top'] .arrow::after
    border-top-color: #212529

  &.bs-popover-right .arrow::after, &.bs-popover-auto[x-placement^='right'] .arrow::after
    border-right-color: #212529

  &.bs-popover-bottom .arrow::after, &.bs-popover-auto[x-placement^='bottom'] .arrow::after
    border-bottom-color: #212529

  &.bs-popover-left .arrow::after, &.bs-popover-auto[x-placement^='left'] .arrow::after
    border-left-color: #212529

.popover-default
  background-color: #172b4d

  .popover-header
    color: #fff
    background-color: #172b4d

  .popover-body
    color: #fff

  .popover-header
    border-color: rgba(255, 255, 255, 0.2)

  &.bs-popover-top .arrow::after, &.bs-popover-auto[x-placement^='top'] .arrow::after
    border-top-color: #172b4d

  &.bs-popover-right .arrow::after, &.bs-popover-auto[x-placement^='right'] .arrow::after
    border-right-color: #172b4d

  &.bs-popover-bottom .arrow::after, &.bs-popover-auto[x-placement^='bottom'] .arrow::after
    border-bottom-color: #172b4d

  &.bs-popover-left .arrow::after, &.bs-popover-auto[x-placement^='left'] .arrow::after
    border-left-color: #172b4d

.popover-white
  background-color: #fff

  .popover-header
    color: #212529
    background-color: #fff

  .popover-body
    color: #212529

  .popover-header
    border-color: rgba(33, 37, 41, 0.2)

  &.bs-popover-top .arrow::after, &.bs-popover-auto[x-placement^='top'] .arrow::after
    border-top-color: #fff

  &.bs-popover-right .arrow::after, &.bs-popover-auto[x-placement^='right'] .arrow::after
    border-right-color: #fff

  &.bs-popover-bottom .arrow::after, &.bs-popover-auto[x-placement^='bottom'] .arrow::after
    border-bottom-color: #fff

  &.bs-popover-left .arrow::after, &.bs-popover-auto[x-placement^='left'] .arrow::after
    border-left-color: #fff

.popover-neutral
  background-color: #fff

  .popover-header
    color: #212529
    background-color: #fff

  .popover-body
    color: #212529

  .popover-header
    border-color: rgba(33, 37, 41, 0.2)

  &.bs-popover-top .arrow::after, &.bs-popover-auto[x-placement^='top'] .arrow::after
    border-top-color: #fff

  &.bs-popover-right .arrow::after, &.bs-popover-auto[x-placement^='right'] .arrow::after
    border-right-color: #fff

  &.bs-popover-bottom .arrow::after, &.bs-popover-auto[x-placement^='bottom'] .arrow::after
    border-bottom-color: #fff

  &.bs-popover-left .arrow::after, &.bs-popover-auto[x-placement^='left'] .arrow::after
    border-left-color: #fff

.popover-darker
  background-color: black

  .popover-header
    color: #fff
    background-color: black

  .popover-body
    color: #fff

  .popover-header
    border-color: rgba(255, 255, 255, 0.2)

  &.bs-popover-top .arrow::after, &.bs-popover-auto[x-placement^='top'] .arrow::after
    border-top-color: black

  &.bs-popover-right .arrow::after, &.bs-popover-auto[x-placement^='right'] .arrow::after
    border-right-color: black

  &.bs-popover-bottom .arrow::after, &.bs-popover-auto[x-placement^='bottom'] .arrow::after
    border-bottom-color: black

  &.bs-popover-left .arrow::after, &.bs-popover-auto[x-placement^='left'] .arrow::after
    border-left-color: black

.progress-wrapper
  position: relative
  padding-top: 1.5rem

.progress
  overflow: hidden
  height: 8px
  margin-bottom: 1rem
  border-radius: .25rem
  background-color: #e9ecef
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1)

  .sr-only
    font-size: 13px
    line-height: 20px
    left: 0
    clip: auto
    width: auto
    height: 20px
    margin: 0 0 0 30px

.progress-sm
  height: 5px

.progress-xs
  height: 3px

.progress-heading
  font-size: 14px
  font-weight: 500
  margin: 0 0 2px
  padding: 0

.progress-bar
  height: auto
  border-radius: 0
  box-shadow: none

.progress-info
  display: flex
  margin-bottom: .5rem
  align-items: center
  justify-content: space-between

.progress-label span
  font-size: .625rem
  font-weight: 600
  display: inline-block
  padding: .25rem 1rem
  text-transform: uppercase
  color: #5e72e4
  border-radius: 30px
  background: rgba(94, 114, 228, 0.1)

.progress-percentage
  text-align: right

  span
    font-size: .875rem
    font-weight: 600
    display: inline-block
    color: #8898aa

.separator
  position: absolute
  top: auto
  right: 0
  left: 0
  overflow: hidden
  width: 100%
  height: 150px
  transform: translateZ(0)
  pointer-events: none

  svg
    position: absolute
    pointer-events: none

.separator-top
  top: 0
  bottom: auto

  svg
    top: 0

.separator-bottom
  top: auto
  bottom: 0

  svg
    bottom: 0

.separator-inverse
  transform: rotate(180deg)

.separator-skew
  height: 60px

@media (min-width: 1200px)
  .separator-skew
    height: 70px

.section-nucleo-icons
  --icon-size:5rem

  --icon-sm-size:3.75rem

  --gutter:7rem

  .icons-container
    position: relative
    z-index: 1
    max-width: 100%
    height: 360px
    margin: 0 auto

    i
      position: absolute
      z-index: 1
      display: inline-flex
      transition: all 0.2s cubic-bezier(0.25, 0.65, 0.9, 0.75)
      transform: translate(-50%, -50%)
      border-radius: 50%
      background: #fff
      box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15)
      align-items: center
      justify-content: center

      &.icon
        font-size: 1.7em
        width: var(--icon-size)
        height: var(--icon-size)

      &.icon-sm
        font-size: 1.5em
        width: var(--icon-sm-size)
        height: var(--icon-sm-size)

      &:nth-child(1)
        font-size: 42px
        z-index: 2
        color: #fb6340
        font-size: 42px
        top: 50%
        left: 50%
        color: #fb6340

      opacity: 1

      &:nth-child(2)
        top: 50%
        left: calc(50% + (var(--gutter) * 1.7))

      &:nth-child(3)
        top: calc(50% + var(--gutter))
        left: calc(50% + var(--gutter))

      &:nth-child(4)
        top: calc(50% - var(--gutter))
        left: calc(50% + var(--gutter))

      &:nth-child(5)
        top: 50%
        left: calc(50% + (var(--gutter) * 4))

      &:nth-child(6)
        top: calc(50% + (var(--gutter) * 1.5))
        left: calc(50% + (var(--gutter) * 2.7))

      &:nth-child(7)
        top: calc(50% - (var(--gutter) * 1.5))
        left: calc(50% + (var(--gutter) * 2.7))

      &:nth-child(8)
        top: 50%
        left: calc(50% - (var(--gutter) * 1.7))

      &:nth-child(9)
        top: calc(50% + var(--gutter))
        left: calc(50% - var(--gutter))

      &:nth-child(10)
        top: calc(50% - var(--gutter))
        left: calc(50% - var(--gutter))

      &:nth-child(11)
        top: 50%
        left: calc(50% - (var(--gutter) * 4))

      &:nth-child(12)
        top: calc(50% + (var(--gutter) * 1.5))
        left: calc(50% - (var(--gutter) * 2.7))

      &:nth-child(13)
        top: calc(50% - (var(--gutter) * 1.5))
        left: calc(50% - (var(--gutter) * 2.7))

.table
  thead th
    font-size: .65rem
    padding-top: .75rem
    padding-bottom: .75rem
    letter-spacing: 1px
    text-transform: uppercase
    border-bottom: 1px solid #e9ecef

  th
    font-weight: 600

  td
    .progress
      width: 120px
      height: 3px
      margin: 0

    font-size: 12px
    white-space: nowrap

  th
    font-size: 12px
    white-space: nowrap

  &.align-items-center
    td, th
      vertical-align: middle

  .thead-dark th
    color: #4d7bca
    background-color: #1c345d

    a
      color: #4d7bca

  .thead-light th
    color: #8898aa
    background-color: #f6f9fc

    a
      color: #8898aa

.table-hover tr
  transition: all .15s ease

@media (prefers-reduced-motion: reduce)
  .table-hover tr
    transition: none

.table-flush
  td, th
    border-right: 0
    border-left: 0

  tbody tr
    &:first-child
      td, th
        border-top: 0

    &:last-child
      td, th
        border-bottom: 0

.card .table
  margin-bottom: 0

  td, th
    position: relative

.table .custom-toggle
  display: block

.lead
  font-size: 1.25rem
  font-weight: 300
  line-height: 1.7
  margin-top: 1.5rem

  + .btn-wrapper
    margin-top: 3rem

.description
  font-size: .875rem

article
  h4:not(:first-child), h5:not(:first-child)
    margin-top: 3rem

  h4, h5
    margin-bottom: 1.5rem

  figure
    margin: 3rem 0

  h5 + figure
    margin-top: 0

.display-1 span, .display-2 span, .display-3 span, .display-4 span
  font-weight: 300
  display: block

h1 > a, h2 > a, h3 > a, h4 > a, h5 > a, h6 > a
  color: inherit

.heading
  font-size: .95rem
  font-weight: 600
  letter-spacing: .025em
  text-transform: uppercase

.heading-small
  font-size: .75rem
  padding-top: .25rem
  padding-bottom: .25rem
  letter-spacing: .04em
  text-transform: uppercase

.heading-title
  font-size: 1.375rem
  font-weight: 600
  letter-spacing: .025em
  text-transform: uppercase

.heading-section
  font-size: 1.375rem
  font-weight: 600
  letter-spacing: .025em
  text-transform: uppercase

  img
    display: block
    width: 72px
    height: 72px
    margin-bottom: 1.5rem

  &.text-center img
    margin-right: auto
    margin-left: auto

.surtitle
  margin-bottom: 0
  letter-spacing: 2px
  text-transform: uppercase
  color: #8898aa

.bg-blue
  background-color: #5e72e4 !important

a.bg-blue
  &:hover, &:focus
    background-color: #324cdd !important

button.bg-blue
  &:hover, &:focus
    background-color: #324cdd !important

.bg-indigo
  background-color: #5603ad !important

a.bg-indigo
  &:hover, &:focus
    background-color: #3d027b !important

button.bg-indigo
  &:hover, &:focus
    background-color: #3d027b !important

.bg-purple
  background-color: #8965e0 !important

a.bg-purple
  &:hover, &:focus
    background-color: #683bd7 !important

button.bg-purple
  &:hover, &:focus
    background-color: #683bd7 !important

.bg-pink
  background-color: #f3a4b5 !important

a.bg-pink
  &:hover, &:focus
    background-color: #ed7790 !important

button.bg-pink
  &:hover, &:focus
    background-color: #ed7790 !important

.bg-red
  background-color: #f5365c !important

a.bg-red
  &:hover, &:focus
    background-color: #ec0c38 !important

button.bg-red
  &:hover, &:focus
    background-color: #ec0c38 !important

.bg-orange
  background-color: #fb6340 !important

a.bg-orange
  &:hover, &:focus
    background-color: #fa3a0e !important

button.bg-orange
  &:hover, &:focus
    background-color: #fa3a0e !important

.bg-yellow
  background-color: #ffd600 !important

a.bg-yellow
  &:hover, &:focus
    background-color: #ccab00 !important

button.bg-yellow
  &:hover, &:focus
    background-color: #ccab00 !important

.bg-green
  background-color: #2dce89 !important

a.bg-green
  &:hover, &:focus
    background-color: #24a46d !important

button.bg-green
  &:hover, &:focus
    background-color: #24a46d !important

.bg-teal
  background-color: #11cdef !important

a.bg-teal
  &:hover, &:focus
    background-color: #0da5c0 !important

button.bg-teal
  &:hover, &:focus
    background-color: #0da5c0 !important

.bg-cyan
  background-color: #2bffc6 !important

a.bg-cyan
  &:hover, &:focus
    background-color: #00f7b5 !important

button.bg-cyan
  &:hover, &:focus
    background-color: #00f7b5 !important

.bg-white
  background-color: #fff !important

a.bg-white
  &:hover, &:focus
    background-color: #e6e6e6 !important

button.bg-white
  &:hover, &:focus
    background-color: #e6e6e6 !important

.bg-gray
  background-color: #8898aa !important

a.bg-gray
  &:hover, &:focus
    background-color: #6a7e95 !important

button.bg-gray
  &:hover, &:focus
    background-color: #6a7e95 !important

.bg-gray-dark
  background-color: #32325d !important

a.bg-gray-dark
  &:hover, &:focus
    background-color: #20203c !important

button.bg-gray-dark
  &:hover, &:focus
    background-color: #20203c !important

.bg-light
  background-color: #ced4da !important

a.bg-light
  &:hover, &:focus
    background-color: #b1bbc4 !important

button.bg-light
  &:hover, &:focus
    background-color: #b1bbc4 !important

.bg-lighter
  background-color: #e9ecef !important

a.bg-lighter
  &:hover, &:focus
    background-color: #cbd3da !important

button.bg-lighter
  &:hover, &:focus
    background-color: #cbd3da !important

.bg-gradient-primary
  background: linear-gradient(87deg, #5e72e4 0, #825ee4 100%) !important

.bg-gradient-secondary
  background: linear-gradient(87deg, #f7fafc 0, #f7f8fc 100%) !important

.bg-gradient-success
  background: linear-gradient(87deg, #2dce89 0, #2dcecc 100%) !important

.bg-gradient-info
  background: linear-gradient(87deg, #11cdef 0, #1171ef 100%) !important

.bg-gradient-warning
  background: linear-gradient(87deg, #fb6340 0, #fbb140 100%) !important

.bg-gradient-danger
  background: linear-gradient(87deg, #f5365c 0, #f56036 100%) !important

.bg-gradient-light
  background: linear-gradient(87deg, #adb5bd 0, #adaebd 100%) !important

.bg-gradient-dark
  background: linear-gradient(87deg, #212529 0, #212229 100%) !important

.bg-gradient-default
  background: linear-gradient(87deg, #172b4d 0, #1a174d 100%) !important

.bg-gradient-white, .bg-gradient-neutral
  background: linear-gradient(87deg, #fff 0, white 100%) !important

.bg-gradient-darker
  background: linear-gradient(87deg, black 0, black 100%) !important

.bg-gradient-blue
  background: linear-gradient(87deg, #5e72e4 0, #825ee4 100%) !important

.bg-gradient-indigo
  background: linear-gradient(87deg, #5603ad 0, #9d03ad 100%) !important

.bg-gradient-purple
  background: linear-gradient(87deg, #8965e0 0, #bc65e0 100%) !important

.bg-gradient-pink
  background: linear-gradient(87deg, #f3a4b5 0, #f3b4a4 100%) !important

.bg-gradient-red
  background: linear-gradient(87deg, #f5365c 0, #f56036 100%) !important

.bg-gradient-orange
  background: linear-gradient(87deg, #fb6340 0, #fbb140 100%) !important

.bg-gradient-yellow
  background: linear-gradient(87deg, #ffd600 0, #beff00 100%) !important

.bg-gradient-green
  background: linear-gradient(87deg, #2dce89 0, #2dcecc 100%) !important

.bg-gradient-teal
  background: linear-gradient(87deg, #11cdef 0, #1171ef 100%) !important

.bg-gradient-cyan
  background: linear-gradient(87deg, #2bffc6 0, #2be0ff 100%) !important

.bg-gradient-white
  background: linear-gradient(87deg, #fff 0, white 100%) !important

.bg-gradient-gray
  background: linear-gradient(87deg, #8898aa 0, #888aaa 100%) !important

.bg-gradient-gray-dark
  background: linear-gradient(87deg, #32325d 0, #44325d 100%) !important

.bg-gradient-light
  background: linear-gradient(87deg, #ced4da 0, #cecfda 100%) !important

.bg-gradient-lighter
  background: linear-gradient(87deg, #e9ecef 0, #e9eaef 100%) !important

.bg-translucent-primary
  background-color: rgba(63, 87, 223, 0.6) !important

a.bg-translucent-primary
  &:hover, &:focus
    background-color: rgba(42, 68, 219, 0.6) !important

button.bg-translucent-primary
  &:hover, &:focus
    background-color: rgba(42, 68, 219, 0.6) !important

.bg-translucent-secondary
  background-color: rgba(221, 234, 242, 0.6) !important

a.bg-translucent-secondary
  &:hover, &:focus
    background-color: rgba(202, 222, 235, 0.6) !important

button.bg-translucent-secondary
  &:hover, &:focus
    background-color: rgba(202, 222, 235, 0.6) !important

.bg-translucent-success
  background-color: rgba(39, 177, 118, 0.6) !important

a.bg-translucent-success
  &:hover, &:focus
    background-color: rgba(34, 156, 104, 0.6) !important

button.bg-translucent-success
  &:hover, &:focus
    background-color: rgba(34, 156, 104, 0.6) !important

.bg-translucent-info
  background-color: rgba(14, 177, 206, 0.6) !important

a.bg-translucent-info
  &:hover, &:focus
    background-color: rgba(12, 156, 183, 0.6) !important

button.bg-translucent-info
  &:hover, &:focus
    background-color: rgba(12, 156, 183, 0.6) !important

.bg-translucent-warning
  background-color: rgba(250, 70, 29, 0.6) !important

a.bg-translucent-warning
  &:hover, &:focus
    background-color: rgba(249, 51, 5, 0.6) !important

button.bg-translucent-warning
  &:hover, &:focus
    background-color: rgba(249, 51, 5, 0.6) !important

.bg-translucent-danger
  background-color: rgba(243, 20, 64, 0.6) !important

a.bg-translucent-danger
  &:hover, &:focus
    background-color: rgba(227, 11, 54, 0.6) !important

button.bg-translucent-danger
  &:hover, &:focus
    background-color: rgba(227, 11, 54, 0.6) !important

.bg-translucent-light
  background-color: rgba(153, 163, 173, 0.6) !important

a.bg-translucent-light
  &:hover, &:focus
    background-color: rgba(139, 150, 162, 0.6) !important

button.bg-translucent-light
  &:hover, &:focus
    background-color: rgba(139, 150, 162, 0.6) !important

.bg-translucent-dark
  background-color: rgba(17, 19, 21, 0.6) !important

a.bg-translucent-dark
  &:hover, &:focus
    background-color: rgba(6, 6, 7, 0.6) !important

button.bg-translucent-dark
  &:hover, &:focus
    background-color: rgba(6, 6, 7, 0.6) !important

.bg-translucent-default
  background-color: rgba(15, 28, 50, 0.6) !important

a.bg-translucent-default
  &:hover, &:focus
    background-color: rgba(9, 17, 30, 0.6) !important

button.bg-translucent-default
  &:hover, &:focus
    background-color: rgba(9, 17, 30, 0.6) !important

.bg-translucent-white
  background-color: rgba(237, 237, 237, 0.6) !important

a.bg-translucent-white
  &:hover, &:focus
    background-color: rgba(224, 224, 224, 0.6) !important

button.bg-translucent-white
  &:hover, &:focus
    background-color: rgba(224, 224, 224, 0.6) !important

.bg-translucent-neutral
  background-color: rgba(237, 237, 237, 0.6) !important

a.bg-translucent-neutral
  &:hover, &:focus
    background-color: rgba(224, 224, 224, 0.6) !important

button.bg-translucent-neutral
  &:hover, &:focus
    background-color: rgba(224, 224, 224, 0.6) !important

.bg-translucent-darker
  background-color: rgba(0, 0, 0, 0.6) !important

a.bg-translucent-darker
  &:hover, &:focus
    background-color: rgba(0, 0, 0, 0.6) !important

button.bg-translucent-darker
  &:hover, &:focus
    background-color: rgba(0, 0, 0, 0.6) !important

.section-primary
  background-color: #f8f9fe !important

a.section-primary
  &:hover, &:focus
    background-color: #cbd3f8 !important

button.section-primary
  &:hover, &:focus
    background-color: #cbd3f8 !important

.section-secondary
  background-color: #f7fafc !important

a.section-secondary
  &:hover, &:focus
    background-color: #d2e3ee !important

button.section-secondary
  &:hover, &:focus
    background-color: #d2e3ee !important

.section-light
  background-color: #ced4da !important

a.section-light
  &:hover, &:focus
    background-color: #b1bbc4 !important

button.section-light
  &:hover, &:focus
    background-color: #b1bbc4 !important

.section-dark
  background-color: #212529 !important

a.section-dark
  &:hover, &:focus
    background-color: #0a0c0d !important

button.section-dark
  &:hover, &:focus
    background-color: #0a0c0d !important

.section-darker
  background-color: black !important

a.section-darker
  &:hover, &:focus
    background-color: black !important

button.section-darker
  &:hover, &:focus
    background-color: black !important

.bg-gradient-primary
  background: linear-gradient(87deg, #5e72e4 0, #825ee4 100%) !important

.bg-gradient-secondary
  background: linear-gradient(87deg, #f7fafc 0, #f7f8fc 100%) !important

.bg-gradient-success
  background: linear-gradient(87deg, #2dce89 0, #2dcecc 100%) !important

.bg-gradient-info
  background: linear-gradient(87deg, #11cdef 0, #1171ef 100%) !important

.bg-gradient-warning
  background: linear-gradient(87deg, #fb6340 0, #fbb140 100%) !important

.bg-gradient-danger
  background: linear-gradient(87deg, #f5365c 0, #f56036 100%) !important

.bg-gradient-light
  background: linear-gradient(87deg, #adb5bd 0, #adaebd 100%) !important

.bg-gradient-dark
  background: linear-gradient(87deg, #212529 0, #212229 100%) !important

.bg-gradient-default
  background: linear-gradient(87deg, #172b4d 0, #1a174d 100%) !important

.bg-gradient-white, .bg-gradient-neutral
  background: linear-gradient(87deg, #fff 0, white 100%) !important

.bg-gradient-darker
  background: linear-gradient(87deg, black 0, black 100%) !important

.fill-primary
  fill: #5e72e4

.stroke-primary
  stroke: #5e72e4

.fill-secondary
  fill: #f7fafc

.stroke-secondary
  stroke: #f7fafc

.fill-success
  fill: #2dce89

.stroke-success
  stroke: #2dce89

.fill-info
  fill: #11cdef

.stroke-info
  stroke: #11cdef

.fill-warning
  fill: #fb6340

.stroke-warning
  stroke: #fb6340

.fill-danger
  fill: #f5365c

.stroke-danger
  stroke: #f5365c

.fill-light
  fill: #adb5bd

.stroke-light
  stroke: #adb5bd

.fill-dark
  fill: #212529

.stroke-dark
  stroke: #212529

.fill-default
  fill: #172b4d

.stroke-default
  stroke: #172b4d

.fill-white
  fill: #fff

.stroke-white
  stroke: #fff

.fill-neutral
  fill: #fff

.stroke-neutral
  stroke: #fff

.fill-darker
  fill: black

.stroke-darker
  stroke: black

.fill-opacity-8
  fill-opacity: .8

.blur--hover
  position: relative

  .blur-item
    transition: 1s cubic-bezier(0.19, 1, 0.22, 1)
    opacity: 1
    will-change: transform
    filter: blur(0)

  .blur-hidden
    position: absolute
    z-index: 100
    top: calc(50% + 7px)
    left: 50%
    transition: all .15s ease
    transform: translate(-50%, -50%)
    opacity: 0

  &:hover
    .blur-item
      z-index: 1
      transform: scale(0.95)
      opacity: .8
      filter: blur(10px)

    .blur-hidden
      top: 50%
      opacity: 1

.floating
  animation: floating 3s ease infinite
  will-change: transform

  &:hover
    animation-play-state: paused

.floating-lg
  animation: floating-lg 3s ease infinite

.floating-sm
  animation: floating-sm 3s ease infinite

@keyframes floating-lg
  0%
    transform: translateY(0px)

  50%
    transform: translateY(15px)

  100%
    transform: translateY(0px)

@keyframes floating
  0%
    transform: translateY(0px)

  50%
    transform: translateY(10px)

  100%
    transform: translateY(0px)

@keyframes floating-sm
  0%
    transform: translateY(0px)

  50%
    transform: translateY(5px)

  100%
    transform: translateY(0px)

.floatfix
  &:before
    display: table
    content: ''

  &:after
    display: table
    content: ''
    clear: both

.img-center
  display: block
  margin-right: auto
  margin-left: auto

.opacity-1
  opacity: .1 !important

.opacity-2
  opacity: .2 !important

.opacity-3
  opacity: .3 !important

.opacity-4
  opacity: .4 !important

.opacity-5
  opacity: .5 !important

.opacity-6
  opacity: .6 !important

.opacity-7
  opacity: .7 !important

.opacity-8
  opacity: .8 !important
  opacity: .9 !important

.opacity-10
  opacity: 1 !important

.overflow-visible
  overflow: visible !important

.overflow-hidden
  overflow: hidden !important

.top-0
  top: 0

.right-0
  right: 0

.bottom-0
  bottom: 0

.left-0
  left: 0

.top-1
  top: .25rem

.right-1
  right: .25rem

.bottom-1
  bottom: .25rem

.left-1
  left: .25rem

.top-2
  top: .5rem

.right-2
  right: .5rem

.bottom-2
  bottom: .5rem

.left-2
  left: .5rem

.top-3
  top: 1rem

.right-3
  right: 1rem

.bottom-3
  bottom: 1rem

.left-3
  left: 1rem

.top-4
  top: 1.5rem

.right-4
  right: 1.5rem

.bottom-4
  bottom: 1.5rem

.left-4
  left: 1.5rem

.top-5
  top: 3rem

.right-5
  right: 3rem

.bottom-5
  bottom: 3rem

.left-5
  left: 3rem

.top--9
  top: -10rem

.right--9
  right: -10rem

.bottom--9
  bottom: -10rem

.left--9
  left: -10rem

.top--8
  top: -8rem

.right--8
  right: -8rem

.bottom--8
  bottom: -8rem

.left--8
  left: -8rem

.top--7
  top: -6rem

.right--7
  right: -6rem

.bottom--7
  bottom: -6rem

.left--7
  left: -6rem

.top--6
  top: -4.5rem

.right--6
  right: -4.5rem

.bottom--6
  bottom: -4.5rem

.left--6
  left: -4.5rem

.top--5
  top: -3rem

.right--5
  right: -3rem

.bottom--5
  bottom: -3rem

.left--5
  left: -3rem

.top--4
  top: -1.5rem

.right--4
  right: -1.5rem

.bottom--4
  bottom: -1.5rem

.left--4
  left: -1.5rem

.top--3
  top: -1rem

.right--3
  right: -1rem

.bottom--3
  bottom: -1rem

.left--3
  left: -1rem

.top--2
  top: -.5rem

.right--2
  right: -.5rem

.bottom--2
  bottom: -.5rem

.left--2
  left: -.5rem

.top--1
  top: -.25rem

.right--1
  right: -.25rem

.bottom--1
  bottom: -.25rem

.left--1
  left: -.25rem

.top-6
  top: 4.5rem

.right-6
  right: 4.5rem

.bottom-6
  bottom: 4.5rem

.left-6
  left: 4.5rem

.top-7
  top: 6rem

.right-7
  right: 6rem

.bottom-7
  bottom: 6rem

.left-7
  left: 6rem

.top-8
  top: 8rem

.right-8
  right: 8rem

.bottom-8
  bottom: 8rem

.left-8
  left: 8rem

.top-9
  top: 10rem

.right-9
  right: 10rem

.bottom-9
  bottom: 10rem

.left-9
  left: 10rem

.center
  left: 50%
  transform: translateX(-50%)

[class*='shadow']
  transition: all .15s ease

.shadow-sm--hover:hover
  box-shadow: 0 0 0.5rem rgba(136, 152, 170, 0.075) !important

.shadow--hover:hover
  box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15) !important

.shadow-lg--hover:hover
  box-shadow: 0 0 3rem rgba(136, 152, 170, 0.175) !important

.shadow-none--hover:hover
  box-shadow: none !important

.h-100vh
  height: 100vh !important

.row.row-grid > [class*='col-'] + [class*='col-'], .row-grid + .row-grid
  margin-top: 3rem

@media (min-width: 992px)
  .row.row-grid > [class*='col-lg-'] + [class*='col-lg-']
    margin-top: 0

@media (min-width: 768px)
  .row.row-grid > [class*='col-md-'] + [class*='col-md-']
    margin-top: 0

@media (min-width: 576px)
  .row.row-grid > [class*='col-sm-'] + [class*='col-sm-']
    margin-top: 0

@media (min-width: 992px)
  .mt--100
    margin-top: -100px !important

  .mr--100
    margin-right: -100px !important

  .mb--100
    margin-bottom: -100px !important

  .ml--100
    margin-left: -100px !important

  .mt--150
    margin-top: -150px !important

  .mb--150
    margin-bottom: -150px !important

  .mt--200
    margin-top: -200px !important

  .mb--200
    margin-bottom: -200px !important

  .mt--300
    margin-top: -300px !important

  .mb--300
    margin-bottom: -300px !important

  .pt-100
    padding-top: 100px !important

  .pb-100
    padding-bottom: 100px !important

  .pt-150
    padding-top: 150px !important

  .pb-150
    padding-bottom: 150px !important

  .pt-200
    padding-top: 200px !important

  .pb-200
    padding-bottom: 200px !important

  .pt-250
    padding-top: 250px !important

  .pb-250
    padding-bottom: 250px !important

  .pt-300
    padding-top: 300px !important

  .pb-300
    padding-bottom: 300px !important

.font-weight-300
  font-weight: 300 !important

.font-weight-400
  font-weight: 400 !important

.font-weight-500
  font-weight: 500 !important

.font-weight-600
  font-weight: 600 !important

.font-weight-700
  font-weight: 700 !important

.font-weight-800
  font-weight: 800 !important

.font-weight-900
  font-weight: 900 !important

.text-underline
  text-decoration: underline

.text-through
  text-decoration: line-through

.text-xs
  font-size: .75rem !important

.text-sm
  font-size: .875rem !important

.text-lg
  font-size: 1.25rem !important

.text-xl
  font-size: 1.5rem !important

.lh-100
  line-height: 1

.lh-110
  line-height: 1.1

.lh-120
  line-height: 1.2

.lh-130
  line-height: 1.3

.lh-140
  line-height: 1.4

.lh-150
  line-height: 1.5

.lh-160
  line-height: 1.6

.lh-170
  line-height: 1.7

.lh-180
  line-height: 1.8

.ls-1
  letter-spacing: .0625rem

.ls-15
  letter-spacing: .09375rem

.ls-2
  letter-spacing: .125rem

.text-blue
  color: #5e72e4 !important

a.text-blue
  &:hover, &:focus
    color: #233dd2 !important

.text-indigo
  color: #5603ad !important

a.text-indigo
  &:hover, &:focus
    color: #310262 !important

.text-purple
  color: #8965e0 !important

a.text-purple
  &:hover, &:focus
    color: #5a2acf !important

.text-pink
  color: #f3a4b5 !important

a.text-pink
  &:hover, &:focus
    color: #ea607e !important

.text-red
  color: #f5365c !important

a.text-red
  &:hover, &:focus
    color: #d40b33 !important

.text-orange
  color: #fb6340 !important

a.text-orange
  &:hover, &:focus
    color: #ea3005 !important

.text-yellow
  color: #ffd600 !important

a.text-yellow
  &:hover, &:focus
    color: #b39600 !important

.text-green
  color: #2dce89 !important

a.text-green
  &:hover, &:focus
    color: #1f8f5f !important

.text-teal
  color: #11cdef !important

a.text-teal
  &:hover, &:focus
    color: #0b90a8 !important

.text-cyan
  color: #2bffc6 !important

a.text-cyan
  &:hover, &:focus
    color: #00dea2 !important

.text-white
  color: #fff !important

a.text-white
  &:hover, &:focus
    color: #d9d9d9 !important

.text-gray
  color: #8898aa !important

a.text-gray
  &:hover, &:focus
    color: #607286 !important

.text-gray-dark
  color: #32325d !important

a.text-gray-dark
  &:hover, &:focus
    color: #17172b !important

.text-light
  color: #ced4da !important

a.text-light
  &:hover, &:focus
    color: #a2aeb9 !important

.text-lighter
  color: #e9ecef !important

a.text-lighter
  &:hover, &:focus
    color: #bdc6cf !important

@media (min-width: 992px)
  .transform-perspective-right
    transform: scale(1) perspective(1040px) rotateY(-11deg) rotateX(2deg) rotate(2deg)

  .transform-perspective-left
    transform: scale(1) perspective(2000px) rotateY(11deg) rotateX(2deg) rotate(-2deg)

.datepicker
  border-radius: .375rem
  direction: ltr

.datepicker-inline
  width: 220px

.datepicker-rtl
  direction: rtl

  &.dropdown-menu
    left: auto

  table tr td span
    float: right

.datepicker-dropdown
  top: 0
  left: 0
  padding: 20px 22px
  box-shadow: 0 50px 100px rgba(50, 50, 93, 0.1), 0 15px 35px rgba(50, 50, 93, 0.15), 0 5px 15px rgba(0, 0, 0, 0.1)

  &.datepicker-orient-left
    &:before
      left: 6px

    &:after
      left: 7px

  &.datepicker-orient-right
    &:before
      right: 6px

    &:after
      right: 7px

  &.datepicker-orient-bottom
    &:before
      top: -7px

    &:after
      top: -6px

  &.datepicker-orient-top
    &:before
      bottom: -7px
      border-top: 7px solid white
      border-bottom: 0

    &:after
      bottom: -6px
      border-top: 6px solid #fff
      border-bottom: 0

.datepicker table
  margin: 0
  -webkit-user-select: none
  -ms-user-select: none
  user-select: none
  -webkit-touch-callout: none

  tr
    td
      border-radius: 50%

    th
      font-weight: 500
      border-radius: .375rem

    td, th
      font-size: .875rem
      width: 36px
      height: 36px
      transition: all .15s ease
      text-align: center
      border: none

.table-striped .datepicker table tr
  td, th
    background-color: transparent

.datepicker
  table tr td
    &.old, &.new
      color: #adb5bd

    &.day:hover, &.focused
      cursor: pointer
      background: white

    &.disabled
      cursor: default
      color: #dee2e6
      background: none

      &:hover
        cursor: default
        color: #dee2e6
        background: none

    &.highlighted
      border-radius: 0

      &.focused
        background: #5e72e4

      &.disabled
        color: #ced4da
        background: #5e72e4

        &:active
          color: #ced4da
          background: #5e72e4

    &.today
      background: white

      &.focused
        background: white

      &.disabled
        color: #8898aa
        background: white

        &:active
          color: #8898aa
          background: white

    &.range
      color: #fff
      border-radius: 0
      background: #5e72e4

      &.focused
        background: #3b53de

      &.disabled
        color: #8a98eb
        background: #324cdd

        &:active
          color: #8a98eb
          background: #324cdd

      &.day.disabled:hover
        color: #8a98eb
        background: #324cdd

      &.highlighted
        &.focused
          background: #cbd3da

        &.disabled
          color: #dee2e6
          background: #e9ecef

          &:active
            color: #dee2e6
            background: #e9ecef

      &.today.disabled
        color: #fff
        background: #5e72e4

        &:active
          color: #fff
          background: #5e72e4

    &.day
      &.range-start
        border-top-right-radius: 0
        border-bottom-right-radius: 0

      &.range-end
        border-top-left-radius: 0
        border-bottom-left-radius: 0

      &.range-start.range-end
        border-radius: 50%

    &.selected
      color: #fff
      background: #5e72e4

      &.highlighted, &:hover, &.highlighted:hover
        color: #fff
        background: #5e72e4

    &.day.range:hover
      color: #fff
      background: #5e72e4

    &.active
      color: #fff
      background: #5e72e4
      box-shadow: none

      &.highlighted, &:hover, &.highlighted:hover
        color: #fff
        background: #5e72e4
        box-shadow: none

    span
      line-height: 54px
      display: block
      float: left
      width: 23%
      height: 54px
      margin: 1%
      cursor: pointer
      border-radius: 4px

      &:hover, &.focused
        background: #e9ecef

      &.disabled
        cursor: default
        color: #dee2e6
        background: none

        &:hover
          cursor: default
          color: #dee2e6
          background: none

      &.active
        text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25)

        &:hover
          text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25)

        &.disabled
          text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25)

          &:hover
            text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25)

      &.old, &.new
        color: #8898aa

  .datepicker-switch
    width: 145px
    cursor: pointer

  .prev, .next, tfoot tr th
    cursor: pointer

  .datepicker-switch:hover, .prev:hover, .next:hover, tfoot tr th:hover
    background: #e9ecef

  .prev.disabled, .next.disabled
    visibility: hidden

  .cw
    font-size: 10px
    width: 12px
    padding: 0 2px 0 5px
    vertical-align: middle

.bootstrap-tagsinput
  display: inline-block
  max-width: 100%
  padding: .25rem
  cursor: text
  vertical-align: middle
  color: #8898aa
  border: 0 solid transparent
  border-radius: .25rem
  background-color: #fff

  input
    width: auto
    max-width: inherit
    margin: 0
    padding: 0 6px
    border: none
    outline: none
    background-color: transparent
    box-shadow: none

    &::-ms-input-placeholder, &::placeholder
      opacity: 1
      color: #adb5bd

    &:focus
      border: none
      box-shadow: none

  .badge
    line-height: 1.5
    position: relative
    margin: .125rem
    padding: .625rem .625rem .5rem
    transition: all .15s ease
    color: #fff
    border-radius: .25rem
    background: #172b4d
    box-shadow: 0 1px 2px rgba(68, 68, 68, 0.25)

    &:hover
      padding-right: 1.5rem

  [data-role='remove']
    position: absolute
    top: 50%
    margin-left: 10px
    cursor: pointer
    transform: translate(0, -50%)
    color: #fff

    &:after
      font-size: 16px
      content: '×'

@media (prefers-reduced-motion: reduce)
  .bootstrap-tagsinput .badge
    transition: none

#chartjs-tooltip
  position: absolute
  transition: all .1s ease
  transform: translate(-50%, 0)
  pointer-events: none
  opacity: 1
  color: white
  border-radius: 3px
  background: rgba(0, 0, 0, 0.7)

.chartjs-tooltip-key
  display: inline-block
  width: 10px
  height: 10px
  margin-right: 10px

.dataTables_wrapper
  font-size: .875rem

table.dataTable
  margin-bottom: 1.25rem !important
  border-bottom: 1px solid #e9ecef

  tbody > tr.selected
    background-color: #5e72e4

.dataTables_length, .dataTables_info, .dt-buttons
  padding-left: 1.5rem

.dataTables_length .form-control
  margin: 0 .375rem

.dataTables_filter
  display: inline-block
  float: right
  padding-right: 1.5rem

.dataTables_paginate
  padding-right: 1.5rem

.dropzone
  position: relative
  display: flex
  flex-direction: column

.dz-message
  z-index: 999
  padding: 5rem 1rem
  cursor: pointer
  transition: all .15s ease
  text-align: center
  color: #8898aa
  border: 1px dashed #dee2e6
  border-radius: .375rem
  background-color: #fff
  order: -1

  &:hover
    color: #525f7f
    border-color: #8898aa

.dz-drag-hover .dz-message
  color: #5e72e4
  border-color: #5e72e4

.dropzone-multiple .dz-message
  padding-top: 2rem
  padding-bottom: 2rem

.dropzone-single.dz-max-files-reached .dz-message
  opacity: 0
  color: white
  background-color: rgba(0, 0, 0, 0.9)

  &:hover
    opacity: 1

.dz-preview-single, .dz-preview-cover
  position: absolute
  top: 0
  right: 0
  bottom: 0
  left: 0
  border-radius: .375rem

.dz-preview-img
  width: 100%
  height: 100%
  border-radius: .375rem
  -o-object-fit: cover
  object-fit: cover

.dz-preview-multiple .list-group-item:last-child
  padding-bottom: 0
  border-bottom: 0

[data-dz-size] strong
  font-weight: 400

.fc-header-toolbar
  display: none

.fc-scroller
  height: auto !important

.fc
  th
    font-size: .75rem
    font-weight: 600
    padding: .75rem 1rem
    text-transform: uppercase
    color: #8898aa

  div.fc-row
    margin-right: 0
    border: 0

  button .fc-icon
    top: -5px

.fc-unthemed td.fc-today
  background-color: transparent

  span
    color: #fb6340

.fc-event
  font-size: .75rem
  padding: 0
  border: 0
  border-radius: .25rem

  .fc-title
    font-weight: 600
    display: block
    overflow: hidden
    padding: .4rem .5rem
    white-space: nowrap
    text-overflow: ellipsis
    color: #fff

  .fc-time
    float: left
    margin: 0 0 0 -1px
    padding: 2px 6px
    background: rgba(0, 0, 0, 0.2)

.fc-view
  overflow: hidden
  border: 0

  > table
    overflow: hidden
    border: 0

    > tbody > tr .ui-widget-content
      border-top: 0

.fc-body
  border: 0

.fc-icon
  font-family: NucleoIcons, sans-serif
  font-size: 1rem
  line-height: 35px
  width: 35px
  height: 35px
  border-radius: 50%

  &:hover
    color: #5e72e4

.fc-button
  border: 0
  background: transparent
  box-shadow: none

  &:focus, &:active
    outline: 0

.calendar
  z-index: 0

  td, th
    border-color: #eff1f3

  .fc-toolbar
    position: relative
    z-index: 2
    height: 250px
    margin-bottom: -2px
    border-radius: .375rem .375rem 0 0
    background-color: #fff

  .fc-day-number
    box-sizing: border-box
    width: 100%
    padding: .5rem 1rem

  .fc-day-header
    text-align: left

  .fc-day-grid-event
    margin: 1px 9px

@media (max-width: 575.98px)
  .calendar .fc-toolbar
    height: 135px

@media (min-width: 576px)
  .calendar .fc-day-number
    font-size: .875rem
    font-weight: 600
    color: #67779d

[data-calendar-month]
  transition: background-image 300ms
  background-size: contain

@media (prefers-reduced-motion: reduce)
  [data-calendar-month]
    transition: none


.card-calendar
  .card-header
    border-bottom: 0

  table
    background: transparent

    tr > td
      &:first-child
        border-left-width: 0

      &:last-child
        border-right-width: 0

.widget-calendar
  position: relative
  z-index: 0

  td, th
    text-align: center
    border-color: transparent

  .fc-toolbar
    margin-top: 1.25rem

    h2
      font-size: 1rem

  .fc-day-number
    width: 100%
    padding: 0
    text-align: center

  .fc
    table
      font-size: .875rem

    th
      font-size: .75rem
      padding: .75rem .5rem

.vector-map
  position: relative
  height: 600px

.vector-map-sm
  height: 280px

.jvectormap-container
  width: 100%
  height: 100%

.jvectormap-zoomin, .jvectormap-zoomout
  position: absolute
  bottom: 0
  left: 0

.jvectormap-zoomin
  bottom: 4.25rem

.jvectormap-zoomout
  bottom: 2rem

/*!
 * Lavalamp
 * http://lavalamp.magicmediamuse.com/

.lavalamp
  position: relative

.lavalamp-item
  position: relative
  z-index: 5

.lavalamp-object
  position: absolute
  top: 0
  left: 0

/* Custom easing transitions

.lavalamp .lavalamp-object
  transition-property: transform, width, height

  &.ease
    transition-timing-function: ease

  &.ease-in
    transition-timing-function: ease-in

  &.ease-out
    transition-timing-function: ease-out

  &.ease-in-out
    transition-timing-function: ease-in-out

  &.linear
    transition-timing-function: linear

  &.easeInQuad
    transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53)

  &.easeInCubic
    transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19)

  &.easeInQuart
    transition-timing-function: cubic-bezier(0.895, 0.03, 0.685, 0.22)

  &.easeInQuint
    transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06)

  &.easeInSine
    transition-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715)

  &.easeInExpo
    transition-timing-function: cubic-bezier(0.95, 0.05, 0.795, 0.035)

  &.easeInCirc
    transition-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.335)

  &.easeInBack
    transition-timing-function: cubic-bezier(0.6, -0.28, 0.735, 0.045)

  &.easeOutQuad
    transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94)

  &.easeOutCubic
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1)

  &.easeOutQuart
    transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1)

  &.easeOutQuint
    transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1)

  &.easeOutSine
    transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1)

  &.easeOutExpo
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1)

  &.easeOutCirc
    transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1)

  &.easeOutBack
    transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275)

  &.easeInOutQuad
    transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955)

  &.easeInOutCubic, &.easeInOutQuart
    transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1)

  &.easeInOutQuint
    transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1)

  &.easeInOutSine
    transition-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95)

  &.easeInOutExpo
    transition-timing-function: cubic-bezier(1, 0, 0, 1)

  &.easeInOutCirc
    transition-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86)

  &.easeInOutBack
    transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55)

.noUi-target
  box-sizing: border-box
  -webkit-user-select: none
  -ms-user-select: none
  user-select: none
  -webkit-touch-callout: none
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0)
  touch-action: none

  *
    box-sizing: border-box
    -webkit-user-select: none
    -ms-user-select: none
    user-select: none
    -webkit-touch-callout: none
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0)
    touch-action: none

  position: relative
  direction: ltr

.noUi-base
  position: relative
  z-index: 1
  width: 100%
  height: 100%

.noUi-connects
  position: relative
  z-index: 1
  width: 100%
  height: 100%
  z-index: 0
  overflow: hidden

.noUi-connect, .noUi-origin
  position: absolute
  z-index: 1
  top: 0
  left: 0
  width: 100%
  height: 100%
  transform-origin: 0 0
  will-change: transform

html:not([dir='rtl']) .noUi-horizontal .noUi-origin
  right: 0
  left: auto

.noUi-vertical .noUi-origin
  width: 0

.noUi-horizontal .noUi-origin
  height: 0

.noUi-handle
  position: absolute

.noUi-state-tap
  .noUi-connect, .noUi-origin
    transition: transform .3s

.noUi-state-drag *
  cursor: inherit !important

.noUi-horizontal
  height: 5px

  .noUi-handle
    top: -6px
    left: -17px
    width: 34px
    height: 28px

.noUi-vertical
  width: 5px

  .noUi-handle
    top: -17px
    left: -6px
    width: 28px
    height: 34px

html:not([dir='rtl']) .noUi-horizontal .noUi-handle
  right: -17px
  left: auto

.noUi-connects
  border-radius: 3px

.noUi-connect
  background: #5e72e4

.noUi-draggable
  cursor: ew-resize

.noUi-vertical .noUi-draggable
  cursor: ns-resize

.noUi-handle
  cursor: default
  border: 1px solid #d9d9d9
  border-radius: 3px
  outline: none
  background: #fff
  box-shadow: inset 0 0 1px #fff, inset 0 1px 7px #ebebeb, 0 3px 6px -3px #bbb

.noUi-active
  outline: none

/* Disabled state;

[disabled]
  .noUi-connect
    background: #b8b8b8

  &.noUi-target, &.noUi-handle, .noUi-handle
    cursor: not-allowed

/* Base;
 *

.noUi-pips
  box-sizing: border-box

  *
    box-sizing: border-box

  position: absolute
  color: #999

/* Values;
 *

.noUi-value
  position: absolute
  text-align: center
  white-space: nowrap

.noUi-value-sub
  font-size: 10px
  color: #ccc

/* Markings;
 *

.noUi-marker
  position: absolute
  background: #ccc

.noUi-marker-sub, .noUi-marker-large
  background: #aaa

/* Horizontal layout;
 *

.noUi-pips-horizontal
  top: 100%
  left: 0
  width: 100%
  height: 80px
  padding: 10px 0

.noUi-value-horizontal
  transform: translate(-50%, 50%)

.noUi-rtl .noUi-value-horizontal
  transform: translate(50%, 50%)

.noUi-marker-horizontal
  &.noUi-marker
    width: 2px
    height: 5px
    margin-left: -1px

  &.noUi-marker-sub
    height: 10px

  &.noUi-marker-large
    height: 15px

/* Vertical layout;
 *

.noUi-pips-vertical
  top: 0
  left: 100%
  height: 100%
  padding: 0 10px

.noUi-value-vertical
  padding-left: 25px
  transform: translate(0, -50%, 0)

.noUi-rtl .noUi-value-vertical
  transform: translate(0, 50%)

.noUi-marker-vertical
  &.noUi-marker
    width: 5px
    height: 2px
    margin-top: -1px

  &.noUi-marker-sub
    width: 10px

  &.noUi-marker-large
    width: 15px

.noUi-tooltip
  position: absolute
  display: block
  padding: 5px
  text-align: center
  white-space: nowrap
  color: #000
  border: 1px solid #d9d9d9
  border-radius: 3px
  background: #fff

.noUi-horizontal .noUi-tooltip
  bottom: 120%
  left: 50%
  transform: translate(-50%, 0)

.noUi-vertical .noUi-tooltip
  top: 50%
  right: 120%
  transform: translate(0, -50%)

.noUi-target
  margin: 15px 0
  cursor: pointer
  border: 0
  border-radius: 5px
  background: #eceeef
  box-shadow: inset 0 1px 2px rgba(90, 97, 105, 0.1)

.noUi-horizontal
  height: 5px

html:not([dir='rtl']) .noUi-horizontal .noUi-handle
  right: -10px

.noUi-vertical
  width: 5px

.noUi-connect
  background: #5e72e4
  box-shadow: none

.noUi-horizontal .noUi-handle, .noUi-vertical .noUi-handle
  top: -5px
  width: 15px
  height: 15px
  cursor: pointer
  transition: box-shadow .15s, transform .15s
  border: 0
  border-radius: 100%
  background-color: #5e72e4
  box-shadow: none

.noUi-horizontal .noUi-handle.noUi-active, .noUi-vertical .noUi-handle.noUi-active
  box-shadow: 0 0 0 2px #5e72e4

.input-slider--cyan .noUi-connect
  background: #2bffc6

/* Disabled state

[disabled]
  .noUi-connect, &.noUi-connect
    background: #b2b2b2

  .noUi-handle, &.noUi-origin
    cursor: not-allowed

/* Range slider value labels

.range-slider-value
  font-size: .75rem
  font-weight: 500
  padding: .4em .8em .3em .85em
  color: #fff
  border-radius: 10px
  background-color: rgba(33, 37, 41, 0.7)

.range-slider-wrapper .upper-info
  font-weight: 400
  margin-bottom: 5px

.input-slider-value-output
  font-size: 11px
  position: relative
  top: 12px
  padding: 4px 8px
  color: #fff
  border-radius: 2px
  background: #333

  &:after
    position: absolute
    bottom: 100%
    left: 10px
    width: 0
    height: 0
    margin-left: -4px
    content: ' '
    pointer-events: none
    border: solid transparent
    border-width: 4px
    border-color: rgba(136, 183, 213, 0)
    border-bottom-color: #333

  &.left:after
    right: auto
    left: 10px

  &.right:after
    right: 10px
    left: auto



.ql-toolbar
  position: relative
  padding: .625rem .75rem
  color: #8898aa
  border: 1px solid #dee2e6
  border-radius: .25rem .25rem 0 0
  background-color: #fff

  + .ql-container
    margin-top: -1px

    .ql-editor
      border-top-left-radius: 0
      border-top-right-radius: 0

.ql-editor
  font-size: 1rem
  line-height: 1.5
  display: block
  width: 100%
  min-height: 6rem
  padding: .625rem .75rem
  transition: all 0.15s cubic-bezier(0.68, -0.55, 0.265, 1.55)
  color: #8898aa
  border: 1px solid #dee2e6
  border-radius: .25rem
  background-color: #fff
  background-clip: padding-box
  box-shadow: 0 3px 2px rgba(233, 236, 239, 0.05)

  &::-ms-expand
    border: 0
    background-color: transparent

  &:focus
    color: #8898aa
    border-color: #5e72e4
    outline: 0
    background-color: #fff
    box-shadow: 0 3px 9px rgba(50, 50, 9, 0), 3px 4px 8px rgba(94, 114, 228, 0.1)
    border-color: #dee2e6

@media (prefers-reduced-motion: reduce)
  .ql-editor
    transition: none

.ql-hidden
  position: absolute
  transform: scale(0)

.ql-editor
  &.ql-blank::before
    font-style: normal
    top: .625rem
    left: .75rem
    color: #adb5bd

  &:focus::before
    display: none

.ql-formats
  padding-right: .5rem
  padding-left: .5rem

  &:first-child
    padding-left: 0

  &:last-child
    padding-right: 0

.ql-toolbar
  button
    padding: 0 .25rem
    cursor: pointer
    transition: all .15s ease
    color: #525f7f
    border: none
    background: none

    &:hover
      color: #5e72e4

    &:first-child
      margin-left: -.25rem

  .ql-active
    color: #5e72e4

  button svg
    width: 1.25rem
    height: 1.25rem

  .ql-stroke
    stroke: currentColor
    stroke-width: 2
    stroke-linecap: round
    stroke-linejoin: round
    fill: none

  .ql-thin
    stroke-width: 1

  .ql-fill
    fill: currentColor

  input.ql-image
    position: absolute
    transform: scale(0)

.ql-tooltip
  position: absolute
  display: flex
  width: 18.5rem
  margin-top: .6rem
  padding: .625rem .75rem
  border: 1px solid rgba(0, 0, 0, 0.05)
  border-radius: .4375rem
  background-color: #fff
  box-shadow: 0 0.5rem 2rem 0 rgba(0, 0, 0, 0.2)
  flex-wrap: nowrap

  &:before, &:after
    position: absolute
    bottom: 100%
    left: 50%
    content: ''
    transform: translateX(-50%)

  &:before
    border-right: .6rem solid transparent
    border-bottom: 0.6rem solid rgba(0, 0, 0, 0.05)
    border-left: .6rem solid transparent

  &:after
    border-right: .5rem solid transparent
    border-bottom: .5rem solid #fff
    border-left: .5rem solid transparent

.ql-container .ql-tooltip:hover
  display: flex !important

.ql-tooltip
  .ql-preview
    font-size: .875rem
    line-height: 1.5
    width: 100%
    padding: .25rem .5rem
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
    border-radius: .25rem

  &.ql-editing .ql-preview
    display: none

  input
    font-size: .875rem
    line-height: 1.5
    display: none
    width: 100%
    padding-top: calc(.25rem + 1px)
    padding-bottom: calc(.25rem + 1px)
    color: #8898aa
    border: none
    background-color: transparent

    &:focus
      outline: none

  &.ql-editing input
    display: block

  .ql-action, .ql-remove
    margin-left: .25rem

  .ql-action::before, .ql-remove::before
    font-size: .875rem
    font-weight: 600
    line-height: 1.5
    display: inline-block
    padding: .25rem .5rem
    cursor: pointer
    -webkit-user-select: none
    -ms-user-select: none
    user-select: none
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
    text-align: center
    vertical-align: middle
    white-space: nowrap
    border: 1px solid transparent
    border-radius: .25rem

  .ql-action::before
    &:hover, &:focus
      text-decoration: none

  .ql-remove::before
    &:hover, &:focus
      text-decoration: none

  .ql-action::before
    &:focus, &.focus
      outline: 0
      box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08)

  .ql-remove::before
    &:focus, &.focus
      outline: 0
      box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08)

  .ql-action::before, &.ql-editing .ql-action::before
    color: #fff
    border-color: #5e72e4
    background-color: #5e72e4
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08)

  .ql-action::before:hover, &.ql-editing .ql-action::before:hover
    color: #fff
    border-color: #5e72e4
    background-color: #5e72e4

  .ql-action::before
    &:focus, &.focus
      box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08), 0 0 0 0 rgba(94, 114, 228, 0.5)

  &.ql-editing .ql-action::before
    &:focus, &.focus
      box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08), 0 0 0 0 rgba(94, 114, 228, 0.5)

  .ql-action::before
    &.disabled, &:disabled
      color: #fff
      border-color: #5e72e4
      background-color: #5e72e4

  &.ql-editing .ql-action::before
    &.disabled, &:disabled
      color: #fff
      border-color: #5e72e4
      background-color: #5e72e4

  .ql-action::before:not(:disabled):not(.disabled)
    &:active, &.active
      color: #fff
      border-color: #5e72e4
      background-color: #324cdd

@media (prefers-reduced-motion: reduce)
  .ql-tooltip .ql-preview
    transition: none

@media (prefers-reduced-motion: reduce)
  .ql-tooltip
    .ql-action::before, .ql-remove::before
      transition: none

.show > .ql-tooltip .ql-action::before.dropdown-toggle
  color: #fff
  border-color: #5e72e4
  background-color: #324cdd

.ql-tooltip.ql-editing .ql-action::before:not(:disabled):not(.disabled)
  &:active, &.active
    color: #fff
    border-color: #5e72e4
    background-color: #324cdd

.show > .ql-tooltip.ql-editing .ql-action::before.dropdown-toggle
  color: #fff
  border-color: #5e72e4
  background-color: #324cdd

.ql-tooltip .ql-action::before:not(:disabled):not(.disabled)
  &:active:focus, &.active:focus
    box-shadow: none, 0 0 0 0 rgba(94, 114, 228, 0.5)

.show > .ql-tooltip .ql-action::before.dropdown-toggle:focus
  box-shadow: none, 0 0 0 0 rgba(94, 114, 228, 0.5)

.ql-tooltip.ql-editing .ql-action::before:not(:disabled):not(.disabled)
  &:active:focus, &.active:focus
    box-shadow: none, 0 0 0 0 rgba(94, 114, 228, 0.5)

.show > .ql-tooltip.ql-editing .ql-action::before.dropdown-toggle:focus
  box-shadow: none, 0 0 0 0 rgba(94, 114, 228, 0.5)

.ql-tooltip
  .ql-action::before
    content: 'Edit'

  &.ql-editing .ql-action::before
    content: 'Save'

  .ql-remove::before
    content: 'Remove'
    color: #212529
    border-color: #fff
    border-color: #dee2e6
    background-color: #fff
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08)

    &:hover
      color: #212529
      border-color: white
      background-color: white

    &:focus, &.focus
      box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08), 0 0 0 0 rgba(255, 255, 255, 0.5)

    &.disabled, &:disabled
      color: #212529
      border-color: #fff
      background-color: #fff

    &:not(:disabled):not(.disabled)
      &:active, &.active
        color: #212529
        border-color: white
        background-color: #e6e6e6

.show > .ql-tooltip .ql-remove::before.dropdown-toggle
  color: #212529
  border-color: white
  background-color: #e6e6e6

.ql-tooltip .ql-remove::before:not(:disabled):not(.disabled)
  &:active:focus, &.active:focus
    box-shadow: none, 0 0 0 0 rgba(255, 255, 255, 0.5)

.show > .ql-tooltip .ql-remove::before.dropdown-toggle:focus
  box-shadow: none, 0 0 0 0 rgba(255, 255, 255, 0.5)

.ql-tooltip.ql-editing .ql-remove::before
  display: none

.ql-editor
  blockquote
    font-size: 1.25rem
    margin-bottom: 1rem

  img
    max-width: 100%
    height: auto

.scroll-wrapper
  position: relative
  overflow: hidden !important
  padding: 0 !important

  > .scroll-content
    position: relative !important
    top: 0
    left: 0
    overflow: scroll !important
    box-sizing: content-box !important
    width: auto !important
    max-width: none !important
    height: auto
    max-height: none
    margin: 0
    padding: 0
    border: none !important

    &::-webkit-scrollbar
      width: 0
      height: 0

  &.scroll--rtl
    direction: rtl

.scroll-element
  display: none
  box-sizing: content-box

  div
    box-sizing: content-box

  .scroll-bar, .scroll-arrow
    cursor: default

  &.scroll-x.scroll-scrollx_visible, &.scroll-y.scroll-scrolly_visible
    display: block

.scroll-textarea
  border: 1px solid #ccc
  border-top-color: #999

  > .scroll-content
    overflow: hidden !important

    > textarea
      position: relative !important
      top: 0
      overflow: scroll !important
      box-sizing: border-box
      width: 100% !important
      max-width: none !important
      height: 100% !important
      max-height: none !important
      margin: 0
      padding: 2px
      border: none !important
      outline: none

      &::-webkit-scrollbar
        width: 0
        height: 0

/*************** SIMPLE INNER SCROLLBAR **************

.scrollbar-inner > .scroll-element
  position: absolute
  z-index: 10
  margin: 0
  padding: 0
  border: none

  div
    position: absolute
    z-index: 10
    margin: 0
    padding: 0
    border: none
    top: 0
    left: 0
    display: block
    width: 100%
    height: 100%

  &.scroll-x
    bottom: 2px
    left: 0
    width: 100%
    height: 8px

  &.scroll-y
    top: 0
    right: 2px
    width: 8px
    height: 100%

  .scroll-element_outer
    overflow: hidden
    border-radius: 8px

  .scroll-element_track, .scroll-bar
    border-radius: 8px

  .scroll-element_track, .scroll-bar
    opacity: .4
    -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=40)'
    filter: alpha(opacity=40)

  .scroll-element_track
    background-color: #e0e0e0

  .scroll-bar
    background-color: #c2c2c2

  &:hover .scroll-bar, &.scroll-draggable .scroll-bar
    background-color: #919191

  &.scroll-x.scroll-scrolly_visible .scroll-element_track
    left: -12px

  &.scroll-y.scroll-scrollx_visible .scroll-element_track
    top: -12px

  &.scroll-x.scroll-scrolly_visible .scroll-element_size
    left: -12px

  &.scroll-y.scroll-scrollx_visible .scroll-element_size
    top: -12px

/* update scrollbar offset if both scrolls are visible

/*************** SIMPLE OUTER SCROLLBAR **************

.scrollbar-outer >
.scroll-element
  position: absolute
  z-index: 10
  margin: 0
  padding: 0
  border: none

  div
    position: absolute
    z-index: 10
    margin: 0
    padding: 0
    border: none
    top: 0
    left: 0
    display: block
    width: 100%
    height: 100%

  background-color: #fff

  &.scroll-x
    bottom: 0
    left: 0
    width: 100%
    height: 12px

  &.scroll-y
    top: 0
    right: 0
    width: 12px
    height: 100%

  &.scroll-x .scroll-element_outer
    top: 2px
    height: 8px

  &.scroll-y .scroll-element_outer
    left: 2px
    width: 8px

  .scroll-element_outer
    overflow: hidden

  .scroll-element_track
    background-color: #eee

  .scroll-element_outer, .scroll-element_track
    border-radius: 8px

  .scroll-bar
    border-radius: 8px
    background-color: #d9d9d9

    &:hover
      background-color: #c2c2c2

  &.scroll-draggable .scroll-bar
    background-color: #919191

  .scroll-content
    &.scroll-scrolly_visible
      left: -12px
      margin-left: 12px

    &.scroll-scrollx_visible
      top: -12px
      margin-top: 12px

  .scroll-element
    &.scroll-x .scroll-bar
      min-width: 10px

    &.scroll-y .scroll-bar
      min-height: 10px

    &.scroll-x.scroll-scrolly_visible .scroll-element_track
      left: -14px

    &.scroll-y.scroll-scrollx_visible .scroll-element_track
      top: -14px

    &.scroll-x.scroll-scrolly_visible .scroll-element_size
      left: -14px

    &.scroll-y.scroll-scrollx_visible .scroll-element_size
      top: -14px

/* scrollbar height/width & offset from container borders

/* update scrollbar offset if both scrolls are visible

/*************** SCROLLBAR MAC OS X **************

.scrollbar-macosx
  > .scroll-element
    position: absolute
    z-index: 10
    margin: 0
    padding: 0
    border: none
    background: none

    div
      position: absolute
      z-index: 10
      margin: 0
      padding: 0
      border: none
      background: none
      top: 0
      left: 0
      display: block
      width: 100%
      height: 100%

    .scroll-element_track
      display: none

    .scroll-bar
      display: block
      transition: opacity .2s linear
      opacity: 0
      border-radius: 7px
      background-color: #6c6e71
      -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=0)'
      filter: alpha(opacity=0)

  &:hover > .scroll-element .scroll-bar
    opacity: .7
    -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=70)'
    filter: alpha(opacity=70)

  > .scroll-element
    &.scroll-draggable .scroll-bar
      opacity: .7
      -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=70)'
      filter: alpha(opacity=70)

    &.scroll-x
      bottom: 0
      left: 0
      overflow: visible
      width: 100%
      min-width: 100%
      height: 0

    &.scroll-y
      top: 0
      right: 0
      width: 0
      height: 100%
      min-height: 100%

    &.scroll-x .scroll-bar
      top: -9px
      min-width: 10px
      height: 7px

    &.scroll-y .scroll-bar
      left: -9px
      width: 7px
      min-height: 10px

    &.scroll-x
      .scroll-element_outer
        left: 2px

      .scroll-element_size
        left: -4px

    &.scroll-y
      .scroll-element_outer
        top: 2px

      .scroll-element_size
        top: -4px

    &.scroll-x.scroll-scrolly_visible .scroll-element_size
      left: -11px

    &.scroll-y.scroll-scrollx_visible .scroll-element_size
      top: -11px

/* scrollbar height/width & offset from container borders

/* update scrollbar offset if both scrolls are visible

/*************** SCROLLBAR LIGHT **************

.scrollbar-light >
.scroll-element
  position: absolute
  z-index: 10
  overflow: hidden
  margin: 0
  padding: 0
  border: none

  div
    position: absolute
    z-index: 10
    overflow: hidden
    margin: 0
    padding: 0
    border: none
    top: 0
    left: 0
    display: block
    width: 100%
    height: 100%

  background-color: #fff

  .scroll-element_outer
    border-radius: 10px

  .scroll-element_size
    border-radius: 10px
    background: #dbdbdb
    background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIxMDAlIiB5Mj0iMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2RiZGJkYiIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiNlOGU4ZTgiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+')
    background: linear-gradient(to right, #dbdbdb 0%, #e8e8e8 100%)

  &.scroll-x
    bottom: 0
    left: 0
    width: 100%
    min-width: 100%
    height: 17px

  &.scroll-y
    top: 0
    right: 0
    width: 17px
    height: 100%
    min-height: 100%

  .scroll-bar
    border: 1px solid #dbdbdb
    border-radius: 10px
    background: #fefefe
    background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIxMDAlIiB5Mj0iMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2ZlZmVmZSIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiNmNWY1ZjUiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+')
    background: linear-gradient(to right, #fefefe 0%, #f5f5f5 100%)

  .scroll-content
    &.scroll-scrolly_visible
      left: -17px
      margin-left: 17px

    &.scroll-scrollx_visible
      top: -17px
      margin-top: 17px

  .scroll-element
    &.scroll-x .scroll-bar
      top: 0
      min-width: 10px
      height: 10px

    &.scroll-y .scroll-bar
      left: 0
      width: 10px
      min-height: 10px

    &.scroll-x
      .scroll-element_outer
        top: 2px
        left: 2px
        height: 12px

      .scroll-element_size
        left: -4px

    &.scroll-y
      .scroll-element_outer
        top: 2px
        left: 2px
        width: 12px

      .scroll-element_size
        top: -4px

    &.scroll-x.scroll-scrolly_visible .scroll-element_size
      left: -19px

    &.scroll-y.scroll-scrollx_visible .scroll-element_size
      top: -19px

    &.scroll-x.scroll-scrolly_visible .scroll-element_track
      left: -19px

    &.scroll-y.scroll-scrollx_visible .scroll-element_track
      top: -19px

/* scrollbar height/width & offset from container borders

/* update scrollbar offset if both scrolls are visible

/*************** SCROLLBAR RAIL **************

.scrollbar-rail >
.scroll-element
  position: absolute
  z-index: 10
  overflow: hidden
  margin: 0
  padding: 0
  border: none

  div
    position: absolute
    z-index: 10
    overflow: hidden
    margin: 0
    padding: 0
    border: none
    top: 0
    left: 0
    display: block
    width: 100%
    height: 100%

  background-color: #fff

  .scroll-element_size
    background-color: #999
    background-color: rgba(0, 0, 0, 0.3)

  .scroll-element_outer:hover .scroll-element_size
    background-color: #666
    background-color: rgba(0, 0, 0, 0.5)

  &.scroll-x
    bottom: 0
    left: 0
    width: 100%
    min-width: 100%
    height: 12px
    padding: 3px 0 2px

  &.scroll-y
    top: 0
    right: 0
    width: 12px
    height: 100%
    min-height: 100%
    padding: 0 2px 0 3px

  .scroll-bar
    border-radius: 2px
    background-color: #d0b9a0
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5)

  .scroll-element_outer:hover .scroll-bar
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.6)

  .scroll-content
    &.scroll-scrolly_visible
      left: -17px
      margin-left: 17px

    &.scroll-scrollx_visible
      top: -17px
      margin-top: 17px

  .scroll-element
    &.scroll-x .scroll-bar
      top: 1px
      min-width: 10px
      height: 10px

    &.scroll-y .scroll-bar
      left: 1px
      width: 10px
      min-height: 10px

    &.scroll-x
      .scroll-element_outer
        left: 5px
        height: 15px

      .scroll-element_size
        top: 5px
        left: -10px
        height: 2px

    &.scroll-y
      .scroll-element_outer
        top: 5px
        width: 15px

      .scroll-element_size
        top: -10px
        left: 5px
        width: 2px

    &.scroll-x.scroll-scrolly_visible .scroll-element_size
      left: -25px

    &.scroll-y.scroll-scrollx_visible .scroll-element_size
      top: -25px

    &.scroll-x.scroll-scrolly_visible .scroll-element_track
      left: -25px

    &.scroll-y.scroll-scrollx_visible .scroll-element_track
      top: -25px

/* scrollbar height/width & offset from container borders

/* update scrollbar offset if both scrolls are visible

/*************** SCROLLBAR DYNAMIC **************

.scrollbar-dynamic > .scroll-element
  position: absolute
  z-index: 10
  margin: 0
  padding: 0
  border: none
  background: none

  div
    position: absolute
    z-index: 10
    margin: 0
    padding: 0
    border: none
    background: none
    top: 0
    left: 0
    display: block
    width: 100%
    height: 100%

  &.scroll-x
    bottom: 2px
    left: 0
    width: 100%
    min-width: 100%
    height: 7px

  &.scroll-y
    top: 0
    right: 2px
    width: 7px
    height: 100%
    min-height: 100%

  .scroll-element_outer
    opacity: .3
    border-radius: 12px

  .scroll-element_size
    transition: opacity .2s
    opacity: 0
    border-radius: 12px
    background-color: #ccc

  .scroll-bar
    border-radius: 7px
    background-color: #6c6e71

  &.scroll-x .scroll-bar
    top: auto
    bottom: 0
    min-width: 24px
    height: 7px

  &.scroll-y .scroll-bar
    right: 0
    left: auto
    width: 7px
    min-height: 24px

  &.scroll-x .scroll-element_outer
    top: auto
    bottom: 0
    left: 2px
    transition: height .2s

  &.scroll-y .scroll-element_outer
    top: 2px
    right: 0
    left: auto
    transition: width .2s

  &.scroll-x .scroll-element_size
    left: -4px

  &.scroll-y .scroll-element_size
    top: -4px

  &.scroll-x.scroll-scrolly_visible .scroll-element_size
    left: -11px

  &.scroll-y.scroll-scrollx_visible .scroll-element_size
    top: -11px

  &:hover .scroll-element_outer, &.scroll-draggable .scroll-element_outer
    overflow: hidden
    opacity: .7
    -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=70)'
    filter: alpha(opacity=70)

  &:hover .scroll-element_outer .scroll-element_size, &.scroll-draggable .scroll-element_outer .scroll-element_size
    opacity: 1

  &:hover .scroll-element_outer .scroll-bar, &.scroll-draggable .scroll-element_outer .scroll-bar
    width: 100%
    height: 100%
    border-radius: 12px

  &.scroll-x
    &:hover .scroll-element_outer, &.scroll-draggable .scroll-element_outer
      height: 20px
      min-height: 7px

  &.scroll-y
    &:hover .scroll-element_outer, &.scroll-draggable .scroll-element_outer
      width: 20px
      min-width: 7px

/* scrollbar height/width & offset from container borders

/* update scrollbar offset if both scrolls are visible

/* hover & drag

/*************** SCROLLBAR GOOGLE CHROME **************

.scrollbar-chrome >
.scroll-element
  position: absolute
  z-index: 10
  overflow: hidden
  margin: 0
  padding: 0
  border: none

  div
    position: absolute
    z-index: 10
    overflow: hidden
    margin: 0
    padding: 0
    border: none
    top: 0
    left: 0
    display: block
    width: 100%
    height: 100%

  background-color: #fff

  .scroll-element_track
    border: 1px solid #dbdbdb
    background: #f1f1f1

  &.scroll-x
    bottom: 0
    left: 0
    width: 100%
    min-width: 100%
    height: 16px

  &.scroll-y
    top: 0
    right: 0
    width: 16px
    height: 100%
    min-height: 100%

  .scroll-bar
    cursor: default
    border: 1px solid #bdbdbd
    border-radius: 2px
    background-color: #d9d9d9

    &:hover
      border-color: #a9a9a9
      background-color: #c2c2c2

  &.scroll-draggable .scroll-bar
    border-color: #7e7e7e
    background-color: #919191

  .scroll-content
    &.scroll-scrolly_visible
      left: -16px
      margin-left: 16px

    &.scroll-scrollx_visible
      top: -16px
      margin-top: 16px

  .scroll-element
    &.scroll-x .scroll-bar
      top: 3px
      min-width: 10px
      height: 8px

    &.scroll-y .scroll-bar
      left: 3px
      width: 8px
      min-height: 10px

    &.scroll-x
      .scroll-element_outer
        border-left: 1px solid #dbdbdb

      .scroll-element_track
        left: -3px
        height: 14px

      .scroll-element_size
        left: -4px
        height: 14px

    &.scroll-y
      .scroll-element_outer
        border-top: 1px solid #dbdbdb

      .scroll-element_track
        top: -3px
        width: 14px

      .scroll-element_size
        top: -4px
        width: 14px

    &.scroll-x.scroll-scrolly_visible .scroll-element_size
      left: -19px

    &.scroll-y.scroll-scrollx_visible .scroll-element_size
      top: -19px

    &.scroll-x.scroll-scrolly_visible .scroll-element_track
      left: -19px

    &.scroll-y.scroll-scrollx_visible .scroll-element_track
      top: -19px

/* scrollbar height/width & offset from container borders

/* update scrollbar offset if both scrolls are visible

.scrollbar-inner
  height: 100%

  &:not(:hover) .scroll-element
    opacity: 0

  .scroll-element
    margin-right: 2px
    transition: opacity 300ms

    .scroll-bar
      transition: background-color 300ms

    .scroll-element_track
      transition: background-color 300ms
      background-color: transparent

    &:hover
      width: 4px

    &.scroll-y
      right: 0
      width: 3px

    &.scroll-x
      bottom: 0
      height: 3px

.select2-selection__arrow
  display: none

.select2.select2-container
  width: 100% !important

.select2-container .select2-selection--single
  font-size: 12px
  line-height: 15px
  display: block
  width: 100%
  height: calc(1.5em + 1.25rem + 2px)
  padding: .625rem .75rem
  transition: all 0.15s cubic-bezier(0.68, -0.55, 0.265, 1.55)
  color: #8898aa
  border: 1px solid #dee2e6
  border-radius: .25rem
  background-color: #fff
  background-clip: padding-box
  box-shadow: 0 3px 2px rgba(233, 236, 239, 0.05)

.select2-container--default
  &.select2-container--focus .select2-selection--multiple, .select2-selection--multiple, .select2-search--dropdown .select2-search__field
    font-size: 12px
    line-height: 15px
    display: block
    width: 100%
    height: calc(1.5em + 1.25rem + 2px)
    padding: .625rem .75rem
    transition: all 0.15s cubic-bezier(0.68, -0.55, 0.265, 1.55)
    color: #8898aa
    border: 1px solid #dee2e6
    border-radius: .25rem
    background-color: #fff
    background-clip: padding-box
    box-shadow: 0 3px 2px rgba(233, 236, 239, 0.05)

  .select2-selection--multiple
    padding: 10px !important

@media (prefers-reduced-motion: reduce)
  .select2-container .select2-selection--single
    transition: none

  .select2-container--default
    &.select2-container--focus .select2-selection--multiple, .select2-selection--multiple, .select2-search--dropdown .select2-search__field
      transition: none

.select2-container .select2-selection--single::-ms-expand
  border: 0
  background-color: transparent

.select2-container--default
  &.select2-container--focus .select2-selection--multiple::-ms-expand, .select2-selection--multiple::-ms-expand, .select2-search--dropdown .select2-search__field::-ms-expand
    border: 0
    background-color: transparent

.select2-container .select2-selection--single:focus
  color: #8898aa
  border-color: #5e72e4
  outline: 0
  background-color: #fff
  box-shadow: 0 3px 9px rgba(50, 50, 9, 0), 3px 4px 8px rgba(94, 114, 228, 0.1)

.select2-container--default
  &.select2-container--focus .select2-selection--multiple:focus, .select2-selection--multiple:focus, .select2-search--dropdown .select2-search__field:focus
    color: #8898aa
    border-color: #5e72e4
    outline: 0
    background-color: #fff
    box-shadow: 0 3px 9px rgba(50, 50, 9, 0), 3px 4px 8px rgba(94, 114, 228, 0.1)

.select2-container .select2-selection--single::-ms-input-placeholder
  opacity: 1
  color: #adb5bd

.select2-container--default
  &.select2-container--focus .select2-selection--multiple::-ms-input-placeholder, .select2-selection--multiple::-ms-input-placeholder, .select2-search--dropdown .select2-search__field::-ms-input-placeholder
    opacity: 1
    color: #adb5bd

.select2-container .select2-selection--single::placeholder
  opacity: 1
  color: #adb5bd

.select2-container--default
  &.select2-container--focus .select2-selection--multiple::placeholder, .select2-selection--multiple::placeholder, .select2-search--dropdown .select2-search__field::placeholder
    opacity: 1
    color: #adb5bd

.select2-container .select2-selection--single
  &:disabled, &[readonly]
    opacity: 1
    background-color: #e9ecef

.select2-container--default
  &.select2-container--focus .select2-selection--multiple
    &:disabled, &[readonly]
      opacity: 1
      background-color: #e9ecef

  .select2-selection--multiple
    &:disabled, &[readonly]
      opacity: 1
      background-color: #e9ecef

  .select2-search--dropdown .select2-search__field
    &:disabled, &[readonly]
      opacity: 1
      background-color: #e9ecef

.select2-container .select2-selection--single .select2-selection__rendered
  overflow: inherit
  padding: 0
  white-space: inherit
  text-overflow: inherit

.select2-container--default .select2-selection--single .select2-selection__rendered
  line-height: inherit
  color: inherit

.select2-dropdown
  padding: .5rem 0
  border: 1px solid #dee2e6
  border-radius: .4375rem
  background-color: #fff

.select2-results__option
  font-size: .875rem
  padding: .5rem .75rem
  color: #212529
  background-color: #fff

  &:hover, &:focus
    text-decoration: none
    color: #16181b
    background-color: #f6f9fc

.select2-container--default
  .select2-results__option--highlighted[aria-selected]
    color: #16181b
    background-color: transparent

  .select2-results__option
    &[aria-selected='true']
      color: #16181b
      background-color: transparent

    &[aria-disabled=true]
      color: #8898aa

  &.select2-container--focus .select2-selection--multiple
    height: auto
    min-height: calc(1.5em + 1.25rem + 2px)

  .select2-selection--multiple
    height: auto
    min-height: calc(1.5em + 1.25rem + 2px)

    .select2-selection__rendered
      display: inline-flex
      margin: 0 0 -.25rem -.25rem
      padding: 0

    .select2-selection__choice
      font-size: .875rem
      line-height: 1.5rem
      display: inline-flex
      margin: 0 0 .25rem .25rem
      padding: 0 .5rem
      color: #8898aa
      border: none
      border-radius: .25rem
      background-color: #adb5bd

    .select2-selection__choice__remove
      margin-left: .5rem
      color: #8898aa
      order: 2

      &:hover
        color: #525f7f

.select2-container .select2-search--inline
/* display: none;

.select2-selection[aria-expanded='true']
  border-bottom-right-radius: 0 !important
  border-bottom-left-radius: 0 !important

.select2-search--dropdown
  padding: .5rem .75rem

.select2-container--default .select2-search--dropdown .select2-search__field
  font-size: .875rem
  line-height: 1.5
  padding: .25rem .5rem
  border-radius: .25rem

.form-control-sm +
.select2-container .select2-selection--single
  font-size: .875rem
  line-height: 1.5
  height: calc(1.5em + .5rem + 2px)
  padding: .25rem .5rem
  border-radius: .25rem

  .select2-container--default
    &.select2-container--focus .select2-selection--multiple, .select2-selection--multiple
      font-size: .875rem
      line-height: 1.5
      height: calc(1.5em + .5rem + 2px)
      padding: .25rem .5rem
      border-radius: .25rem

    &.select2-container--focus .select2-selection--multiple
      min-height: calc(1.5em + .5rem + 2px)

    .select2-selection--multiple
      min-height: calc(1.5em + .5rem + 2px)

      .select2-selection__choice
        line-height: 1.3125rem

.form-control-lg +
.select2-container .select2-selection--single
  font-size: 1.25rem
  line-height: 1.5
  height: calc(1.5em + 1.75rem + 2px)
  padding: .875rem 1rem
  border-radius: .4375rem

  .select2-container--default
    &.select2-container--focus .select2-selection--multiple, .select2-selection--multiple
      font-size: 1.25rem
      line-height: 1.5
      height: calc(1.5em + 1.75rem + 2px)
      padding: .875rem 1rem
      border-radius: .4375rem

    &.select2-container--focus .select2-selection--multiple
      min-height: calc(1.5em + 1.75rem + 2px)

    .select2-selection--multiple
      min-height: calc(1.5em + 1.75rem + 2px)

      .select2-selection__choice
        line-height: 1.875rem

.swal2-popup
  padding: 1.5rem

  .swal2-title
    font-size: 1.5rem

  .swal2-content
    font-size: .875rem

  .swal2-image
    max-width: 200px

.sr-only
  position: absolute
  overflow: hidden
  clip: rect(0, 0, 0, 0)
  width: 1px
  height: 1px
  margin: -1px
  padding: 0
  border: 0

.bootstrap-datetimepicker-widget
  .btn
    &[data-action='incrementHours']::after, &[data-action='incrementMinutes']::after, &[data-action='decrementHours']::after, &[data-action='decrementMinutes']::after, &[data-action='showHours']::after, &[data-action='showMinutes']::after, &[data-action='togglePeriod']::after, &[data-action='clear']::after, &[data-action='today']::after
      position: absolute
      overflow: hidden
      clip: rect(0, 0, 0, 0)
      width: 1px
      height: 1px
      margin: -1px
      padding: 0
      border: 0

  .picker-switch::after
    position: absolute
    overflow: hidden
    clip: rect(0, 0, 0, 0)
    width: 1px
    height: 1px
    margin: -1px
    padding: 0
    border: 0

  table th
    &.prev::after, &.next::after
      position: absolute
      overflow: hidden
      clip: rect(0, 0, 0, 0)
      width: 1px
      height: 1px
      margin: -1px
      padding: 0
      border: 0

  list-style: none

  a .btn:hover
    background-color: transparent

  &.dropdown-menu
    width: 254px
    max-width: 254px
    padding: 8px 6px

    .tim-icons
      opacity: 1

    .picker-switch .table-condensed:hover
      border-radius: 3px
      background: #eee

    .timepicker-picker .table-condensed
      margin-top: 10px
      margin-bottom: 5px

      .btn[data-action='togglePeriod']:hover, .separator
        color: #9a9a9a !important

    .table-condensed
      .month, .year, .decade
        color: #9a9a9a

      .month.active, .year.active, .decade.active
        color: #fff

    &.bottom:before
      position: absolute
      right: auto
      display: inline-block
      width: 0
      height: 0
      content: ''
      vertical-align: middle
      border-right: .4em solid transparent
      border-bottom: .4em solid
      border-left: .4em solid transparent

    &.top
      &:before
        display: none

      &:after
        position: absolute
        top: auto
        right: auto
        bottom: -6px
        left: 10px
        display: inline-block
        width: 0
        height: 0
        content: ''
        vertical-align: middle
        color: #fff
        border-top: .4em solid
        border-right: .4em solid transparent
        border-left: .4em solid transparent

      margin-top: auto

      &.open
        margin-top: auto

    &.pull-right
      &:before
        right: 6px
        left: auto

      &:after
        right: 7px
        left: auto

  .list-unstyled
    margin: 0

  a
    &[data-action]
      padding: 0
      color: #fff
      border-width: 0

    &[data-action='togglePicker']
      color: #5e72e4

      &:hover
        color: #5e72e4

    &[data-action]:active
      box-shadow: none

  .timepicker-hour, .timepicker-minute, .timepicker-second
    font-size: 1.5em
    font-weight: 300
    line-height: 40px
    width: 40px
    height: 40px
    margin: 3px
    color: #9a9a9a
    border-radius: 50%

  button[data-action]
    width: 38px
    height: 38px
    padding: 0
    box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.2)

  .btn
    margin: 0 !important

    &[data-action='incrementHours']::after
      content: 'Increment Hours'

    &[data-action='incrementMinutes']::after
      content: 'Increment Minutes'

    &[data-action='decrementHours']::after
      content: 'Decrement Hours'

    &[data-action='decrementMinutes']::after
      content: 'Decrement Minutes'

    &[data-action='showHours']::after
      content: 'Show Hours'

    &[data-action='showMinutes']::after
      content: 'Show Minutes'

    &[data-action='togglePeriod']::after
      content: 'Toggle AM/PM'

    &[data-action='clear']::after
      content: 'Clear the picker'

    &[data-action='today']::after
      content: 'Set the date to today'

  .picker-switch
    text-align: center
    color: #5e72e4
    border-radius: 3px

    &::after
      content: 'Toggle Date and Time Screens'

    td
      line-height: inherit
      width: auto
      height: auto
      margin: 0
      padding: 0

      span
        line-height: 2.5
        width: 100%
        height: 2.5em
        margin: 2px 0 !important
        border-radius: 3px

  table
    width: 100%
    margin: 0
    text-align: center

    td > div
      text-align: center

    th
      > div
        text-align: center

      font-weight: 300
      line-height: 20px
      width: 20px
      height: 20px

      &.picker-switch
        width: 145px

      &.disabled
        cursor: not-allowed
        color: #cfcfca
        background: none

        &:hover
          cursor: not-allowed
          color: #cfcfca
          background: none

      &.prev span, &.next span
        font-size: 12px
        line-height: 28px
        width: 27px
        height: 27px
        text-align: center
        color: #5e72e4
        border-radius: 4px
        border-radius: 50%

      &.prev::after
        content: 'Previous Month'

      &.next::after
        content: 'Next Month'

      &.dow
        padding-top: 10px
        padding-bottom: 5px
        text-align: center
        color: #5e72e4

    thead tr:first-child th
      cursor: pointer

      &:hover span, &.picker-switch:hover
        background: #eee

    td
      &.cw > div
        font-size: .8em
        line-height: 20px
        height: 20px
        color: #cfcfca

      &.day > div, &.minute > div, &.hour > div
        font-size: 14px
        font-weight: 300
        line-height: 2.2
        position: relative
        z-index: -1
        width: 30px
        height: 30px
        margin: 0 auto
        padding: 0
        cursor: pointer
        transition: all 300ms ease 0s
        text-align: center
        color: #9a9a9a
        border: none
        border-radius: 50%

      &.day:hover > div, &.hour:hover > div, &.minute:hover > div, &.second:hover > div
        cursor: pointer
        background: #eee

      &.old > div, &.new > div
        color: #172b4d

      &.today, &.day
        background: transparent !important

      &.today > div:before
        position: absolute
        right: 4px
        bottom: 4px
        display: inline-block
        content: ''
        border: 0 0 7px 7px solid transparent
        border-top-color: rgba(0, 0, 0, 0.2)
        border-bottom-color: #68b3c8

      &.active
        > div, &:hover > div
          color: #fff
          background-color: #5e72e4
          box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.2)

        &.today:before > div
          border-bottom-color: #fff

      &.disabled
        > div, &:hover > div
          cursor: not-allowed
          color: #cfcfca
          background: none

      span
        line-height: 40px
        display: inline-block
        width: 40px
        height: 40px
        margin: 0 3px
        cursor: pointer
        text-align: center
        border-radius: 50%

        &.active
          color: #fff
          background-color: #5e72e4

        &.old
          color: #cfcfca

        &.disabled
          cursor: not-allowed
          color: #cfcfca
          background: none

          &:hover
            cursor: not-allowed
            color: #cfcfca
            background: none

  .timepicker-picker span, .timepicker-hours span, .timepicker-minutes span
    border-radius: 50% !important

  &.usetwentyfour td.hour
    line-height: 27px
    height: 27px

@media (min-width: 768px)
  .bootstrap-datetimepicker-widget.dropdown-menu.timepicker-sbs
    width: 38em

@media (min-width: 992px)
  .bootstrap-datetimepicker-widget.dropdown-menu.timepicker-sbs
    width: 38em

@media (min-width: 1200px)
  .bootstrap-datetimepicker-widget.dropdown-menu.timepicker-sbs
    width: 38em

.input-group.date .input-group-addon
  cursor: pointer

.table-condensed >
tbody > tr >
td, th
  z-index: 1
  padding: 1px
  cursor: pointer
  text-align: center

  tfoot > tr >
  td, th
    z-index: 1
    padding: 1px
    cursor: pointer
    text-align: center

  thead > tr >
  td, th
    z-index: 1
    padding: 1px
    cursor: pointer
    text-align: center

.bootstrap-datetimepicker-widget .separator
  position: relative
  width: auto
  height: auto
  color: red

input.datetimepicker
  &[data-color] + .bootstrap-datetimepicker-widget
    .picker-switch
      color: #9a9a9a

    table
      th
        &.prev span, &.next span
          color: #9a9a9a

      td.day > div
        color: #9a9a9a

    a[data-action='togglePicker']
      color: #9a9a9a

      &:hover
        color: #9a9a9a

    span, .timepicker-hours span, .timepicker-minutes span, .separator
      color: #9a9a9a

    table
      td
        &.minute > div, &.hour > div
          color: #9a9a9a

      th.dow
        color: rgba(255, 255, 255, 0.8)

      td
        &.old > div, &.new > div
          color: rgba(255, 255, 255, 0.4)

    button[data-action]
      background-color: #fff

    table
      td
        &.active
          > div, &:hover > div
            background-color: #fff

        &:not(.active).day:hover > div, &.hour:hover > div, &.minute:hover > div, &.second:hover > div, span:hover
          background: rgba(255, 255, 255, 0.2)

      thead tr:first-child th
        &:hover span, &.picker-switch:hover
          background-color: rgba(255, 255, 255, 0.2)

  &[data-color='orange'] + .bootstrap-datetimepicker-widget
    background-color: #5e72e4

    table td.active
      > div, &:hover > div
        color: #5e72e4

    button[data-action]
      color: #5e72e4

    &.dropdown-menu
      &.top:after, &.bottom:before
        color: #5e72e4

  &[data-color='blue'] + .bootstrap-datetimepicker-widget
    background-color: #11cdef

    table td.active
      > div, &:hover > div
        color: #11cdef

    button[data-action]
      color: #11cdef

    &.dropdown-menu
      &.top:after, &.bottom:before
        color: #11cdef

  &[data-color='green'] + .bootstrap-datetimepicker-widget
    background-color: #2dce89

    table td.active
      > div, &:hover > div
        color: #2dce89

    button[data-action]
      color: #2dce89

    &.dropdown-menu
      &.top:after, &.bottom:before
        color: #2dce89

  &[data-color='red'] + .bootstrap-datetimepicker-widget
    background-color: #f5365c

    table td.active
      > div, &:hover > div
        color: #f5365c

    button[data-action]
      color: #f5365c

    &.dropdown-menu
      &.top:after, &.bottom:before
        color: #f5365c

  &[data-color='yellow'] + .bootstrap-datetimepicker-widget
    background-color: #fb6340

    table td.active
      > div, &:hover > div
        color: #fb6340

    button[data-action]
      color: #fb6340

    &.dropdown-menu
      &.top:after, &.bottom:before
        color: #fb6340

.bootstrap-datetimepicker-widget.dropdown-black
  .datepicker-days
    .day, .minute
      color: rgba(255, 255, 255, 0.7)

    .day:hover, .minute:hover
      color: #212529

  table td
    span, &.minute, &.hour
      color: rgba(255, 255, 255, 0.7)

    span:hover, &.minute:hover, &.hour:hover
      color: rgba(255, 255, 255, 0.4)
      background: transparent

    span:hover div, &.minute:hover div, &.hour:hover div
      background: transparent

    .btn
      &[data-action]
        color: #fff

      &[data-action='togglePeriod']
        background: transparent

.row-eq-height
  display: -webkit-box
  display: -webkit-flex
  display: -ms-flexbox
  display: flex

.img-login
  width: 100%
  height: 100%
  object-fit: cover
  object-position: right

.login-form
  position: relative
  top: 0
  bottom: 0
  margin-top: auto
  margin-bottom: auto
  width: 70%
  left: 0
  right: 0
  margin-left: auto
  margin-right: auto
  height: max-content
  padding: 60px 0px

  input
    width: 100%
    height: 100%

  img
    margin: 50px auto
    display: block

.login-ab
  position: absolute
  padding: 0 !important

.img-intro
  position: relative
  width: 100%
  height: 100%
  z-index: 1
  background-size: cover !important
  background-position: right !important

  &:before
    content: ''
    position: absolute
    width: 100%
    height: 100%
    top: 0
    left: 0
    background: linear-gradient(45deg, #35324ea1, #9f4ca05c)
    z-index: -1

.intro-login-text
  position: absolute
  top: 0
  bottom: 0
  margin-top: auto
  margin-bottom: auto
  height: max-content
  left: 6em
  width: max-content

  h1
    color: white
    font-size: 60px

    margin-bottom: 10px
    border-bottom: 3px solid #fff

  h6
    font-size: 35px
    color: white


.login-dim a
  background: #e0deef
  color: #000 !important
  text-align: center
  padding: 10px 11px
  border-radius: 30px
  text-transform: uppercase
  font-size: 11px
  font-weight: 600
  width: 50%
  margin: 0 auto

a.crea-account
  background:#000
  color: #fff !important
  text-align: center
  padding: 20px 11px
  text-transform: uppercase
  font-size: 13px
  font-weight: 700
  width: 100%
  margin: 0 auto
  display: block
  font-style: italic
  position: absolute
  bottom: 0
  text-shadow: 0 0 18px #4536ab

.login-dim
  display: flex
  width: 100%

.accedi
  width: 100%
  border-radius: 30px
  padding: 15px 20px
  text-transform: uppercase
  font-size: 12px
  float: revert
  background: #031d6d
  border: #031d6d
  color: white
  margin: 20px 0

  &:hover
    color: #fff

  &:not(:disabled):not(.disabled):active
    background: #dee1e9
    border: none

  &:focus
    background: #031d6d
    border: none

.card.card-colored
  padding: 30px
  z-index: 1
  position: relative
  box-shadow: 0 0 15px rgb(195 195 195 / 30%)
  border-radius: .375rem
  overflow: hidden

.card-colored:before
  content: ''
  position: absolute
  top: 0
  height: 180px
  background: #000
  width: 100%
  left: 0
  z-index: -1

.card.card-colored h1
  font-weight: 800
  padding: 20px 0 20px
  font-style: italic
  color: white !important

.card-colored h5

  font-size: 16px

.height-box
  height: 120px

.box
  &:before
    content: ''
    position: absolute
    bottom: 0
    width: 100%
    background: #E94277
    left: 0

  box-shadow: 0 0 15px rgb(179 179 179 / 30%)
  padding: 20px
  background: #fff
  position: relative
  margin-bottom: 20px
  border-radius: 10px

  p
    font-weight: 500
    margin-bottom: 8px

  h5
    font-weight: 500 !important
    color: #000
    font-size: 13px
    text-transform: uppercase


  i
    margin-right: 10px
    font-size: 15px

.container-box
  margin-top: 20px


.table-user
  i
    font-size: 15px
    margin: 0
    float: right

  p
    margin-bottom: 0

    span
      color: #AE2A73
      margin-right: 5px
      text-transform: uppercase
      font-weight: 600


.utente-img h4
  font-weight: 800
  text-transform: uppercase
  color: black

.logo-cont
  padding: 20px 0

.logo-cont.logo-header
  display: none


.card-play
  padding: 0
  display: inline-block
  width: 100%

.box-play
  background: #f2f5f8
  padding: 50px 20px
  text-align: center
  border-radius: 5px

  i
    font-size: 60px
    margin-bottom: 20px
    color: #c981bd
    background: #AE2A73
    -webkit-background-clip: text
    -webkit-text-fill-color: transparent

  h4
    text-transform: uppercase
    font-size: 24px

    color: black

.faq-link
  padding: 10px 20px 0

  p
    font-weight: 600
    border-bottom: 2px solid #e8d1e0
    padding-bottom: 10px
    font-weight: 600
    border-bottom: 2px solid #e8d1e0
    padding-bottom: 10px

  padding: 20px 20px 10px


  ul
    list-style: none
    padding: 0

    li
      padding-bottom: 5px
      font-weight: 500

/* display: inline-flex;
/* width: 49%;

.info-tabella
  h4
    margin: 0 10px

    /* float: right;
    font-size: 12px
    padding: 5px 15px

  display: inline-flex
  margin-bottom: 20px
  justify-content: flex-end

.active-t
  background: #7d73a6
  padding: 5px 15px
  border-radius: 30px
  color: #fff

.card-body-scelta
  position: absolute
  top: 0
  bottom: 0
  margin-top: auto
  margin-bottom: auto
  width: 70%
  left: 0
  right: 0
  margin-left: auto
  margin-right: auto
  height: max-content
  background: white
  padding: 100px 50px
  border-radius: 20px
  text-align: center
  box-shadow: 0 0 15px rgb(82 79 113 / 30%)

  a
    display: block
    background: #000
    padding: 13px 40px
    margin: 15px auto
    border-radius: 5px
    color: #ffffff
    font-weight: 600
    width: 320px
    max-width: 100%


a.back-login
  background: none !important
  font-size: 13px
  color: #000 !important
  border-bottom: 2px solid #ccc
  border-radius: 0
  padding: 10px 0
  width: max-content

.card-body-scelta
  img
    margin-bottom: 40px

  a i
    margin-right: 12px
    vertical-align: middle
    font-size: 14px

.form-group
  position: relative

  input
    padding-left: 40px

  i
    position: absolute
    top: 17px
    left: 14px
    font-size: 14px

    color:#000

input:-webkit-autofill
  transition: background-color 5000s ease-in-out 0s

  &:hover, &:focus, &:active
    transition: background-color 5000s ease-in-out 0s

.height-100vh
  height: 100vh

.select2-container--default
  .select2-selection--single
    height: 45px

  .select2-selection--multiple .select2-selection__choice__remove
    &:hover, &:focus
      margin: 0 !important

    margin: 0 !important

.errore-input:before
  background: #e05179
  content: ''
  position: absolute
  top: 0
  width: 50px
  height: 100%
  z-index: 1
  right: 0
  clip-path: polygon(100% 0, 0% 100%, 100% 100%)
  border-top-right-radius: 4px
  border-bottom-right-radius: 4px

.check-error:before
  border: 2px solid #f37497 !important

.select2-container
  z-index: 9999999 !important

.img-org img
  width: 100%
  box-shadow: 0 4px 20px rgb(127 127 175 / 28%), 0 1px 4px rgb(171 165 218)
  border-radius: 10px
  margin-bottom: 20px
  max-height: 190px
  object-fit: cover
  background: white

.org-name
  h4
    &:before
      content: ''
      position: absolute
      bottom: 0px
      width: 105%
      height: 12px
      background: #e7f1fb
      z-index: -1
      left: -4px

    position: relative
    margin-bottom: 15px
    z-index: 1
    margin-top: 10px
    font-size: 18px
    width: max-content


  p
    color: #302d33

    font-size: 16px

  i
    margin-left: 15px
    color: #aaa0d1
    cursor: pointer

.edit-img-org
  i
    position: absolute
    bottom: 20px
    left: 0px
    background: black
    width: 40px
    height: 40px
    padding: 14px 15px 7px 9px
    color: white
    border-bottom-left-radius: 10px
    border-top-right-radius: 30px
    text-align: center

  position: relative
  cursor: pointer

.img-org
  position: relative
  z-index: 1
  max-width: 200px
  margin: 0 auto
  display: block

  &:before
    content: ''
    position: absolute
    top: -7px
    width: 100%
    height: 100%
    z-index: -1
    transform: rotate(-18deg)
    border-radius: 10px
    left: -1px
    background: -webkit-linear-gradient(to right, rgb(253 185 155 / 40%), rgb(218 176 241 / 40%), rgb(207 195 234 / 40%))
    background: linear-gradient(to right, rgb(253 185 155 / 40%), rgb(218 176 241 / 40%), rgb(207 195 234 / 40%))

.tabella-negozi img
  border-radius: 50%
  width: 50px
  height: 50px
  object-fit: cover

.modifica-img
  position: absolute
  left: 110px
  background: white
  font-size: 15px !important
  padding: 7px
  border-radius: 30px
  top: 30px
  cursor: pointer


button.btn.btn-primary.btn-modal
  padding: 0

button.btn.btn-psw
  height: 25px
  padding: 0
  border-radius: 4px
  color: #000
  text-align: center

button.btn.btn-psw i
  margin: 0



.aggiungi-prof
  padding: 10px 20px
  font-weight: 500
  margin-bottom: 30px
  text-transform: uppercase


.modal-content
  input , select
    margin-bottom: 20px
    box-shadow: none

  label
    margin-bottom: 3px
    font-weight: 500
    text-transform: uppercase
    font-size: 11px
    padding-left: 5px

.border-bottom-ab
  position: relative
  padding: 10px 0 !important

  &:before
    content: ''
    position: absolute
    bottom: 0
    width: 200px
    height: 2px
    background: #ffffff !important

.table-elenco-r
  img
    width: 30px
    height: 30px
    object-fit: cover
    border-radius: 30px

  thead
    th, td
      border: none

    background: #fff
    border: none
    color: black

.italic
  font-style: italic !important

.bg-big
  &:before
    content: ''
    position: absolute
    left: -15px
    width: 103%
    height: 100%
    background: #e6dff7
    top: 0
    right: 0
    z-index: -1

  background: #e6dff7
  margin: 30px 0
  padding: 20px 10px

.btn-toggle
  margin: 0
  padding: 0
  position: relative
  border: none
  height: 1.5rem
  width: 3rem
  border-radius: 1.5rem
  color: #6b7381
  background: #d4d4d4

  &:focus, &.focus, &:focus.active, &.focus.active
    outline: none

  &:before, &:after
    line-height: 1.5rem
    width: 4rem
    text-align: center
    font-weight: 600
    font-size: 0.75rem
    text-transform: uppercase
    letter-spacing: 2px
    position: absolute
    bottom: 0
    transition: opacity 0.25s

  > .handle
    position: absolute
    top: 0.1875rem
    left: 0.1875rem
    width: 1.125rem
    height: 1.125rem
    border-radius: 1.125rem
    background: #fff
    transition: left 0.25s

  &.active
    transition: background-color 0.25s

    > .handle
      left: 1.6875rem
      transition: left 0.25s

    &:before
      opacity: 0.5

    &:after
      opacity: 1

  &.btn-sm
    &:before, &:after
      line-height: -0.5rem
      color: #fff
      letter-spacing: 0.75px
      left: 0.4125rem
      width: 2.325rem

    &:before
      text-align: right

    &:after
      text-align: left
      opacity: 0

    &.active
      &:before
        opacity: 0

      &:after
        opacity: 1

  &.btn-xs
    &:before, &:after
      display: none

  &:before, &:after
    color: #6b7381

  &.active
    background: #031d6d

.button-scelta
  display: inline-flex
  margin: 5px

  button
    font-size: 11px !important
    text-transform: uppercase
    padding: 10px 40px
    font-weight: 500
    margin: 2px


button.btn
  &.scelta-1
    background: #2f2e33

  &.scelta-2
    background: #e94176





li.nav-item.li-active
  position: relative
  background: #f3f5f8

.li-active a:before
  content: ''
  position: absolute
  top: 0
  left: -40px
  width: 10px
  height: 100%
  background: #000
  border-radius: 50px




@media (max-width: 1199px)
  .temp-emailbox
    position: relative !important
    top: auto !important
    right: auto !important
    margin: 0px 0px 30px
    width: 100%

    .input-box-col
      input
        &[type="text"], &[type="email"]
          min-width: 190px !important

      .emailbox-input
        min-width: 190px !important

.temp-emailbox .btn-rds span
  display: none

#tm-body .temp-emailbox h2
  font-size: 20px !important
  color: #fff !important
  line-height: 100% !important
  margin-top: 0 !important
  padding-top: 0
  margin-bottom: 25px !important

.temp-emailbox-form
  display: -webkit-box
  display: -webkit-flex
  display: -ms-flexbox
  display: flex
  -webkit-box-pack: center
  -webkit-justify-content: center
  -ms-flex-pack: center
  justify-content: center
  -webkit-box-align: center
  -webkit-align-items: center
  -ms-flex-align: center
  align-items: center
  background: #eef0f3
  border-radius: 40px
  padding: 0
  width: fit-content
  padding: 0 5px 0px 0px

.temp-emailbox .input-box-col
  /* padding: 0 5px;
  display: -webkit-box
  display: -webkit-flex
  display: -ms-flexbox
  display: flex
  -webkit-box-pack: center
  -webkit-justify-content: center
  -ms-flex-pack: center
  justify-content: center
  -webkit-box-align: center
  -webkit-align-items: center
  -ms-flex-align: center
  align-items: center

  .input-warp
    padding: 6px
    background-color: rgb(255 255 255)
    border-radius: 30px
    height: 55px
    display: -webkit-box
    display: -webkit-flex
    display: -ms-flexbox
    display: flex
    -webkit-box-pack: center
    -webkit-justify-content: center
    -ms-flex-pack: center
    justify-content: center
    -webkit-box-align: center
    -webkit-align-items: center
    -ms-flex-align: center
    align-items: center
    position: relative
    width: 100%
    margin-right: 4px
    position: a
    left: 0

  input
    &[type="text"], &[type="email"]
      border: 0
      background: transparent
      margin: 0
      padding: 10px 15px
      font-size: 14px !important
      font-weight: 500
      color: #232121
      height: 52px
      min-width: 300px

  .emailbox-input
    border: 0
    background: transparent
    margin: 0
    padding: 10px 15px
    font-size: 14px !important
    font-weight: 500
    color: #232121
    height: 52px
    min-width: 300px

  input
    &[type="text"]:focus, &[type="email"]:focus
      outline: none

  .emailbox-input:focus
    outline: none

.temp-emailbox-text
  text-align: center
  padding-top: 30px
  color: #7A7C80
  font-size: 14px
  font-weight: 500

  p
    color: #7A7C80
    font-size: 14px !important
    font-weight: 500 !important
    padding: 0
    margin: 0

.icon-btn
  width: 42px
  height: 42px
  text-align: center
  border: 0
  padding: 0
  margin: 0
  border-radius: 50%
  background: #eef0f3


button.btn-rds.icon-btn
  &.btn-l-gary.popover-qr i
    font-size: 17px
    vertical-align: text-bottom
    color: #000

  &.bg-theme.click-to-copy.copyIconGreenBtn i
    font-size: 17px
    vertical-align: text-bottom
    color: var(--white)

.btn-rds
  &:focus
    outline: none

  cursor: pointer

button.btn.btn-social
  background: #ffffff24
  padding: 10px 20px
  color: #fff
  text-transform: uppercase
  font-size: 12px
  font-weight: 500
  border-radius: 10px
  margin: 0 5px

  img
    vertical-align: bottom
    margin-right: 6px

.temp-emailbox
  position: absolute
  top: 40px
  right: 30px

.details
  padding-top: 20px
  display: block

.product-title, .price, .sizes, .colors
  text-transform: uppercase
  font-weight: bold

.checked, .price span
  color: #ff9f1a

.product-title, .rating, .product-description, .price, .vote, .sizes
  margin-bottom: 15px

.product-title
  margin-top: 0

.size
  margin-right: 10px

  &:first-of-type
    margin-left: 40px

.color
  display: inline-block
  vertical-align: middle
  margin-right: 10px
  height: 2em
  width: 2em
  border-radius: 2px

  &:first-of-type
    margin-left: 30px

.orange
  background: #ff9f1a

.green
  background: #85ad00

.blue
  background: #0076ad

.tooltip-inner
  padding: 1.3em

@-webkit-keyframes opacity
  0%
    opacity: 0
    -webkit-transform: scale(3)
    transform: scale(3)

  100%
    opacity: 1
    -webkit-transform: scale(1)
    transform: scale(1)

@keyframes opacity
  0%
    opacity: 0
    -webkit-transform: scale(3)
    transform: scale(3)

  100%
    opacity: 1
    -webkit-transform: scale(1)
    transform: scale(1)

.main-img img
  width: 100%
  max-height: 300px
  object-fit: contain

.imgs
  img
    width: 100%
    max-height: 300px
    object-fit: contain
    cursor: pointer
    max-height: 70px
    object-fit: contain
    border-radius: 3px

  display: grid
  grid-template-columns: repeat(5, 1fr)
  grid-gap: 5px
  margin-top: 10px

/* Fade in animation
@keyframes fadeIn
  to
    opacity: 1

.fade-in
  opacity: 0
  animation: fadeIn var(--fade-time) ease-in 1 forwards

.price span
  margin-left: 32px
  text-transform: initial
  color: #8282ad

.tabella-prezzo
  color: #34363c

  th
    padding: 0

    width: 160px

  td
    font-weight: 500

.button-mag
  select, input
    margin-bottom: 15px

  margin-top: 60px !important

.img-mag
  height: 100%

  /* display: flex;


  img
    object-fit: cover
    height: 220px

ul.ul-mag
  list-style: none
  padding-left: 20px
  margin-bottom: 10px
  display: inline-block

  li
    position: relative

    &:before
      content: ''
      position: absolute
      left: -15px
      bottom: 8px
      width: 5px
      height: 5px
      background: #543885
      border-radius: 50%

.prod-mag
  background: #ffffff
  padding: 20px
  margin-bottom: 30px
  border-radius: 20px

  /* border: 2px solid #e4e4e4;
  box-shadow: 0 0 10px #dedede
  position: relative

.info-prod
  position: absolute
  top: 5px
  right: 30px
  display: block

.btn-acq
  width: 45%
  margin: 20px 5px 0
  text-transform: uppercase

.tableIntable
  background: #f0f0f7
  border-radius: 10px
  overflow: hidden
  margin: 10px 0px

.panel-group a
  &:focus, &:hover, &:active
    outline: 0
    text-decoration: none

.panel
  box-shadow: none

  &:last-child
    border-bottom: none

.panel-group
  > .panel:first-child .panel-heading
    border-radius: 4px 4px 0 0

  .panel
    border-radius: 0
    margin-bottom: 10px

    + .panel
      margin-top: 0

.panel-heading
  color: #33323a
  padding: 0
  border: 1px solid

.panel-title a
  display: block
  color: #36373e
  padding: 10px
  position: relative
  font-size: 13px
  font-weight: 400
  font-style: italic


.panel-body
  background: #ffffff
  padding: 20px 20px
  border: 1px solid #000
  border-top: none

.panel:last-child
  .panel-body
    border-radius: 0 0 4px 4px

  .panel-heading
    border-radius: 0 0 4px 4px
    transition: border-radius 0.3s linear 0.2s

    &.active
      border-radius: 0
      transition: border-radius linear 0s

/* #bs-collapse icon scale option

.panel-heading
  a:before
    content: '\e146'
    position: absolute
    font-family: 'Material Icons'
    right: 5px
    top: 10px
    font-size: 24px
    transition: all 0.5s
    transform: scale(1)

  &.active a:before
    content: ' '
    transition: all 0.5s
    transform: scale(0)

#bs-collapse .panel-heading
  a:after
    content: ' '
    font-size: 24px
    position: absolute
    font-family: 'Material Icons'
    right: 5px
    top: 10px
    transform: scale(0)
    transition: all 0.5s

  &.active a:after
    content: '\e909'
    transform: scale(1)
    transition: all 0.5s

/* #accordion rotate icon option

#accordion .panel-heading
  a:before
    content: '\f077'
    font-size: 14px
    position: absolute
    right: 20px
    top: 9px
    transform: rotate(180deg)
    transition: all 0.5s
    font-family: "Font Awesome 5 Free"
    font-weight: 600

  &.active a:before
    transform: rotate(0deg)
    transition: all 0.5s

.wrap
  border-radius: 4px
  display: inline-block
  width: 100%

.panel h4
  margin: 0

.actionI
  display: flex
  margin-top: 30px
  gap: 6px

.actions-i
  background: #031d6d
  color: #fff
  padding: 10px
  border-radius: 3px
  width: 35px
  height: 35px
  font-size: 13px
  text-align: center
  line-height: 15px
  cursor: pointer



.panel-body p
  padding-bottom: 5px

  color: black
  border-bottom: 1px solid #e6e6e6
  margin-bottom: 7px

span.counter-i
  background: #AE2A73
  color: #fff
  width: 20px
  height: 20px
  display: block
  float: left
  border-radius: 3px
  text-align: center
  margin-right: 7px
  font-size: 12px
  line-height: 21px
  margin-top: 1px

.giac
  padding-right: 5px
  margin: 0 !important
  text-transform: uppercase
  font-weight: 500
  font-size: 16px

.first-level p:before
  content: ''
  position: absolute
  left: -20px
  top: -4px
  width: 140%
  height: 30px
  background: #031d6d
  z-index: -1
  border-top-right-radius: 3px
  border-bottom-right-radius: 3px


.first-level p
  width: max-content
  position: relative
  z-index: 0
  color: white !important


.info-col
  display: inline-block

.second-level
  padding: 20px
  border-radius: 5px
  width: 100%
  margin-bottom: 20px
  display: flex
  border: 1px solid

  img
    margin-bottom: 10px
    display: block
    width: 90px
    float: left
    margin-right: 13px

.third-level
  background: #fff
  margin-top: 40px
  padding: 20px
  border-radius: 20px

.details p
  margin-bottom: 3px
    color: #717171

.container-box b
  color: #000
  text-transform: uppercase
  font-weight: 600


.articolo-table
  background: #f9f9f9
  padding: 20px
  border-radius: 16px
  overflow: hidden
  padding: 20px
  border-radius: 16px
  overflow: hidden

button.btn.btn-primary.mod-art
  position: absolute
  top: -21px
  right: 30px
  padding: 10px 40px
  text-transform: uppercase

  background: black
  border: black
  font-size: 14px !important

.magg-dett
  i
    margin-left: 3px
    vertical-align: sub
    font-size: 13px
  a
    color: black

  padding: 4px 14px
  border-radius: 4px
  font-size: 11px
  text-transform: uppercase
  cursor: pointer
  border: 1px solid black
  color: black !important
  margin-left: 5px
  display: inline

  &.classe-brand
    background:#000

.box-level p
  margin-bottom: 3px


  color:#000

.main-img i
  position: absolute
  top: 0px
  margin: 0
  background: #031d6d
  padding: 5px
  border-radius: 3px
  left: 0
  cursor: pointer
  font-size: 12px
  color: #fff
  height: 25px
  width: 25px
  text-align: center
  line-height: 15px

.classe-brand .container-box b, .dettagli-arti.classe-brand p b
  color:#000

.classe-brand .container-box a
  color: var(--6)

.classe-retailer .container-box a
  color: #031d6d

.main-img
  position: relative

.modal-box
  position: relative

  i
    position: absolute
    bottom: -5px
    left: -3px
    margin: 0
    font-size: 12px
    background: #fff
    border-radius: 50%
    padding: 5px
    cursor: pointer

.select2-container .select2-selection--single
  box-sizing: border-box
  cursor: pointer
  display: block
  height: auto !important
  user-select: none
  -webkit-user-select: none
  font-size: 12px

span.select2-selection.select2-selection--single
  font-size: .875rem
  font-weight: 400
  line-height: 1.5
  display: block
  width: 100%
  padding: 10px
  transition: all 0.15s cubic-bezier(0.68, -0.55, 0.265, 1.55)
  color: #8898aa
  border: 1px solid #dee2e6
  border-radius: .25rem
  background-color: #ffffff
  background-clip: padding-box

  height: 45px !important

.select2-results__option
  font-size: 12px
  padding: .5rem .75rem
  color: #212529
  background-color: #fff
  font-weight: 500

button.btn.btn-mod-art
  position: absolute
  right: 0
  background: #031d6d
  top: 0
  border: white
  padding: 13px 40px 15px 50px
  text-transform: uppercase
  color: #ffffff
  font-weight: 500

  font-size: 14px !important
  border-top-right-radius: 0
  box-shadow: none
  border-top-left-radius: 0
  border-bottom-right-radius: 0
  border-bottom-left-radius: 80px

  i
    margin: 0
    font-size: 12px
    margin-left: 10px
    line-height: 15px

.elenco-cerca
  display: flex

  button
    width: 100%
    margin-right: 10px



.social-info
  display: flex
  padding: 5px
  border-bottom-left-radius: 20px
  justify-content: center
  gap: 10px

  i
    color: #000000
    font-size: 14px
    background: #eaeef2
    padding: 5px
    border-radius: 4px
    width: 30px
    height: 30px
    text-align: center
    line-height: 21px

.contenitore-var
  background: #f3f4f6
  padding: 20px 10px
  margin-bottom: 20px
  border-radius: 5px

.card-b-e-box
  box-shadow: 0 0 20px #afaff1
  background: #fff
  border-radius: 20px
  padding: 0
  margin-bottom: 30px

  margin-top: 20px

  button
    border-radius: 0
    width: 100%
    border-bottom-left-radius: 20px
    border-bottom-right-radius: 20px
    background: black
    border-top: 7px solid #abe8cf
    border-left: none
    border-right: none
    border-bottom: none

.card-b-e
  padding: 20px

  /* box-shadow: 0 0 20px #afaff1;
  border-radius: 20px

  /* background: white;
  position: relative
  height: 200px

  img
    width: 50px

    /* position: absolute;
    /* top: -10px;
    border-radius: 50%
    margin-top: -40px !important
    background: white
    padding: 7px

    /* margin: 0 auto;
    display: block

  h4


  p
    a
      color: #565656

    i
      font-size: 15px
      margin-right: 8px
      color: #543885

    margin-bottom: 11px
    border-bottom: 1px solid #e0e0e0
    padding-bottom: 8px

.modal-footer button
  &.btn.btn-link
    background: #efefef
    color: #031d6d


  text-transform: uppercase

.button-flex
  display: flex
  width: 40%


.button-flex
  button
    width: 100%
    margin: 0 0px
    border-radius: 0
    text-transform: uppercase

    color: #000
    box-shadow: none

  i
    margin-right: 5px
    font-size: 14px
    color: #fff
    vertical-align: text-bottom


  .btn2
    background: #fff
    color: #031d6d
    font-weight: 600
    border: 1px solid #000
    box-shadow: none
    i
      color: #000

.btn2:hover
  background: #fff
  color: #000 !important
  border: 1px solid #000

.btn3
  background: #031d6d
  border: #031d6d
  color: #fff !important
  i
    margin-right: 5px
    font-size: 14px
    color: #fff
    vertical-align: text-bottom


.btn1
  background: #031d6d
  border: 1px solid #031d6d
  color: #fff !important

.btn1:hover , .btn3:hover
  background: #000
  color: #fff !important


.button-flex
  button:hover
    box-shadow: none
    color: inherit

    i
      box-shadow: none
      color: inherit
      opacity: .9


/* BOX LUNGO

.card-b-e-box-
  img
    width: 30px
    margin-right: 8px

  background: #f9f9f9
  padding: 15px
  border-radius: 3px
  margin-bottom: 20px

  p
    margin-bottom: 10px
    padding-bottom: 5px

.button-card-b-e-
  position: absolute
  bottom: -15px
  right: 0px
  background: #031d6d
  border: #031d6d
  color: white
  text-transform: uppercase
  top: -15px
  width: 40px
  height: 137%
  padding: 0
  border-radius: 0

.card-b-e-box- button
  border: #AE2A73
  background: #AE2A73
  display: block
  width: -webkit-max-content
  color: #fff
  position: absolute
  right: 0
  bottom: -15px
  border-radius: 0
  border-top-left-radius: 30px
  padding: 10px 40px
  width: 180px

  &:hover
    color: #fff

.card-b-e-
  a
    color: #000



  p i
    font-size: 15px
    margin-right: 8px
    color: #000000
    vertical-align: sub

    &.far.fa-envelope-open
      margin-left: 10px



/* FINE BOX LUNGO

.card-b-e-box-mod
  background: #ffffff
  padding: 15px
  margin: 20px 0 0
  border-radius: 20px
  box-shadow: 0 0 15px #dadada

  img
    width: 30px
    display: block
    margin-bottom: 5px

.sidebar
  height: 100%
  width: 0
  position: fixed
  z-index: 1
  top: 0
  right: 0
  background-color: #fff
  overflow-x: hidden
  transition: 0.5s
  box-shadow: 4px 0 14px #717171
  padding: 50px 0px 20px

  a
    padding: 0px 15px 0px 0px
    text-decoration: none
    font-size: 25px
    color: #818181
    display: block
    transition: 0.3s
    float: left

    &:hover
      color: #f1f1f1

  .closebtn
    position: absolute
    top: 10px
    right: 35px
    font-size: 22px
    margin-left: 50px
    color: #000000
    background: #f1f1f1
    text-align: center
    padding: 10px
    width: 40px
    height: 40px
    border-radius: 5px
    line-height: 15px

.openbtn
  font-size: 20px
  cursor: pointer
  background-color: #111
  color: white
  padding: 10px 15px
  border: none

  &:hover
    background-color: #444

.agg-mag
  position: absolute
  right: 0
  background: #fff
  top: 0
  border: white
  padding: 10px 40px 10px 40px
  text-transform: uppercase
  color: #000
  font-weight: 500
  border-bottom-left-radius: 5px
  box-shadow: none

  &:hover
    background: #031d6d
    color: #fff

.tooltipp
  position: relative
  display: inline-block

.tooltip-top::after
  content: ""
  position: absolute
  top: 100%
  left: 50%
  margin-left: -5px
  border-width: 5px
  border-style: solid
  border-color: #555 transparent transparent transparent

.tooltipp
  .tooltiptext
    visibility: hidden
    width: max-content
    background-color: black
    color: #fff
    text-align: center
    border-radius: 6px
    padding: 20px 10px
    position: absolute
    z-index: 1
    bottom: 30px
    left: 0

  &:hover .tooltiptext
    visibility: visible

span.sconto
  position: relative

  &:before
    content: ''
    position: absolute
    width: 100%
    height: 2px
    background: #6d6d6d
    bottom: 7px
    transform: rotate(45deg)
    left: -2px

.colore i
  margin-right: 10px
  color: #000
  vertical-align: middle

.table-elenco-r
  margin-top: 20px

span.type
  color: #000
  background: #f4f5f8
  padding: 4px 10px
  border-radius: 5px
  font-size: 12px
  font-weight: 600
  margin: 8px 2px
  display: inline-block

span.type img
  margin-right: 5px
  width: 15px
  vertical-align: text-top
  filter: invert(100%)

span.type i
  color: #000
  margin-right: 5px



p.colore
  font-size: 14px
  display: block

.condivisoR2W
  position: absolute
  right: -10px
  top: -20px
  background: #000
  height: 37px
  text-align: center
  display: flex
  border-bottom-left-radius: 20px
  padding: 0 10px
  width: max-content

  span
    line-height: 18px
    padding: 10px
    word-break: break-word
    z-index: 1

    text-transform: uppercase
    font-size: 12px
    color: #fff

  i
    font-size: 16px
    color: #fff
    margin-right: 5px
    vertical-align: text-bottom

a.bottoneA
  border-top-right-radius: 0
  border-bottom-right-radius: 0
  background: #f3f5f8
  border-top-left-radius: 30px
  border-bottom-left-radius: 30px
  color: black

a.bottoneB
  border-top-left-radius: 0
  border-bottom-left-radius: 0
  background: black
  border-color: black
  color: #fff
  border-top-right-radius: 30px
  border-bottom-right-radius: 30px

.bottoni-dettagli
  width: 100%
  display: flex
  align-items: end
  height: 100%
  justify-content: flex-end

  button
    border: none
    padding: 10px 30px

    &.btn
      &.btn-left
        border-top-right-radius: 0
        border-bottom-right-radius: 0
        background: #f3f5f8
        border-top-left-radius: 30px
        border-bottom-left-radius: 30px
        color: black

      &.btn-primary
        border-top-left-radius: 0
        border-bottom-left-radius: 0
        background: black
        border-color: black
        color: #fff
        border-top-right-radius: 30px
        border-bottom-right-radius: 30px

        &:active
          background: #031d6d
          color: white
          opacity: .8

  a, button.btn.btn-secondary
    font-weight: 700
    width: 100%
    text-transform: uppercase
    box-shadow: none
    font-size: 11px !important
    text-align: center
    padding: 10px
    align-self: flex-end


  button.btn.btn-primary:hover
    color: #fff

.select2-container--default.select2-container--focus .select2-selection--multiple
  outline: 0
  font-size: 12px
  font-weight: 400
  line-height: 15px
  display: block
  width: 100%
  padding: 4px 10px 10px 10px
  transition: all 0.15s cubic-bezier(0.68, -0.55, 0.265, 1.55)
  color: #8898aa
  border: 1px solid #dee2e6
  border-radius: 30px !important
  background-color: #ffffff
  background-clip: padding-box
  box-shadow: 0 0px 10px rgb(148 171 222 / 30%)

  height: 45px !important
  overflow-y: hidden

.add-img
  background: #f3f3f3
  width: 100%
  height: 100%
  position: relative
  display: flex

  i
    bottom: 0
    top: 0
    background: none
    margin-top: auto
    margin-bottom: auto
    left: 0 !important
    right: 0 !important
    margin-right: auto !important
    margin-left: auto !important
    position: relative
    text-align: center
    margin: 0 auto
    display: flex
    font-size: 30px
    line-height: 60px
    color: rgb(0 0 0 / 15%)

.temp-emailbox-search
  position: relative
  top: auto
  right: auto
  float: right

.card-elenco
  h1
    color: #fff !important
    font-size: 16px
    color: #e94176 !important

  border-radius: 20px

  .border-bottom-ab:before
    background: #543885
    height: 2px

.img-elenco-
  float: left
  margin-right: 10px

  img
    height: 45px
    width: 45px
    border-radius: 3px
    object-fit: cover
    background: #f3f5f8
    vertical-align: middle
    margin-right: 5px

input
  &[type=checkbox]
    &.switch
      width: 38px
      border-radius: 11px

    --active:#275EFE

    --active-inner:#fff

    --focus:2px rgba(39, 94, 254, 0.3)

    --border:#BBC1E1

    --border-hover:#275EFE

    --background:#fff

    --disabled:#F6F8FF

    --disabled-inner:#E1E6F9
    -webkit-appearance: none
    -moz-appearance: none
    height: 21px
    outline: none
    display: inline-block
    vertical-align: top
    position: relative
    margin: 0
    cursor: pointer
    border: 1px solid var(--bc, var(--border))
    background: var(--b, var(--background))
    transition: background 0.3s, border-color 0.3s, box-shadow 0.2s

  &[type=radio]
    --active:#275EFE

    --active-inner:#fff

    --focus:2px rgba(39, 94, 254, 0.3)

    --border:#BBC1E1

    --border-hover:#275EFE

    --background:#fff

    --disabled:#F6F8FF

    --disabled-inner:#E1E6F9
    -webkit-appearance: none
    -moz-appearance: none
    height: 21px
    outline: none
    display: inline-block
    vertical-align: top
    position: relative
    margin: 0
    cursor: pointer
    border: 1px solid var(--bc, var(--border))
    background: var(--b, var(--background))
    transition: background 0.3s, border-color 0.3s, box-shadow 0.2s

  &[type=checkbox]
    &.switch:after
      left: 2px
      top: 2px
      border-radius: 50%
      width: 15px
      height: 15px
      background: var(--ab, var(--border))
      transform: translateX(var(--x, 0))

    &:after
      content: ""
      display: block
      left: 0
      top: 0
      position: absolute
      transition: transform var(--d-t, 0.3s) var(--d-t-e, ease), opacity var(--d-o, 0.2s)

  &[type=radio]:after
    content: ""
    display: block
    left: 0
    top: 0
    position: absolute
    transition: transform var(--d-t, 0.3s) var(--d-t-e, ease), opacity var(--d-o, 0.2s)

  &[type=checkbox]
    &.switch
      &:checked
        --ab:var(--active-inner)

        --x:17px

      width: 38px
      border-radius: 11px

    &:checked
      --b:#031d6d

      --bc:#031d6d

      --d-o:.3s

      --d-t:.6s

      --d-t-e:cubic-bezier(0.2, 0.85, 0.32, 1.2)

  &[type=radio]:checked
    --b:#031d6d

    --bc:#031d6d

    --d-o:.3s

    --d-t:.6s

    --d-t-e:cubic-bezier(0.2, 0.85, 0.32, 1.2)

.dropdown.bootstrap-select
  &.form-control
    background: none
    box-shadow: none
    margin-bottom: 15px

    button
      background: #fff
      font-weight: 400
      line-height: 15px
      display: block
      width: 100%
      padding: 12px 20px 10px 10px
      color: #000000
      border: 1px solid #000000
      border-radius: 5px
      background-clip: padding-box
      box-shadow: none
      height: 40px !important

  &.show-tick.form-control button:focus
    outline: none !important

.bs-searchbox input
  height: auto !important
  padding: 8px

.form-flex
  display: flex
  gap: 10px



label.giac-label
  font-weight: 500
  margin-right: 10px
  margin-bottom: 0

  color: #8898aa
  font-size: 12px

.giac-input
  background: white
  padding: 5px
  border-radius: 5px
  text-align: center
  border: 1px solid #000
  height: 40px


  label

  input
    vertical-align: middle




.btn2
  border-radius: 0 !important
  background: #fff
  border: 1px solid #000




.azioni-mod
  margin: 30px 0


  button
    margin: 0 7px
    padding: 10px 40px

.qta-t.table-elenco-r.classe-brand thead
  background:#000

.tot-cart
  background: #e8e8e8

button.btn.btn-cart
  background: #031d6d
  width: 100%
  border-radius: 0
  padding: 15px
  color: #fff
  text-transform: uppercase
  font-weight: 700
  font-size: 13px !important



.cart-box

  display: table
  width: 100%

.riepilogo-container
  display: inline-table
  width: 100%

.riepilogo
  img
    float: left
    margin: 0px 15px 10px 0px

  width: 70%
  float: left

.price-cart
  width: 30%
  float: left
  text-align: right

.r-cart
  margin-bottom: 15px
  margin-top: 10px

.riepilogo p
  margin: 0

.img-cart
  width: 50px
  height: 50px
  display: block
  float: left
  margin-right: 10px

.dettagli-arti i , .details i
  background: black
  height: 25px
  width: 25px
  text-align: center
  line-height: 25px
  border-radius: 3px
  color: white
  font-size: 11px

.classe-retailer .main-img i
  color: #fff !important

select
  border: 1px solid #ccc
  padding: 5px
  border-radius: 5px

  &:focus
    outline: none

ul.albero
  list-style: none
  padding-left: 20px

  input
    -webkit-appearance: auto

  label
    margin-bottom: 5px

.conferma-mond
  background: #fff
  padding: 10px
  border-radius: 4px

  i
    font-size: 20px
    margin: 3px

.modal-qta
  input
    margin-bottom: 0px
    box-shadow: none
    width: 50px
    border: none
    border-radius: 5px
    text-align: center

    &:focus
      outline: none

  img
    width: 100px
    height: 100px
    float: left
    margin-right: 10px

  h3
    margin-bottom: 0px
    text-transform: uppercase


.carrello-doppio
  display: inline-flex

.dett-art-mkeyframesod
  margin: 20px 20px
  background: #f5f5f5
  padding: 20px
  border-radius: 10px

.ret-magazzino td
  background: #031d6d !important
  color: #fff !important

.mat-tab-label
  opacity: 1 !important
  color: #000
  text-transform: uppercase
  font-family: 'AMPermanent'
  width: 100%
  height: 60px !important

.mat-tab-label-active
  background: #eef0f1

.box.utente-img
  display: inline-block
  width: 100%

.utente-img img
  width: 120px
  height: 120px
  border-radius: 8% !important
  background: #f3f5f8
  object-fit: cover
  margin-right: 20px

.utente-img h4
  text-transform: uppercase

.modifica-img
  position: absolute
  left: 15px
  background: #000000
  border-radius: 6px
  color: #ffffff
  font-size: 12px !important
  width: 25px
  height: 25px
  text-align: center
  line-height: 13px
  bottom: 16px
  top: auto

.box.utente-img span
  color: #031d6d
  text-transform: uppercase
  position: relative
  z-index: 0
  padding: 0 3px 0 2px
  font-weight: 600


.organizzazione img
  width: 90px
  border-radius: 8%
  height: 90px
  object-fit: cover
  background: #f3f5f8
  box-shadow: 0 0 10px rgb(0 0 0 / 11%)
  margin-top: 30px


.img-org.organizzazione
  margin-top: -90px
  text-align: center

.organizzazione
  margin: 0 auto
  display: block


.org-name
  text-align: center

.org-box
  position: relative
  display: inline-block
  margin-bottom: 25px


.org-box i
  position: absolute
  width: 25px
  height: 25px
  font-size: 12px
  line-height: 24px
  color: white
  border-radius: 50%
  left: 60px
  bottom: 60px
  cursor: pointer
  text-align: center


.organizzazione
  position: relative
  margin-top: -70px

.org-box i
  background: #031d6d

.org-box i
  background: var(--3)

.organizzazione span
  color: var(--3)
  letter-spacing: 0
  text-transform: initial
  display: block
  cursor: pointer
  font-size: 12px

.organizzazione span
  color: #000


.organizzazione h5
  text-transform: uppercase
  float: right
  margin-top: 60px
  margin-left: 20px
  font-size: 20px

.socialProfile
  position: relative
  border-radius: 0
  background: transparent
  margin: 10px 0

.socialProfile i
  font-size: 13px
  background: #e4e9f1
  margin-right: 5px
  width: 30px
  height: 30px
  text-align: center
  line-height: 30px
  color: black
  border-radius: 3px


app-item-list-brand
  flex-basis: 49%

.contB
  margin-top: 20px
  display: flex
  flex-wrap: wrap
  gap: 20px

.socialP
  position: relative
  width: max-content
  border-radius: 0
  margin: 15px 0
  background: none


.socialP i
  background: #f3f5f8
  border-radius: 3px
  height: 30px
  width: 30px
  text-align: center
  line-height: 22px
  font-size: 16px

.mat-tab-label
  font-family: "AMPermanent", sans-serif

.table-mc-light-blue th
  background: #0d1c6c !important
  letter-spacing: 0 !important
  color: #fff !important

.table-mc-light-blue th b
  color: white


.table-mc-light-blue
  text-align: left

.modal-body .modal4
  background: #f4f5f8
  padding: 20px
  text-align: center
  text-transform: uppercase
  font-weight: 600
  margin-bottom: 20px

.zig-zag
  height: 10px
  width: 100%
  background: linear-gradient(135deg, white 35%, transparent 25%) -25px 0, linear-gradient(225deg, white 35%, transparent 25%) -25px 0, linear-gradient(315deg, white 35%, transparent 25%), linear-gradient(45deg, white 35%, transparent 25%)
  background-size: 10px 10px
  background-color: black
  margin-top: -10px

.box-dati-dett h4
  padding-bottom: 10px

.box-dati-dett .zig-zag
  margin-bottom: 10px

.mat-tab-group.mat-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary .mat-ink-bar
  background-color: #000000

.boxBorder
  background: transparent
  border: 1px solid #000
  box-shadow: none
